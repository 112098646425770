<template>
  <div class="my-form">
    <Form :model="formData" :label-width="100">
    <Row>
        <Col span="10">
          <div class="form-title">基础信息</div>
          <FormItem label="姓名">
            <Input v-model="formData.DealerInfo.Name" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="电话(手机)">
            <Input v-model="formData.DealerInfo.Tel" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="密码">
            <Input v-model="formData.DealerInfo.Password" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="固定电话">
            <Input v-model="formData.DealerInfo.ContactTel" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="维护专员">
            <Input v-model="formData.DealerInfo.Broker" style="width:500px" placeholder=""></Input>
          </FormItem>
        </Col>
    </Row>

      <FormItem>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
      <Spin size="large" fix v-if="spinShow"></Spin>

    </Form>
  </div>
</template>


<script>
export default {

  data() {
    return {
      spinShow: false,
      imgFile:'',
      imgviewmodal: false,
      currentImgurl: "",
      StoreHeadImg_uploadUrl:'',
      StoreDetailImg_uploadUrl:'',
      formData: {
        DealerInfo: {
          ID: 0,
          Name: "",
          Tel: "",
          DealerName:"",
          AutoPreCode: "",
          ContactTel: "",
          DealerAddr: "",
          Password: "",
          Broker: "",
          Level: "1",
          ParentId: 0,
          ParentDealerDesc: "",
          CurrentInCreaseNum: 0,
          TotalInCreaseNum: 0,
          WXOpenId: "",
          _CreateBy: 0,
          _CreateTime: "",
          _IsDelete: 0,
          _UpdateBy: 0,
          _UpdateTime: ""
        },
      }
    };
  },
  methods: {
    BackList: function() {
      this.$router.push({ name: "dealer_childdealerlist" });
    },
    GetDealer: function(id) {
      var that = this;
      this.$http
        .get("Dealer/GetDealerWithStrore", { params: { id: id } })
        .then(res => {          
          this.formData = res.data.Data;
        })
        .then(res => {
          that.spinShow = false;
        });
    },

  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetDealer(this.$route.query.id);
  }
};
</script>

<style>
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
