<template>
  <div class="my-form">
    <div class="form-title">编辑预约单（管道清洗）
      <Icon type="edit"></Icon>
    </div>
    <Form :model="prepressData" :label-width="100">
      <FormItem label="姓名">
        <Input v-model="prepressData.CustName" style="width:500px" placeholder="输入客户姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="prepressData.CustTel" style="width:500px" placeholder="输入客户电话"></Input>
      </FormItem>
      <FormItem label="短信电话 ">
        <Input v-model="prepressData.MsgTel" style="width:500px" placeholder="用于发送试压服务进度的短信接收号码"></Input>
      </FormItem>
      <FormItem label="行政区">
        <Cascader @on-change="AddressChange" :data="AddressData" v-model="selRegionCode" :load-data="loadregions" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="安装地址">
        <Input v-model="prepressData.CustAddr" style="width:500px" placeholder="具体门牌地址"></Input>
      </FormItem>
      <FormItem label="户型">
        <div style="width:30px;float:left;">
          <Input v-model="prepressData.KitchenNum" style="width:30px"></Input>
        </div>
        <div style="width:30px;float:left;margin-left:5px;">厨</div>
        <div style="width:30px;float:left;">
          <Input v-model="prepressData.WashNum" style="width:30px"></Input>
        </div>
        <div style="width:30px;float:left;margin-left:5px;">卫</div>
      </FormItem>
      <FormItem label="安装年限">
        <Input v-model="prepressData.InstallYear" style="width:500px" placeholder="输入年限"></Input>
      </FormItem>
      <FormItem label="预约清洗日期">
        <DatePicker @on-change="DateChange" v-model="prepressData.WashDate" format="yyyy/MM/dd" type="date" placeholder="选择日期" style="width: 200px"></DatePicker>
      </FormItem>
      <!-- <FormItem label="报单号码">
        <Input v-model="prepressData.CallTel" style="width:500px" placeholder="输入报单号码"></Input>
      </FormItem> -->
      <FormItem label="备注">
        <Input v-model="prepressData.Remark" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinShow: false,
      AddressData: [],
      checkbox: {
        Identity: []
      },
      selRegionCode: [],
      prepressData: {
        AcceptTime: "",
        AgentDesc: "",
        AgentId: 0,
        CustAddr: "",
        CustName: "",
        CustTel: "",
        ID: 0,
        Identity: 0,
        MsgTel: "",
        KitchenNum: "",
        WashNum: "",
        HouseType:"",
        InstallYear:"",
        WashDate: "",
        RegionFullDesc: "",
        RegionLevel1: 430000,
        RegionLevel2: 430100,
        RegionLevel3: 430103,
        Status: 0,
        WorkerID: 0,
        DealerId:0,
        DealerName:"",
        _CreateBy: 0,
        _CreateTime: "",
        _IsDelete: 0,
        _UpdateBy: 0,
        _UpdateTime: "",
        Remark: ""
      }
    };
  },
  methods: {
    AddressChange: function(value, selectedData) {
      this.prepressData.RegionFullDesc =
        selectedData[0].label + selectedData[1].label + selectedData[2].label;

      this.prepressData.RegionLevel1 = selectedData[0].value;
      this.prepressData.RegionLevel2 = selectedData[1].value;
      this.prepressData.RegionLevel3 = selectedData[2].value;
    },
    DateChange: function(value) {
      this.prepressData.WashDate = value;
    },

    SubmitData: function() {
      var that = this;
      var url = "WashPrePress/EditWash";
      try {
        that.prepressData.WashDate =  that.prepressData.WashDate.toLocaleDateString();
      } catch (error) {}

      console.log(that.prepressData.WashDate);
      this.prepressData.HouseType=this.prepressData.KitchenNum+'厨'+this.prepressData.WashNum+'卫';
      this.$http.post(url, this.prepressData).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_washlist" });
      });
    },
    loadregions: function(item, callback) {
      item.loading = true;
      this.GetRegions(item.level + 1, item.value).then(res => {
        item.children = PushAddress(res.data);
        item.loading = false;
        callback();
      });
    },
    GetRegions: function(level, regionID) {
      let that = this;
      return that.$http.get("Region/GetRegionByLevel", {
        params: {
          level: level,
          regionID: regionID
        }
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=5").then(res => {
        that.checkbox.Identity = res.data;
      });
    },
    GetPrePress: function(id) {
      var that = this;
      this.$http
        .get("WashPrePress/GetWashPrePress", { params: { id: id } })
        .then(res => {
          this.prepressData = res.data.Data.prepress;

          var houseType=this.prepressData.HouseType;
          var washNum=houseType.substring(houseType.lastIndexOf("厨")+1,houseType.lastIndexOf("卫"));
          var kitchenNum=houseType.substring(0,houseType.lastIndexOf("厨"));
          this.prepressData.WashNum=washNum;
          this.prepressData.KitchenNum=kitchenNum;
          
          this.selRegionCode = [];
          this.selRegionCode.push(res.data.Data.prepress.RegionLevel1);
          this.selRegionCode.push(res.data.Data.prepress.RegionLevel2);
          this.selRegionCode.push(res.data.Data.prepress.RegionLevel3);
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    BackList: function() {
      this.$http.SetConditions();
      this.$router.go(-1);
    }
  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetCheckbox();
    this.GetPrePress(this.$route.query.id);
    this.GetRegions(1, "").then(res => {
      this.AddressData = PushAddress(res.data);
    });
  }
};

//地址数组填充
function PushAddress(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 3) {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID
      });
    }
  });
  return regionList;
}
</script>

<style>
</style>
