<template>
  <div class="listform">
    <div class="form-title">商品列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="success" @click="AddGoods" icon="ios-document">新建</Button>
      <Button type="primary" @click="ToCategoryList" icon="ios-document">商品类别</Button>
    </div>
    <div class="radio-group">
      <RadioGroup v-model="currentStatus" @on-change="StatusChange" type="button">
        <Radio label="">全部</Radio>
        <Radio label="0">下架</Radio>
        <Radio label="1">上架</Radio>
      </RadioGroup>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentStatus: "",
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "GoodsName", label: "商品名" },
        { value: "Unit", label: "单位" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "商品名",
            key: "GoodsName",
            align: "center",
            tooltip: true
          },
          {
            title: "单位",
            key: "Unit",
            align: "center",
            tooltip: true
          },
          {
            title: "单价",
            key: "UnitPrice",
            align: "center",
            tooltip: true
          },
          {
            title: "品类",
            key: "CategoryDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "库存",
            key: "TotalStock",
            align: "center",
            tooltip: true
          },
          {
            title: "是否上架",
            key: "Status",

            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 1 && "success") || "error";
              const text = (row.Status == 1 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "商品图片",
            key: "GoodsImg",
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color = (row.GoodsImg.length > 0 && "success") || "error";
              const text = (row.GoodsImg.length > 0 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "创建时间",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            width: 180,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditGoods(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.GoodsSku(params.row);
                      }
                    }
                  },
                  "商品规格"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    AddGoods: function(params) {
      this.$router.push({ name: "dealer_goodsoption" });
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    EditGoods: function(params) {
      this.$router.push({
        name: "dealer_goodsoption",
        query: { id: params.ID }
      });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_goodslist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Goods/GetGoodsPageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort,
            status: that.currentStatus 
            }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    ToCategoryList:function(){
      this.$router.push({name:"dealer_categorylist"});
    },
    GoodsSku: function(params) {
      this.$router.push({
        name: "goods_sku",
        query: { id: params.ID }
      });
    },
    StatusChange: function(e) {
      this.currentStatus = e;
      this.table.pageIndex = 1;
      if (e == "0") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 180,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditGoods(params.row);
                      }
                    }
                  },
                  "编辑"
                )
              ]);
          }
        });
      } else if (e == "1") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 180,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditGoods(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.GoodsSku(params.row);
                      }
                    }
                  },
                  "商品规格"
                )
              ]);
          }
        });
      }
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 