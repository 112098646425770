
<template>
  <div class="my-form">
    <div class="form-title">新建经销商
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="120">
      <FormItem label="是否系统内经销商">
        <RadioGroup v-model="postdata.Level">
          <Radio label="1">是</Radio>
          <Radio label="0">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="店名">
        <Input v-model="postdata.DealerName" style="width:500px" placeholder="请填写店名"></Input>
      </FormItem>
      <FormItem label="姓名 ">
        <Input v-model="postdata.Name" style="width:500px" placeholder="请填写姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="postdata.Tel" style="width:500px" placeholder="请填写电话"></Input>
      </FormItem>
      <FormItem label="密码 ">
        <Input v-model="postdata.Password" style="width:500px" placeholder="请填写密码"></Input>
      </FormItem>
      <FormItem label="固定电话 ">
        <Input v-model="postdata.ContactTel" style="width:500px" placeholder="请填写电话"></Input>
      </FormItem>
      <FormItem label="系统内经销商" v-if="postdata.Level!='1'">
        <Input search @on-search="ModalShow"  v-model="postdata.ParentDealerDesc" style="width:500px" placeholder="请点击右侧图标选择上级经销商" enter-button></Input>
      </FormItem>
      <FormItem label="维护人员">
        <Input v-model="postdata.Broker" style="width:500px" placeholder="维护人姓名"></Input>
      </FormItem>
      <FormItem label="详细地址">
        <Input v-model="postdata.DealerAddr" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="详细地址填写"></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Modal title="选择经销商" @on-cancel="ModalCancel" width="1000" height="600" :footer-hide="true" v-model="modal.show">
      <dealer-RowSel v-if="modal.show" v-on:rowsel="ModalRowSel"></dealer-RowSel>
    </Modal>
    <Spin size="large" fix v-if="false"></Spin>
  </div>
</template>
<script>
  import dealerSel from "../../components/public_component/dealerSel";
export default {  
  data() {
    return {
      postdata: {
        Name: "",
        Tel: "",
        AutoPreCode: "",
        ContactTel: "",
        DealerAddr: "",
        Password: "",
        Broker: "",
        Level: "1",
        ParentId: 0,
        ParentDealerDesc: "",
        CurrentInCreaseNum: 0,
        TotalInCreaseNum: 0,
        WXOpenId: ""
      },
      modal: {
        show: false
      }
    };
  },
  components: {
    "dealer-RowSel": dealerSel
  },
  methods: {
    ModalShow: function(e) {
      var that=this;
      var conditions = [];
      conditions.push({
        PropertyName:'Level',
        Operater: 2,
        LogicOperater:0,
        Value: 1
      });
      this.$http.SetConditions(conditions);
      this.modal.show = true;
    },
    SubmitData: function(e) {
      if (this.postdata.Level == "1") {
        this.postdata.ParentId = 0;
        this.postdata.ParentDealerDesc = "无";
      }
      this.$http.post("Dealer/AddDealer", this.postdata).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_childdealerlist" });
      });
    },
    BackList: function() {
      this.$router.push({ name: "dealer_childdealerlist" });
    },
    ModalRowSel: function(item) {
      this.postdata.ParentId=item.ID;
      this.postdata.ParentDealerDesc=item.Name;
      this.modal.show=false;
      this.ModalCancel();
    },
    ModalCancel: function() {
      this.$http.SetConditions();
    }
  }
};
</script>

<style>
</style>
