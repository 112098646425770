<template>
  <div class="my-form">
    <div class="form-title">编辑预约单
      <Icon type="edit"></Icon>
    </div>
    <Form :model="formData" :label-width="100">
      <Row>
        <Col span="10">
      <div class="form-title">基础信息</div>
      <FormItem label="姓名">
        <Input v-model="formData.PrePressInfo.CustName" style="width:500px" placeholder="输入客户姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="formData.PrePressInfo.CustTel" style="width:500px" placeholder="输入客户电话"></Input>
      </FormItem>
      <FormItem label="短信电话 ">
        <Input v-model="formData.PrePressInfo.MsgTel" style="width:500px" placeholder="用于发送试压服务进度的短信接收号码"></Input>
      </FormItem>
      <!-- <FormItem label="身份">
        <RadioGroup v-model="prepressData.Identity">
          <Radio v-for="item in checkbox.BuyerIdentity" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem> -->
      <FormItem label="管材类型">
        <RadioGroup v-model="formData.PrePressInfo.PipeTypeID" @on-change="PipeTypeChange">
          <Radio v-for="item in checkbox.PipeLineType" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否家装单">
        <RadioGroup v-model="formData.PrePressInfo.IsCompany" @on-change="RadioChange">
          <Radio :label="0">否</Radio>
          <Radio :label="1">是</Radio>
        </RadioGroup>
      </FormItem>
      <div v-show="showCompany">
        <FormItem label="家装公司">
          <RadioGroup v-model="formData.PrePressInfo.CompanyID">
            <Radio v-for="item in checkbox.CompanyType" :label="item.ID">{{item.Name}}</Radio>
          </RadioGroup>
        </FormItem>
      </div> 
      <div v-show="showChargeAmount">
         <FormItem label="收费">
          <Input v-model="formData.PrePressInfo.ChargeAmount" style="width:500px" placeholder="收费请输入大于0的数字"></Input>
        </FormItem>
      </div>
      <FormItem label="行政区">
        <Cascader @on-change="AddressChange" :data="AddressData" v-model="selRegionCode" :load-data="loadregions" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="安装地址">
        <Input v-model="formData.PrePressInfo.CustAddr" style="width:500px" placeholder="具体门牌地址" @on-change="CheckRepeat"></Input>
      </FormItem>
      <FormItem label="预约试压日期">
        <DatePicker @on-change="DateChange" v-model="formData.PrePressInfo.PressureStartTime" format="yyyy/MM/dd" type="date" placeholder="选择日期" style="width: 200px"></DatePicker>
      </FormItem>
      <FormItem label="预约时间选择">
        <RadioGroup v-model="formData.PrePressInfo.HalfDay" @on-change="RadioChange">
          <Radio key="上午" label="上午">上午</Radio>
          <Radio key="下午" label="下午">下午</Radio>
          <Radio key="上午/下午" label="上午/下午">上午/下午</Radio>
        </RadioGroup>
      </FormItem>
      <!-- <FormItem label="预约时间选择">
        <CheckboxGroup v-model="formData.PrePressInfo.HalfDay">
            <Checkbox key="上午" label="上午">上午</Checkbox>
            <Checkbox key="下午" label="下午">下午</Checkbox>
        </CheckboxGroup>
      </FormItem> -->
      <div v-show="showTime">
        <FormItem label="具体时间">
          <TimePicker v-model="formData.PrePressInfo.DetailTime" format="HH:mm" placeholder="Select time" style="width: 120px" />
        </FormItem>
      </div>
      <FormItem label="报单号码">
        <Input v-model="formData.PrePressInfo.CallTel" style="width:500px" placeholder="输入报单号码"></Input>
      </FormItem>
      <FormItem label="备注">
        <Input v-model="formData.PrePressInfo.Remark" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="Enter something..."></Input>
      </FormItem>
      </Col>
      <Col span="12">
      <div class="form-title">地图标点信息</div>
      <FormItem label="地址">
        <Input v-model="formData.PrePressMark.Title" style="width:500px" placeholder=""></Input>
        <div style="padding:10px">
          <iframe id="mapPage" width="100%" height="500px" frameborder=0
              src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ZZNBZ-A4X3S-FUNOJ-6FBLD-3NCSH-T4BPK&referer=myapp">
          </iframe>
        </div>
      </FormItem>
      </Col>
      </Row>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinShow: false,
      AddressData: [],
      showChargeAmount: true,
      showTime: false,
      showCompany: false,
      checkbox: {
        PipeLineType: [],
        BuyerIdentity: [],
        CompanyType: [],
      },
      selRegionCode: [],
      formData: {
        PrePressInfo: {
          AcceptTime: "",
          AgentDesc: "",
          AgentId: 0,
          CreateTime: "",
          CustAddr: "",
          CustAddrNum: "",
          CustName: "",
          CustSrvID: 0,
          CustTel: "",
          ID: 0,
          Identity: 0,
          LastEditTime: "",
          MsgTel: "",
          PipeTypeID: 0,
          PreSaleCode: "",
          PreSaleId: 0,
          PressureEndTime: "",
          PressureStartTime: "",
          RefuseReason: "",
          RefuseTime: "",
          RegionFullDesc: "",
          RegionLevel1: 430000,
          RegionLevel2: 430100,
          RegionLevel3: 430103,
          Status: 0,
          WorkerID: 0,
          _CreateBy: 0,
          _CreateTime: "",
          _IsDelete: 0,
          _UpdateBy: 0,
          _UpdateTime: "",
          ChargeAmount: 0,
          CallTel: "",
          Remark: "",
          IsCompany: 0,
          DetailTime: "",
          CompanyID:0,
          HalfDay: ""
        },
        PrePressMark:{
          PrePressId:"",
          Title:"",
          Lat:"",
          Lgt:"",
          RegionLevel1: 430000,
          RegionLevel2: 430100,
          RegionLevel3: 430103,
          _CreateBy: 0,
          _CreateTime: "",
          _IsDelete: 0,
          _UpdateBy: 0,
          _UpdateTime: ""
        }
      },
      // prepressData: {
      //   AcceptTime: "",
      //   AgentDesc: "",
      //   AgentId: 0,
      //   CreateTime: "",
      //   CustAddr: "",
      //   CustAddrNum: "",
      //   CustName: "",
      //   CustSrvID: 0,
      //   CustTel: "",
      //   ID: 0,
      //   Identity: 0,
      //   LastEditTime: "",
      //   MsgTel: "",
      //   PipeTypeID: 0,
      //   PreSaleCode: "",
      //   PreSaleId: 0,
      //   PressureEndTime: "",
      //   PressureStartTime: "",
      //   RefuseReason: "",
      //   RefuseTime: "",
      //   RegionFullDesc: "",
      //   RegionLevel1: 430000,
      //   RegionLevel2: 430100,
      //   RegionLevel3: 430103,
      //   Status: 0,
      //   WorkerID: 0,
      //   _CreateBy: 0,
      //   _CreateTime: "",
      //   _IsDelete: 0,
      //   _UpdateBy: 0,
      //   _UpdateTime: "",
      //   ChargeAmount: 0,
      //   CallTel: "",
      //   Remark: "",
      //   IsCompany: 0,
      //   DetailTime: ""
      // }
    };
  },
  methods: {
    AddressChange: function(value, selectedData) {
      // this.postdata.selRegionCode = selectedData;
      this.formData.PrePressInfo.RegionFullDesc =
        selectedData[0].label + selectedData[1].label + selectedData[2].label;
      this.formData.PrePressInfo.CustAddrNum =
        selectedData[0].value +
        "," +
        selectedData[1].value +
        "," +
        selectedData[2].value;
      this.formData.PrePressInfo.RegionLevel1 = selectedData[0].value;
      this.formData.PrePressInfo.RegionLevel2 = selectedData[1].value;
      this.formData.PrePressInfo.RegionLevel3 = selectedData[2].value;
    },
    DateChange: function(value) {
      this.formData.PrePressInfo.PressureStartTime = value;
      this.formData.PrePressInfo.PressureEndTime = value;
    },
    SetLoc:function (v) {
      this.formData.PrePressMark.Lat=v.latlng.lat; //经度
      this.formData.PrePressMark.Lgt=v.latlng.lng; //纬度
      this.formData.PrePressMark.Title=v.poiname;    
    },

    // SubmitData: function() {
    //   var that = this;
    //   var url = "PrePress/EditPrePress";
    //   try {
    //     that.prepressData.PressureEndTime = that.prepressData.PressureStartTime = that.prepressData.PressureStartTime.toLocaleDateString();
    //   } catch (error) {}

    //   console.log(that.prepressData.PressureStartTime);
    //   this.$http.post(url, that.prepressData).then(res => {
    //     var modaltitle = "";
    //     if (res.data != "success") {
    //       modaltitle = res.data;
    //     } else {
    //       modaltitle = "提交成功！";
    //     }
    //     this.$Modal.info({
    //       title: "提示",
    //       content: modaltitle
    //     });
    //     this.$router.push({ name: "dealer_prepresslist" });
    //   });
    // },
    SubmitData: function() {
      var that = this;
      var url = "PrePress/EditPrepress";
      try {
        that.formData.PrePressInfo.PressureEndTime = that.formData.PrePressInfo.PressureStartTime = that.formData.PrePressInfo.PressureStartTime.toLocaleDateString();
      } catch (error) {}

      console.log(that.formData.PrePressInfo.PressureStartTime);
      // var halfdays = "";
      // if(that.formData.PrePressInfo.HalfDay!=null)
      // {
      //   for (let i = 0; i < that.formData.PrePressInfo.HalfDay.length; i++) {
      //     if(that.formData.PrePressInfo.HalfDay[i]!=null)
      //     {
      //       halfdays += that.formData.PrePressInfo.HalfDay[i] + ",";
      //     }
      //   }
      //   that.formData.PrePressInfo.HalfDay = halfdays.slice(0, halfdays.length-1);
      // } else {
      //   this.$Modal.warning({
      //     title: "提示",
      //     content: "请选择预约时间选择"
      //   });
      //   return false;
      // }
      if(that.formData.PrePressInfo.HalfDay==null||that.formData.PrePressInfo.HalfDay==""){
        this.$Modal.warning({
          title: "提示",
          content: "请选择预约时间选择"
        });
        return false;
      }
      
      console.log(that.formData.PrePressInfo.HalfDay);
      this.$http.post(url, that.formData).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_prepresslist" });
      });
    },
    loadregions: function(item, callback) {
      item.loading = true;
      this.GetRegions(item.level + 1, item.value).then(res => {
        item.children = PushAddress(res.data);
        item.loading = false;
        callback();
      });
    },
    GetRegions: function(level, regionID) {
      let that = this;
      return that.$http.get("Region/GetRegionByLevel", {
        params: {
          level: level,
          regionID: regionID
        }
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=5").then(res => {
        that.checkbox.BuyerIdentity = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=4").then(res => {
        that.checkbox.PipeLineType = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=33").then(res => {
        that.checkbox.CompanyType = res.data;
      });
    },
    // GetPrePress: function(id) {
    //   var that = this;
    //   this.$http
    //     .get("PrePress/GetPrePress", { params: { id: id } })
    //     .then(res => {
    //       this.prepressData = res.data.Data.prepress;
    //       if(this.prepressData.IsCompany==1){
    //         this.showTime=true; 
    //       }
    //       this.selRegionCode = [];
    //       this.selRegionCode.push(res.data.Data.prepress.RegionLevel1);
    //       this.selRegionCode.push(res.data.Data.prepress.RegionLevel2);
    //       this.selRegionCode.push(res.data.Data.prepress.RegionLevel3);
    //     })
    //     .then(res => {
    //       that.spinShow = false;
    //     });
    // },
    GetPrePress: function(id) {
      var that = this;
      this.$http
        .get("PrePress/GetPrePressWithMark", { params: { id: id } })
        .then(res => {
          this.formData = res.data.Data;
          if(this.formData.PrePressInfo.IsCompany == 1){
            this.showTime = true; 
            this.showCompany = true; 
          }
          this.selRegionCode = [];
          this.selRegionCode.push(res.data.Data.PrePressInfo.RegionLevel1);
          this.selRegionCode.push(res.data.Data.PrePressInfo.RegionLevel2);
          this.selRegionCode.push(res.data.Data.PrePressInfo.RegionLevel3);
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    PipeTypeChange: function(value) {   //选择管道类型改变事件
      if(value == 13){                  //皓白系列
          this.showChargeAmount = true; 
      }else{
          this.showChargeAmount = false; 
          this.formData.PrePressInfo.ChargeAmount=0;
      }
    },
    RadioChange: function(value) {
      if(value == 1){
        this.showTime = true;
        this.showCompany = true; 
      }else{
        this.showTime = false; 
        this.formData.PrePressInfo.DetailTime = "";
        this.showCompany = false; 
        this.formData.PrePressInfo.CompanyID = 0;
      }
    },
    BackList: function() {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    // 重复地址试压记录提示
    CheckRepeat() {
      this.$http
        .get("/Customer/CheckRepeat", {
          params: {
            custaddr: this.formData.PrePressInfo.CustAddr
          }
        }).then(res => {
          if(res.data!="success"){
            this.$Modal.info({ title: "提示", content: res.data });           
          }
      });
    }
  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetCheckbox();
    this.GetPrePress(this.$route.query.id);
    this.GetRegions(1, "").then(res => {
      this.AddressData = PushAddress(res.data);
    });

    window.addEventListener('message', function(event) {
      // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
      var loc = event.data;
      if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
        that.SetLoc(loc);
      }
    }, false);
  }
};

//地址数组填充
function PushAddress(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 3) {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID
      });
    }
  });
  return regionList;
}
</script>

<style>
</style>
