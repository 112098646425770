<template>
  <div class="listform">
    <div class="form-title">年度积分记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>      
    </div>
    <div class="search_option">
      <Button @click="SendSDWorkerMsg()" type="primary" icon="ios-clipboard">一键发送短信</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" :no-data-text="数据加载为空" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      table: {
        tablecolumsTitle: [
          {
            title: "水工id",
            key: "ID",
            align: "center",
            tooltip: true,              
          },
          {
            title: "水工姓名",
            key: "SDWorkerName",
            align: "center",
            tooltip: true,              
          },
          {
            title: "水工电话",
            key: "SDWorkerTel",
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "年度丝口积分",
            key: "Integral",
            align: "center",
            tooltip: true
          },
          {
            title: "应奖励积分",
            key: "RewardIntegral",
            align: "center",
            tooltip: true
          }
        ],
        datalist: [],
        // pageIndex: 1,
        // total: 0,
        // pageSize: 1,
        loading: true,
        // sort:" "
      }
    };
  },
  methods: {
    // PageChange: function(e) {
    //   this.table.pageIndex = e;
    //   this.GetTableList();
    // },
    GetTableList: function() {
      // var conditions = [];
      // conditions.push({
      //   PropertyName: "SDWorkerId",
      //   Operater: 2,
      //   LogicOperater: 0,
      //   Value: this.$route.params.sdworkerid
      // });

      // this.$http.SetConditions(conditions);
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorker/YearMaterialIntegralReward", {
          params: {  }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.Data;
        });
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    SendSDWorkerMsg: function(item) {//一键发送短信
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要一键发送积分短信吗?",
        onOk: function(e) {
          that.$http
            .get("SDWorker/SendIntegralMsg", {
              params: {
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("发送成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 