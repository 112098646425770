<template>
  <div class="my-form">
    <Form :model="formData" :label-width="100">
    <Row>
        <Col span="10">
          <div class="form-title">基础信息</div>
          <FormItem label="姓名">
            <Input v-model="formData.DealerInfo.Name" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="电话(手机)">
            <Input v-model="formData.DealerInfo.Tel" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="密码">
            <Input v-model="formData.DealerInfo.Password" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="固定电话">
            <Input v-model="formData.DealerInfo.ContactTel" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="维护专员">
            <Input v-model="formData.DealerInfo.Broker" style="width:500px" placeholder=""></Input>
          </FormItem>
        </Col>
        <Col span="14">
          <div class="form-title">门店信息</div>
          <FormItem label="店名">
            <Input v-model="formData.DealerInfo.DealerName" style="width:500px" placeholder=""></Input>
          </FormItem>

          <FormItem label="门店电话">
            <Input v-model="formData.DealerStore.StoreTel" style="width:500px" placeholder=""></Input>
          </FormItem>
          <FormItem label="门店类型">
                <Select v-model="formData.DealerStore.StoreType" style="width:200px">
                  <Option :value="1" >专卖店</Option>
                  <Option :value="2" >授权店</Option>
                  <Option :value="3" >在建店</Option>
                </Select>
          </FormItem>
          <FormItem label="门店标签">
              <Input @on-search="addTag"  search enter-button="添加" placeholder="输入一个标签名" />              
              <Tag :key="idx"  :name="idx" size="large" closable  @on-close="removeTag" v-for="(item,idx) in splitedList(formData.DealerStore.StoreTag)">
                {{item}}</Tag>              
          </FormItem>
          <FormItem label="营业时间">
            <Input v-model="formData.DealerStore.OpenTime" style="width:500px" placeholder="9:00"></Input>
            <br>
            <Input v-model="formData.DealerStore.CloseTime" style="width:500px" placeholder="18:00"></Input>
          </FormItem>

          <FormItem label="门头照片">
            <div class="img-list">
              <div v-for="(item, index) in formData.DealerStoreImgs"
                v-if="item.ImgType==1"
               :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item.ImgUrl+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
                  <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(index)"></Icon>
                </div>
              </div>
            </div>
            <Upload type="drag" :format="['jpg']" :max-size="2048" 
              :with-credentials="true" :show-upload-list="true"
              :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload" :action="StoreHeadImg_uploadUrl"
              style="display: inline-block;min-width:600px;" >
              <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
                <p>将图片拖拽到此处上传</p>
              </div>
            </Upload>
          </FormItem>
          <FormItem label="详情照片">
            <div class="img-list">
              <div v-for="(item, index) in formData.DealerStoreImgs"
                v-if="item.ImgType==2"
               :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item.ImgUrl+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
                  <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(index)"></Icon>
                </div>
              </div>
            </div>
            <Upload type="drag" :format="['jpg']" :max-size="2048" 
              :with-credentials="true" :show-upload-list="true"
              :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize" :before-upload="handleDetailBeforeUpload" :action="StoreDetailImg_uploadUrl"
              style="display: inline-block;min-width:600px;">
              <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
                <p>将图片拖拽到此处上传</p>
              </div>
            </Upload>
          </FormItem>
          <FormItem label="地址">
                <Input v-model="formData.DealerInfo.DealerAddr" style="width:500px" placeholder=""></Input>
                <div style="padding:10px">
                  <iframe id="mapPage" width="100%" height="500px" frameborder=0
                      src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=ZZNBZ-A4X3S-FUNOJ-6FBLD-3NCSH-T4BPK&referer=myapp">
                  </iframe>
                </div>
          </FormItem>
        </Col>
    </Row>

      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
      <Spin size="large" fix v-if="spinShow"></Spin>

    </Form>
    <Modal v-model="imgviewmodal" width="880px">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt="">
      </div>
    </Modal>
  </div>
</template>


<script>
import oss from 'ali-oss';
export default {

  data() {
    return {
      spinShow: false,
      imgFile:'',
      imgviewmodal: false,
      currentImgurl: "",
      StoreHeadImg_uploadUrl:'',
      StoreDetailImg_uploadUrl:'',
      formData: {
        DealerInfo: {
          ID: 0,
          Name: "",
          Tel: "",
          DealerName:"",
          AutoPreCode: "",
          ContactTel: "",
          DealerAddr: "",
          Password: "",
          Broker: "",
          Level: "1",
          ParentId: 0,
          ParentDealerDesc: "",
          CurrentInCreaseNum: 0,
          TotalInCreaseNum: 0,
          WXOpenId: "",
          _CreateBy: 0,
          _CreateTime: "",
          _IsDelete: 0,
          _UpdateBy: 0,
          _UpdateTime: ""
        },
        DealerStore:{
          DealerID:"",
          StoreType:"",
          StoreTag:"a,2,3",
          Lat:"",
          Lgt:"",
          OpenTime:"",
          CloseTime:"",
          StoreTel:"",
          _CreateBy: 0,
          _CreateTime: "",
          _IsDelete: 0,
          _UpdateBy: 0,
          _UpdateTime: ""
        },
        DealerStoreImgs:[],
      }
    };
  },
  methods: {
    BackList: function() {
      this.$router.push({ name: "dealer_childdealerlist" });
    },
    SubmitData: function() {
      var that = this;
      var url = "Dealer/EditDealer";
      this.$http.post(url, that.formData).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_childdealerlist" });
      });
    },
    GetDealer: function(id) {
      var that = this;
      this.$http
        .get("Dealer/GetDealerWithStrore", { params: { id: id } })
        .then(res => {          
          this.formData = res.data.Data;
          // this.tagArray = this.splitedList(res.data.Data.DealerStore.StoreTag);
        })
        .then(res => {
          that.spinShow = false;
        });
    },

    SetLoc:function (v) {
      this.formData.DealerStore.Lat=v.latlng.lat; //经度
      this.formData.DealerStore.Lgt=v.latlng.lng; //纬度
      this.formData.DealerInfo.DealerAddr=v.poiaddress;      
    },
    //#region tag相关
    splitedList:function (str) {      
      if(str==null||str=='')  {
        return [];
      }
        return str.split(',');
    },
    addTag:function(value){
      var tag=","+value;
      if(this.formData.DealerStore.StoreTag==''){

        this.formData.DealerStore.StoreTag += value;
      }else{
        this.formData.DealerStore.StoreTag += tag;
      }
    },
    removeTag:function(event, name){      
      var oldArr= this.splitedList(this.formData.DealerStore.StoreTag);      
      oldArr.splice(name,1);
      this.formData.DealerStore.StoreTag = oldArr.join();      
    },
//#endregion

    //#region 图片相关方法    
    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item.ImgUrl;
    },
    handleRemove(idx) {
      var that = this;
      this.formData.DealerStoreImgs.splice(idx,1)      
    },
    handledetRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteGoodsDetImg", {
          params: { id: that.postdata.ID, filename: item.name }
        })
        .then(res => {
          this.$Message.info("操作成功!");
          that.GetData(that.$route.query.id);
        });
    },
    //#endregion

    //#region 上传相关
    UploadSuccess: function(response, file, fileList) {
      this.$Message.info("上传成功!");
      this.GetDealer(this.$route.query.id);
    },
    UploadError: function(error, file, fileList) {
      this.$Notice.error({
        title: "系统异常"
      });
    },
    handleBeforeUpload(file) {
      console.log('开始');
      if (this.formData.DealerStore.ID == undefined) {
        this.$Notice.warning({
          title: "提示",
          desc: "请先保存门店基础信息后上传图片！"
        });
        return false;
      }
      this.file = file;  // 将回调的文件信息存入data.file
      this.upload(1);
      return false     // 返回false，表示手动上传，取消默认的自动上传
    },
    handleDetailBeforeUpload(file) {
      console.log('开始');
      if (this.formData.DealerStore.ID == undefined) {
        this.$Notice.warning({
          title: "提示",
          desc: "请先保存门店基础信息后上传图片！"
        });
        return false;
      }
      this.file = file;  // 将回调的文件信息存入data.file
      this.upload(2);
      return false     // 返回false，表示手动上传，取消默认的自动上传
    },

     // 点击上传按钮触发上传操作
    upload(type){
        console.log('开始手动上传')
        // sdk提供的创建客户端实例方法
        const client = new oss({
            region: 'oss-cn-hangzhou',   // 创建Bucket时会选择不同地区，根据自己的选择填入对应名称
            accessKeyId: 'LTAI5tPTBs6Dvfp5Bf4t9oQp',   // 填入你的accessKeyId
            accessKeySecret: 'OITcxx2SOT3RoNprm53WuxGoEnDirE', // 填入你的accessKeySecret
            bucket: 'reaca-bucket',// 填入你的bucket名
        });
        
        const Name = this.file.name;
        const suffix = Name.substr(Name.indexOf('.'));              // 文件后缀
        const filename = Date.parse(new Date()) + suffix           // 组成新文件名
        var path ='DealerStoreImg/'+this.formData.DealerInfo.ID+"/"+filename;

        client.multipartUpload(path, this.file).then(res => {   // 上传
            console.log('上传成功：',res);
            this.formData.DealerStoreImgs.push({
              ImgType:type,
              ImgUrl:this.$file.url+path,
              StoreID:0
            })            
        }).catch(err => {
            this.$Notice.warning({
              title: "上传失败",
              desc: "请联系系统管理员"
            });
        })
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: "文件 " + file.name + " 格式错误,请选择jpg格式"
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件超出大小",
        desc: "文件: " + file.name + " 不能超过2M"
      });
    }
    //#endregion
  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetDealer(this.$route.query.id);

    window.addEventListener('message', function(event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          that.SetLoc(loc);
        }
    }, false);
  }
};
</script>

<style>
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
