<template>
  <div class="listform">
    <div class="form-title">水电清单材料列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="success" @click="AddGoods" icon="ios-document">新建</Button>
      <!-- <Button type="primary" @click="ToCategoryList" icon="ios-document">商品类别</Button> -->
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "名称" }        
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "名称",
            key: "Name",
            align: "center",
            tooltip: true
          },
          {
            title: "单位",
            key: "UnitName",
            align: "center",
            tooltip: true
          },
          {
            title: "图片",
            key: "ImgUrl",
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color = (row.ImgUrl.length > 0 && "success") || "error";
              const text = (row.ImgUrl.length > 0 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "是否上架",
            key: "_IsDelete",

            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color = (row._IsDelete == 0 && "success") || "error";
              const text = (row._IsDelete == 0 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "创建时间",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            width: 150,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditGoods(params.row);
                      }
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    AddGoods: function(params) {
      this.$router.push({ name: "dealer_sdworkermatoption" });
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    EditGoods: function(params) {
      this.$router.push({
        name: "dealer_sdworkermatoption",
        query: { id: params.ID }
      });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "sdworker_matlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorkerMatList/GetSDWorkerMatPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    ToCategoryList:function(){
      this.$router.push({name:"dealer_categorylist"});
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 