<template>
  <div style="padding: 10px;">
    <div>
      <img src="../../assets/logo3.png" fit="contain" width="100%" alt="" />
    </div>
    <Card
      :bordered="true"
      dis-hover
      v-for="(customer, customerindex) in datalist"
      :key="customerindex" style="height:300px;">
      <p slot="title">{{ customer.CustAddress }}</p>
      <div slot="extra">
        <van-button round size="mini" type="primary" v-show="customer.flag" @click="towarranty(customer.ID)">点击查看质保卡</van-button>
      </div>
      <div>
        <p>质&nbsp;保&nbsp;卡&nbsp;号: {{ customer.WarrantyNum }}</p>
        <p>客&nbsp;户&nbsp;姓&nbsp;名: {{ customer.CustName }}</p>
        <p>客&nbsp;户&nbsp;电&nbsp;话: {{ customer.CustTel }}</p>
        <p>管&nbsp;道&nbsp;类&nbsp;型: {{ customer.PipelineName }}</p>
        <p>厨&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;房: {{ customer.KitchenNum }} 个</p>
        <p>卫&nbsp;&nbsp;&nbsp;生&nbsp;&nbsp;&nbsp;间: {{ customer.WashNum }} 个</p>
        <p>过滤器数量: {{ customer.PreFilterNum }}</p>
        <p>过滤器类型: {{ customer.PreFilterName }}</p>
      </div>
      <div style="float:right;">
        <van-button size="normal" type="default" @click="toVisit(customer)">评价服务</van-button>
        <van-button size="normal" type="primary" @click="toCustomerImg(customer)" style="margin-left:10px;">点击下载管路图</van-button>
      </div>
    </Card>
  </div>
</template>

<script>
import VConsole from "vconsole";
import wx from "weixin-js-sdk";
//const vConsole = new VConsole({ theme: 'dark' });
import { Button, ImagePreview } from "vant";
export default {
  data() {
    return {
      datalist: [{ CustAddress: "", imgurl: [], img2url: [],StandardDesc:""}],
      dataobj: {},
      imgurls: [],
      img2urls: [],
      StandardDesc: "",
      base_url: this.$file.url,
      // base_url: "http://localhost:24737/",
      showViewVR: false,
      VRImgUrl: "",
      showViewDraw: false,
      resource: 0,
    };
  },
  methods: {
    GetData() {
      var that = this;
      this.$http.get("Customer/GetCustomersByTel").then((res) => {
        that.resource=1;
        that.LoadImgNew(res);
        // that.LoadImg(res);
      });
    },
    GetDataByWarrantyNum(WarrantyNum) {
      var that = this;
      this.$http
        .get("Customer/GetCustomersByWarrantyNum?warrantyNum=" + WarrantyNum)
        .then((res) => {
          that.resource=2;
          that.LoadImgNew(res);
          // that.LoadImg(res);
        });
    },
    LoadImgNew(res) {
      var that = this;
      if (res.data.Msg == "success") {
        that.datalist = res.data.ListData;
        for (let i = 0; i < res.data.ListData.length; i++) {

          // 使用电子质保卡 可查询质保卡内容
          var iswarranty = res.data.ListData[i].IsWebWarrantyNum;
          var type = res.data.ListData[i].WarrantyType.split(',');
          if(iswarranty==1 && type.length==2)
          {
            that.datalist[i].flag=true;
          }else if(iswarranty==1 && type.length==1){
            that.datalist[i].flag=true;
          }else{
            that.datalist[i].flag=false;
          }
          // if(res.data.ListData[i].WarrantyNum.indexOf("R")==0){
          //   that.datalist[i].flag=true;
          // }else if(res.data.ListData[i].WarrantyNum.indexOf("W")==0){
          //   res.data.ListData[i].flag=true;
          // }else{
          //   res.data.ListData[i].flag=false;
          // }
        }
      }
    },
    towarranty(item) {
      var that = this;
      that.$http
        .get("/Customer/GetCustomerByDi", {
          params: {
            id: item,
          },
        })
        .then((res) => {
          that.dataobj = res.data.Data;
          // var path='/pages/presale/previewred/previewred'
          var type = that.dataobj.WarrantyType.split(',');
          if(type.length==2){
            that.$router.push({
              name: "customerWarranty_Red",
              params: { id: item },
            });
          }else{
            that.$router.push({
              name: "customerWarranty_White",
              params: { id: item },
            });
          }
          // 双重质保
          // if (that.dataobj.WarrantyNum.indexOf("R") == 0) {
            
          //   that.$router.push({
          //     name: "customerWarranty_Red",
          //     params: { id: item },
          //   });
          // }
          // // 产品质保
          // if (that.dataobj.WarrantyNum.indexOf("W") == 0) {
          //   // path='/pages/presale/previewwhite/previewwhite';
          //   that.$router.push({
          //     name: "customerWarranty_White",
          //     params: { id: item },
          //   });
          // }
          // that.GetMiniProgramLink(path,encodeURIComponent(`customerid=${item}&isCust=1`));
        });
    },
    toVisit(customer) {
      var that = this;
      that.$router.push({
        name: "customerVisit",
        params: { 
          WarrantyNum: customer.WarrantyNum,
          CustAddr: customer.CustAddress,
          CustomerID: customer.ID,
          Resource: that.resource
        },
      });
    },
    toCustomerImg(customer) {
      var that = this;
      that.$router.push({
        name: "customerImg",
        params: { WarrantyNum: customer.WarrantyNum },
      });
    },
    onClickLeft() {
      this.$router.back(-1);
    },

    GetMiniProgramLink: function (path,query) {
      var that = this;      
      this.$http.get(`WeChatApi/GetMiniProgramLink?pagePath=${path}&query=${query}`).then((res) => {
        // console.log(res);
        window.location=res.data.Data.url_link;
      });
    },
  },

  
  created: function () {
    console.log(this.$route.params.WarrantyNum);
    var WarrantyNum = this.$route.params.WarrantyNum;
    // this.GetMiniProgramLink();
    if (WarrantyNum != undefined) {
      this.GetDataByWarrantyNum(WarrantyNum);
    } else {
      this.GetData();
    }

    // this.$http();
  },
  mounted: function () {},
  components: {
    [Button.name]: Button,
  },
};
</script>

<style>
</style>
