<template>
  <div>
      <van-nav-bar title="中奖记录" left-text="" left-arrow @click-left="onClickLeft()" /> 
      <div class="table_detail" style="margin-top:10px;">
      <table>
        <thead>
          <tr>
            <th>序号</th>
            <th>奖品名称</th>
            <!-- <th>是否核销</th> -->
            <th>兑奖码</th>
            <th>中奖时间</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,idx) in drawList" v-bind:key="idx">
            <td>{{ idx+1 }}</td>
            <td>{{ item.PrizeName }}</td>
            <!-- <td>{{ item.IsGrant==1?'是':'否' }}</td> -->
            <td>{{ item.WinningCode }}</td>
            <td>{{ item._CreateTime }}</td>
          </tr>
        </tbody>
      </table>
    </div>      
    <van-pagination
      mode="simple"
      v-model="currentPage"            
      :page-count="pageCount"
      :show-page-size="3"
      force-ellipses
      style="margin-top:10px;" @change="handleCurrentChange" />    
    </div>    
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, NavBar, Grid, GridItem, Tabs, Tab } from "vant";
import { Button, Field } from "vant";
import { Divider } from "vant";
import { Popup } from "vant";
import { CountDown } from 'vant';
import { Pagination } from 'vant';

export default {
  name: "LuckDrawRecord",
  data() {
    return {      
      currentPage: 1,
      drawList: [],
      loading: false, 		// 是否处在加载状态
      finished: false, 		// 是否已加载完成
      total: 0,				    // 数据总条数
      pageCount: 0,       // 总页数
      pageSize: 0,    // 每页条数
    };
  },
  props: {},
  computed:{
    isLogined(){return this.$store.state.isLogin}
  },
  methods: {
    GetData(){
      var that=this;
      var warranty=this.$route.params.warranty;
      that.$http.get("Customer/GetLuckyDrawRecord", {
              params: {
                warrantyNum: warranty,
                pageindex: that.currentPage,
                sort: '_CreateTime DESC',
              }
            }).then(res=>{
              for (let i = 0; i < res.data.ListData.length; i++) 
              {
                var record=res.data.ListData[i];
                // if(record.IsGrant==1){
                //   record.IsGrantDesc="是";
                // }else{
                //   record.IsGrantDesc="否";
                // }
                switch (record.WinningNum) {
                  case 1:
                    record.PrizeName="豪华全景VR";
                    break;
                  case 2:
                    record.PrizeName="天力定制版精美雨伞";
                    break;
                  case 3:
                    record.PrizeName="天力精品水杯一个";
                    break;
                  case 4:
                    record.PrizeName="现金大礼2023元";
                    break;
                  default:
                    break;
                }
              }
              that.drawList=res.data.ListData;
              that.total = res.data.TotalCount;		// 给数据条数赋值
              that.loading = true;			// 加载状态结束 
              that.pageCount = res.data.PageCount;
              that.pageSize = res.data.PageSize;
              // 如果list长度大于等于总数据条数,数据全部加载完成
              if (that.drawList.length >= res.data.TotalCount) {
                  that.finished = true;		// 结束加载状态
              }
        });
    },
    handleCurrentChange (page) {
      this.currentPage = page;
      this.GetData();
    },
    /*返回 */
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  created() {
    this.GetData();
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [CountDown.name]:CountDown,
    [Pagination.name]:Pagination,
    [NavBar.name]:NavBar,
    [Grid.name]:Grid,
    [GridItem.name]:GridItem,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab
  },
};
</script>

<style scoped>
  .table_detail{
    overflow:auto;
    width: 100%;
    /* 固定滚动高度 */
    max-height: calc(100vh - 220px);
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
    border-right: 0;
    
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }
  td, th {
    border-right: 1px solid #EBEBEB;
    border-bottom :1px solid #EBEBEB;
    width:100px;
    height: 35px;
    color: #333;
    box-sizing: border-box;
    background-color: #ffffff;
    font-size: 12px;
    text-align: center;
    padding: 5px 5px 5px 0;
  }
  th {
    background: #FAFAFA;
    color: #999;
    font-weight: normal;
  }
  /* 固定首列*/
  td:first-child,
  th:first-child {
    width:100px;
    position:sticky;
    left:0;
    z-index:1;
    padding-left: 5px;
  }
  /* 固定表头*/
  thead tr th {
    position:sticky;
    top:0;
  }
  th:first-child{
    z-index:2;
  }
  /* 固定表头*/
  tfoot tr th {
    position:sticky;
    bottom:0;
    color: #333;
    font-weight: bold;
    border-bottom: 0;
  }
  th:first-child{
    z-index:2;
  }

</style>
