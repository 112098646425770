import axios from 'axios';
import router from '../Route/route'
import {Message} from 'view-design'
import {Modal} from 'view-design'

export const fileAddr={url:"https://oss.reaca.net/"};
export default {
  install: function(Vue) {
    // var fileAddr={url:"http://localhost:24737/"};
    // var fileAddr={url:"http://121.41.11.131:8088/"};
    // var fileAddr={url:"https://sale.reaca.net/"};
    // var fileAddr={url:"https://oss.reaca.net/"};
    var apihost={url:"https://api.reaca.net/api/"}
    var axiosInstance = axios.create({
      // baseURL: 'http://localhost:24737/api/',      
      // baseURL:'http://121.41.11.131:8088/api/',      
      // baseURL:'https://sale.reaca.net/api/',      
      baseURL:'https://api.reaca.net/api/',      
      withCredentials: true,
      crossDomain: true,  
      /* other custom settings */
    });
    axiosInstance.SetConditions=function(conditonsArray){
      var Conditions=[];
      for (const key in conditonsArray) {
        if (conditonsArray.hasOwnProperty(key)) {
          Conditions.push(conditonsArray[key]);          
        }
      }
      axiosInstance.defaults.headers.QueryConditionList=encodeURI(JSON.stringify(Conditions));
    };
    // axiosInstance.SetUserId=function(id){
    //   axiosInstance.defaults.headers.UserId=id;
    // };
    axiosInstance.interceptors.response.use((res) => {
      // axiosInstance.SetConditions([]);
      // 在这里对返回的数据进行处理
      if(res.data=="登录超时！"){      
        Message.info({
          content: "登录超时,请重新登录",
          duration: 10,
          closable: true
        });        
        router.push('/agent')                  
      }
      if(res.data=="您没有权限操作！"){      
        Modal.info({
          title: "提示",
          content: '您没有权限操作！',          
        });                
      }
      return res;
    }, (error) => {
      
      // TODO 待补充
      Message.warning({
        content: "网络异常！"+error,
        duration: 10,
        closable: true
      });
      return Promise.reject(error)
    });
    Object.defineProperty(Vue.prototype, '$http', { value: axiosInstance });
    Object.defineProperty(Vue.prototype, '$apihost', { value: apihost });
    Object.defineProperty(Vue.prototype, '$http_d', { value: axios });
    Object.defineProperty(Vue.prototype,'$file',{value:fileAddr});
  }
}