
<template>
  <div class="my-form">
    <div class="form-title">新建物资积分规则
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="120">
      <FormItem label="积分类型 ">
        <RadioGroup v-model="postdata.IntegralType">
          <Radio label="30">每单获取若干积分</Radio>
          <Radio label="31">大流量新品积分</Radio>
          <Radio label="32">配套产品积分</Radio>
          <Radio label="33">丝口数量积分</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="管道类型 ">
        <RadioGroup v-model="postdata.PipeType">
          <Radio v-for="item in postdata.Pipetypes" :label="item.ID" :key="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="倍率 ">
        <Input v-model="postdata.MaterialIntegralMultiple" style="width:500px" placeholder="请填写厨房倍率"></Input>
      </FormItem>
      <FormItem label="代理运营商 ">
         <Select v-model="postdata.AgentId" filterable style="width:500px">
            <Option v-for="item in postdata.Agents" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
        </Select>
      </FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="false"></Spin>
  </div>
</template>
<script>
  import dealerSel from "../../components/public_component/dealerSel";
export default {  
  data() {
    return {
      postdata: {
        MaterialIntegralMultiple: 0,
        IntegralType: "",
        PipeType: "",
        AgentId: "",
        Pipetypes: [],
        Agents: [],
      },
      modal: {
        show: false
      }
    };
  },
  components: {
    "dealer-RowSel": dealerSel
  },
  methods: {
    SubmitData: function(e) {//提交
      if(!this.CheckData(this.postdata)){
        return ;
      }
      this.$http.post("IntegralRule/AddMaterialIntegralRule", this.postdata).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_materialintegralrulelist" });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.AgentId == "") {
        content = "请选择运营代理商";
        checkflag = false;
      }
      if (obj.IntegralType == "") {
        content = "请选择积分类型";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    BackList: function() {//返回
      this.$router.push({ name: "dealer_materialintegralrulelist" });
    },
    GetAgentDealer: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.postdata.Agents = res.data;
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=4").then(res => {
        that.postdata.Pipetypes = res.data;
      });
    }
  },
  watch: {},
  created: function() {//页面初始化
    this.GetCheckbox();
    this.GetAgentDealer();
  }
};
</script>

<style>
</style>
