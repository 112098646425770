<template>
  <div>

    <Card :bordered="false" dis-hover>
      <p slot="title">预约试压服务</p>
      <van-cell-group>
        <van-field v-model="presalecode" label="预约编号" placeholder="请填写购买管道时发放的预约编号" error-message="" />
        <van-field v-model="tel" label="手机号码" placeholder="我们将通过短信通知您服务进度" error-message="" />
        <van-field v-model="date" type="date" center clearable label="试压日期" placeholder="请选择预约试压日期" />
      </van-cell-group>
    </Card>
    <div style="padding:15px;">
      <Button type="primary" @click="submit" long style="width:100%;height:35px;">提交</Button>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      date: "",
      tel: "",
      presalecode: ""
    };
  },
  methods: {
    submit() {
      var that = this;

      if (this.presalecode == "") {
        this.$Modal.warning({
          title: "提示",
          content: "请填写预约编号"
        });
        return;
      }
      if (this.date.length == "") {
        this.$Modal.warning({
          title: "提示",
          content: "请填写预约日期"
        });
        return;
      }
      if (this.checktel(this.tel)) {
        this.$http
          .get("prepress/addprepress", {
            params: {
              presalecode: that.presalecode,
              prepressdate: that.date,
              msgtel: that.tel
            }
          })
          .then(res => {
            var modaltitle = "";
            if (res.data != "success") {
              modaltitle = res.data;
            } else {
              modaltitle = "提交成功！";
            }
            this.$Modal.info({
              title: "提示",
              content: modaltitle
            });
          });
      }
    },
    checktel: function(tel) {
      if (!/^1(2|3|4|5|6|7|8|9)\d{9}$/.test(tel)) {
        this.$Modal.warning({
          title: "提示",
          content: "手机号码格式错误"
        });
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style>
</style>
