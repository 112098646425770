<template>
  <div class="listform">
    <div class="form-title">物料列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="success" @click="AddMaterials" icon="ios-document">新建</Button>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist">
      </Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator>
      </Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentStatus: "",
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "MaterialName", label: "物料名" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "物料名",
            key: "MaterialName",
            align: "center",
            tooltip: true
          },
          {
            title: "最高领取数量",
            key: "HighNum",
            align: "center",
            tooltip: true
          },
          {
            title: "库存",
            key: "TotalStock",
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => {
              let stock = params.row.TotalStock;
              if(stock < 1000) {
                return h('span',{
                  'style':{
                      'color':'red'
                  }
                },params.row.TotalStock);
              } else {
                return h('span',{
                },params.row.TotalStock);
              }
            }
          },
          {
            title: "创建时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            width: 180,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditMaterials(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                    "Button",
                    {
                      props: { type: "warning", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.Delete(params.row);
                        }
                      }
                    },
                    "删除"
                  )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    AddMaterials: function(params) {
      this.$router.push({ name: "dealer_newmaterial" });
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    EditMaterials: function(params) {
      this.$router.push({
        name: "dealer_newmaterial",
        query: { id: params.ID }
      });
    },
    Delete: function(e) {
      var that=this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认删除吗?",
        onOk: () => {
          that.$http.get('WorkerMaterial/DeleteMat',{
            params:{id:e.ID}
          })
          .then(res=>{
            if (res.data == "success") {
              that.$Message.info("删除成功");
              that.GetTableList();
            } else {
              this.$Message.info(res.data);
            }
          })
        }      
      });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_materiallist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("WorkerMaterial/GetMaterialPageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort,
            status: that.currentStatus 
            }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 