<template>
  <div class="modal_list">
    <div class="search_option">
    </div>
    <div class="datatable">
      <Table highlight-row :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="自提/带货安装" width="800" height="700" v-model="vouviewmodal.show" @on-ok="ModalOK" @on-cancel="ModalCancel">
      <Form :model="postdata" :label-width="200">
      <FormItem label="取货凭证图片上传">
        <div class="img-list">
          <div v-for="(item, index) in postdata.imgArray" :key="index" class="img-cell"
            v-bind:style="{'background-image':'url(' + item.url+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
              <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(item)"></Icon>
            </div>
          </div>
        </div>
        <Upload type="drag" :format="['jpg']" :max-size="2048" :with-credentials="true" :show-upload-list="false"
          :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
          style="display: inline-block;min-width:450px;" :action="uploadUrl">
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
            <p>将图片拖拽到此处上传</p>
          </div>
        </Upload>
      </FormItem>
      </Form>
    </Modal>
    <Modal v-model="imgviewmodal" width="880px">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl.url" alt="">
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vouviewmodal: {
        show: false,
      },
      postdata: {
        ID: 0,
        OrderSn: 0,
        GoodsId: 0,
        VoucherImg: ""
      },
      uploadUrl: "",
      currentImgurl: "",
      imgviewmodal: false,
      /* table+翻页相关数据 */
      table: {
        tablecolumsTitle: [
          {
            title: "商品名称",
            key: "GoodsName",            
            align: "center",
            tooltip: true
          },
          {
            title: "规格",
            key: "Specs",
            align: "center",
            tooltip: true
          },
          {
            title: "下单数量",
            key: "Num",            
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "购买时单价",
            key: "UnitPrice",            
            align: "center",
            tooltip: true
          },
          {
            title: "小计",
            key: "RowPrice",            
            align: "center",
            tooltip: true
          },
          {
            title: "快递单号",
            key: "DeliverySn",
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "",
            width: 100,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              if (params.row.DeliverySn==""
              || params.row.VoucherImg!=null) {
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "primary", size: "small" },
                      style: { marginRight: "3px" },
                      on: {
                        click: () => {
                          this.Voucher(params.row);
                        }
                      }
                    },
                    "凭证图片"
                  )
                ]);
              }
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  components: {},
  props: {
    orderid: ""
  },
  methods: {
    GetTable: function() {
      var that = this;
      var conditions = [];
      conditions.push({
        PropertyName: "OrderID",
        Operater: 2,
        LogicOperater: 0,
        Value: that.orderid
      });
      that.$http.SetConditions(conditions);
      that.table.loading = true;
      this.$http
        .get("Order/GetOrderDetailPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            sort: that.table.sort            
          }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.loading = true;
      this.GetTable();
    },
    ModalOK: function() {
      var that = this;
      if (that.postdata.VoucherImg.length <= 0) {
        this.$Message.warning("请上传凭证图片！");
        return;
      }
      this.$http("Order/UpdateOrderByVoucher", {
        params: { id: that.postdata.OrderSn }
      }).then(res => {
        that.$Message.info("操作成功!");
        that.GetTable();
      });
    },
    Voucher: function(row) {   //凭证图片上传
      this.postdata={
        ID: 0,
        OrderSn: 0,
        GoodsId: 0,
        VoucherImg: ""
      };
      var that = this;
      that.postdata.OrderSn = row.OrderID;
      that.postdata.GoodsId = row.GoodsId;
      that.vouviewmodal.show = true;
      that.GetData();
    },
    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.postdata.imgArray[this.postdata.imgArray.length - 1];
      } else {
        nextImgUrl = this.postdata.imgArray[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.postdata.imgArray.length - 1) {
        nextImgUrl = this.postdata.imgArray[index + 1];
      } else {
        nextImgUrl = this.postdata.imgArray[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    handleRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteVoucherImg", {
          params: { id: that.postdata.ID, filename: item.name },
        })
        .then((res) => {
          that.$Message.info("操作成功!");
          that.GetData();
        });
    },

    UploadSuccess: function (response, file, fileList) {
      this.$Message.info("上传成功!");
      this.GetData();
    },
    UploadError: function (error, file, fileList) {
      this.$Notice.error({
        title: "系统异常",
      });
    },
    handleBeforeUpload() {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: "文件 " + file.name + " 格式错误,请选择jpg格式",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件超出大小",
        desc: "文件: " + file.name + " 不能超过512Kb",
      });
    },
    GetData: function() {
      let that = this;
      var orderId=that.postdata.OrderSn;
      var goodsId=that.postdata.GoodsId;
      that.$http.get("Order/GetDeliveryById?orderId=" +orderId+"&goodsId="+goodsId).then(res => {
          if(res.data.Data!=null){
            var imgNameArray = res.data.Data.VoucherImg.split(",");
            var imgArray = [];
            if(imgNameArray[0]!=""){
              imgNameArray.forEach(item => {
                imgArray.push({
                  name: item,
                  url:
                    that.$file.url +
                    "Images/Mall/VoucherImg" +
                    "/" +
                    item
                });
              });
            }
            
            res.data.Data.imgArray = imgArray;
            that.postdata = res.data.Data;
            
          }
          that.uploadUrl =
              that.$file.url +
              "api/Order/AddVoucherImg?orderid=" +
              orderId+"&goodsid="+goodsId; 
        });
    },
    ModalCancel: function(e) {
      this.$http.SetConditions([]);
    },
  },
  created: function() {
    this.GetTable();
  }
};
</script>

<style>
</style>
