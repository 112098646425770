<template>
  <div class="listform">
    <div class="form-title">
      公司积分兑换按月统计
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <DatePicker type="month" @on-change="DateTimeSelect" placement="bottom-end" placeholder="根据兑换时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
    </div>

    <div class="datatable">
      <Table :border="false" row-key="ID" border size="small" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist">
      </Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "经销商ID",
            key: "DealerId",
            align: "center",
            fixed: "left",
            width: 100,
          },
          {
            title: "姓名",
            key: "Name",
            align: "center",
            tooltip: true,
          },
          {
            title: "电话",
            key: "Tel",
            align: "center",
            tooltip: true,
          },
          {
            title: "兑换积分",
            key: "ChangeIntegralNumber",
            align: "center",
            tooltip: true,
          },
          {
            title: "水工姓名",
            key: "SDWorkerName",
            align: "center",
            tooltip: true,
          },
          {
            title: "水工电话",
            key: "SDWorkerTel",
            align: "center",
            tooltip: true,
          },
          {
            title: "本月获得积分",
            key: "Integral",
            align: "center",
            tooltip: true,
          },
          {
            title: "兑换时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
          }
        ],
        datalist: [],
        pageIndex:1,
        pageSize:20,
        total:0
      },
    };
  },
  components: {},
  methods: {
    //行选中事件
    WorkerRowSel: function (e) {},
    ModalCancel: function (e) {},
    Search: function(e) {
      this.loading = true;
      this.GetTableList();
    },
    DateTimeSelect: function (e) {
      this.dateSearchValue = e;
    },
    PageChange:function (e) {
      this.table.pageIndex = e;
      this.GetTableList();
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Dealer/GetDealer2IntegralByMonth", {
          params: {
              date:that.dateSearchValue,
              pageIndex:that.table.pageIndex
          },
        })
        .then((res) => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData; 
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;         
        });
    },
    handleSpan: function ({ row, column, rowIndex, columnIndex }) {},
    handleLoadData (item, callback) {
        console.log(item);
        this.GetTableList(item.DealerId,1,data=>{
            data.map((v,i,list)=>{
                v.DealerName='';
                v.CurrentIntegralNum='';
            });
            callback(data);
        })
    },
    ExportExcel: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http
        .get("Dealer/ExportIntegral2", {
          params: {
            date: that.dateSearchValue,
          }
        })
        .then(res => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
  },
  created: function () {
    var timestamp = Date.parse(new Date());
    var date = new Date(timestamp);
    //获取年份
    var Y = date.getFullYear();
    //获取月份
    var M = date.getMonth()+1;
    this.dateSearchValue = Y + "-" + M;
    this.GetTableList();
  },
};
</script>
<style>
/* .radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
} */
</style>
 