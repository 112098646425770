<template>
  <div id="app" class="app-main">
    <!-- <img src="./assets/logo.png"> -->
    <!-- <router-view/> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  provide(){
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      userinfo: {},
      isRouterAlive : true
    };
  },
  methods:{
    reload(){
       this.isRouterAlive  = false
       this.$nextTick(function(){
         this.isRouterAlive  = true
       })
    }
  }
};
</script>
<style>
.ivu-table td,
.ivu-table th {
  height: 40px;
}
</style>

<style>
html,
body {
  height: 100%;
}
.app-main {
  width: 100%;
  height: 100%;
}
.form-title {
  font-size: 15px;
  color: #464c5b;
  font-weight: bold;
  margin: 0 0 10px 10px;
  margin-bottom: 10px;
}
.employee-content {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.form-content-left {
  /* border-right: 5px solid red; */
  height: 100%;
  width: 18%;
}

.form-content-right {
  width: 80%;
}
.my-form {
  padding-left: 40px;
}
.search_option > * {
  margin-left: 20px;
}
.datatable {
  margin-top: 30px;
}
.footerpage {
  margin-top: 30px;
  text-align: center;
}
.img-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  justify-content: flex-start;
}
.img-cell {
  height: 10vh;
  width: 10vh;
  margin: 5px;
  text-align: center;
  vertical-align: middle;   
}
.img-deletespan {
  float: right;
  margin: 3px;
  color: white;
  font-size: large;
  /* background-color: black; */
}
/* .ivu-table-cell{
    white-space: nowrap;
    word-break: keep-all;
} */
/* .ivu-table-cell:hover {height: auto; word-break:break-all; white-space: pre-wrap;  text-decoration: none;} */
</style>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  background-color: #aeaeae14;
}
.content {
  /* margin-bottom: 20px; */
  height: 100%;
}

.swap {
  position: relative;
  overflow: scroll;
  z-index: 1;
}

.van-nav-bar {
  background: #fff;
  color: #3d4145;
  border-bottom: 1px solid #eee;
}
.login-form {
  padding-top: 30px;
  padding: 20px;
}
.login-form-btn {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.count-down {
  padding-top: 10px;
}
</style>