<template>
  <div class="my-form">
    <div class="form-title">编辑试压员
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="姓名 ">
        <Input v-model="postdata.Name" style="width:500px" placeholder="请填写姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="postdata.TelNum" style="width:500px" placeholder="请填写11位手机电话"></Input>
      </FormItem>
      <FormItem label="密码 ">
        <Input v-model="postdata.Password" style="width:500px" placeholder="请填写密码"></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinShow: false,
      postdata: {
        ID: 0,
        CardNum:"",
        AgentId:0,
        AgentDesc:"",
        UserName:"",
        Name: "",
        TelNum: "",
        Password: "",
        _CreateBy: 0,
        _CreateTime: "",
        _IsDelete: 0,
        _UpdateBy: 0,
        _UpdateTime: ""
      },
    };
  },
  methods: {

    SubmitData: function() {
      var that = this;
      
      try {
      } catch (error) {}
      
      if(!this.CheckData(that.postdata)){
        return ;
      }
      this.$http.post("Worker/EditWorker", that.postdata).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_workerlist" });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.Name == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.TelNum == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (obj.Password == "") {
        content = "请填写密码";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    GetWorker: function(id) {
      var that = this;
      this.$http
        .get("Worker/GetWorker", { params: { id: id } })
        .then(res => {
          this.postdata = res.data.Data;
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    BackList: function() {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetWorker(this.$route.query.id);
  }
};

</script>

<style>
</style>
