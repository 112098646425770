<template>
  <div>
      <van-nav-bar title="积分记录" left-text="" left-arrow @click-left="onClickLeft()" />
      <div class="table_detail" style="margin-top:10px;">
        <table>
          <thead>
            <tr>
              <th style="width:80px;">序号</th>
              <th style="width:180px;">地址</th>
              <th>积分数</th>
              <th>时间</th>
              <th>管道类型</th>
              <th style="width:180px;">备注</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,idx) in integralList" v-bind:key="idx">
              <td style="width:80px;">{{ idx+1 }}</td>
              <td style="width:180px;text-align:left;">{{ item.CustAddress==null?'':item.CustAddress+'' }}</td>
              <td>{{ item.ChangeIntegralNumber+'' }}</td>
              <td>{{ item._CreateTime+'' }}</td>
              <td>{{ item.PipeLineTypeDesc==null?'':item.PipeLineTypeDesc+'' }}</td>
              <td style="width:180px;text-align:left;">{{ item.Remark==null?'':item.Remark+'' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <van-pagination
        mode="simple"
        v-model="currentPage"            
        :page-count="pageCount"
        :show-page-size="3"
        force-ellipses
        style="margin-top:20px;" @change="handleCurrentChange" /> 
    </div>    
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, NavBar, Grid, GridItem, Tabs, Tab } from "vant";
import { Button, Field } from "vant";
import { Divider } from "vant";
import { Popup } from "vant";
import { CountDown } from 'vant';
import { Pagination } from 'vant';

export default {
  name: "IntegrallogList",
  data() {
    return {      
      currentPage: 1,
      integralList: [],
      loading: false, 		// 是否处在加载状态
      finished: false, 		// 是否已加载完成
      total: 0,				    // 数据总条数
      pageCount: 0,       // 总页数
      pageSize: 0,    // 每页条数
      active: 0
    };
  },
  props: {},
  computed:{
    isLogined(){return this.$store.state.isLogin}
  },
  methods: {
    ShowLogin(){
      this.$store.commit("showLogin");
    },
    /*查询误工积分记录 */
    GetData(){
      var that=this;
      if(that.isLogined){
        var conditions = [];
        conditions.push({
          PropertyName: "SDWorkerId",
          Operater: 2,
          LogicOperater: 0,
          Value: this.$route.params.sdworkerid
        });

        this.$http.SetConditions(conditions);
        that.$http.get("SDWorker/GetIntegralLogPageList", {
              params: {
                pageindex: that.currentPage,
                sort: '_CreateTime DESC',
              }
            }).then(res=>{
              that.integralList=res.data.ListData;
              that.total = res.data.TotalCount;		// 给数据条数赋值
              that.loading = true;			// 加载状态结束 
              that.pageCount = res.data.PageCount;
              that.pageSize = res.data.PageSize;
              // 如果list长度大于等于总数据条数,数据全部加载完成
              if (that.integralList.length >= res.data.TotalCount) {
                  that.finished = true;		// 结束加载状态
              }
        });
      }
    },
    handleCurrentChange (page) {
      this.currentPage = page;      
      this.GetData();
    },
    /*返回 */
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  mounted(){
    this.active = 0;
    this.GetData();
  },
  created() {
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [CountDown.name]:CountDown,
    [Pagination.name]:Pagination,
    [NavBar.name]:NavBar,
    [Grid.name]:Grid,
    [GridItem.name]:GridItem,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab
  },
};
</script>


<style scoped>
  
    body {
        margin: 0;
        padding: 0;
    }

    .scroll-table-demo {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .scroll-table-title {
        height: 80px;
        line-height: 24px;
        font-weight: bold;
        background-color: #7fd75a;
        color: #fff;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    /* 全局css配置 */
    .scroll-table {
        height: calc(100% - 80px);
    }

    .scroll-table table {
        border: none;
        border-collapse: collapse;

    }

    .scroll-table td,
    .scroll-table th {
        padding: 0;
    }

    .scroll-table-container {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    .scroll-table-left,
    .scroll-table-slider {
        display: flex;
        flex-direction: column;
        box-shadow: 1px 3px 5px #eee;
    }


    .scroll-table-left-top,
    .scroll-table-slider-top {
        height: 45px;
        background-color: #f5f7fa;
        position: relative;
        z-index: 10;
    }

    .scroll-table-right-top {
        background-color: #f5f7fa;
    }

    .scroll-table-left-top div,
    .scroll-table-slider div {
        width: 60px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-left-top tr,
    .scroll-table-slider-top tr {
        border-bottom: 1px solid #ebeef5;
    }

    .scroll-table-left-freeze,
    .scroll-table-slider-freeze {
        flex: 1;
        height: calc(100% - 45px - 45px);
    }

    .scroll-table-left-freeze tr,
    .scroll-table-slider-freeze tr {
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
    }

    .scroll-table-left-freeze td>div,
    .scroll-table-slider-freeze td>div {
        width: 60px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-left-bottom,
    .scroll-table-slider-bottom {
        height: 45px;
        background-color: #f5f7fa;
        position: relative;
        z-index: 10;
    }

    .scroll-table-left-bottom tr,
    .scroll-table-slider-bottom .tr {
        margin: 0;
        padding: 0;
    }

    .scroll-table-left-bottom th>div,
    .scroll-table-slider-bottom th>div {
        width: 60px;
    }

    .scroll-table-right {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }

    .scroll-table-right-bottom {
        background-color: #1890ff;
        color: #fff;
    }

    .scroll-table-right-bottom td>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-right-top tr {
        border-bottom: 1px solid #ebeef5;
        background-color: #f5f7fa;
    }

    .scroll-table-right-top th>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-right-content {
        flex: 1;
        overflow: hidden;
    }

    .scroll-table-right-content tr {
        border-bottom: 1px solid #ebeef5;
    }

    .scroll-table-right-content td>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }
    .van-grid-item {
        height: 45px;
    }

    /* 样式 */
    .table_detail{
      overflow:auto;
      width: 100%;
      /* 固定滚动高度 */
      max-height: calc(100vh - 220px);
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      border-right: 0;
      
    }
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }
    td, th {
      border-right: 1px solid #EBEBEB;
      border-bottom :1px solid #EBEBEB;
      width:100px;
      height: 35px;
      color: #333;
      box-sizing: border-box;
      background-color: #ffffff;
      font-size: 12px;
      text-align: center;
      padding: 5px 5px 5px 0;
    }
    th {
      background: #FAFAFA;
      color: #999;
      font-weight: normal;
    }
    /* 固定首列*/
    td:first-child,
    th:first-child {
      width:100px;
      position:sticky;
      left:0;
      z-index:1;
      padding-left: 5px;
    }
    /* 固定表头*/
    thead tr th {
      position:sticky;
      top:0;
    }
    th:first-child{
      z-index:2;
    }
    /* 固定表头*/
    tfoot tr th {
      position:sticky;
      bottom:0;
      color: #333;
      font-weight: bold;
      border-bottom: 0;
    }
    th:first-child{
      z-index:2;
    }

</style>
