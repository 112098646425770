<template>
  <div class="listform">
    <div class="form-title">过滤器编码
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Input v-model="queryvalue" placeholder="输入要查询的编码" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Input v-model="codecount" placeholder="输入要生成的编码数量" style="width: 200px" v-if="issystem==1"></Input>
      <Button @click="CreateCode()" type="primary" icon="ios-clipboard" v-if="issystem==1">生成新编码</Button>
    </div>

    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort"  :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      IsSystem: 0,
      AgentId: 0,
      issystem:0,
      table: {
        tablecolumsTitle: [
          {
            title: "编码",
            key: "Code",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "是否已使用",
            key: "IsUse",
            // width: 100,
            align: "center",
            tooltip: true,
            render: (h, params) => { // 0未使用 1已使用
              const row = params.row;
              const text = (row.IsUse == 1 && "已使用") || "未使用";
              return h("div",
                text
              );
            }
          },
          {
            title: "生成年份",
            key: "Year",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "生成月份",
            key: "Month",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              if(this.IsSystem==0&&this.AgentId==1){
                if(params.row.IsUse == 1){// 编码已使用,可关联客户档案
                  return h("div", [
                      h(
                        "Button",
                        {
                          props: { type: "primary", size: "small" },
                          style: { marginRight: "3px" },
                          on: {
                            click: () => {
                              this.CustomerRelateList(params.row);
                            }
                          }
                        },
                        "客户档案"
                      )
                  ]);
                }
              }else{
                if(params.row.IsUse == 1){// 编码已使用,可关联客户档案
                  return h("div", [
                      h(
                        "Button",
                        {
                          props: { type: "primary", size: "small" },
                          style: { marginRight: "3px" },
                          on: {
                            click: () => {
                              this.CustomerRelateList(params.row);
                            }
                          }
                        },
                        "客户档案"
                      )
                  ]);
                }else{                  // 编码未使用,可删除
                    return h("div", [
                    h(
                      "Button",
                      {
                        props: { type: "warning", size: "small" },
                        style: { marginRight: "3px" },
                        on: {
                          click: () => {
                            this.Delete(params.row);
                          }
                        }
                      },
                      "删除"
                    )
                  ]);
                }
              }
              // if(params.row.IsUse == 1){// 编码已使用,可关联客户档案
              //     return h("div", [
              //         h(
              //           "Button",
              //           {
              //             props: { type: "primary", size: "small" },
              //             style: { marginRight: "3px" },
              //             on: {
              //               click: () => {
              //                 this.CustomerRelateList(params.row);
              //               }
              //             }
              //           },
              //           "客户档案"
              //         )
              //     ]);
              // }else{                  // 编码未使用,可删除
              //     return h("div", [
              //     h(
              //       "Button",
              //       {
              //         props: { type: "warning", size: "small" },
              //         style: { marginRight: "3px" },
              //         on: {
              //           click: () => {
              //             this.Delete(params.row);
              //           }
              //         }
              //       },
              //       "删除"
              //     )
              //   ]);
              // }
            }
          }
        ],
        queryvalue: "",
        codecount:0,
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort:" "
      }
    };
  },
  methods: {
    PageChange: function(e) {   // 分页查询
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name:"dealer_prefiltercodelist",
        query: { pageindex: e }
      });
    },
    Search: function(e) {   // 根据输入的编码查询
      this.loading = true;
      var conditions = [];            
      conditions.push({
        PropertyName: "Code",
        Operater: 0,
        LogicOperater: 0,
        Value: this.queryvalue.trim()
      });      
      
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GetTableList: function() {   // 查询过滤器编码列表
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Customer/GetPreFilterCodePageList", {
          params: { pageIndex: that.table.pageIndex,sort:that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort:function (sort) {     // 排序
      if(sort.key!=undefined&&sort.order!=undefined){
        if(sort.order!="normal"){
          this.table.sort=sort.key+" "+sort.order;
        }else{
          this.table.sort="";
        }
      }
      this.GetTableList();
    },
    CreateCode:function () {   // 生成过滤器编码
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      this.$http.get("Customer/CreateCode",{
        params:{count:that.codecount}
      }).then(res=>{
        that.$Spin.hide();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    Delete: function(e) {   // 删除过滤器编码
      var that=this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认删除吗?",
        onOk: () => {
          that.$http.get('Customer/DeleteCode',{
            params:{id:e.ID}
          })
          .then(res=>{
            if (res.data == "success") {
                that.$Message.info("删除成功");
                that.GetTableList();
            } else {
                this.$Message.info(res.data);
            }
          })
        }      
      });
    },
    GetCurEmployee: function() {
      let that = this;
      that.$http.get("Employee/GetCurEmployee").then(res => {
        that.IsSystem=res.data.IsSystem;
        that.AgentId=res.data.AgentDealerId;
      });
    },
    CustomerRelateList: function(e) {
      this.$router.push({
        name: "dealer_childcustomer",
        params: { preFilterCode: e.Code }
      });
    }
  },
  created: function() {   // 初始化加载过滤器编码
    this.GetTableList();
    this.GetCurEmployee();

    // 当前登录用户
    var user = this.$store.state.currentUser;
    this.issystem = user.IsSystem;
  }
};
</script>
<style>
</style>
 