import Vue from 'vue'
import App from './App.vue'
import myrouter from './Route/route'
import iView from 'view-design';
// import style
import 'view-design/dist/styles/iview.css';
/*
import iView from 'iview'
import 'iview/dist/styles/iview.css'
*/

import systemconfig from './SysConfig/sysconfig'
import AxiosPlugin from './utils/axiosPlugin'

import Vant from 'vant';
import 'vant/lib/index.css';
import Vuex from 'vuex'

Vue.use(Vant);

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts 

/*全局子定义组件 */
Vue.use(iView,{
  size: 'small'
});
Vue.use(systemconfig);
Vue.use(AxiosPlugin);

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLogin:false,
    loginShow:false,
    cartCount:0,
    currentUser:{ID:0,Name:'',Tel:'',AgentDealerId:0,IsSystem:0,EmployeesType:0},
  },
  mutations: {
    login (state) {
      state.isLogin=true
    },
    showLogin(state){
      state.loginShow=true;
    },
    hideLogin(state){
      state.loginShow=false
    },
    loginOut(state){
      state.isLogin=false;
    },
    setCartCount(state,num){
      state.cartCount=num;
    },
    setCurrentUser(state,data){
      state.currentUser=data;
    }
  }
})


Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

myrouter.beforeEach((to, from, next) => {
  iView.LoadingBar.start();
  next();
});

myrouter.afterEach(() => {
  iView.LoadingBar.finish();
});

new Vue({
  render: h => h(App),
  router:myrouter,
  store
}).$mount('#app')
