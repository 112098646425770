<template>
  <div>
    <Row :gutter="24">
      <Col :span="6">
        <Card>
          <p slot="title">客户档案信息</p>
          <!-- <Tag  color="blue">{{dataobj.ProcessStatusDesc}}</Tag> -->
          <Button
            slot="extra"
            style="margin-left: 30px"
            type="primary"
            @click="Extract()"
            icon="md-barcode"
            >抽查</Button
          >
          <Button
            slot="extra"
            style="margin-left: 10px"
            type="info"
            @click="GoBack()"
            icon="md-arrow-back"
            >返回</Button
          >
          <p>客户姓名: {{ dataobj.CustName }}</p>
          <p>客户电话: {{ dataobj.CustTel }}</p>
          <p>详细地址: {{ dataobj.CustAddress }}</p>
          <p>管材品牌: {{ dataobj.Brand }}</p>
          <p>户型: {{ dataobj.HouseType }}</p>
          <p>是否侧漏: {{ dataobj.IsLeak == 0 ? "否" : "是" }}</p>
          <!-- <p>角阀数量: {{dataobj.ValveNum}}</p>
          <p>软管数量: {{dataobj.HoseNum}}</p> -->
          <p>
            是否安装净水产品: {{ dataobj.IsInstallFilter == 0 ? "否" : "是" }}
          </p>
          <p>收费金额: {{ dataobj.ChargeMoney }}</p>
          <p>是否清洗完成: {{ dataobj.IsCompleted == 0 ? "否" : "是" }}</p>
          <p>未完成清洗原因: {{ dataobj.UnReason }}</p>
          <p>预约经销商: {{ dataobj.DealerName }}</p>
          <p>试压员: {{ dataobj.WorkerName }}</p>
          <p>备注: {{ dataobj.Remark }}</p>
        </Card>
      </Col>
      <Col :span="4">
        <Card>
          <p slot="title">收费明细信息</p>
          <p v-for="(item, idex) in dataobj.ChargeProductList" :key="index">
            {{ item.Name }}({{ item.Num }}{{ item.Unit }} /
            {{ item.UnitPrice * item.Num }}元)
          </p>
        </Card>
      </Col>
      <Col :span="14">
        <Tabs type="card" @on-click="handleClick">
          <TabPane label="图片" name="">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl"
                @click="viewimg(item, 1)"
                :key="index"
                class="img-cell"
                v-bind:style="{
                  'background-image': 'url(' + item + ')',
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-repeat': 'no-repeat',
                  'background-color': 'black',
                }"
              ></div>
            </div>
          </TabPane>
          <Button @click="downloadImg" size="small" slot="extra">下载</Button>
        </Tabs>
      </Col>
    </Row>

    <Modal v-model="imgviewmodal" :type="info" width="880px">
      <p slot="header" style="text-align: center">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align: center; display: flex; align-items: center">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img
          style="max-width: 760px; max-height: 600px"
          :src="currentImgurl"
          alt=""
        />
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataobj: {},
      integralobj: {},
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      agentId: 0,
      // base_url: "http://121.41.11.131:8088/"
      base_url: this.$file.url,
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    downloadImg: function () {
      var that = this;
      this.$Spin.show({
        render: (h) => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18,
              },
            }),
            h("div", "生成中.."),
          ]);
        },
      });
      this.$http
        .get("/Customer/ExportCustomerImgs", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    viewimg(item) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.imgurl[this.imgurl.length - 1];
      } else {
        nextImgUrl = this.imgurl[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.imgurl.length - 1) {
        nextImgUrl = this.imgurl[index + 1];
      } else {
        nextImgUrl = this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    GetData() {
      this.$http
        .get("/WashCustomer/GetWashCustomer", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          var that = this;
          this.dataobj = res.data.Data;
          if (res.data.Data.Imgs != null && res.data.Data.Imgs.length > 0) {
            var imgStr = res.data.Data.Imgs;
            var imgarray = imgStr.split(",");
            // var imgUrlArray = [];
            var imgUrlArray = imgarray.map((item) => {
              return (
                that.base_url +
                "Images/upload/washCustomer" +
                res.data.Data.ID +
                "/" +
                item +
                ".jpg"
              );
            });
            that.imgurl = imgUrlArray;
          } else {
            that.imgurl = [];
          }
        });
    },
    // GetIntegral() {
    //   this.$http
    //     .get("/Customer/GetIntegral", {
    //       params: {
    //         id: this.$route.params.id,
    //       },
    //     })
    //     .then((res) => {
    //       // var that = this;
    //       this.integralobj = res.data.Data;
    //     });
    // },
    handleClick: function (e) {
      this.active = e;
      this.GetData(e);
    },
    GoBack: function () {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    Extract: function () {
      // var that = this;
      var url = "/WashCustomer/DoWashExtractAudit";
      this.$http
        .get(url, { params: { customerId: this.$route.params.id } })
        .then(() => {
          this.$Message.info("操作成功");
        });
    },
  },
  created() {
    this.GetData();
    // this.GetIntegral();
  },
};
</script>

<style>
.detail-form {
}
</style>
