<template>
  <div id="page">
    <van-nav-bar title="抽奖活动" left-text="" left-arrow @click-left="onClickLeft()" />
    <div class="pond-head" v-on:click="init">
      <img src="../assets/pond-head.png" alt="">
    </div>
    <van-notice-bar left-icon="volume-o" :scrollable="false">
      <van-swipe vertical class="notice-swipe" :autoplay="1500" :show-indicators="false">
        <van-swipe-item v-for="(item,index) in luckyerList" :key="index">{{item}}</van-swipe-item>
      </van-swipe>
    </van-notice-bar>
    <div class="container-out">
      <div class="container-in">
        <div class="content-out" v-for="(box,index) in list" v-bind:key="index"
          :style="{left:box.left+'px',top:box.top+'px','background-color':index==indexSelect?colorAwardSelect:colorAwardDefault}">
          <img class="pond-name-img" :src="box.image_url" alt="">
          <div class="pond-name">{{box.displayName}}</div>
        </div>
      </div>
      <div class="start-btn" v-on:click="startBtn">
        <img src="../assets/pond-button.png" style="height:80px;width:100%;" alt="">
        <img src="../assets/pond-cj.png" :style="{'top':btntop+'px'}"
          style="position:absolute;left:5px;height:68px;width:89px;top:0;" alt="">
      </div>
      <div class="circle" v-for="(item,index) in circleList" v-bind:key="index"
        :style="{'top':item.topCircle+'px','left':item.leftCircle+'px','background-color':index%2==0?colorCircleFirst:colorCircleSecond}">
      </div>
    </div>
    <div class="prize" v-show="prize>0">
      <div class="prize-box">
        <img class="prize-img" src="../assets/pond-success.png" alt="">
        <div class="prize-msg">
          <div>恭喜</div>
          <div>您抽中了{{prize_name}}</div>
          <div class="continue" v-on:click="conTinue">{{"关闭"}}</div>
        </div>
      </div>
    </div>
    <div class="men" v-show="men"></div>
    <div v-show="period>1">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" v-on:click="GoProcess">
        助力进度
      </van-button>
    </div>
    <div style="text-align:center;margin:0px 0; font:normal 10px/24px 'MicroSoft YaHei';color:#fafafa">
      <p>您当前剩余抽奖次数{{remaindrawChance}}次</p>
      <p>您已累计可抽奖次数{{drawChance}}次</p>
    </div>
    <div style="text-align:center;margin:10px 0; font:normal 10px/24px 'MicroSoft YaHei';">
      <p>48.8元红包直接通过积分发放到您的账户</p>
    </div>
  </div>
</template>
<script>
import { Swipe, SwipeItem, Button, Tabs } from "vant";
import AppVue from "../App.vue";
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          type: 1,
          displayName: "现金红包",
          name: "0元红包",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 3,
          type: 1,
          displayName: "热熔机/工具包",
          name: "热熔机/工具包",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 2,
          type: 2,
          displayName: "现金红包",
          name: "48.8元红包",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 8,
          type: 2,
          displayName: "现金红包",
          name: "2021元红包",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 7,
          type: 1,
          displayName: "瓦斯钉枪一台",
          name: "瓦斯钉枪一台",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 6,
          type: 2,
          displayName: "天力舒适型过滤器一台",
          name: "天力舒适型过滤器一台",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 5,
          type: 1,
          displayName: "趴地扇一台",
          name: "趴地扇一台",
          image_url: require("../assets/zhongjiang.png"),
        },
        {
          id: 4,
          type: 2,
          displayName: "黄芙蓉王4包",
          name: "黄芙蓉王4包",
          image_url: require("../assets/zhongjiang.png"),
        },
      ],
      period: 2,
      remaindrawChance: 0,
      drawChance: 0,
      luckyerList: [],
      circleList: [],
      colorCircleFirst: "#F12416",
      colorCircleSecond: "#FFFFFF",
      colorAwardDefault: "#F5F0FC",
      colorAwardSelect: "#ffe400",
      btntop: 0, //按钮的样式
      time: "", //定时器
      indexSelect: 0, //奖品下标
      lottert: 0, //中奖下标
      prize: 0, //是否中奖
      prize_name: "", //奖品名字
      men: false,
    };
  },
  created() {
    console.log(this.$route.params.period);
    console.log(AppVue.data().user);
    this.period = this.$route.params.period;
    this.init();
    this.GetData();
  },
  methods: {
    init: function () {
      var ts = this;
      var list = this.list;
      var left = 9;
      var top = 9;

      for (var i = 0; i < 8; i++) {
        if (i == 0) {
          left = 9;
          top = 9;
        } else if (i < 3 && i != 0) {
          top = top;
          left = left + 98 + 4;
        } else if (i >= 3 && i < 5) {
          left = left;
          top = top + 79 + 4;
        } else if (i >= 5 && i < 7) {
          left = left - 98 - 4;
          top = top;
        } else if (i >= 7 && i < 8) {
          left = left;
          top = top - 79 - 4;
        }
        list[i].top = top;
        list[i].left = left;
      }
      this.list = list;
      var cleft = 4; //e
      var ctop = 4; //a
      var dian = [];
      for (var j = 0; j < 24; j++) {
        if (j == 0) {
          cleft = 4;
          ctop = 4;
        } else if (j < 6) {
          ctop = 2;
          cleft += 55;
        } else if (j == 6) {
          ctop = 2;
          cleft = 330;
        } else if (j < 12) {
          ctop += 46;
          cleft = 331.5;
        } else if (j == 12) {
          ctop = 272.5;
          cleft = 330;
        } else if (j < 18) {
          ctop = 275;
          cleft -= 55;
        } else if (j == 18) {
          ctop = 273;
          cleft = 5;
        } else {
          if (!(j < 24)) return;
          (ctop -= 46), (cleft = 2.5);
        }
        dian.push({
          topCircle: ctop,
          leftCircle: cleft,
        });
      }

      this.circleList = dian;
      setInterval(function () {
        if (ts.colorCircleFirst == "#FFFFFF") {
          ts.colorCircleFirst = "#F12416";
          ts.colorCircleSecond = "#FFFFFF";
        } else {
          ts.colorCircleFirst = "#FFFFFF";
          ts.colorCircleSecond = "#F12416";
          ts.btntop = 0;
        }
      }, 900);
      this.time = setInterval(function () {
        if (ts.btntop == 0) {
          ts.btntop = -3;
        } else {
          ts.btntop = 0;
        }
      }, 900);
    },
    startBtn: function (e) {
      clearInterval(this.time);
      this.men = true;
      this.btntop = 0;
      this.lottert = 0;
      var ts = this;
      var i = this.indexSelect;
      var list = this.list;
      var time = null;
      var s = 0;
      var that = this;
      this.$http
        .get("SDWorker/Lucky2Draw2021Xt?period="+that.period, {
          params: {},
        })
        .then((res) => {
          if (res.data.Msg == "success") {
            console.log(res.data.Data);
            var lucklyNum = res.data.Data;
            time = setInterval(function () {
              i++;
              i %= 8;
              s += 30;
              ts.indexSelect = i;
              if (ts.lottert > 0 && i + 1 == ts.lottert) {
                clearInterval(time);
                ts.time = setInterval(function () {
                  if (ts.btntop == 0) {
                    ts.btntop = -3;
                  } else {
                    ts.btntop = 0;
                  }
                }, 900);
                ts.prize_name = list[i].name;
                ts.prize = lucklyNum;
              }
            }, 200 + s);

            setTimeout(function () {
              for (let i = 0; i < list.length; i++) {
                const element = list[i];
                if (element.id == lucklyNum) {
                  ts.lottert = i + 1;
                }
              }
            }, 4e3);
          } else {
            console.log(res.data);
            that.$toast(res.data);
          }
        });
    },
    GetData() {
      var that = this;
      that.$http.get("SDWorker/GetLuckyerXt?period="+that.period).then((res) => {
        that.luckyerList = res.data.ListData;
      });
      that.$http.get("SDWorker/GetDrawChanceXt?period="+that.period).then((res) => {
        that.remaindrawChance = res.data.Data.RemainChance;
        that.drawChance = res.data.Data.ChanceNum;
      });
    },
    GoProcess() {
      var that=this;
      console.log(that.$store.state.currentUser.ID);
      this.$router.push({
        name: "process",
        params: { sdworkerId:that.$store.state.currentUser.ID },
      });
    },
    conTinue: function () {
      this.men = false;
      this.prize = 0;
      this.GetData();
    },
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
  },
};
</script>
<style scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}

html {
  background: #f12416;
}
#page {
  width: 100%;
  background: #f12416;
}

.pond-head img {
  width: 100%;
  height: 215px;
}
.container-out {
  height: 283px;
  width: 340px;
  background-color: #f47915;
  margin: 16px auto 15px auto;
  border-radius: 8px;
  position: relative;
}
.circle {
  position: absolute;
  display: block;
  border-radius: 50%;
  height: 7px;
  width: 7px;
}
.content-out {
  position: absolute;
  height: 72px;
  width: 98px;
  background-color: #f5f0fc;
  border-radius: 8px;
  box-shadow: 0 8px 0 #ffcec0;
}
.container-in {
  width: 320px;
  height: 263px;
  background-color: #f12416;
  border-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.pond-name-img {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 46px;
  z-index: 1;
}
.pond-name {
  position: absolute;
  top: 75%;
  color: #c62015;
  width: 98px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-size: 9pt;
  text-align: center;
}
.start-btn {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  height: 79px;
  width: 98px;
  color: #f6251e;
  text-align: center;
  font-weight: bolder;
  line-height: 77px;
}
.prize {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  z-index: 100;
}
.prize-box {
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.prize-img {
  width: 100vw;
  max-height: 231px;
  max-width: 375px;
}
.prize-img2 {
  width: 100vw;
  max-height: 120px;
  max-width: 375px;
}
.prize-msg {
  margin-top: -7px;
  width: 78.5vw;
  max-width: 294.5px;
  text-align: center;
  background: #ffffff;
  padding-bottom: 6px;
  font-size: 13pt;
}
.prize_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.continue {
  width: 240px;
  height: 40px;
  margin: 28px auto;
  background: #ff5c5c;
  line-height: 40px;
  color: #ffffff;
  border-radius: 20px;
}
.men {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  left: 0;
  top: 0;
}
div{
  text-align: center;
}
</style>