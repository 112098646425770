<template>
  <Steps :current="step">
    <Step title="接到售后"></Step>
    <Step title="分配组织"></Step>
    <Step title="安排售后人员"></Step>
    <Step title="现场勘察"></Step>
    <Step title="图片上传"></Step>
    <Step title="完结"></Step>
  </Steps>
</template>

<script>
export default {
  computed: {
    step() {
      var code = this.row.ProcessStatusCode;
      switch (code) {
        case "12":
          return 2;          
        case "14":
          return 3;          
        case "18":
          return 4;          
        case "20":
          return 6;          
      }
    }
  },
  props: {
    row: {}
  }
};
</script>

<style>
</style>
