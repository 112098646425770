<template>
  <div class="listform">
    <div class="form-title">客户预约单
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="date" @on-change="DateTimeSelect" placement="bottom-end" placeholder="根据预约试压时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button v-if="issystem==1" @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
      <Button @click="SetAllWorker()" type="primary" icon="ios-clipboard">一键分配试压</Button>
      <Button @click="NewPrepress(true)" type="success" icon="ios-clipboard">新增(有预约单号)</Button>
      <Button @click="NewPrepress(false)" type="" icon="ios-clipboard">新增(无预约单号)</Button>
      <Button @click="PrepressMap()" type="success" icon="ios-clipboard">地图派单</Button>
    </div>
    <!-- <div style="margin-top:10px;margin-left:20px;" v-if="AgentId==1">
      <Tabs type="card" v-model="iscompany" @on-click="tabchange">
        <TabPane label="非家装单"></TabPane>
        <TabPane label="家装单"></TabPane>
      </Tabs>
    </div> -->
    <div class="radio-group">
      <RadioGroup v-model="currentStatus" @on-change="StatusChange" type="button">
        <Radio label="10">待处理</Radio>
        <Radio label="30">待分配试压</Radio>
        <Radio label="40">待上传档案</Radio>
        <Radio label="50">已完成</Radio>
      </RadioGroup>
    </div>

    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="选择试压员" @on-cancel="ModalCancel" width="1000" height="600" :footer-hide="true" v-model="workermodal.show">
      <worker-RowSel v-if="workermodal.show" v-on:rowsel="WorkerRowSel"></worker-RowSel>
    </Modal>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      currentStatus: "10",
      currentPrePressRow: {},
      IsEditWorker: false,
      workermodal: {
        show: false
      },
      AgentId: 0,
      issystem:0,
      // iscompany: "0",
      queryvalue: "",
      dateSearchValue: "",
      sel_field: "",
      sel_values: [
        { value: "PreSaleCode", label: "预约单号" },
        { value: "CustName", label: "姓名" },
        { value: "MsgTel", label: "短信号码" },
        { value: "CustAddr", label: "地址" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "预约单号",
            key: "PreSaleCode",
            width: 110,
            align: "center",
            tooltip: true
          },
          {
            title: "经销商姓名",
            key: "DealerName",
            width: 105,
            align: "center",
            tooltip: true
          },
          {
            title: "短信号码",
            key: "MsgTel",
            width: 110,
            align: "center",
            tooltip: true
          },
          {
            title: "预约上门试压日期",
            key: "PressureStartTime",
            width: 90,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "预约时间",
            key: "HalfDay",
            width: 90,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "预约具体时间",
            key: "DetailTime",
            width: 70,
            align: "center",
            tooltip: true
          },
          {
            title: "购买人身份",
            key: "IdentityDesc",
            width: 85,
            align: "center",
            tooltip: true
          },
          {
            title: "管道类型",
            key: "PipeLineTypeDesc",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom"            
          },
          {
            title: "客户姓名",
            key: "CustName",
            width: 80,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            width: 110,
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddr",
            width: 220,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              let pipetype=params.row.PipeTypeID;
              let reason=params.row.ChangeReason;
              let status=params.row.Status;
              let dealername=params.row.DealerName;
              if(pipetype==126){
                return h('span',{
                  'style':{
                      'color':'red'
                  }
                },params.row.CustAddr);
              }else if(reason!=undefined&&reason!=""&&status<50){
                return h('span',{
                  'style':{
                      'color':'Blue'
                  }
                },params.row.CustAddr);
              }else if(dealername=="工程部(经销商)"){
                return h('span',{
                  'style':{
                      'color':'#FF00FF'
                  }
                },params.row.CustAddr);
              }else{
                return h('span',{},params.row.CustAddr);
              }
            }
          },
          {
            title: "是否家装单",
            key: "IsCompany",
            width: 75,
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => {
              let iscompany = params.row.IsCompany;
              if(iscompany == "是") {
                return h('span',{
                  'style':{
                      'color':'red'
                  }
                },params.row.IsCompany);
              } else {
                return h('span',{},params.row.IsCompany);
              }
            }    
          },
          {
            title: "公司名称",
            key: "Company2Name",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 145,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "试压员",
            key: "WorkerName",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "处理人",
            key: "DisposeByDesc",
            width: 80,
            align: "center",
            tooltip: true,            
          },
          {
            title: "备注",
            key: "Remark",
            width: 120,
            align: "center",
            tooltip: true,            
          },
          {
            title: "上门时间",
            key: "VisitTime",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "改派原因",
            key: "ChangeReason",
            width: 120,
            align: "center",
            tooltip: true,            
          },
          {
            title: "报单号码",
            key: "CallTel",
            width: 110,
            align: "center",
            tooltip: true,            
          },
          {
            title: "收费",
            key: "ChargeAmount",
            width: 70,
            align: "center",
            tooltip: true,            
          },
          {
            title: "连接方式",
            key: "ConnectModeDesc",
            width: 80,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              let mode=params.row.ConnectModeDesc;
              return h('span',{},mode==null?'':mode);
            }            
          },
          {
            title: "地图是否标点",
            key: "IsMark",
            width: 80,
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => {
              let mark=params.row.IsMark;
              if(mark=="是"){
                return h('span',{
                  'style':{
                      'color':'red'
                  }
                },params.row.IsMark);
              }else{
                return h('span',{},params.row.IsMark);
              }
            }       
          },
          // {
          //   title: "销售单备注",
          //   key: "PreRemark",
          //   width: 150,
          //   align: "center",
          //   tooltip: true,            
          // },
          {
            title: "操作",
            key: "bus",
            width: 240,
            align: "center",
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.AcceptOrder(params.row);
                      }
                    }
                  },
                  "接受预约"
                ),
                h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.Edit(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.Delete(params.row);
                      }
                    }
                  },
                  "删除"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.EditWorker(undefined, e);
        return;
      }
      this.PreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    tabchange(index, change) {
      // this.iscompany=index;
      this.GetTableList();
    },
    //接受预约
    AcceptOrder: function(e) {
      var that = this;
      var id = e.ID;
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认接受预约后会短信通知客户</p>",
        onOk: () => {
          this.$http
            .get("PrePress/AcceptPrePress", {
              params: {
                prepressid: id
              }
            })
            .then(res => {
              that.GetTableList();
            });
        },
        onCancel: () => {
          this.$Message.info("已取操作");
        }
      });
    },
    //确认分配试压员
    SelWorker: function(prepressrow) {
      var that = this;
      this.$http
        .get("PrePress/SetWorker", {
          params: {
            prepressid: prepressrow.ID
          }
        })
        .then(res => {
          if (res.data == "success") {
            this.$Message.info("分配成功");
          } else {
            this.$Message.warning(res.data);
          }
          that.GetTableList();
        });
    },
    //修改试压员
    EditWorker: function(prepressrow, workerrow) {
      var that = this;
      this.$http.SetConditions();
      that.IsEditWorker = true;
      if (prepressrow != undefined) {
        this.currentPrePressRow = prepressrow;
        this.workermodal.show = true;
        return;
      }
      if (workerrow != undefined) {
        this.$http
          .get("PrePress/EditWorker", {
            params: {
              workerid: workerrow.ID,
              prepressid: that.currentPrePressRow.ID
            }
          })
          .then(res => {
            if (res.data == "success") {
              this.$Message.info("修改成功");
            } else {
              this.$Message.warning(res.data);
            }
            this.workermodal.show = false;
            that.GetTableList();
          });
        that.IsEditWorker = false;
      }
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      if (this.dateSearchValue != "") {
        conditions.push({
          PropertyName: "PressureStartTime",
          Operater: 2,
          LogicOperater: 0,
          Value: this.dateSearchValue
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    StatusChange: function(e) {
      this.currentStatus = e;
      this.table.pageIndex = 1;
      if (e == "10") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 230,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.AcceptOrder(params.row);
                    }
                  }
                },
                "接受预约"
              ),

              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Edit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Delete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "30") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 300,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.PreSelWorker(params.row);
                    }
                  }
                },
                "预选试压员"
              ),
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.SelWorker(params.row);
                    }
                  }
                },
                "分配试压"
              ),
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Edit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Delete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "40") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 230,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.EditWorker(params.row);
                    }
                  }
                },
                "修改试压员"
              ),
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Edit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Delete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "50") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 160,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.Edit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.AddAgain(params.row);
                    }
                  }
                },
                "再次试压"
              )
            ]);
          }
        });
      }
      this.GetTableList();
    },
    //预选试压员
    PreSelWorker: function(prepressrow, workerrow) {
      var that = this;
      this.$http.SetConditions();
      if (prepressrow != undefined) {
        this.currentPrePressRow = prepressrow;
        this.workermodal.show = true;
        return;
      }
      if (workerrow != undefined) {
        this.$http
          .get("PrePress/PreSetWorker", {
            params: {
              workerid: workerrow.ID,
              prepressid: that.currentPrePressRow.ID
            }
          })
          .then(res => {
            if (res.data == "success") {
              this.$Message.info("预分配成功");
            } else {
              this.$Message.warning(res.data);
            }
            this.workermodal.show = false;
            that.GetTableList();
          });
      }
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_prepresslist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      that.$http
        .get("PrePress/GetPrePressPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            sort: that.table.sort,
            status: that.currentStatus,
            // iscompany: that.iscompany
          }
        })
        .then(res => {
          var list = res.data.ListData.map(item => {
            item.IsCompany = (item.IsCompany == 0 && "否") || "是";
            item.IsMark = (item.IsMark == 0 && "否") || "是";
          });
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    NewPrepress: function(e) {
      if (e) {
        this.$router.push({ name: "orderprepressform" });
      } else {
        this.$router.push({ name: "dealer_newprepress" });
      }
    },
    Edit: function(item) {
      this.$router.push({
        name: "dealer_prepressdetail",
        query: { id: item.ID }
      });
    },
    Delete: function(item) {//删除
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要删除这条预约单吗?",
        onOk: function(e) {
          that.$http
            .get("PrePress/Delete", {
              params: {
                id: item.ID
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("删除成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    },
    AddAgain:function (row) {//再次试压
      var id=row.ID;
      this.$http.get("PrePress/AddPrePressByPre",{
        params:{id:id}
      }).then(res=>{
        if(res.data=="success"){
          this.$Message.info('操作成功');
        }else{
          this.$Message.error(res.data);
        }
      });
    },
    ExportExcel:function () {//导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("PrePress/ExportPrePressExcel",{
        params:{
          status:that.currentStatus
        }
      }).then(res=>{
        that.$Spin.hide();
        that.GetTableList();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    SetAllWorker: function(item) {//一键分配试压
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要一键分配试压吗?",
        onOk: function(e) {
          that.$http
            .get("PrePress/SetAllWorker", {
              params: {
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("分配成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    },
    GetCurEmployee: function() {
      let that = this;
      that.$http.get("Employee/GetCurEmployee").then(res => {
        that.AgentId=res.data.AgentDealerId;
      });
    },
    //跳转地图派单页面
    PrepressMap:function () {
      this.$router.push({
        name: "dealer_prepressmap",
        // query: { id: item.ID }
      });
    }
  },
  created: function() {
    this.GetTableList();
    this.GetCurEmployee();

    // 当前登录用户
    var user = this.$store.state.currentUser;
    this.issystem = user.IsSystem;
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 