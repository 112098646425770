<template>
  <div class="listform">
    <div class="form-title">湘潭2021抽奖记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    <div class="datatable">
      <Table :border="false" border size="small" :no-data-text="数据加载为空" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "SDWorkerTel", label: "水工电话" },
        { value: "WinningCode", label: "兑奖码" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "SDWorkerName",
            align: "center",
            tooltip: true,
          },
          {
            title: "电话",
            key: "SDWorkerTel",
            align: "center",
            tooltip: true,
          },
          {
            title: "奖品",
            key: "PriceName",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "兑奖号码",
            key: "WinningCode",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "抽奖时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
          },
          {
            title: "是否已发放",
            key: "IsGrant",
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.IsGrant ==1 && "success") || "error";
              const text = (row.IsGrant==1&& "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "操作",
            key: "",
            width: 120,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              if(params.row.IsGrant == 0)
              {
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.EditDraw(params.row);
                        }
                      }
                    },
                    "兑换"
                  )
                ]);
              }
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort:" "
      },
    };
  },
  methods: {
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_xtluckydrawlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorker/GetXtDrawPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort },
        })
        .then((res) => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    GoBack: function (e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    EditDraw: function(e) {
      var that = this;
      var id = e.ID;
      this.$http
            .get("SDWorker/EditLuckDraw", {
              params: {
                id: id
              }
            })
            .then(res => {
              if(res.data=="success"){
                this.$Modal.info({
                  title: "提示",
                  content: "兑换成功"
                });
              }
              that.GetTableList();
            });
    },
  },
  created: function () {
    this.GetTableList();
  },
};
</script>
<style>
</style>
 