<template>
  <div>
    <Row :gutter="24">
      <Col :span="8">
        <Card>
          <p slot="title">客户档案信息审核</p>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 30px"
            type="success"
            @click="DoPass()"
            icon="md-cloud-upload"
            >通过</Button
          >
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px"
            type="warning"
            @click="NotPass()"
            icon="md-remove-circle"
            >不通过</Button
          >
          <Button
            slot="extra"
            style="margin-left: 30px"
            type="ifno"
            @click="GoBack()"
            icon="md-arrow-back"
            >返回</Button
          >

          <p>客户姓名: {{ dataobj.CustName }}</p>
          <p>客户电话: {{ dataobj.CustTel }}</p>
          <p>详细地址: {{ dataobj.CustAddress }}</p>
          <p>管材品牌: {{ dataobj.Brand }}</p>
          <p>户型: {{ dataobj.HouseType }}</p>
          <p>是否测漏: {{ dataobj.IsLeak == 0 ? "否" : "是" }}</p>
          <!-- <p>角阀数量: {{ dataobj.ValveNum }}</p>
          <p>软管数量: {{ dataobj.HoseNum }}</p> -->
          <p>
            是否安装净水产品: {{ dataobj.IsInstallFilter == 0 ? "否" : "是" }}
          </p>
          <p>收费金额: {{ dataobj.ChargeMoney }}</p>
          <p>是否清洗完成: {{ dataobj.IsCompleted == 0 ? "否" : "是" }}</p>
          <p>未完成清洗原因: {{ dataobj.UnReason }}</p>
          <p>预约经销商: {{ dataobj.DealerName }}</p>
          <p>试压员: {{ dataobj.WorkerName }}</p>
          <p>备注: {{ dataobj.Remark }}</p>
        </Card>
      </Col>
      <Col :span="4">
        <Card>
          <p slot="title">收费明细信息</p>
          <p v-for="(item,idex) in dataobj.ChargeProductList" :key="index">
              {{item.Name}}({{item.Num}}{{item.Unit}} / {{item.UnitPrice*item.Num}}元)       
          </p>
        </Card>
      </Col>
      <Col :span="12">
        <Tabs type="card" @on-click="handleClick" name="img">
          <TabPane label="图片" name="all" tab="img">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl"
                @click="viewimg(item, 1)"
                :key="index"
                class="img-cell"
                v-bind:style="{
                  'background-image': 'url(' + item + ')',
                  'background-size': 'cover',
                  'background-position': 'center center',
                  'background-repeat': 'no-repeat',
                  'background-color': 'black',
                }"
              ></div>
            </div>
          </TabPane>

          <!-- <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="success"
            :disabled="dis"
            @click="PipeCheck(1)"
            icon="md-cloud-upload"
          >合格</Button>
          <Button
            slot="extra"
            :loading="submitloading"
            style="margin-left: 10px;"
            type="warning"
            :disabled="dis"
            @click="PipeUnCheck(-1)"
            icon="md-remove-circle"
          >不合格</Button> -->
        </Tabs>
      </Col>
    </Row>
    <Modal v-model="imgviewmodal" width="880px" draggable="true">
      <p slot="header" style="text-align: center">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align: center; display: flex; align-items: center">
        <div @click="preImgNew()" @keyup.left="preImg">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img
          style="max-width: 760px; max-height: 600px"
          :src="currentImgurl"
          alt
        />
        <div @click="nextImgNew()" @keyup.right="nextImg">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>

    <!-- <Modal title="管路图不合格" width="600" height="800" v-model="unreasonmodal.show" @on-ok="UnReason_ModalOK" @on-cancel="ModalCancel">
      <Input v-model="unreasonmodal.unreason" :number="unreasonmodal.show" type="textarea" style="width:570px" :autosize="{minRows: 3,maxRows: 10}" placeholder="请填写不合格原因"></Input>
    </Modal> -->
  </div>
</template>

<script>
import { CellGroup, Collapse, CollapseItem, Field, Icon, Image } from "vant";
export default {
  inject: ["reload"],
  data() {
    return {
      // unreasonmodal: {
      //   show: false,
      //   unreason: "",
      //   customerid: ""
      // },
      dataobj: {},
      integralobj: {},
      submitloading: false,
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      // dis: false,
      // ischecks: "",
      agentId: 0,
      activeNames: [""],
      base_url: this.$file.url,
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    viewimg(item) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.imgurl[this.imgurl.length - 1];
      } else {
        nextImgUrl = this.imgurl[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.imgurl.length - 1) {
        nextImgUrl = this.imgurl[index + 1];
      } else {
        nextImgUrl = this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    //提交审核
    DoPass() {
      var url = "/WashCustomer/DoWashAuditPass";
      var that = this;
      this.submitloading = true;
      this.$http
        .get(url, { params: { id: this.$route.params.id } })
        .then((res) => {
          that.submitloading = false;
          var content = "";
          if (res.data == "success") {
            content = "操作成功";
          } else {
            content = res.data;
          }
          this.$Modal.info({ title: "提示", content: content });
          that.GoBack();
        });
    },
    NotPass() {
      var url = "/WashCustomer/DoWashAuditNotPass";
      var that = this;
      this.submitloading = true;
      this.$http
        .get(url, { params: { id: this.$route.params.id } })
        .then((res) => {
          that.submitloading = false;
          var content = "";
          if (res.data == "success") {
            content = "操作成功";
          } else {
            content = "系统异常";
          }
          this.$Modal.info({ title: "提示", content: content });
          that.GoBack();
        });
    },
    nextImgNew() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.imgurl.length - 1) {
        nextImgUrl = this.imgurl[index + 1];
      } else {
        nextImgUrl = this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    preImgNew() {
      var index = this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.imgurl[this.imgurl.length - 1];
      } else {
        nextImgUrl = this.imgurl[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    GetData() {
      this.$http
        .get("/WashCustomer/GetWashCustomer", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          var that = this;
          this.dataobj = res.data.Data;

          if (res.data.Data.Imgs != null && res.data.Data.Imgs.length > 0) {
            var imgStr = res.data.Data.Imgs;
            var imgarray = imgStr.split(",");
            // var imgUrlArray = [];

            var imgUrlArray = imgarray.map((item) => {
              return (
                that.base_url +
                "Images/upload/washCustomer/" +
                res.data.Data.ID +
                "/" +
                item +
                ".jpg"
              );
            });
            that.imgurl = imgUrlArray;
          } else {
            that.imgurl = [];
          }
        });
    },
    // GetIntegralInfo() {
    //   this.$http
    //     .get("/Customer/GetIntegralInfo", {
    //       params: {
    //         id: this.$route.params.id
    //       }
    //     })
    //     .then(res => {
    //       this.integralobj = res.data.Data;
    //     });
    // },
    handleClick: function (e) {
      this.active = e;
      this.GetData(e);
    },
    ModalCancel: function () {
      console.log("取消");
      this.$http.SetConditions();
    },
    GoBack: function () {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    watchKey(e) {
      var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
      if (keyNum == 39) {
        this.nextImg();
        //按下回车按钮要做的事;
      } else if (keyNum == 37) {
        this.preImg();
      }
    },
    onChange: function (event) {
      this.activeNames = event.detail;
    },
  },
  created() {
    this.GetData();
    // this.GetIntegralInfo();

    var that = this;
    document.addEventListener("keydown", that.watchKey);
  },
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Icon.name]: Icon,
  },
};
</script>

<style>
</style>
