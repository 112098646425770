<template>
<div class="listform">
  <div style="background:#eee;padding: 20px">
    <Card :bordered="false">
      <p slot="title">批量导入快递单</p>
      <p>
        <Upload 
          ref="upload"    
          :show-upload-list="true"
          :on-format-error="handleFormatError"
          :on-success="handleSuccess"
          :on-error="handleError"
          :with-credentials="true"
          :format ="['xlsx','xls']"
          :action="uploadUrl">
        <Button type="info" icon="ios-cloud-upload-outline">选择文件</Button>
        </Upload>
      </p>
    </Card>
  </div>
 </div>
</template>

<script>
export default {
  data() {
    return {
      uploadUrl: "",
    };
  },
  props: {
    aftsalerow: ""
  },
  methods: {
    handleFormatError(file){
      this.$Notice.warning({
          title: '文件格式不正确',
          desc: '文件 ' + file.name + ' 格式不正确，请上传.xls,.xlsx文件。'
      })
    },
    handleSuccess(res,file){
      if(res == "success"){
        this.dialoLead = false;
        this.$Message.success("数据导入成功！");
        this.$router.push({ name: "dealer_orderlist" });
        this.$refs.upload.clearFiles();
      }else{
        this.$Message.warning(res);
        this.$refs.upload.clearFiles();
        return;
      }
    },
    handleError(error,file){
        this.$Message.error("数据导入失败！");
    },
},
created: function() {
  // 导入地址
  this.uploadUrl=
    this.$apihost.url+"Order/ImportDeliverySn";
} 
};
</script>

<style>
</style>
