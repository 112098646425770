<template>
 
  <div class="listform">
    <div class="form-title">规格/价格/库存
      <Icon type="briefcase"></Icon>
    </div>
    <Form :label-width="100">
      <FormItem label="商品规格">
        <div v-for="(item, index) in specData" :key="index">
          <FormItem label="规格名">
            <Input style="width:300px" placeholder="输入规格名称" :value="item.SpecName"></Input>
          </FormItem>
          <FormItem label="规格值">
            <CheckboxGroup v-model="checkboxGroup" @on-change="specValueChange">
              <Checkbox v-for="(item2, index2) in item.specValue" 
                :key="index2" 
                :label="`${item2.ID}-${item2.SpecValue},${item.ID}-${item.SpecName}`" 
                style="margin-right:30px;">{{item2.SpecValue}}</Checkbox>
            </CheckboxGroup>
          </FormItem>
          <br/>
        </div>
      </FormItem>

      <FormItem label="规格明细">
         <div class="stepFour">
          <div class="stepFour__name">
            <div
              v-for="(nameItem,nameIndex) in templateNameList"
              :key="`nameItem${nameIndex}`"
              class="item">
              <div v-if="nameIndex!=(templateNameList.length-1) && nameIndex!=(templateNameList.length-2)">
                {{ nameItem.split("-")[1] }}
              </div>
              <div v-else>
              {{ nameItem }}</div></div>
          </div>
          <div v-show="newTemplateData.length>0" class="stepFour__box">
            <div
              v-for="(item,index) in newTemplateData"
              :key="`item${index}`"
              class="stepFour__panel">
              <div
                v-for="(childItem,childIndex) in item"
                :key="`childItem${childIndex}`"
                class="item">
                <span
                  v-show="childIndex!=(item.length-1) && childIndex!=(item.length-2)">
                  {{ childItem.toString().split("-")[1] }}</span>
                <Input
                  v-show="childIndex==(item.length-2)"
                  v-model="childItem.stockNum"
                  placeholder="输入库存"
                  style="width:100%" />
                <Input
                  v-show="childIndex==(item.length-1)"
                  v-model="childItem.price"
                  placeholder="输入价格"
                  style="width:100%" />
              </div>
            </div>
          </div>
        </div>
      </FormItem>

      <FormItem>
        <Button @click="_saveTemplate" type="primary" style="margin-top:10px;margin-left: 50px;">保存</Button>
      </FormItem>
    </Form>
    
  </div>
</template>

<script>
import { Button, Checkbox, CheckboxGroup, Field, Icon, Tag } from 'vant';
export default {
  data() {
    return {
      //每一个sku规格的数据
      templateData: [],
      //  每一个sku规格里面拟定的数据如：颜色里面的[红色，蓝色，黄色]这些
      dynamicTags: [],
      // 这个是第四步步骤里的for循环的颜色的模板名称，如[颜色，尺寸，库存，价格]
      templateNameList: [],
      // 这个是第四步步骤里的for循环的数据，如[红色，S，11个，56元]
      newTemplateData: [],
      checkboxGroup: [],
      specData: [],
      goodsId: 0,
    };
  },
  methods: {
    specValueChange(value){
      this.templateData = [];
      for (var i = 0; i < value.length; i++) {
        var arrindex=this.templateData.findIndex((item, index, arr) => {
          return item.name === value[i].split(",")[1];
        });//获取下标
        if(arrindex==-1){//不存在盘符数据的添加盘符数据
          this.templateData.push({
            name: value[i].split(",")[1],
            dynamicTags: [value[i].split(",")[0]]
          });
        }else{//有盘符则往盘符数据中添加
          this.templateData[arrindex].dynamicTags.push(value[i].split(",")[0]);
        }
      }
      this._nextStep();
    },
    _nextStep() {
      this.newTemplateData = [];
      this.templateNameList = [];
      const array = [];
      this.templateData.forEach(element => {
        this.templateNameList.push(element.name);
        array.push(element.dynamicTags);
      });
      if(array.length>0){
        this.newTemplateData = this.recursionData(array).filter(item => {
          item.push({ stockNum: '' }, { price: '' });
          return item;
        });
        console.log(this.newTemplateData);
        this.templateNameList.push('库存', '价格');
        console.log(this.templateNameList);
      }
      
    },
 // 多数组求笛卡儿积function cartesianProduct(array){
    recursionData(array) {
      return array.reduce(
        function(a, b) {
          return a
            .map(function(x) {
              return b.map(function(y) {
                return x.concat(y);
              });
            })
            .reduce(function(a, b) {
              return a.concat(b);
            }, []);
        },
        [[]]
      )
    },
    // 保存添加的模板
    _saveTemplate() {
      if(this.templateNameList.length==0){
        this.$toast.fail("请选择商品规格");
        return;
      }
      if(this.newTemplateData!=null&&this.newTemplateData.length>0){
        for (let z = 0; z < this.newTemplateData.length; z++) {
          const item = this.newTemplateData[z];
          for (let y = 0; y < item.length; y++) {
            const item2 = item[y];
            if(item2.price==""){
              this.$toast.fail("请输入价格");
              return;
            }
            if(item2.stockNum==""){
              this.$toast.fail("请输入库存");
              return;
            }
          }
        }
      }
      
      var newVal = [];
      const sku = this.newTemplateData.reduce((c, item) => {
        newVal = [];
        for (let k = 0; k < item.length - 2; k++) {
          newVal.push({
            keyName: [...this.templateNameList][k].split("-")[1],
            keyVal: item[k].split("-")[1],
            keyNameId: [...this.templateNameList][k].split("-")[0],
            keyValId: item[k].split("-")[0]
          });
        }
        return c.concat({
          price: item[item.length - 1].price,
          stockNum: item[item.length - 2].stockNum,
          item_id: [...newVal]
        });
      }, []);
      var jsonArryStr=JSON.stringify(sku);
      this.$http.post("Goods/SaveGoodsSku?skuStr="+jsonArryStr+"&goodsId="+this.goodsId).then(res=>{
        if(res.data=="success"){
          this.$toast("操作成功");
          this.$router.back(-1);
        }else{
          this.$toast(res.data);
        }
      });
    },
    GetData() {
      var that = this;
      that.$http.get("Goods/GetGoodsSpecList").then((res) => {
        that.specData = res.data.Data;
      });
    },
    GetSkuData(){
      this.$http.get("Goods/GetGoodsSku?goodsId="+this.goodsId).then((res) => {
        if(res.data.Msg=="success"){
          this.templateNameList=res.data.Data.templateNameList;
          this.newTemplateData=res.data.Data.newTemplateData;
          this.checkboxGroup=res.data.Data.checkList;
        }
      });
    },
  },
  created: function() {
    this.goodsId = this.$route.query.id;
    this.GetData();
    this.GetSkuData();
  },
  components: {
    [Button.name]: Button,
    [Tag.name]: Tag,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
  },
};
</script>

<style>
  .stepFour{
    width: 80%;
    margin-left: 50px;
    /* margin: auto; */
    
  }
  .stepFour__name{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
      
  }
  .item{
    flex: 1;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    border-right: 1px solid #eee;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .stepFour__box{
      
  }
  .stepFour__panel{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
        
  }
  .van-field__control{
    border: 1px solid #eee;
  }
  
</style>
 