<template>
  <div class="listform">
    <div class="form-title">账户管理
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Select filterable style="width:200px" clearable @on-change="AgentSearch">
        <Option v-for="item in select.Agent" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
      </Select>
      <Button type="success" @click="NewAgent" icon="ios-document">新建</Button>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      select: {
        Agent: []
      },

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "TelNum", label: "电话" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "Name",
            width: 180,
            align: "center",
            tooltip: true
          },
          {
            title: "电话号码",
            key: "TelNum",
            width: 180,
            align: "center",
            tooltip: true
          },
          {
            title: "组织机构",
            key: "FilialeDesc",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "代理运营商",
            key: "AgentDealeDesc",
            width: 260,
            align: "center",
            sortable: "custom",
            tooltip: true
          },
          {
            title: "用户名",
            key: "UserName",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "密码",
            key: "PassWord",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "是否服务人员",
            key: "IsWorker",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => { // 0否 1是
              const row = params.row;
              const text = (row.IsWorker == 1 && "是") || "否";
              return h("div",
                text
              );
            }
          },
          {
            title: "是否地市管理员",
            key: "IsMannager",
            width: 200,
            align: "center",
            tooltip: true,
            render: (h, params) => { // 0否 1是
              const row = params.row;
              const text = (row.IsMannager == 1 && "是") || "否";
              return h("div",
                text
              );
            }
          },
          {
            title: "操作",
            key: "",
            width: 200,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.Edit(params.row);
                      }
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    //编辑用户
    Edit: function(item) {
      this.$router.push({
        name: "dealer_agentdetail",
        query: { id: item.ID }
      });
    },
    ModalCancel: function() {},
    NewAgent: function(params) {//新建用户
      this.$router.push({ name: "dealer_newagent" });
    },
    PageChange: function(e) {//分页
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_agentlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {//查询用户列表
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Employee/GetAgentPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {//排序
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {//条件查询
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GetAgent: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.select.Agent = res.data;
      });
    },
    AgentSearch: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      
      if (e != "") {
        conditions.push({
          PropertyName: "AgentDealerId",
          Operater: 2,
          LogicOperater: 0,
          Value: e
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
  },
  created: function() {//页面初始化
    this.GetTableList();
    this.GetAgent();
  }
};
</script>
<style>
</style>
 