<template>
  <div>
    <van-nav-bar
      title="活动规则"
      left-text=""
      left-arrow
      @click-left="onClickLeft()"
    >
  </van-nav-bar>
    <img src="../assets/lydraw/rule_bg.jpg" fit="contain" width="100%" alt="" />
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  name: "LyActivityRule",
  data() {
    return {
    };
  },
  components: {
    [NavBar.name]: NavBar,
  },
  mounted() {
  },
  computed: {
    isLogined() {
      return this.$store.state.isLogin;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1);
    },
  },
};

</script>

<style>
</style>