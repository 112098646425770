<template>
  <div class="listform">
    <div class="form-title">预警审核记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>      
    </div>
    <div class="search_option">
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      table: {
        tablecolumsTitle:  [
          {
            title: "试压地址",
            key: "CustAddress",            
            align: "center",
            tooltip: true,
            fixed: "left"
          },
          {
            title: "水工姓名",
            key: "SDWorkerName",            
            align: "center",
            tooltip: true
          },
          {
            title: "水工电话",
            key: "SDWorkerTel",            
            align: "center",
            tooltip: true
          },
          {
            title: "审核人",
            key: "_CreateDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "审核备注",
            key: "AuditRemark",
            align: "center",
            tooltip: true
          },
          {
            title: "试压时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
            sortable: "custom"
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_warnauditlog",
        params: { workerid: this.$route.params.workerid, sdworkertel: this.$route.params.sdworkertel}
      });
    },
    GetTableList: function() {
      var conditions = [];
      conditions.push({
        PropertyName: "WorkerId",
        Operater: 2,
        LogicOperater: 0,
        Value: this.$route.params.workerid
      });
      conditions.push({
        PropertyName: "SDWorkerTel",
        Operater: 2,
        LogicOperater: 0,
        Value: this.$route.params.sdworkertel
      });

      this.$http.SetConditions(conditions);
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Customer/GetWarnAuditPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort,  }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 