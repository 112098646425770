<template>
  <div class="my-form">
    <div class="form-title">新建售后工单
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="工单编号 ">
        <Input disabled v-model="postdata.AfterSaleCode" style="width:500px" placeholder=""></Input>
      </FormItem>
      <FormItem label="客户姓名 ">
        <Input v-model="postdata.CustomerName" style="width:500px" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem label="客户电话 ">
        <Input v-model="postdata.CustomerTel" style="width:500px" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem label="性别">
        <RadioGroup v-model="postdata.Gender">
          <Radio label="男">男</Radio>
          <Radio label="女">女</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="地址">
        <Cascader @on-change="AddressChange" :data="AddressData" v-model="AddressValue" :load-data="loadregions" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="售后类别">
        <RadioGroup  v-model="postdata.AfterBillTypeCode" @on-change="AfterTypeChange">
          <Radio v-for="item in checkbox.AfterBillType" :key="item.ID" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <!-- <div v-show="showCharge">
         <FormItem label="收费">
          <Input v-model="postdata.Charge" style="width:500px" placeholder="收费请输入大于0的数字"></Input>
        </FormItem>
      </div> -->
      <FormItem label="收费">
          <Input v-model="postdata.Charge" style="width:500px" placeholder="收费请输入大于0的数字"></Input>
        </FormItem>
      <FormItem label="收费类型">
        <CheckboxGroup v-model="postdata.ChargeTypeCode">
            <Checkbox v-for="item in checkbox.ChargeType" :key="item.ID" :label="item.ID">{{item.Name}}</Checkbox>
        </CheckboxGroup>
      </FormItem>
      <div v-show="showWarranty">
        <FormItem label="质保卡类型">
          <RadioGroup v-model="postdata.WarrantyType">
            <Radio :label="1">双重质保卡</Radio>
            <Radio :label="2">系统质保卡</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="质保卡号">
          <Input v-model="postdata.WarrantyNum" style="width:500px" placeholder="请输入质保卡号"></Input>
        </FormItem>
        <FormItem label="试压员">
          <Input v-model="postdata.WorkerName" style="width:500px" placeholder="请输入试压员"></Input>
        </FormItem>
        <FormItem label="试压日期">
          <Input v-model="postdata.WorkerTime" style="width:500px" placeholder="请输入试压日期"></Input>
        </FormItem>
      </div>
      <FormItem  label="可选组织" style="width:500px">
        <RadioGroup v-if="AddressValue.length>0" v-model="postdata.FilialeID">
          <Radio v-for="item in Filiales" :key="item.ID" :label="item.ID">{{item.FilialeName}}</Radio>
        </RadioGroup>
        <p v-else>请先选择地区</p>
      </FormItem>
      <FormItem label="详细地址">
        <Input v-model="postdata.AddressDetail" style="width:500px" placeholder="具体门牌地址..." @on-change="CheckRepeat"></Input>
      </FormItem>
      <FormItem label="详细描述信息">
        <Input v-model="postdata.DetailText" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEdit: "",
      spinShow: false,
      AddressValue: [],
      AddressData: [],
      Filiales: [],
      Sel_Filiale: "",
      // showCharge: false,
      showWarranty: false,
      //AfterBillType: [],
      //CurrentAfterBillType: "",
      checkbox: {
        AfterBillType: [],
        ChargeType: []
      },
      postdata: {
        AddressDetail: "",
        AddressLevel1Id: "",
        AddressLevel2Id: "",
        AddressLevel3Id: "",
        AddressText: "",
        AfterBillTypeCode: 0,
        AfterBillTypeDesc: "",
        AfterSaleCode: "自动生成",
        CustomerName: "",
        CustomerTel: "",
        DetailText: "",
        DisposeBy: 0,
        FilialeID: 0,
        Gender: "男",
        IsResolve: 0,
        ProcessStatusCode: "",
        ProcessStatusDesc: "",
        ID: 0,
        _CreateBy: 0,
        _CreateTime: "",
        _IsDelete: 0,
        _UpdateBy: 0,
        _UpdateTime: "",
        Charge: 0,
        WarrantyNum: "",
        WorkerName: "",
        WorkerTime: "",
        WarrantyType: 0,
        ChargeTypeCode: ""
      },
      acount:0,
    };
  },
  methods: {
    AddressChange: function(value, selectedData) {
      this.AddressValue = selectedData;
    },
    BackList: function() {
      this.$router.push({ name: "aftersalelist" });
    },
    SubmitData: function() {
      var that = this;
      var url = "AfterSaleBill/SaveAfterSaleBill";
      if (typeof that.AddressValue[2].__label != "undefined") {
        this.postdata.AddressLevel1Id = that.AddressValue[0].value + "";
        this.postdata.AddressLevel2Id = that.AddressValue[1].value + "";
        this.postdata.AddressLevel3Id = that.AddressValue[2].value + "";
        this.postdata.AddressText = that.AddressValue[2].__label;
      }
      if (typeof this.$route.params.billid != "undefined") {
        url = "AfterSaleBill/EditAfterSaleBill";
        this.postdata.ID = this.$route.params.billid;
      } else {
        delete this.postdata.ID;
        delete this.postdata._CreateBy;
        delete this.postdata._CreateTime;
        delete this.postdata._IsDelete;
        delete this.postdata._UpdateBy;
        delete this.postdata._UpdateTime;
      }
      var AfterBillDesc = "";
      for (let i = 0; i < this.checkbox.AfterBillType.length; i++) {
        if (
          this.checkbox.AfterBillType[i].ID == this.postdata.AfterBillTypeCode
        ) {
          AfterBillDesc = this.checkbox.AfterBillType[i].Name;
        }
      }
      //售后类别和售后类别名称分别赋值
      this.postdata.AfterBillTypeCode = this.postdata.AfterBillTypeCode;
      this.postdata.AfterBillTypeDesc = AfterBillDesc;
      
      // 收费类型（多选）
      if(this.postdata.ID == undefined) {
        var codes = "";
        if(this.postdata.ChargeTypeCode != null)
        {
          for (let j = 0; j < this.postdata.ChargeTypeCode.length; j++) {
            if(this.postdata.ChargeTypeCode[j]!=null)
            {
              codes += this.postdata.ChargeTypeCode[j] + ",";
            }
          }
          this.postdata.ChargeTypeCode = codes.slice(0, codes.length-1);
        }
      } else {
        var codes = "";
        // if(Array.isArray(this.postdata.ChargeTypeCode)&&this.postdata.ChargeTypeCode.length>0)
        // {
        //   var list=this.postdata.ChargeTypeCode;
        //   list.forEach(item => {
        //     if(item!=null){
        //       codes += item + ",";
        //     }
        //   });
        //   this.postdata.ChargeTypeCode = codes.slice(0, codes.length-1);
        // }
        if(Array.isArray(this.postdata.ChargeTypeCode)&&this.postdata.ChargeTypeCode.length>0)
        {
          var list=this.postdata.ChargeTypeCode;
          list.forEach(item => {
            if(item!=null){
              codes += item + ",";
            }
          });
          this.postdata.ChargeTypeCode = codes.slice(0, codes.length-1);
        }else if(!Array.isArray(this.postdata.ChargeTypeCode)&&this.postdata.ChargeTypeCode!=""){
          var list=this.postdata.ChargeTypeCode.split(",");
          list.forEach(item => {
            if(item!=null){
              codes += item + ",";
            }
          });
          this.postdata.ChargeTypeCode = codes.slice(0, codes.length-1);
        }else{
          this.postdata.ChargeTypeCode = codes;
        }
      }
      
      // if(this.postdata.ChargeTypeCode != null)
      // {
      //   for (let j = 0; j < this.postdata.ChargeTypeCode.length; j++) {
      //     if(this.postdata.ChargeTypeCode[j]!=null)
      //     {
      //       codes += this.postdata.ChargeTypeCode[j] + ",";
      //     }
      //     // codes += this.postdata.ChargeTypeCode[j] + ",";
      //   }
      //   this.postdata.ChargeTypeCode = codes.slice(0, codes.length-1);
      // } 

      if(!this.CheckData(this.postdata)){
        return ;
      }

      this.$http.post(url, this.postdata).then(e => {
        that.BackList();
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.CustomerName == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.CustomerTel == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (obj.AddressDetail == "") {
        content = "请填写详细地址";
        checkflag = false;
      }
      if(obj.Charge>0 && obj.ChargeTypeCode == "") {
        content = "请选择收费类型";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    loadregions: function(item, callback) {
      item.loading = true;
      this.GetRegions(item.level + 1, item.value).then(res => {
        item.children = PushAddress(res.data);
        item.loading = false;
        callback();
      });
    },
    GetRegions: function(level, regionID) {
      let that = this;
      return that.$http.get("Region/GetRegionByLevel", {
        params: {
          level: level,
          regionID: regionID
        }
      });
    },
    GetAfterSaleBill: function() {//获取售后类别信息
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=14").then(res => {
        that.checkbox.AfterBillType = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=36").then(res => {
        that.checkbox.ChargeType = res.data;
      });
    },
    // 重复地址售后记录提示
    CheckRepeat() {
      this.$http.get("/AfterSaleBill/CheckRepeat", {
          params: {
            addressDetail: this.postdata.AddressDetail
          }
        }).then(res => {
          if(res.data!="success"){
            this.$Modal.info({ title: "提示", content: res.data });           
          }
      });
    },
    CheckRepeat2(id) {
      this.$http.get("/AfterSaleBill/CheckRepeat2", {
          params: {
            id: id
          }
        }).then(res => {
          if(res.data.Msg=="success"){
            this.acount=res.data.Data.AftersaleCount;
            var content = "";
            if(this.acount>=1){
              content="该地址有"+this.acount+"次售后记录，<br>";
            }
            if(content!=""){
              content=content+"请核实情况!"
              this.$Modal.info({ title: "提示", content: content });
            }         
          }
      });
    },
    AfterTypeChange: function(value) {
      // 有质保卡
      // if(value == 67){
      //   this.showWarranty = true; 
      //   this.showCharge = false;
      //   this.postdata.Charge=0;
      // }else if(value == 75){
      //   this.showWarranty = false;
      //   this.showCharge = true;
      //   this.postdata.WarrantyNum="";
      //   this.postdata.WorkerName="";
      //   this.postdata.WorkerTime="";
      //   this.postdata.WarrantyType=0;
      // }else{
      //   this.showWarranty = false;
      //   this.showCharge = false;
      //   this.postdata.Charge=0;
      //   this.postdata.WarrantyNum="";
      //   this.postdata.WorkerName="";
      //   this.postdata.WorkerTime="";
      //   this.postdata.WarrantyType=0;
      // }
      if(value == 67){
        this.showWarranty = true; 
      }else{
        this.showWarranty = false;
        this.postdata.WarrantyNum="";
        this.postdata.WorkerName="";
        this.postdata.WorkerTime="";
        this.postdata.WarrantyType=0;
      }
    },
  },
  watch: {
    AddressValue: function(val) {
      if (val.length >= 3) {
        console.log("可以加载组织机构");
        var value=val[2].value;
        if(typeof this.$route.params.billid != "undefined"){
          value=val[2];
        }
        this.$http
          .get("Filiale/GetFilialesByRegion", {
            params: {
              regionId:value
            }
          })
          .then(res => {
            console.log(res.data)
            if (res.data.length > 0) {
              this.Filiales = res.data;
            } else {
              this.$Modal.warning({
                title: "提示",
                content: "<p>该地区系统内暂未录入任何经销/代理商!</p><br><br><p>请进入 系统维护/组织机构维护 进行录入!</p>"
              });
              this.AddressValue=[];
            }
          });
      } else {
        console.log("取消显示");
      }
    }
  },
  created: function() {
    //加载售后类别
    this.GetAfterSaleBill();

    this.isEdit = typeof this.$route.params.billid != "undefined";
    //编辑模式
    if (typeof this.$route.params.billid != "undefined") {
      this.CheckRepeat2(this.$route.params.billid);

      this.spinShow = true;
      this.$http
        .get("AfterSaleBill/EditAfterSaleBillPre", {
          params: { id: this.$route.params.billid }
        })
        .then(res => {
          if (res.status == 200) {            
            this.spinShow = false;
            this.postdata = res.data.Data;
            this.AddressValue.push(parseInt(this.postdata.AddressLevel1Id));
            this.AddressValue.push(parseInt(this.postdata.AddressLevel2Id));
            this.AddressValue.push(parseInt(this.postdata.AddressLevel3Id));
            console.log(this.AddressValue);

            if(this.postdata.AfterBillTypeCode==67){
              this.showWarranty = true; 
            }
            
          }
        });
    }
    this.GetRegions(1, "").then(res => {
      this.AddressData = PushAddress(res.data);
    });
  }
};

//地址数组填充
function PushAddress(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 3) {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID
      });
    }
  });
  return regionList;
}
</script>

<style>
</style>
