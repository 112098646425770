<template>
  <div class="listform">
    <div class="form-title">客户预约单
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="date" @on-change="DateTimeSelect" placement="bottom-end" placeholder="根据预约试压时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <!-- <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button> -->
      <Button @click="SetWashAllWorker()" type="primary" icon="ios-clipboard">一键分配清洗</Button>
      <Button @click="NewPrepress(true)" type="success" icon="ios-clipboard">新增(管道清洗)</Button>
    </div>
    <div class="radio-group">
      <RadioGroup v-model="currentStatus" @on-change="WashStatusChange" type="button">
        <Radio label="10">待处理</Radio>
        <Radio label="30">待分配试压</Radio>
        <Radio label="40">待上传档案</Radio>
        <Radio label="50">已完成</Radio>
      </RadioGroup>
    </div>
    
    <div class="datatable">
        <Table :border="false" size="small" @on-sort-change="WashSort" :columns="washtable.tablecolumsTitle" :loading="washtable.loading" :data="washtable.datalist"></Table>
      </div>
      <div class="footerpage">
        <Page :total="washtable.total" :page-size="washtable.pageSize" @on-change="WashPageChange" :current="washtable.pageIndex" show-elevator></Page>
      </div>
    <Modal title="选择试压员" @on-cancel="ModalCancel" width="1000" height="600" :footer-hide="true" v-model="workermodal.show">
      <worker-RowSel v-if="workermodal.show" v-on:rowsel="WorkerRowSel"></worker-RowSel>
    </Modal>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      currentStatus: "10",
      currentPrePressRow: {},
      IsEditWorker: false,
      workermodal: {
        show: false
      },
      queryvalue: "",
      dateSearchValue: "",
      sel_field: "",
      sel_values: [
        { value: "DealerName", label: "预约经销商" },
        { value: "CustName", label: "姓名" },
        { value: "MsgTel", label: "短信号码" },
        { value: "CustAddr", label: "地址" }
      ],
      washtable: {
        tablecolumsTitle: [
          {
            title: "经销商姓名",
            key: "DealerName",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "短信号码",
            key: "MsgTel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "预约上门清洗日期",
            key: "WashDate",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "购买人身份",
            key: "IdentityDesc",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "客户姓名",
            key: "CustName",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddr",
            width: 230,
            align: "center",
            tooltip: true
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 150,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "试压员",
            key: "WorkerName",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "户型",
            key: "HouseType",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "备注",
            key: "Remark",
            width: 150,
            align: "center",
            tooltip: true,            
          },
          {
            title: "处理人",
            key: "DisposeByDesc",
            width: 120,
            align: "center",
            tooltip: true,            
          },
          // {
          //   title: "报单号码",
          //   key: "CallTel",
          //   width: 120,
          //   align: "center",
          //   tooltip: true,            
          // },
          {
            title: "操作",
            key: "bus",
            width: 240,
            align: "center",
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.WashAcceptOrder(params.row);
                      }
                    }
                  },
                  "接受预约"
                ),
                h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.WashEdit(params.row);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.WashDelete(params.row);
                      }
                    }
                  },
                  "删除"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.WashEditWorker(undefined, e);
        return;
      }
      this.WashPreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    tabchange(index, change) {
      this.GetWashTableList();
    },
    WashAcceptOrder: function(e) {
      var that = this;
      var id = e.ID;
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认接受预约后会短信通知客户</p>",
        onOk: () => {
          this.$http
            .get("WashPrePress/AcceptPrePress", {
              params: {
                prepressid: id
              }
            })
            .then(res => {
              that.GetWashTableList();
            });
        },
        onCancel: () => {
          this.$Message.info("已取操作");
        }
      });
    },
    //确认分配试压员
    WashSelWorker: function(prepressrow) {
      var that = this;
      this.$http
        .get("WashPrePress/SetWorker", {
          params: {
            prepressid: prepressrow.ID
          }
        })
        .then(res => {
          if (res.data == "success") {
            this.$Message.info("分配成功");
          } else {
            this.$Message.warning(res.data);
          }
          that.GetWashTableList();
        });
    },
    //修改试压员
    WashEditWorker: function(prepressrow, workerrow) {
      var that = this;
      this.$http.SetConditions();
      that.IsEditWorker = true;
      if (prepressrow != undefined) {
        this.currentPrePressRow = prepressrow;
        this.workermodal.show = true;
        return;
      }
      if (workerrow != undefined) {
        this.$http
          .get("WashPrePress/EditWorker", {
            params: {
              workerid: workerrow.ID,
              prepressid: that.currentPrePressRow.ID
            }
          })
          .then(res => {
            if (res.data == "success") {
              this.$Message.info("修改成功");
            } else {
              this.$Message.warning(res.data);
            }
            this.workermodal.show = false;
            that.GetWashTableList();
          });
        that.IsEditWorker = false;
      }
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      if (this.dateSearchValue != "") {
        conditions.push({
          PropertyName: "WashDate",
          Operater: 2,
          LogicOperater: 0,
          Value: this.dateSearchValue
        });
      }
      this.$http.SetConditions(conditions);
      this.GetWashTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    WashStatusChange: function(e) {
      this.currentStatus = e;
      this.washtable.pageIndex = 1;
      if (e == "10") {
        this.washtable.tablecolumsTitle.pop();
        this.washtable.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 230,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashAcceptOrder(params.row);
                    }
                  }
                },
                "接受预约"
              ),

              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashEdit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashDelete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "30") {
        this.washtable.tablecolumsTitle.pop();
        this.washtable.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 300,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashPreSelWorker(params.row);
                    }
                  }
                },
                "预选试压员"
              ),
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashSelWorker(params.row);
                    }
                  }
                },
                "分配试压"
              ),
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashEdit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashDelete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "40") {
        this.washtable.tablecolumsTitle.pop();
        this.washtable.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 230,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "primary", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashEditWorker(params.row);
                    }
                  }
                },
                "修改试压员"
              ),
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashEdit(params.row);
                    }
                  }
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: { type: "error", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashDelete(params.row);
                    }
                  }
                },
                "删除"
              )
            ]);
          }
        });
      } else if (e == "50") {
        this.washtable.tablecolumsTitle.pop();
        this.washtable.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 160,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "success", size: "small" },
                  style: { marginRight: "3px" },
                  on: {
                    click: () => {
                      this.WashEdit(params.row);
                    }
                  }
                },
                "编辑"
              )
            ]);
          }
        });
      }
      this.GetWashTableList();
    },
    WashPreSelWorker: function(prepressrow, workerrow) {
      var that = this;
      this.$http.SetConditions();
      if (prepressrow != undefined) {
        this.currentPrePressRow = prepressrow;
        this.workermodal.show = true;
        return;
      }
      if (workerrow != undefined) {
        this.$http
          .get("WashPrePress/PreSetWorker", {
            params: {
              workerid: workerrow.ID,
              prepressid: that.currentPrePressRow.ID
            }
          })
          .then(res => {
            if (res.data == "success") {
              this.$Message.info("预分配成功");
            } else {
              this.$Message.warning(res.data);
            }
            this.workermodal.show = false;
            that.GetWashTableList();
          });
      }
    },
    WashPageChange: function(e) {
      this.washtable.pageIndex = e;
      this.GetWashTableList();
      this.$router.push({
        name: "dealer_washlist",
        query: { pageindex: e }
      });
    },
    GetWashTableList: function() {
      var that = this;
      that.washtable.loading = true;
      that.$http
        .get("WashPrePress/GetWashPrePressPageList", {
          params: {
            pageIndex: that.washtable.pageIndex,
            sort: that.washtable.sort,
            status: that.currentStatus
          }
        })
        .then(res => {
          that.washtable.loading = false;
          that.washtable.datalist = res.data.ListData;
          that.washtable.total = res.data.TotalCount;
          that.washtable.pageSize = res.data.PageSize;
        });
    },
    WashSort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.washtable.sort = sort.key + " " + sort.order;
        } else {
          this.washtable.sort = "";
        }
      }
      this.GetWashTableList();
    },
    NewPrepress: function(e) {
      this.$router.push({ name: "dealer_newwash" });
    },
    WashEdit: function(item) {
      this.$router.push({
        name: "dealer_washdetail",
        query: { id: item.ID }
      });
    },
    WashDelete: function(item) {//删除
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要删除这条预约单吗?",
        onOk: function(e) {
          that.$http
            .get("WashPrePress/Delete", {
              params: {
                id: item.ID
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("删除成功");
                that.GetWashTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    },
    ExportExcel:function () {//导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("PrePress/ExportPrePressExcel",{
        params:{
          status:that.currentStatus
        }
      }).then(res=>{
        that.$Spin.hide();
        that.GetWashTableList();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    SetWashAllWorker: function(item) {//一键分配清洗
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要一键分配清洗吗?",
        onOk: function(e) {
          that.$http
            .get("WashPrePress/SetAllWorker", {
              params: {}
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("分配成功");
                that.GetWashTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    }
  },
  created: function() {
    this.GetWashTableList();
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 