<template>
  <div class="modal_list">
    <!-- <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>      
    </div> -->
    <div class="datatable">
      <Table @on-current-change="RowSel" highlight-row :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.employee_list"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      /* 搜索栏相关数据 */
      sel_field: "",
      sel_values: [
        { value: "CustomerName", label: "姓名" },
        { value: "CustomerTel", label: "电话" }
      ],
      queryvalue: "",

      /* table+翻页相关数据 */
      table: {
        tablecolumsTitle: [
          // { type: "selection", width: 60, align: "center" },
          { title: "ID", key: "ID", width: 60 },
          { title: "编号", key: "LiquidCode" },
          { title: "姓名", key: "Name" },
          { title: "电话", key: "TelNum" },
          { title: "所属机构", key: "FilialeDesc" }
        ],
        employee_list: [],
        pageIndex: 1,
        total: 0,
        pageSize: 20,
        loading: true
      }
    };
  },
  props: {
    aftsalerow: ""
  },
  methods: {
    GetEmployees() {
      this.$http.SetConditions();
      this.$http
        .get("Employee/GetEmployeePageListByFiliale", {
          params: {
            pageIndex: this.table.pageIndex,
            fialiaID: this.aftsalerow.FilialeID
          }
        })
        .then(e => {
          this.table.loading = false;
          this.table.employee_list = e.data.ListData;
          this.table.total = e.data.TotalCount;
          this.table.pageSize = e.data.PageSize;
        });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.loading = true;
      this.GetEmployees();
    },
    Search() {},
    RowSel(e) {
      this.$emit("rowsel", e);
    }
  },
  created: function() {
    this.GetEmployees();
  }
};
</script>

<style>
</style>
