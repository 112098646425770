<template>
  <div class="process_background">
    <van-nav-bar title="我的助力详情" left-text="" left-arrow @click-left="onClickLeft()" />
    <div class="user-poster">
      <van-row class="user-info">
        <van-col offset="2">
          <van-image class="user-avatar" round width="4rem" height="4rem" :src="avatar" />
        </van-col>
        <van-col span="16" class="user-info-text">
          <div class="user-nickname">
            <span>{{userinfo.name}}</span>
          </div>
          <div class="user-mobile">{{userinfo.tel}}</div>
        </van-col>
      </van-row>
    </div>

    <div style="margin-top:20px;" class="process">
      <div style="margin-top:10px;">
        <van-tag round type="primary" style="font-size:14px;">我的助力可用次数{{canHelpNum}}</van-tag>
      </div>
      <div>
        <van-divider content-position="left">我的助力进度</van-divider>
        <van-circle v-model="currentRate" :rate="rate" size="140px" :color="gradientColor" :stroke-width="120"
          :text="processtext"/>
      </div>
      <div>
        <van-divider content-position="left">我的助力列表</van-divider>
        <van-grid :column-num="4" style="margin-top:20px;">
          <van-grid-item text="身份" />
          <van-grid-item text="姓名" />
          <van-grid-item text="电话" />
          <van-grid-item text="时间" />
        </van-grid>
        <van-grid :column-num="4" v-for="(item,idx) in processHelperDetailList" v-bind:key="idx">
          <van-grid-item :text="item.Type" />
          <van-grid-item :text="item.Name" />
          <van-grid-item :text="item.Tel" />
          <van-grid-item :text="item.HelpTime" />
        </van-grid>

      </div>
      <div style="margin-top:20px;">
        <van-button round color="linear-gradient(to right, #ff6034, #ee0a24)" v-on:click="GoProcess">
          自已助力
        </van-button>
        <van-button round color="linear-gradient(to right, #ff6034, #ee0a24)" v-on:click="HelpOtherProcess"
          style="margin-left:10px;">
          他人助力
        </van-button>
      </div>
      
    </div>
    <van-popup v-model="helpProcess" round position="bottom" :style="{ height: '250px' }">
      <div class="login-form">
        <van-field v-model="tel" type="tel" label="手机号" />
        <div class="login-form-btn">
          <van-button round @click="DoHelpProcess" block="" type="info">助力</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>

import {
  Swipe,
  SwipeItem,
  Button,
  Circle,
  Popup,
  Field,
  Toast,
  NavBar,
  Divider,
  Grid,
  GridItem,
  Tag,
  Row,
  Col,
} from "vant";
import AppVue from "../App.vue";
export default {
  data() {
    return {
      userinfo: {
        name: "",
        tel: "",
      },

      sdworkerId: 0,
      helpProcess: false,
      tel: "",
      canHelpNum: 0,
      processHelperDetailList: [],
      // processHelperList: [""],
      rate: 0,
      processtext: "",
      currentRate: 1,
      gradientColor: {
        "0%": "#3fecff",
        "100%": "#6149f6",
      },
      avatar: this.$file.url + "Images/Mall/UserDefaultAvatar/" + "user.jpg",
    };
  },
  methods: {
    GetData() {
      var that = this;

      that.$http
        .get("SDWorker/GetHelperListXt?sdworkerId=" + that.sdworkerId)
        .then((res) => {
          that.processHelperDetailList = res.data.Data;
        });
      that.$http
        .get("SDWorker/GetProcessXt?sdworkerId=" + that.sdworkerId)
        .then((res) => {
          console.log(res.data.Data);
          if (res.data.Msg == "success") {
            that.processtext = "助力进度(" + res.data.Data + "/3)";
            that.rate = res.data.Data/3 * 100;
            that.currentRate = res.data.Data/3 * 100;
          }
        });

      that.$http
        .get("SDWorker/GetCanHelpNumXt?sdworkerId=" + that.sdworkerId)
        .then((res) => {
          console.log(res.data.Data);
          if (res.data.Msg == "success") {
            that.canHelpNum = res.data.Data;
          }
        });

      that.$http
        .get("SDWorker/GetSDWorker?id=" + that.sdworkerId)
        .then((res) => {
          console.log(res.data.Data);
          if (res.data.Msg == "success") {
            that.userinfo.name = res.data.Data.Name;
            that.userinfo.tel = res.data.Data.Tel;
            }
        });
    },
    
    GoProcess() {
      var that = this;
      that.$http.get("SDWorker/AddProcessXt?to=" + "-1").then((res2) => {
        if (res2.data == "success") {
          Toast.success("助力成功");
        } else {
          Toast.fail(res2.data);
        }
        that.GetData();
      });
    },
    HelpOtherProcess() {
      // 查询可助力次数
      if(this.canHelpNum<=0){
        Toast.fail("您的可助力次数不足！");
      }else{
        this.helpProcess = true;
      }
    },
    DoHelpProcess() {
      if (this.tel != "") {
        var that = this;
        that.$http.get("SDWorker/GetSDWorkerByTel", {
            params: {
              sdworkertel: this.tel,
            },
          })
          .then((res) => {
            console.log(res.data.Data);
            if (res.data.Data != null) {
              // 查询输入的手机号码是否有试压记录
              that.$http.get("SDWorker/GetDrawedNumXt", {
                  params: {
                    sdworkerId: res.data.Data.ID
                  },
                })
                .then((res2) => {
                  if (res2.data.Msg == "success"&&res2.data.Data>0) {
                    that.$router.push({
                      name: "otherProcess",
                      params: {sdworkerId:res.data.Data.ID},
                    }); 
                  } else {
                    Toast.fail("该手机号现阶段无符合要求试压记录，无法助力！");
                  }
              });
            } else {
              Toast.fail("该手机号不是系统内用户");
            }
          });
      } else {
        Toast.fail("请填写手机号");
      }
    },
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [Circle.name]: Circle,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [NavBar.name]: NavBar,
    [Divider.name]: Divider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  created() {
    this.sdworkerId = this.$route.params.sdworkerId;
    this.GetData();
  },
};
</script>
<style scoped>
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.processmain {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-content: center;
}
.process_background {
  width: 100%;
  height: 100%;
  /* background-image: url("../assets/process/process.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}
.login-form {
  padding-top: 30px;
  padding: 20px;
}
.login-form-btn {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-poster {
  width: 100%;
  height: 120px;
  background: #099 center top no-repeat;
  background-size: cover;
  text-align: center;
  position: relative;
}
.user-info {
  padding-top: 2rem;
}
.user-avatar {
  align-items: center;
  margin-right: 10px;
}
.user-info-text {
  text-align: left;
  color: #fff;
  font-size: 16px;
}
.user-nickname {
  font-size: 16px;
}
.user-mobile {
  margin-top: 10px;
}
.van-grid {
  border-radius: 5px;
  margin-top: 0px;
  height: 68px;
  overflow: hidden;
}
.process{
  text-align: center;
}
</style>