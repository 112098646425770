import Vue from 'vue'
import Router from 'vue-router'
import login from '@/Page/login.vue'
import index_mobile from '../Mobie/index_mobile'


import aftersale_mobie from '../Mobie/mobieChildCompenent/aftersale_mobie'
import imgupload from '..//Mobie/mobieChildCompenent/imgupload'


import agentlogin from '../Dealer/dealerlogin'
import dealer_index from '../Dealer/dealerindex'
import dealer_customerlist from '../Dealer/customer/customerlist'
import dealer_customerimage from '../Dealer/customer/customerimage'
import dealer_waitauditcustomerlist from '../Dealer/customer/waitauditcustomerlist'
import dealer_waitauditcustomeroption from '../Dealer/customer/waitauditcustomeroption'
import dealer_prepresslist from '../Dealer/prepress/prepresslist'
import dealer_newprepress from '../Dealer/prepress/newprepress'
import dealer_prepressdetail from '../Dealer/prepress/prepressdetail'
import dealer_prepressmap from '../Dealer/prepress_map/prepressmap'
import dealer_presalelist from '../Dealer/presale/presalelist'
import dealer_presaledetail from '../Dealer/presale/presaledetail'
import dealer_prefilterCode from '../Dealer/PreFilterCode/prefiltercodelist'
import dealer_childcustomer from '../Dealer/PreFilterCode/childcustomer'
import dealer_childcustomerimage from '../Dealer/PreFilterCode/childcustomerimage'
import dealer_sdworkerlist from '../Dealer/sdworker/sdworkerlist'
import dealer_sdworkerintegrallog from '../Dealer/sdworker/sdworkerintegrallog'
import dealer_sdworkerMatIntegralLog from '../Dealer/sdworker/sdworkerMatIntegralLog'
import dealer_sdworkerlog from '../Dealer/sdworker/sdworkerlog'
import dealer_rewardIntegral from '../Dealer/sdworker/rewardIntegral' 
import dealer_warnauditlog from '../Dealer/customer/warnauditlog'
import dealer_sdworkerimage from '../Dealer/sdworker/sdworkerimage'
// import dealer_newwashprepress from '../Dealer/prepress/newwashprepress'
// import dealer_washprepressdetail from '../Dealer/prepress/washprepressdetail'

import dealer_washlist from '../Dealer/wash/washlist'
import dealer_newwash from '../Dealer/wash/newwash'
import dealer_washdetail from '../Dealer/wash/washdetail'
import dealer_waitauditwashcustomerlist from '../Dealer/washcustomer/waitauditwashcustomerlist'
import dealer_waitauditwashcustomeroption from '../Dealer/washcustomer/waitauditwashcustomeroption'
import dealer_washcustomerlist from '../Dealer/washcustomer/washcustomerlist'
import dealer_washcustomerimage from '../Dealer/washcustomer/washcustomerimage'


import dealer_workerlist from '../Dealer/worker/workerlist'
import dealer_newworker from '../Dealer/worker/newworker'
import dealer_workerdetail from '../Dealer/worker/workerdetail'
import dealer_childdealerlist from '../Dealer/childdealer/childdealerlist'
import dealer_childdealerdetail from '../Dealer/childdealer/childdealerdetail'
import dealer_childdealerinfo from '../Dealer/childdealer/childdealerinfo'
import dealer_childdealerintegrallog from '../Dealer/childdealer/childdealerintegrallog'
import dealer_childdealercaintegrallog from '../Dealer/childdealer/childdealercaintegrallog'
import dealer_newchilddealer from '../Dealer/childdealer/newchilddealer' 
import aftersalebill from '../Dealer/Aftsale/aftersalebill'
import aftersalelist from '../Dealer/Aftsale/aftersalelist'
import waitauditaftersalelist from '../Dealer/Aftsale/waitauditaftersalelist'
import waitauditaftersaledetail from '../Dealer/Aftsale/waitauditaftersaledetail'
import billdetail from '../Dealer/Aftsale/aftersaledetail'
import dealer_agentlist from '../Dealer/agent/agentlist'
import dealer_newagent from '../Dealer/agent/newagent'
import dealer_agentdetail from '../Dealer/agent/agentdetail'
import dealer_businesslist from '../Dealer/business/businesslist'
import dealer_employeesdworkerlist from '../Dealer/business/employeesdworkerlist'
import dealer_newsdworker from '../Dealer/business/newsdworker'
import dealer_importsdworker from '../Dealer/business/importsdworker'
import dealer_integralrulelist from '../Dealer/IntegralRule/integralrulelist'
import dealer_newintegralrule from '../Dealer/IntegralRule/newintegralrule'
import dealer_integralruledetail from '../Dealer/IntegralRule/integralruledetail'
import dealer_materialintegralrulelist from '../Dealer/materialintegralrule/materialintegralrulelist'
import dealer_newmaterialintegralrule from '../Dealer/materialintegralrule/newmaterialintegralrule'
import dealer_materialintegralruledetail from '../Dealer/materialintegralrule/materialintegralruledetail'


import customerLogin from '../Mobie/OrderPress/customerLogin'
import oldCustomerLogin from '../Mobie/OrderPress/oldCustomerLogin'
import customerImg from  '../Mobie/OrderPress/customerImg'
import oldCustomerImg from  '../Mobie/OrderPress/oldCustomerImg'
import vrview from  '../Mobie/OrderPress/720view'
import vredit from  '../Mobie/OrderPress/vredit'
import vrindex from  '../Mobie/OrderPress/vrindex'
import orderpressform from '../Mobie/OrderPress/orderpressform'
import customerWarranty_Red from  '../Mobie/OrderPress/customerWarranty_Red'
import customerWarranty_White from  '../Mobie/OrderPress/customerWarranty_White'
import OldNewSysSel_prepress from '../Mobie/OldNewSysSel_prepress'
import OldNewSysSel_CustImg from '../Mobie/OldNewSysSel_CustImg'
import customerVisit from  '../Mobie/OrderPress/customerVisit'
import luckdraw2023 from  '../Mobie/OrderPress/LuckDraw'
import luckDrawRecord2023 from  '../Mobie/OrderPress/LuckDrawRecord'
import customerInfo from  '../Mobie/OrderPress/customerInfo'

import dealer_goodslist from  '../Dealer/Mall/goodslist'
import dealer_categorylist from '../Dealer/Mall/categorylist'
import dealer_goodsoption from  '../Dealer/Mall/goodsoption'
import dealer_orderlist from  '../Dealer/Mall/orderlist'
import dealer_luckydrawlist from  '../Dealer/Mall/luckydrawlist'
import dealer_importdelivery from  '../Dealer/Mall/importdelivery'
import dealer_xtluckydrawlist from  '../Dealer/Mall/xtluckydrawlist'
import dealer_luckydrawlist2 from  '../Dealer/Mall/luckydrawlist2'

import sdworker_matlist from '../Dealer/SDWorkerMatrial/sdworkermatList'
import dealer_sdworkermatoption from  '../Dealer/SDWorkerMatrial/sdworkermatoption'
import goods_sku from  '../Dealer/Mall/goodssku'

import dealer_main from  '../Dealer/main/main'

import userLogin from  '../Xiangtan/userLogin'
import luckDraw from '../Xiangtan/luckDraw'
import luckDrawRecord from  '../Xiangtan/luckDrawRecord'
import myCenter from  '../Xiangtan/myCenter'
import otherProcess from  '../Xiangtan/otherProcess'
import process from  '../Xiangtan/process'
import integrallogList from  '../Xiangtan/integrallogList'

import dealer_billCode from '../Dealer/BillCode/billcodelist'
import dealer_filedownload from '../Dealer/download/filedownload'

import dealer_statistics from '../Dealer/Statistics/dealerstatistics'
import dealer_statistics_month from '../Dealer/Statistics/dealerstatisticsByMonth'
import dealer_statistics2_month from '../Dealer/Statistics/dealerstatistics2ByMonth'
import dealer2_statistics from '../Dealer/Statistics/dealer2statistics'

import lyUserLogin from  '../LiuYang/lyUserLogin'
import lyLuckDraw from '../LiuYang/LyLuckDraw'
import lyLuckDrawRecord from  '../LiuYang/LyLuckDrawRecord'
import lyMyCenter from  '../LiuYang/lyMyCenter'
import lyIntegrallogList from  '../LiuYang/LyIntegrallogList'
import lyActivityRule from  '../LiuYang/LyActivityRule'

import dealer_lyluckydrawlist from  '../Dealer/sdworker/lyluckydrawlist'
import dealer_materialloglist from  '../Dealer/workermaterial/materialloglist'
import dealer_materiallist from  '../Dealer/workermaterial/materiallist'
import dealer_newmaterial from  '../Dealer/workermaterial/newmaterial'
import dealer_matstockloglist from  '../Dealer/workermaterial/matstockloglist'
import dealer_companyloglist from  '../Dealer/workermaterial/companyloglist'

// import dealer_rolelist from '../Dealer/role/rolelist'
// import dealer_editrole from '../Dealer/role/editrole'
// import dealer_menulist from '../Dealer/menu/menulist'
// import dealer_editmenu from '../Dealer/menu/editmenu'

Vue.use(Router)


export default new Router({
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path:'/agent',
      name:'agentlogin',
      component:agentlogin,
    },
    {
      path:'/dealerindex',
      name:'dealerindex',
      component:dealer_index,
      children:[
        {
          path:'',
          name:'aftersalelist',
          component:aftersalelist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/waitauditaftersalelist',
          name:'waitauditaftersalelist',
          component:waitauditaftersalelist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'newbill/:billid?',
          name:'newaftersalebill',
          component:aftersalebill
        },
        {
          path:'billdetail/:id',
          name:'billdetail',
          component:billdetail
        },
        {
          path:'waitauditaftersaledetail/:id',
          name:'waitauditaftersaledetail',
          component:waitauditaftersaledetail
        },
        {
          path:'/customerlist',
          name:'dealer_customerlist',
          component:dealer_customerlist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/customerimage/:id',
          name:'dealer_customerimage',
          component:dealer_customerimage,
        },
        {
          path:'/waitauditcustomerlist',
          name:'dealer_waitauditcustomerlist',
          component:dealer_waitauditcustomerlist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/waitauditcustomeroption/:id',
          name:'dealer_waitauditcustomeroption',
          component:dealer_waitauditcustomeroption,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/warnauditlog/:workerid',
          name:'dealer_warnauditlog',
          component:dealer_warnauditlog,
        },
        {
          path:'/prepresslist',
          name:'dealer_prepresslist',
          component:dealer_prepresslist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/presalelist',
          name:'dealer_presalelist',
          component:dealer_presalelist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/presaledetail',
          name:'dealer_presaledetail',
          component:dealer_presaledetail,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/prefiltercodelist',
          name:'dealer_prefiltercodelist',
          component:dealer_prefilterCode,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/billcodelist',
          name:'dealer_billCode',
          component:dealer_billCode,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/filedownload',
          name:'dealer_filedownload',
          component:dealer_filedownload,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/dealer_statistics',
          name:'dealer_statistics',
          component:dealer_statistics,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/dealer_statistics_month',
          name:'dealer_statistics_month',
          component:dealer_statistics_month,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/dealer_statistics2_month',
          name:'dealer_statistics2_month',
          component:dealer_statistics2_month,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/dealer2_statistics',
          name:'dealer2_statistics',
          component:dealer2_statistics,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/dealer_childcustomer/:preFilterCode',
          name:'dealer_childcustomer',
          component:dealer_childcustomer,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/childcustomerimage/:id',
          name:'dealer_childcustomerimage',
          component:dealer_childcustomerimage,
        },
        {
          path:'/childdealerlist',
          name:'dealer_childdealerlist',
          component:dealer_childdealerlist,
          meta: {
            keepAlive: true
          },          
        },
        {
          path:'dealer_childdealerintegrallog/:dealerid',
          name:'dealer_childdealerintegrallog',
          component:dealer_childdealerintegrallog,
          meta: {
            keepAlive: true
          },          
        },
        {
          path:'dealer_childdealercaintegrallog/:dealerid',
          name:'dealer_childdealercaintegrallog',
          component:dealer_childdealercaintegrallog,
          meta: {
            keepAlive: true
          },          
        },
        {
          path:'/childdealerdetail',
          name:'dealer_childdealerdetail',
          component:dealer_childdealerdetail,          
        },
        {
          path:'/childdealerinfo',
          name:'dealer_childdealerinfo',
          component:dealer_childdealerinfo,          
        },
        {
          path:'/newchilddealer',
          name:'dealer_newchilddealer',
          component:dealer_newchilddealer,          
        },
        {
          path:'/workerlist',
          name:'dealer_workerlist',
          component:dealer_workerlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/newworker',
          name:'dealer_newworker',
          component:dealer_newworker,          
        },
        {
          path:'/workerdetail',
          name:'dealer_workerdetail',
          component:dealer_workerdetail,
        },
        {
          path:'/importsdworker',
          name:'dealer_importsdworker',
          component:dealer_importsdworker,          
        },
        {
          path:'/newprepress',
          name:'dealer_newprepress',
          component:dealer_newprepress,
        },
        {
          path:'/prepressdetail',
          name:'dealer_prepressdetail',
          component:dealer_prepressdetail,
        },
        {
          path:'/prepressmap',
          name:'dealer_prepressmap',
          component:dealer_prepressmap,
        },
        {
          path:'/sdworkerlist',
          name:'dealer_sdworkerlist',
          component:dealer_sdworkerlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/sdworkerintegrallog/:sdworkerid',
          name:'dealer_sdworkerintegrallog',
          component:dealer_sdworkerintegrallog,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/sdworkerMatIntegralLog/:sdworkerid',
          name:'dealer_sdworkerMatIntegralLog',
          component:dealer_sdworkerMatIntegralLog,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/sdworkerlog/:sdworkerid',
          name:'dealer_sdworkerlog',
          component:dealer_sdworkerlog,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/rewardIntegral',
          name:'dealer_rewardIntegral',
          component:dealer_rewardIntegral,
        },
        {
          path:'/sdworkerimage/:id',
          name:'dealer_sdworkerimage',
          component:dealer_sdworkerimage,
        },
        // {
        //   path:'/newwashprepress',
        //   name:'dealer_newwashprepress',
        //   component:dealer_newwashprepress,
        // },
        // {
        //   path:'/washprepressdetail',
        //   name:'dealer_washprepressdetail',
        //   component:dealer_washprepressdetail,
        // },
        {
          path:'/Mall/goodslist',
          name:'dealer_goodslist',
          component:dealer_goodslist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/categorylist',
          name:'dealer_categorylist',
          component:dealer_categorylist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/orderlist',
          name:'dealer_orderlist',
          component:dealer_orderlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/luckydrawlist',
          name:'dealer_luckydrawlist',
          component:dealer_luckydrawlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/xtluckydrawlist',
          name:'dealer_xtluckydrawlist',
          component:dealer_xtluckydrawlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/goodsoption',
          name:'dealer_goodsoption',
          component:dealer_goodsoption,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/Mall/importdelivery',
          name:'dealer_importdelivery',
          component:dealer_importdelivery,          
        },
        {
          path:'/Mall/luckydrawlist2',
          name:'dealer_luckydrawlist2',
          component:dealer_luckydrawlist2,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/agentlist',
          name:'dealer_agentlist',
          component:dealer_agentlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/main',
          name:'dealer_main',
          component:dealer_main,         
        },
        {
          path:'/newagent',
          name:'dealer_newagent',
          component:dealer_newagent,
        },
        {
          path:'/agentdetail',
          name:'dealer_agentdetail',
          component:dealer_agentdetail,          
        },
        {
          path:'/businesslist',
          name:'dealer_businesslist',
          component:dealer_businesslist,
          meta: {
            keepAlive: true
          }         
        },
        {
          path:'/employeesdworkerlist/:employeeid',
          name:'dealer_employeesdworkerlist',
          component:dealer_employeesdworkerlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/newsdworker',
          name:'dealer_newsdworker',
          component:dealer_newsdworker,          
        },
        {
          path:'/integralrulelist',
          name:'dealer_integralrulelist',
          component:dealer_integralrulelist,          
        },
        {
          path:'/newintegralrule',
          name:'dealer_newintegralrule',
          component:dealer_newintegralrule,          
        },
        {
          path:'/integralruledetail',
          name:'dealer_integralruledetail',
          component:dealer_integralruledetail,          
        },
        {
          path:'/materialintegralrulelist',
          name:'dealer_materialintegralrulelist',
          component:dealer_materialintegralrulelist,          
        },
        {
          path:'/newmaterialintegralrule',
          name:'dealer_newmaterialintegralrule',
          component:dealer_newmaterialintegralrule,          
        },
        {
          path:'/materialintegralruledetail',
          name:'dealer_materialintegralruledetail',
          component:dealer_materialintegralruledetail,          
        },
        {
          path:'/sdworkermatList',
          name:'sdworker_matlist',
          component:sdworker_matlist,
          meta: {
            keepAlive: true
          }          
        },
        {
          path:'/SDWorkerMatrial/sdworkermatoption',
          name:'dealer_sdworkermatoption',
          component:dealer_sdworkermatoption      
        },
        {
          path:'/Mall/goodssku',
          name:'goods_sku',
          component:goods_sku         
        },
        {
          path:'/washlist',
          name:'dealer_washlist',
          component:dealer_washlist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/newwash',
          name:'dealer_newwash',
          component:dealer_newwash,
        },
        {
          path:'/washdetail',
          name:'dealer_washdetail',
          component:dealer_washdetail,
        },
        {
          path:'/waitauditwashcustomerlist',
          name:'dealer_waitauditwashcustomerlist',
          component:dealer_waitauditwashcustomerlist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/waitauditwashcustomeroption/:id',
          name:'dealer_waitauditwashcustomeroption',
          component:dealer_waitauditwashcustomeroption,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/washcustomerlist',
          name:'dealer_washcustomerlist',
          component:dealer_washcustomerlist,
          meta: {
            keepAlive: true
          },
        },
        {
          path:'/washcustomerimage/:id',
          name:'dealer_washcustomerimage',
          component:dealer_washcustomerimage,
        },
        {
          path:'/lyluckydrawlist',
          name:'dealer_lyluckydrawlist',
          component:dealer_lyluckydrawlist        
        },
        {
          path:'/materialloglist',
          name:'dealer_materialloglist',
          component:dealer_materialloglist        
        },
        {
          path:'/materiallist',
          name:'dealer_materiallist',
          component:dealer_materiallist        
        },
        {
          path:'/newmaterial',
          name:'dealer_newmaterial',
          component:dealer_newmaterial        
        },
        {
          path:'/matstockloglist',
          name:'dealer_matstockloglist',
          component:dealer_matstockloglist        
        },
        {
          path:'/companyloglist',
          name:'dealer_companyloglist',
          component:dealer_companyloglist        
        }
        // {
        //   path:'/rolelist',
        //   name:'dealer_rolelist',
        //   component:dealer_rolelist        
        // },
        // {
        //   path:'/editrole',
        //   name:'dealer_editrole',
        //   component:dealer_editrole,
        // },
        // {
        //   path:'/menulist',
        //   name:'dealer_menulist',
        //   component:dealer_menulist        
        // },
        // {
        //   path:'/editmenu',
        //   name:'dealer_editmenu',
        //   component:dealer_editmenu,
        // },
      ]
    },
    {
      path:'/index_mobile',
      name:'index_mobile',
      component:index_mobile,
      children:[
        {
          path:'',
          name:'aftersale_mobie',
          component:aftersale_mobie
        },
        {
          path:'imgupload/:id',
          name:'imgupload',
          component:imgupload
        }
      ]      
    },
    {
      path: '/customerLogin',
      name: 'customerLogin',
      component: customerLogin
    },
    {
      path: '/oldCustomerLogin',
      name: 'oldCustomerLogin',
      component: oldCustomerLogin
    },
    {
      path: '/vrview/:warranty',
      name: 'vrview',
      component: vrview
    },
    {
      path: '/vredit/:warranty',
      name: 'vredit',
      component: vredit
    },
    {
      path: '/vrindex/:warranty',
      name: 'vrindex',
      component: vrindex
    },
    
    {
      path:'/customerImg/:WarrantyNum',
      name:'customerImg',
      component:customerImg
    },
    {
      path:'/customerInfo/:WarrantyNum',
      name:'customerInfo',
      component:customerInfo
    },
    {
      path:'/oldCustomerImg/:tel',
      name:'oldCustomerImg',
      component:oldCustomerImg
    },
    {
      path: '/luckdraw2023/:warranty',
      name: 'luckdraw2023',
      component: luckdraw2023
    },
    {
      path: '/luckDrawRecord2023/:warranty',
      name: 'luckDrawRecord2023',
      component: luckDrawRecord2023
    },
    {
      path:'/customerWarranty_Red/:id',
      name:'customerWarranty_Red',
      component:customerWarranty_Red
    },
    {
      path:'/customerWarranty_White/:id',
      name:'customerWarranty_White',
      component:customerWarranty_White
    },
    {
      path:'/OldNewSysSel_prepress',
      name:'OldNewSysSel_prepress',
      component:OldNewSysSel_prepress
    },
    {
      path:'/OldNewSysSel_CustImg',
      name:'OldNewSysSel_CustImg',
      component:OldNewSysSel_CustImg
    },
    {
      path: '/orderprepressform',
      name: 'orderprepressform',
      component: orderpressform
    },
    {
      path:'/customerVisit',
      name:'customerVisit',
      component:customerVisit
    },
    {
      path: '/userLogin',
      name: 'userLogin',
      component: userLogin
    },
    {
      path: '/luckDraw/:period',
      name: 'luckDraw',
      component: luckDraw
    },
    {
      path: '/luckDrawRecord',
      name: 'luckDrawRecord',
      component: luckDrawRecord
    },
    {
      path: '/myCenter',
      name: 'myCenter',
      component: myCenter
    },
    {
      path: '/otherProcess/:sdworkerId',
      name: 'otherProcess',
      component: otherProcess
    },
    {
      path: '/process/:sdworkerId',
      name: 'process',
      component: process
    },
    {
      path: '/integrallogList/:sdworkerid',
      name: 'integrallogList',
      component: integrallogList
    },
    {
      path: '/lyUserLogin',
      name: 'lyUserLogin',
      component: lyUserLogin
    },
    {
      path: '/lyLuckDraw/:period',
      name: 'lyLuckDraw',
      component: lyLuckDraw
    },
    {
      path: '/lyLuckDrawRecord',
      name: 'lyLuckDrawRecord',
      component: lyLuckDrawRecord
    },
    {
      path: '/lyMyCenter',
      name: 'lyMyCenter',
      component: lyMyCenter
    },
    {
      path: '/lyIntegrallogList/:sdworkerid',
      name: 'lyIntegrallogList',
      component: lyIntegrallogList
    },
    {
      path: '/lyActivityRule',
      name: 'lyActivityRule',
      component: lyActivityRule
    }
  ]
})
