export default {
    init: function (){
      const AK = "ZZNBZ-A4X3S-FUNOJ-6FBLD-3NCSH-T4BPK";
      //<script charset="utf-8" src="https://map.qq.com/api/gljs?v=1.exp&key=ZZNBZ-A4X3S-FUNOJ-6FBLD-3NCSH-T4BPK"></script>
      const TMap_URL = "https://map.qq.com/api/gljs?v=1.exp&libraries=tools,geometry&key="+ AK +"&callback=onMapCallback";
      return new Promise((resolve, reject) => {
        // 如果已加载直接返回
        if(typeof TMap !== "undefined") {
          resolve(TMap);
          return true;
        }
        // 地图异步加载回调处理
        window.onMapCallback = function () {
          resolve(TMap);
        };
  
        // 插入script脚本
        let scriptNode = document.createElement("script");
        scriptNode.setAttribute("type", "text/javascript");
        scriptNode.setAttribute("src", TMap_URL);
        document.body.appendChild(scriptNode);
      });
    }
  } 
