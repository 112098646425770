<template>
  <div>
    <div style="margin-top:2px;">
      <img src="../assets/logo3.png" fit="contain" width="100%" alt="" />
    </div>
    <Card :bordered="false" dis-hover>
      <p slot="title" style="font-weight:bold;">管道图查看下载</p>
      <p >长沙新系统启用时间是2018年9月，部分地区新、旧系统同时使用，请根据管路安装时间在新、旧系统查询。</p>
    </Card>
    <div style="padding:15px;">
      <Button type="success" @click="newsystem" long style="width:100%;height:35px;">新系统通道</Button>
    </div>
    <div style="padding:15px;">
      <Button type="primary" @click="oldsystem" long style="width:100%;height:35px;">旧系统通道</Button>
    </div>
  </div>
</template>


<script>
export default {
  methods:{
    newsystem(){
      this.$router.push({name:"customerLogin"});
    },
    oldsystem(){     
      this.$router.push({name:"oldCustomerLogin"}); 
      // window.location="http://wechat.reaca.net/CustomerServer/GetCustomerServer#/";
    }
  }
};
</script>

<style>
</style>
