<template>
  <div class="listform">
    <div class="form-title">水工试压记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>      
    </div>
    <div class="search_option">
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" }
      ],
      table: {
        tablecolumsTitle:  [
          {
            title: "质保卡号",
            key: "WarrantyNum",
            width: 150,            
            align: "center",
            tooltip: true,
            // fixed: "left"
          },
          {
            title: "客户姓名",
            key: "CustName",  
            width: 120,          
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel", 
            width: 120,           
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddress",
            width: 300,
            align: "center",
            tooltip: true
          },
          {
            title: "积分",
            key: "ChangeIntegralNumber",
            width: 100,
            align: "center",
            tooltip: true
          },
          // {
          //   title: "管道米数",
          //   key: "PipeLength",
          //   align: "center",
          //   tooltip: true
          // },
          {
            title: "丝口数量",
            key: "ScrewNum",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "试压员",
            key: "WorkerNameDesc",
            width: 130,
            align: "center",
            tooltip: true
          },
          {
            title: "管道类型",
            key: "PipeLineTypeDesc",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "试压日期",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            // width: 150,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.ViewImgs(params.row);
                      }
                    }
                  },
                  "试压图"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_sdworkerlog",
        params: { dealerid: this.$route.params.sdworkerid }
      });
    },
    GetTableList: function() {
      var conditions = [];
      conditions.push({
        PropertyName: "SDWorkerId",
        Operater: 2,
        LogicOperater: 0,
        Value: this.$route.params.sdworkerid
      });

      this.$http.SetConditions(conditions);
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Customer/GetCustomerLogPageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort,
            sdworkerId: this.$route.params.sdworkerid
          }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    ViewImgs: function(row) {
      this.$router.push({
        name: "dealer_sdworkerimage",
        params: { id: row.ID, agentId: row.AgentId }
      });
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 