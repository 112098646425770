<template>
  <div class="page page-login" id="page-login">
    <van-nav-bar title="登录" />

    <div class="loggin-box">
      <h2>会员登录</h2>
      <van-cell-group>
        <van-field v-model="tel" label="手机号" placeholder="请输入手机号" left-icon="contact" />
        <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码" left-icon="certificate">
          <!-- <template #button>
            <van-button size="small" :loading="smsLoading" @click="SendSms" type="primary">发送验证码</van-button>
          </template> -->
        </van-field>
      </van-cell-group>
      <van-row class="btn-box" type="flex" justify="center">
        <van-col span="8">
          <van-button size="small" type="primary" :loading="smsLoading" @click="SendSms">发送验证码</van-button>
        </van-col>
        <van-col span="6">
          <van-button size="small" type="info" @click="Login">登录</van-button>
        </van-col>
      </van-row>
    </div>

    <van-divider />
  </div>
</template>
<script>
import { CellGroup, Col, Divider, NavBar, Row } from "vant";
import { Sticky } from "vant";
import { Popup } from "vant";
import { Button, Field } from "vant";
import AppVue from '../App.vue';
export default {
  data() {
    return {
      smsLoading: false,
      sms: "",
      tel: "",
      active: 0,
      user: {},
    };
  },
  computed: {
    loginshow: {
      get() {
        return this.$store.state.loginShow;
      },
      set() {
        this.$store.commit("hideLogin");
      },
    },
    cartNum(){
      return this.$store.state.cartCount;
    }
  },
  methods: {
    ShowLogin() {
      this.$store.commit("showLogin");
    },
    SendSms() {
      if (this.tel != "") {
        var that = this;
        this.smsLoading = true;
        this.$http
          .get("Common/SendAuthCode", {
            params: {
              tel: this.tel,
              usertype: "sdworker",
            },
          })
          .then((res) => {
            that.smsLoading = false;
            if (res.data == "success") {
              that.$toast("发送成功!");
            } else {
              that.$toast(res.data);
            }
          });
      } else {
        that.$toast("请填写手机号");
      }
    },
    Login() {
      var that = this;
      if(this.tel==""){
        that.$toast("请填写手机号");
      }
      this.$http
        .get("SDWorker/SDWorkerLogin", {
          params: {
            tel: this.tel,
            authcode: this.sms,
            agentid: 5,
          },
        })
        .then((res) => {
          if (res.data == "success") {
            that.$store.commit("login");
            that.$store.commit("hideLogin");
            that.$toast.success("登录成功");
            that.$router.push({
                name: "lyMyCenter",
                params: {},
            });
            that.$http.get("SDWorker/GetSDWorkerByTel?sdworkertel="+that.tel).then(res2=>{
              that.$store.commit("setCurrentUser", res2.data.Data);
              console.log(that.$store.state.currentUser);
            })
          }else{
            that.$toast(res.data);
          }
        });
    },
    /*返回 */
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  mounted() {

    var that = this;
    this.$http.get("SDWorker/CheckLogin").then((res) => {
      if (res.data == "success") {
        that.$store.commit("login");
        that.$store.commit("hideLogin");        
      }
    });    
  },
  beforeMount() {
    var h = document.documentElement.clientHeight || document.body.clientHeight;

    this.curHeight = h - 50; //减去页面上固定高度height
  },
  components: {
    [NavBar.name]: NavBar,
    [Sticky.name]: Sticky,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Row.name]: Row,
    [Col.name]: Col,
    [Divider.name]: Divider,
  },
};
</script>
<style scoped>
.loggin-box {
    margin: 20px;
    margin-top: 15%;
    padding: 30px 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: center;
  }
  .logo {
      position: relative;
      margin-top: -50%;
    }
    .btn-box {
      margin-top: 30px;
    }
  
</style>