<template>
  <div class="my-form">
    <div class="form-title">新建预约单
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="姓名">
        <Input v-model="postdata.BuyerName" style="width:500px" placeholder="输入客户姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="postdata.BuyerTel" style="width:500px" placeholder="输入客户电话"></Input>
      </FormItem>
      <FormItem label="短信电话 ">
        <Input v-model="prepressData.MsgTel" style="width:500px" placeholder="用于发送试压服务进度的短信接收号码"></Input>
      </FormItem>
      <FormItem label="身份">
        <RadioGroup v-model="postdata.CurrentBuyerIdentity">
          <Radio v-for="item in postdata.BuyerIdentity" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="管材类型">
        <RadioGroup v-model="postdata.CurrentPipeLineType" @on-change="PipeTypeChange">
          <Radio v-for="item in postdata.PipeLineType" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否家装单*">
        <RadioGroup v-model="prepressData.IsCompany" @on-change="RadioChange">
          <Radio :label="0">否</Radio>
          <Radio :label="1">是</Radio>
        </RadioGroup>
      </FormItem>
      <div v-show="showCompany">
        <FormItem label="家装公司">
          <RadioGroup v-model="prepressData.CurrentCompanyType">
            <Radio v-for="item in prepressData.CompanyType" :label="item.ID">{{item.Name}}</Radio>
          </RadioGroup>
        </FormItem>
      </div>
      <div v-show="showChargeAmount">
         <FormItem label="收费">
          <Input v-model="prepressData.ChargeAmount" style="width:500px" placeholder="收费请输入大于0的数字"></Input>
        </FormItem>
      </div>
      <FormItem label="行政区">
        <Cascader @on-change="AddressChange" :data="AddressData" v-model="postdata.selRegionCode" :load-data="loadregions" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="安装地址">
        <Input v-model="postdata.InstallAddress" style="width:500px" placeholder="具体门牌地址" @on-change="CheckRepeat"></Input>
      </FormItem>
      <FormItem label="预约试压日期">
        <DatePicker v-model="prepressData.prepressDate" type="date" placeholder="Select date" style="width: 200px"></DatePicker>
      </FormItem>
      <FormItem label="预约时间选择">
        <RadioGroup v-model="prepressData.HalfDay" @on-change="RadioChange">
          <Radio key="上午" label="上午">上午</Radio>
          <Radio key="下午" label="下午">下午</Radio>
          <Radio key="上午/下午" label="上午/下午">上午/下午</Radio>
        </RadioGroup>
      </FormItem>
      <!-- <FormItem label="预约时间选择">
        <CheckboxGroup v-model="prepressData.HalfDay">
            <Checkbox key="上午" label="上午">上午</Checkbox>
            <Checkbox key="下午" label="下午">下午</Checkbox>
        </CheckboxGroup>
      </FormItem> -->
      <div v-show="showTime">
        <FormItem label="具体时间">
          <TimePicker v-model="prepressData.DetailTime" format="HH:mm" placeholder="Select time" style="width: 120px" />
        </FormItem>
      </div>
      <FormItem label="报单号码">
        <Input v-model="prepressData.CallTel" style="width:500px" placeholder="输入报单号码"></Input>
      </FormItem>
      <FormItem label="备注">
        <Input v-model="prepressData.Remark" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <!-- <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button> -->
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // AddressValue: [],
      AddressData: [],
      showChargeAmount: false,
      showTime: false,
      showCompany: false,
      postdata: {
        BuyerName: "",
        BuyerTel: "",
        KitchenNum: "",
        WashNum: "",
        OwnerName: "",
        OwnerTel: "",
        BuyerIdentity: [],
        InstallAddress: "",
        CurrentBuyerIdentity: "",
        PipeLineType: [],
        CurrentPipeLineType: "",
        region: "省市区",
        selRegionCode: []
      },
      prepressData: {
        presalecode: "",
        prepressDate: "",
        MsgTel: "",
        ChargeAmount: 0,
        CallTel: "",
        Remark: "",
        IsCompany: 0,
        DetailTime: "",
        CompanyType: [],
        CurrentCompanyType: 0,
        HalfDay: "",
      }
    };
  },
  methods: {
    AddressChange: function(value, selectedData) {
      // this.postdata.selRegionCode = selectedData;
      this.postdata.region =
        selectedData[0].label + selectedData[1].label + selectedData[2].label;
    },
    PipeTypeChange: function(value) {//选择管道类型改变事件
      if(value==13){//皓白系列
        this.showChargeAmount=true; 
      }else{
        this.showChargeAmount=false; 
        this.prepressData.ChargeAmount=0;
      }
    },
    RadioChange: function(value) {
      if(value==1){
        this.showTime=true;
        this.showCompany=true; 
      }else{
        this.showTime=false; 
        this.prepressData.DetailTime="";
        this.showCompany=false; 
        this.prepressData.CurrentCompanyType=0;
      }
    },

    SubmitData: function() {
      var that = this;
      var url = "PreSale/AddPresale";

      var BuyerIdentityDesc = "";
      for (let i = 0; i < this.postdata.BuyerIdentity.length; i++) {
        if (
          this.postdata.BuyerIdentity[i].ID ==
          this.postdata.CurrentBuyerIdentity
        ) {
          BuyerIdentityDesc = this.postdata.BuyerIdentity[i].Name;
        }
      }
      var PipeTypeDesc = "";
      for (let i = 0; i < this.postdata.PipeLineType.length; i++) {
        if (
          this.postdata.PipeLineType[i].ID == this.postdata.CurrentPipeLineType
        ) {
          PipeTypeDesc = this.postdata.PipeLineType[i].Name;
        }
      }
      // var halfdays = "";
      // for (let i = 0; i < this.prepressData.HalfDay.length; i++) {
      //   if(this.prepressData.HalfDay[i]!=null)
      //   {
      //     halfdays += this.prepressData.HalfDay[i] + ",";
      //   }
      // }
      // halfdays = halfdays.slice(0, halfdays.length-1);
      var data = {
        BuyerName: this.postdata.BuyerName,
        BuyerTel: this.postdata.BuyerTel,
        BuyerIdentity: this.postdata.CurrentBuyerIdentity,
        BuyerIdentityDesc: BuyerIdentityDesc,
        WashNum: 0,
        KitchenNum: 0,
        InstallAddress: this.postdata.InstallAddress,
        OwnerName: this.postdata.BuyerName,
        OwnerTel: this.postdata.BuyerTel,
        PipeType: this.postdata.CurrentPipeLineType,
        PipeTypeDesc: PipeTypeDesc,
        RegionLevel1: this.postdata.selRegionCode[0],
        RegionLevel2: this.postdata.selRegionCode[1],
        RegionLevel3: this.postdata.selRegionCode[2],
        RegionFullDesc: this.postdata.region,
        _CreateTime: new Date()
      };
      if(!this.CheckData(data)){
        return ;
      }

      this.$http.post(url, data).then(e => {
        if (e.data.Msg == "success") {
          that.prepressData.presalecode = e.data.Data.AutoPreSaleCode;
          that.$http
            .get("prepress/addprepress", {
              params: {
                presalecode: that.prepressData.presalecode,
                prepressdate: that.prepressData.prepressDate,
                msgtel: that.prepressData.MsgTel,
                chargeAmount: that.prepressData.ChargeAmount,
                callTel: that.prepressData.CallTel, 
                remark: that.prepressData.Remark, 
                isCompany: that.prepressData.IsCompany,
                time: that.prepressData.DetailTime,
                companyId: that.prepressData.CurrentCompanyType,
                halfDay: that.prepressData.HalfDay        
              }
            })
            .then(res => {
              var modaltitle = "";
              if (res.data != "success") {
                modaltitle = res.data;
              } else {
                modaltitle = "提交成功！";
                that.$router.push({ name: "dealer_prepresslist" });
              }
              this.$Modal.info({
                title: "提示",
                content: modaltitle
              });
            });
        }
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.BuyerName == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.BuyerTel == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (this.prepressData.MsgTel == "") {
        content = "请填写短信电话";
        checkflag = false;
      }
      if (obj.BuyerIdentityDesc == "") {
        content = "请选择身份信息";
        checkflag = false;
      }
      if (obj.PipeTypeDesc == "") {
        content = "请选择管道类型";
        checkflag = false;
      }
      if (this.postdata.selRegionCode.length <= 0) {
        content = "请选择行政区";
        checkflag = false;
      }
      if (obj.InstallAddress =="") {
        content = "请填写安装地址";
        checkflag = false;
      }
      if (this.prepressData.prepressDate =="") {
        content = "请选择预约试压日期";
        checkflag = false;
      }
      if (this.prepressData.HalfDay =="") {
        content = "请选择预约时间选择";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    loadregions: function(item, callback) {
      item.loading = true;
      this.GetRegions(item.level + 1, item.value).then(res => {
        item.children = PushAddress(res.data);
        item.loading = false;
        callback();
      });
    },
    GetRegions: function(level, regionID) {
      let that = this;
      return that.$http.get("Region/GetRegionByLevel", {
        params: {
          level: level,
          regionID: regionID
        }
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=5").then(res => {
        that.postdata.BuyerIdentity = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=4").then(res => {
        that.postdata.PipeLineType = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=33").then(res => {
        that.prepressData.CompanyType = res.data;
      });
    },
    // 重复地址试压记录提示
    CheckRepeat() {
      this.$http
        .get("/Customer/CheckRepeat", {
          params: {
            custaddr: this.postdata.InstallAddress
          }
        }).then(res => {
          if(res.data!="success"){
            this.$Modal.info({ title: "提示", content: res.data });           
          }
      });
    }
  },
  watch: {},
  created: function() {
    this.GetCheckbox();
    this.GetRegions(1, "").then(res => {
      this.AddressData = PushAddress(res.data);
    });
  }
};

//地址数组填充
function PushAddress(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 3) {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID
      });
    }
  });
  return regionList;
}
</script>

<style>
</style>
