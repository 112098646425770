<template>
  <div class="listform">
    <div class="form-title">2024抽奖记录(下)
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      sel_values: [
        { value: "SDWorkerTel", label: "水工电话" },
        { value: "WinningCode", label: "兑奖码" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "SDWorkerName",
            align: "center",
            tooltip: true,
          },
          {
            title: "电话",
            key: "SDWorkerTel",
            align: "center",
            tooltip: true,
          },
          {
            title: "奖品",
            key: "PrizeName",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "兑奖号码",
            key: "WinningCode",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "抽奖时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
          },
          {
            title: "是否已发放",
            key: "IsGrant",
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.IsGrant ==1 && "success") || "error";
              const text = (row.IsGrant==1&& "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
    };
  },
  methods: {
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorker/GetLuckyDrawRecord2024", {
          params: { 
            period: 2,
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort 
          },
        }).then((res) => {
          res.data.ListData.forEach(item => {
            switch (item.WinningNum) {
              case 1:
                item.PrizeName="28.8元红包";
                break;
              case 2:
                item.PrizeName=item.Prize!=null?item.Prize:"天力保温杯";
                break;
              case 3:
                item.PrizeName=item.Prize!=null?item.Prize:"天力冲锋衣";
                break;
              case 4:
                item.PrizeName=item.Prize!=null?item.Prize:"天力热熔机";
                break;
              case 5:
                item.PrizeName=item.Prize!=null?item.Prize:"按摩足浴桶";
                break;
              case 6:
                item.PrizeName="2024元红包";
                break;
              default:
                break;
            }
          });
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_luckydrawlist",
        query: { pageindex: e }
      });
    },
    GoBack: function (e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
  },
  created: function () {
    this.GetTableList();
  },
};
</script>
<style>
</style>
 