<template>
  <div style="height: 100%" class="vrpage">
    <!--vr通知栏-->
    <van-notice-bar
      left-icon="volume-o"
      color="#FFFFFF"
      background="#3D3D3D"
      class="logoimg"
      :text="noticeText"
    >
    </van-notice-bar>
    <!--logo-->
    <img class="logo" :src="logourl" alt="" />

    <!-- 自定义导航栏 -->
    <div class="navbar"></div>
    <van-action-sheet
      v-model="showmodal"
      :actions="imgList"
      @select="onSelect"
      description="选择要跳转的场景"
    />

    <van-action-sheet
      v-model="showDelete"
      :actions="deleteAction"
      @select="onDelSelect"
      description="是否删除"
    />
    <!--  -->
    <img
      class="xiangkuang"
      :src="xiangkuangImgUrl"
      alt=""
      v-show="showCamera"
    />
    <div ref="arrow" class="arrow" v-show="showNav" id="arrow">
      <!-- <van-icon class-prefix="icon" name="arrow_up_fill" size="35" /> -->
      <img
        style="width: 50px; height: 50px"
        src="http://reaca-font2.oss-cn-hangzhou.aliyuncs.com/pic/ahead.gif"
      />
      <p class="arrow-text">{{ navTo.name }}</p>
    </div>
    <div id="viewer" style="width: 100vw; height: 100vh"></div>
    <div class="tools">
      <div class="tools-btn" @click="openCamera">
        <van-icon name="eye" size="30" />
        <p>视角</p>
      </div>
      <div class="tools-btn" @click="editScene">
        <van-icon name="location" size="30" />
        <p>场景</p>
      </div>
      <div class="tools-btn" @click="sortImg">
        <van-icon name="sort" size="30" />
        <p>排序</p>
      </div>
      <div class="tools-btn" @click="EditNotice">
        <van-icon name="font" size="30" />
        <p>横幅</p>
      </div>
      <div v-show="showSave" class="tools-btn" @click="save">
        <van-icon name="checked" size="30" />
        <p>保存</p>
      </div>
    </div>
    <div class="imgList" id="imgList">
      <draggable
        v-model="imgList"
        chosen-class="chosen"
        force-fallback="true"
        group="img"
        animation="1000"
        :disabled="sortdisabled"
      >
        <transition-group class="warp">
          <div
            class="imgList-det"
            v-for="(item, index) in imgList"
            @click="changeImg(index)"
            :key="index"
          >
            <p class="title">{{ item.name }}</p>
            <img class="img" :src="item.url" alt="" />
          </div>
        </transition-group>
      </draggable>
    </div>
    <van-dialog
      v-model="showEditNotice"
      title="轮播信息编辑"
      show-cancel-button
      @confirm="saveNoice"
    >
      <van-field
        v-model="noticeText"
        rows="3"
        autosize
        type="textarea"
        maxlength="250"
        placeholder="请输入想要滚动的横幅内容"
        show-word-limit
      />
    </van-dialog>
  </div>
</template>
        

<script>
import draggable from "vuedraggable";
import { Viewer } from "photo-sphere-viewer";
import { Animation } from "photo-sphere-viewer";
import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers";

import "photo-sphere-viewer/dist/plugins/markers.css";
import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import { Col, Row, NoticeBar, Icon, Toast, ActionSheet, Dialog } from "vant";
require("@/assets/iconfont/iconfont.css/"); //正确

var viewer = {};
var markersPlugin = {};

export default {
  data() {
    return {
      customerInfo: {},
      vrLogoObject: {
        id: "image",
        longitude: "0deg",
        width: 150,
        height: 150,
        latitude: "-90deg",
        image: require("../../assets/vr_logo.png"),
        // image:"http://mall.reaca.net/img/%E5%B9%B4%E8%B4%A7%E7%A4%BC%E5%8C%85.8ead0a9f.jpg",
      },
      currentImg: {},
      sortdisabled: true, // 是否禁用拖动排序
      showNav: false, //展示导航箭头
      showSave: false, //展示保存按钮 开启编辑时才展示
      showCamera: false, //相框展示 选择视角时才会展示
      showDelete: false, //展示删除导航选项
      showEditNotice: false, //编辑顶部轮播文字的弹窗
      deleteAction: [{ name: "删除" }],
      navTo: { url: "", name: "" }, //要导航到的场景
      noticeText: "坚守敬畏之心，铸造匠心精品，家装好水管，就用天力管！",
      xiangkuangImgUrl: require("../../assets/xiangkuang.png"),
      logourl: require("../../assets/title_logo.png"),
      imgList: [],
      showmodal: false,
      arrowImgUrl: require("../../assets/arrow.png"),
      toAddMarker: [],
      arrowLgt: 0,
      arrowLat: 0,
      lock: false,
      markerId: "",
    };
  },
  created: function () {},
  mounted: function () {
    this.LoadData();
    window.vuethis = this;
    // this.musicPlay();
  },
  methods: {
    sortImg() {
      this.sortdisabled = false;
      this.showSave = true;
      Toast("拖动下方图片即可进行排序");
    },

    //编辑场景跳转
    editScene() {
      this.showmodal = true;
    },
    onSelect(item, idx) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      console.log(item);
      if (item.filename == this.currentImg.filename) {
        Toast("无法跳转到自身场景");
        return;
      }
      this.showmodal = false;
      Toast("点击对应位置放置跳转箭头");
      Toast({
        message: "点击对应位置放置跳转箭头",
        duration: 0,
      });
      this.showSave = true;
      this.showNav = true;
      this.navTo = item;
    },

    onDelSelect(item) {
      var markerid = this.markerId;
      var toDelIdx = -1;
      this.toAddMarker.map((x, idx) => {
        if (x.markerid == markerid) {
          toDelIdx = idx;
        }
      });

      this.toAddMarker.splice(toDelIdx, 1);
      this.saveMarker();
    },

    //开启视角调整
    openCamera() {
      Toast("调整至合适的视角后点击保存");
      this.showCamera = true;
      this.showSave = true;
    },

    openSort() {},

    //取消编辑
    cancelEdit() {
      this.showSave = false;
      this.showNav = false;
      this.sortdisabled = true;
      this.showCamera = false;
      this.showSave = false;
    },

    EditNotice() {
      this.showEditNotice = true;
    },
    saveNoice() {
      var that = this;
      console.log(this.noticeText);
      this.$http
        .post("VRImgInfo/SaveVRNoticeText", {
          CustomerID: that.customerInfo.ID,
          NoticeText: that.noticeText,
        })
        .then((res) => {
          if (res.data == "success") {
            Toast("修改成功!");
          }
          console.log(res);
        });
    },
    //点击保存
    save() {
      if (this.showCamera) {
        var s = viewer.getPosition();
        console.log(s);
        var that = this;
        this.showCamera = false;
        this.showSave = false;
        //Todo 调用保存初始视角
        this.$http
          .post("VRImgInfo/SaveDefultView", {
            CustomerID: that.customerInfo.ID,
            Lat: s.latitude,
            Lgt: s.longitude,
            FileName: that.currentImg.filename,
          })
          .then((res) => {
            if (res.data == "success") {
              Toast("修改成功!");
            }
            console.log(res);
          });
        return;
      }
      if (this.showNav) {
        var that = this;
        var markerid = "#" + Math.random();
        Toast.clear();
        this.toAddMarker.push({
          markerid: markerid,
          markerName: that.navTo.name,
          filename: that.currentImg.filename,
          longitude: that.arrowLgt,
          latitude: that.arrowLat,
          data: {
            to: {
              filename: that.navTo.filename,
              name: that.navTo.name,
              url: that.navTo.url,
            },
          },
        });
        this.saveMarker();
        return;
      }
      if (!this.sortdisabled) {
        var that = this;
        var fileStrArray = [];
        var fileStrDescArray = [];
        this.imgList.map((x) => {
          fileStrArray.push(x.filename);
          fileStrDescArray.push(x.name);
        });
        that.showSave = false;
        that.sortdisabled = true;
        this.$http
          .post("VRImgInfo/SaveImgSort", {
            CustomerID: that.customerInfo.ID,
            FileStr: fileStrArray.join(","),
            FileDescStr: fileStrDescArray.join(","),
          })
          .then((res) => {
            if (res.data == "success") {
              Toast("保存成功!");
              // that.LoadData();
            }
          });
      }
    },
    saveMarker() {
      var that = this;
      this.$http
        .post("VRImgInfo/SaveAllMarkers", {
          CustomerID: that.customerInfo.ID,
          JsonStr: JSON.stringify(that.toAddMarker),
          FileName: that.currentImg.filename,
        })
        .then((res) => {
          if (res.data == "success") {
            Toast("修改成功!");
          } else {
            Toast(res);
          }
          that.lock = false;
          that.RefreshMakers();
        });
      this.showNav = false;
      this.showSave = false;
      this.showDelete = false;
    },
    changeImg: function (idx) {
      if (
        this.showCamera ||
        this.showSave ||
        this.showNav ||
        this.showmodal ||
        !this.sortdisabled
      ) {
        Toast("编辑中,不可切换场景");
        return;
      }
      this.currentImg = this.imgList[idx];
      var url = this.imgList[idx].url;
      var that = this;

      //   console.log("加载完成");
      // viewer.destroy();
      // that.LoadMaker();
      viewer.setPanorama(url).then(() => {
        viewer.animate({
          longitude: that.currentImg.lgt,
          latitude: that.currentImg.lat,
          zoom: 1,
          speed: "30rpm",
        });
        that.LoadMaker(function callback(params) {
          console.log("切换完成", url);
          that.lock = false;
          that.RefreshMakers();
        });
      });
    },
    LoadData: function () {
      var warranty = this.$route.params.warranty;
      var that = this;
      this.$http
        .get("VRImgInfo/GetVRImgInfo?warrantyNum=" + warranty)
        .then((res) => {
          that.customerInfo = res.data.Data;
          document.title = "天力管健康源-" + res.data.Data.CustAddress;
          var imgFileArray = res.data.Data.VRImgs.split(",");
          var descArray = [];
          if (res.data.Data.VRImgsDesc.length > 0) {
            descArray = res.data.Data.VRImgsDesc.split(",");
          } else {
            imgFileArray.map(() => {
              descArray.push("未知");
            });
          }

          if (res.data.VRNoticeText != undefined) {
            if (res.data.VRNoticeText.length > 0) {
              that.noticeText = res.data.VRNoticeText;
            }
          }

          imgFileArray.map((item, idx) => {
            var name = "未知";
            try {
              name = descArray[idx];
            } catch (error) {
              console.error("场景没有命名");
            }

            var lgt = 0;
            var lat = 0;
            if (res.data.Data.VRExInfo.length > 0) {
              res.data.Data.VRExInfo.map((ex) => {
                if (ex.ImgFileName == item) {
                  lgt = ex.InitLgt;
                  lat = ex.InitLat;
                }
              });
            }

            that.imgList.push({
              name: name,
              filename: item,
              lgt: lgt,
              lat: lat,
              url:
                that.$file.url +
                "Images/upload/" +
                warranty +
                "/VRImgs/" +
                item +
                ".jpg",
            });
          });
          that.currentImg = that.imgList[0];
          that.LoadMaker(undefined, true);
        });
    },
    LoadMaker: function (cb, needInit) {
      var that = this;
      this.$http
        .get("VRImgInfo/GetImgMakers?filename=" + that.currentImg.filename)
        .then((res) => {
          if (res.data.Data.length > 0) {
            var makers = JSON.parse(res.data.Data);
            that.toAddMarker = makers;
          } else {
            that.toAddMarker = [];
            console.log(that.toAddMarker);
          }
          if (needInit) {
            that.InitVR();
          }
          try {
            if (cb != undefined) {
              cb();
            }
          } catch (error) {
            console.log("cb null");
          }
          // cb();
        });
    },
    RefreshMakers: function () {
      var that = this;
      markersPlugin = viewer.getPlugin(MarkersPlugin);
      if (!this.lock) {
        markersPlugin.clearMarkers();
        this.toAddMarker.map((item) => {
          markersPlugin.addMarker({
            id: item.markerid,
            longitude: item.longitude,
            latitude: item.latitude,
            html: `<img style=" width: 50px;height: 50px;" src="http://reaca-font2.oss-cn-hangzhou.aliyuncs.com/pic/ahead.gif"/> <b style="text-align: center;
    color: #FFFFFF;
    font-family: MicrosoftYahei;
    font-size: 12px;
    padding: 3px 10px;
    background-color: rgba(7, 43, 248, 0.8);
    margin: 0px 0px 0px 0px;
    border-radius: 5px;">${item.markerName}</b>`,
            style: {
              width: "70px",
              color: "#102d4a",
              fontSize: "15px",
              fontFamily: "Helvetica, sans-serif",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
            data: item.data,
          });
          // markersPlugin.renderMarkers();
        });

        markersPlugin.addMarker(that.vrLogoObject);
        this.lock = true;
      }
    },

    InitVR: function (idx) {
      var that = this;
      var img = that.currentImg;

      viewer = new Viewer({
        container: document.querySelector("#viewer"),
        // withCredentials:true,
        panorama: img.url,
        size: {
          width: "100%",
          height: "95vh",
        },
        maxFov: 100,
        minFov: 10,
        defaultZoomLvl: 1,
        // mousewheel: false,
        touchmoveTwoFingers: false,
        moveSpeed: 1,
        loadingImg: null,
        loadingTxt: "加载中 请稍后...",
        navbar: false,
        plugins: [
          [
            MarkersPlugin,
            {
              markers: [that.vrLogoObject],
            },
          ],
        ],
      });
      viewer.on("ready", () => {
        new Animation({
          properties: {
            lat: { start: -Math.PI / 2, end: img.lat },
            long: { start: Math.PI, end: img.lgt },
            zoom: { start: 0, end: 0 },
            fisheye: { start: 4, end: 0 },
          },
          duration: 2000,
          easing: "inOutQuad",
          onTick: (properties) => {
            viewer.setOption("fisheye", properties.fisheye);
            viewer.rotate({
              longitude: properties.long,
              latitude: properties.lat,
            });
            viewer.zoom(properties.zoom);
            that.RefreshMakers();
          },
        });
      });
      viewer.plugins.markers.on("select-marker", function (e, marker, data) {
        console.log(e, marker, data);
        that.markerId = marker.id;
        that.showDelete = true;
        // if (marker.data && marker.data.generated) {
        //   if (data.dblclick) {
        //     markersPlugin.removeMarker(marker);
        //   } else if (data.rightclick) {
        //     markersPlugin.updateMarker({
        //       id: marker.id,
        //       image: 'https://photo-sphere-viewer.js.org/assets/pin-blue.png',
        //     });
        //   }
        // }
      });
      // console.log(viewer);
      viewer.on("click", (e) => {
        console.log(e);
        if (that.showNav) {
          that.$refs.arrow.style.left = e.args[0].clientX - 35 + "px";
          that.$refs.arrow.style.top = e.args[0].clientY - 35 + "px";
          this.arrowLat = e.args[0].latitude;
          this.arrowLgt = e.args[0].longitude;
        }
      });

      viewer.on("position-updated", (e) => {});
    },
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [NoticeBar.name]: NoticeBar,
    [Icon.name]: Icon,
    draggable,
    [ActionSheet.name]: ActionSheet,
    [Dialog.name]: Dialog,
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        let x = 0;
        let y = 0;
        let l = 0;
        let t = 0;
        let isDown = false;
        var that = this;
        //鼠标按下事件
        el.ontouchstart = function (e) {
          //获取x坐标和y坐标
          x = e.targetTouches[0].clientX;
          y = e.targetTouches[0].clientY;

          //获取左部和顶部的偏移量
          l = el.offsetLeft;
          t = el.offsetTop;
          console.log(x, y, l, t);
          //开关打开
          isDown = true;
          //设置样式
          el.style.cursor = "move";
        };
        //鼠标移动
        window.ontouchmove = function (e) {
          if (isDown == false) {
            return;
          }
          //console.log(e);
          //获取x和y
          let nx = e.targetTouches[0].clientX;
          let ny = e.targetTouches[0].clientY;
          //计算移动后的左偏移量和顶部的偏移量
          let nl = nx - (x - l);
          let nt = ny - (y - t);
          this.arrowScreenX = nx;
          this.arrowScreenY = ny;
          el.style.left = nl + "px";
          el.style.top = nt + "px";
        };
        //鼠标抬起事件
        el.ontouchend = function () {
          //开关关闭
          isDown = false;
          el.style.cursor = "default";
        };
      },
    },
  },
};
</script>

<style scoped>
.vrpage {
  touch-action: none !important;
}
.logo {
  position: absolute;
  top: 75px;
  width: 25%;
  z-index: 999;
  text-align: left;
  color: white;
  border-radius: 3px;
  letter-spacing: 2px;
  margin-left: 10px;
}
.van-row {
  margin-top: 5px;
}
.logoimg {
  height: 5vh;
}

.tools {
  bottom: 80px;
  height: 65px;
  width: 200px;
  z-index: 99;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: #ffffff94;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  color: #1989fa;
  align-content: center;
  align-items: center;
  font-weight: bold;
}
.tools-btn {
  float: none;
  text-align: center;
  vertical-align: middle;
}
.imgList {
  bottom: 0px;
  height: 100px;
  width: 100%;
  z-index: 99;
  position: absolute;
}
.warp {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}
.imgList-det {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.title {
  color: aliceblue;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: small;
}
.img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/*选中样式*/
.chosen {
  border: solid 1px #3089dc !important;
}

.arrow {
  top: 50%;
  display: flex;
  right: 50%;
  position: absolute;
  z-index: 90;
  width: 70px;
  /* border: solid 1px #3089dc; */
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.arrow-text {
  text-align: center;
  color: #ffffff;
  font-family: MicrosoftYahei;
  font-size: 12px;
  padding: 3px 10px;
  background-color: rgba(7, 43, 248, 0.8);
  margin: 0px 0px 0px 0px;
  border-radius: 5px;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  position: fixed;
  z-index: 90;
  width: 35px;
  border-radius: 5px;
  background: rgba(61, 61, 61, 0.7);
  -webkit-transition: bottom ease-in-out 0.1s;
  transition: bottom ease-in-out 0.1s;
}

.navbar {
  top: 80px;
  right: 10px;
}

.button {
  width: 30px;
  height: 30px;
  padding: 5.5px;
  margin: 5px 2.5px 5px 2.5px;
}

.button {
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
}
.van-action-sheet {
  max-height: 30%;
}
.xiangkuang {
  position: absolute;
  z-index: 800;
  top: 220px;
  pointer-events: none;
}
</style>
