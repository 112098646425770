<template>
  <div>
    <div class="form-title">管路图
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>

    </div>
    <Row :gutter="15">
      <Col :span="15">
      <Tabs type="card" v-if="agentId==1" @on-click="handleClick">
          <TabPane label="全部" name="all">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurls" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="卫生间" name="wash">
            <div class="img-list">
              <div
                v-for="(item, index) in waimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="厨房" name="kitchen">
            <div class="img-list">
              <div
                v-for="(item, index) in kiimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="客厅" name="parlour">
            <div class="img-list">
              <div
                v-for="(item, index) in paimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="阳台" name="balcony">
            <div class="img-list">
              <div
                v-for="(item, index) in baimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="其他" name="">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
    <Modal v-model="imgviewmodal" :type="info" width="880px">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImgNew()">
          <Icon type="md-arrow-dropleft" size="40"/>
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt="">
        <div @click="nextImgNew()">
          <Icon type="md-arrow-dropright" size="40"/>    
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      waimgurl: [],
      kiimgurl: [],
      paimgurl: [],
      baimgurl: [],
      agentId: 0,
      active: "all",
      imgurls: [],
      // base_url: "http://121.41.11.131:8088/"
      base_url: this.$file.url     
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;      
    },
    preImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex == 0) {
            anextImgUrl = this.imgurls[this.imgurls.length - 1];
          } else {
            anextImgUrl = this.imgurls[aindex - 1];
          }
          this.currentImgurl = anextImgUrl;
          break;
        case "wash":
          var windex = this.waimgurl.indexOf(this.currentImgurl);
          var wnextImgUrl = "";
          if (windex == 0) {
            wnextImgUrl = this.waimgurl[this.waimgurl.length - 1];
          } else {
            wnextImgUrl = this.waimgurl[windex - 1];
          }
          this.currentImgurl = wnextImgUrl;
          break;
        case "kitchen":
          var kindex = this.kiimgurl.indexOf(this.currentImgurl);
          var knextImgUrl = "";
          if (kindex == 0) {
            knextImgUrl = this.kiimgurl[this.kiimgurl.length - 1];
          } else {
            knextImgUrl = this.kiimgurl[kindex - 1];
          }
          this.currentImgurl = knextImgUrl;
          break;
        case "parlour":
          var pindex = this.paimgurl.indexOf(this.currentImgurl);
          var pnextImgUrl = "";
          if (pindex == 0) {
            pnextImgUrl = this.paimgurl[this.paimgurl.length - 1];
          } else {
            pnextImgUrl = this.paimgurl[pindex - 1];
          }
          this.currentImgurl = pnextImgUrl;
          break;
        case "balcony":
          var bindex = this.baimgurl.indexOf(this.currentImgurl);
          var bnextImgUrl = "";
          if (bindex == 0) {
            bnextImgUrl = this.baimgurl[this.baimgurl.length - 1];
          } else {
            bnextImgUrl = this.baimgurl[bindex - 1];
          }
          this.currentImgurl = bnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index == 0) {
            nextImgUrl = this.imgurl[this.imgurl.length - 1];
          } else {
            nextImgUrl = this.imgurl[index - 1];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    nextImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex < this.imgurls.length - 1) {
            anextImgUrl = this.imgurls[aindex + 1];
          } else {
            anextImgUrl = this.imgurls[0];
          }
          this.currentImgurl = anextImgUrl;
          break;
        case "wash":
          var windex = this.waimgurl.indexOf(this.currentImgurl);
          var wnextImgUrl = "";
          if (windex < this.waimgurl.length - 1) {
            wnextImgUrl = this.waimgurl[windex + 1];
          } else {
            wnextImgUrl = this.waimgurl[0];
          }
          this.currentImgurl = wnextImgUrl;
          break;
        case "kitchen":
          var kindex = this.kiimgurl.indexOf(this.currentImgurl);
          var knextImgUrl = "";
          if (kindex < this.kiimgurl.length - 1) {
            knextImgUrl = this.kiimgurl[kindex + 1];
          } else {
            knextImgUrl = this.kiimgurl[0];
          }
          this.currentImgurl = knextImgUrl;
          break;
        case "parlour":
          var pindex = this.paimgurl.indexOf(this.currentImgurl);
          var pnextImgUrl = "";
          if (pindex < this.paimgurl.length - 1) {
            pnextImgUrl = this.paimgurl[pindex + 1];
          } else {
            pnextImgUrl = this.paimgurl[0];
          }
          this.currentImgurl = pnextImgUrl;
          break;
        case "balcony":
          var bindex = this.baimgurl.indexOf(this.currentImgurl);
          var bnextImgUrl = "";
          if (bindex < this.baimgurl.length - 1) {
            bnextImgUrl = this.baimgurl[bindex + 1];
          } else {
            bnextImgUrl = this.baimgurl[0];
          }
          this.currentImgurl = bnextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index < this.imgurl.length - 1) {
            nextImgUrl = this.imgurl[index + 1];
          } else {
            nextImgUrl = this.imgurl[0];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    GetDataNew(url) {
      this.$http
        .get("/Customer/GetCustomerPic", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;

          switch (url) {
            case "all":
              that.imgurls=[];
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.WashImgs != null && res.data.Data.customerex.WashImgs.length > 0) {
                var awimgStr = res.data.Data.customerex.WashImgs;
                var awimgarray = awimgStr.split(",");
                var awimgUrlArray = [];
                var awimgUrlArray = awimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "wash" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.waimgurl = awimgUrlArray;
                that.imgurls = that.imgurls.concat(that.waimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.KitchenImgs != null && res.data.Data.customerex.KitchenImgs.length > 0) {
                var akimgStr = res.data.Data.customerex.KitchenImgs;
                var akimgarray = akimgStr.split(",");
                var akimgUrlArray = [];
                var akimgUrlArray = akimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "kitchen" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.kiimgurl = akimgUrlArray;
                that.imgurls = that.imgurls.concat(that.kiimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.ParlourImgs != null && res.data.Data.customerex.ParlourImgs.length > 0) {
                var apimgStr = res.data.Data.customerex.ParlourImgs;
                var apimgarray = apimgStr.split(",");
                var apimgUrlArray = [];
                var apimgUrlArray = apimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "parlour" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.paimgurl = apimgUrlArray;
                that.imgurls = that.imgurls.concat(that.paimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.BalconyImgs != null && res.data.Data.customerex.BalconyImgs.length > 0) {
                var abimgStr = res.data.Data.customerex.BalconyImgs;
                var abimgarray = abimgStr.split(",");
                var abimgUrlArray = [];
                var abimgUrlArray = abimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "balcony" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.baimgurl = abimgUrlArray;
                that.imgurls = that.imgurls.concat(that.baimgurl);
              }
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var aimgStr = res.data.Data.customer.Imgs;
                var aimgarray = aimgStr.split(",");
                var aimgUrlArray = [];
                var aimgUrlArray = aimgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = aimgUrlArray;
                that.imgurls = that.imgurls.concat(that.imgurl);
              }
              break;
            case "wash":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.WashImgs != null && res.data.Data.customerex.WashImgs.length > 0) {
                var wimgStr = res.data.Data.customerex.WashImgs;
                var wimgarray = wimgStr.split(",");
                var wimgUrlArray = [];
                var wimgUrlArray = wimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.waimgurl = wimgUrlArray;
              } else {
                that.waimgurl = [];
              }
              break;
            case "kitchen":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.KitchenImgs != null && res.data.Data.customerex.KitchenImgs.length > 0) {
                var kimgStr = res.data.Data.customerex.KitchenImgs;
                var kimgarray = kimgStr.split(",");
                var kimgUrlArray = [];
                var kimgUrlArray = kimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.kiimgurl = kimgUrlArray;
              } else {
                that.kiimgurl = [];
              }
              break;
            case "parlour":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.ParlourImgs != null && res.data.Data.customerex.ParlourImgs.length > 0) {
                var pimgStr = res.data.Data.customerex.ParlourImgs;
                var pimgarray = pimgStr.split(",");
                var pimgUrlArray = [];
                var pimgUrlArray = pimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.paimgurl = pimgUrlArray;
              } else {
                that.paimgurl = [];
              }
              break;
            case "balcony":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.BalconyImgs != null && res.data.Data.customerex.BalconyImgs.length > 0) {
                var bimgStr = res.data.Data.customerex.BalconyImgs;
                var bimgarray = bimgStr.split(",");
                var bimgUrlArray = [];
                var bimgUrlArray = bimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.baimgurl = bimgUrlArray;
              } else {
                that.baimgurl = [];
              }
              break;
            default:
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var imgStr = res.data.Data.customer.Imgs;
                var imgarray = imgStr.split(",");
                var imgUrlArray = [];
                var otherProductArray = res.data.Data.customer.OtherProduct.split(",");
                if (otherProductArray.length >= 3) {
                  res.data.Data.customer.OtherProduct = 40;
                } else {
                  res.data.Data.customer.OtherProduct = 0;
                }
                var imgUrlArray = imgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = imgUrlArray;
              } else {
                that.imgurl = [];
              }
              break;
          }
        });
      
    },
    
    handleClick: function(e) {
      this.active=e;
      this.GetDataNew(e);
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
  },
  created() {
    // this.GetData();
    this.agentId = this.$route.params.agentId;
    if(this.agentId == 1){
      this.GetDataNew(this.active);
    }
  }
};
</script>

<style>
.detail-form {
}
</style>
