<template>
  <div class="my-form">
    <div class="form-title">我的清单
      <Icon type="edit"></Icon>
    </div>
    <Tabs type="card" @on-click="handleClick">
      <TabPane label="系统开单" name="system">
        <van-collapse v-model="activeNames" @change="onChange" v-if="MaterialList.length>0" style="width:60%;">
          <van-collapse-item title="材料清单" name="1" value="点击展开明细">
            <div class="list" v-for="item in MaterialList" v-bind:key="item.ID">
              <div>
                <van-image lazy-load width="45" height="45" fit="contain" :src="item.ImgUrl" />
              </div>
              <div class="text-display-detail">
                <div class="text-title">{{item.MaterialName}}</div>
                <div class="text-tips">规格：{{item.MaterialSpecName}}</div>
              </div>
              <div class="step">
                <div>{{item.FacePrice}}×{{item.MaterialNum}}({{item.MaterialUnitName}})</div>
              </div>
            </div>
            <van-cell-group>
              <van-field label="开单金额" v-model="PreSaleEX.BillAmount" :border="false" readonly />
            </van-cell-group>
            <!-- <van-cell-group>
              <van-field label="开单折扣" v-model="PreSaleEX.Discount" :border="false" readonly />
            </van-cell-group>
            <van-cell-group>
              <van-field label="折后金额" v-model="PreSaleEX.SaleAmount" :border="false" readonly />
            </van-cell-group> -->
          </van-collapse-item>
        </van-collapse>
      </TabPane>
      <TabPane label="上传图片" name="upload">
        <div class="img-list" v-if="imgurl.length>0">
          <div
            v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
            v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
          </div>
        </div>
      </TabPane>
    </Tabs>
    <!-- <van-collapse v-model="activeNames" @change="onChange" v-if="billway == 1" style="width:60%;">
      <van-collapse-item title="材料清单" name="1" value="点击展开明细">
        <div class="list" v-for="item in MaterialList" v-bind:key="item.ID">
          <div>
            <van-image lazy-load width="45" height="45" fit="contain" :src="item.ImgUrl" />
          </div>
          <div class="text-display-detail">
            <div class="text-title">{{item.MaterialName}}</div>
            <div class="text-tips">规格：{{item.MaterialSpecName}}</div>
          </div>
          <div class="step">
            <div>{{item.FacePrice}}×{{item.MaterialNum}}({{item.MaterialUnitName}})</div>
          </div>
        </div>
        <van-cell-group>
          <van-field label="开单金额" v-model="PreSaleEX.BillAmount" :border="false" readonly />
        </van-cell-group>
      </van-collapse-item>
    </van-collapse> -->
    <!-- <div v-if="billway == 2">
      <div>开单凭证</div>
      <div class="img-list">
        <div
          v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
          v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
        </div>
      </div>
    </div> -->
    <Modal v-model="imgviewmodal" width="880px" draggable="true">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt />
      </div>
    </Modal>
  </div>
</template>

<script>
import { CellGroup, Collapse, CollapseItem, Field, Image, Tab, Tabs } from 'vant';

export default {
  data() {
    return {
      spinShow: false,
      activeNames: [''],
      presaleId: 0,
      MaterialList:[],
      PreSaleEX:'',
      billway:0,
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      active: "system",
      base_url: this.$file.url      
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    onChange:function (event) {
      this.activeNames = event.detail;
    },
    handleClick: function(e) {
      this.active=e;
    },
    GetPresale: function(id) {
      var that = this;
      this.$http
        .get("SDWorkerMatList/GetMaterialDetailByPre", { params: { presaleId: id } })
        .then(res => {
          if(res.data.Data.presaleEx!=null&&res.data.Data.presaleEx.BillImgs!=null){
            that.billway=2;
          }else{
            that.billway=1;
          }

          if (res.data.Data.presaleEx.BillImgs != null && res.data.Data.presaleEx.BillImgs.length > 0) {
            var imgStr = res.data.Data.presaleEx.BillImgs;
            var imgarray = imgStr.split(",");
            var imgUrlArray = [];
            var imgUrlArray = imgarray.map(item => {
              //新路径格式 2018年9月10日
                return (
                  that.base_url +
                  "BillImgs/" +
                  res.data.Data.presaleEx.BillCode +
                  "/" +
                  item +
                  ".jpg"
                );
            });
            that.imgurl = imgUrlArray;
          }
          that.PreSaleEX = res.data.Data.presaleEx;
          that.MaterialList = res.data.Data.matList;
          that.MaterialList.forEach((item, idx, array) => {           
            array[idx].ImgUrl =
              that.$file.url +
              "Images/SDWorkerMaterial/" +
              item.MaterialID +
              "/" +
              item.ImgUrl;
          });

        })
        .then(res => {
          that.spinShow = false;
        });
    },
    
    BackList: function() {
      this.$http.SetConditions();
      this.$router.go(-1);
    }
  },
  watch: {},
  created: function() {
    var that = this;
    this.spinShow = true;
    this.GetPresale(this.$route.query.id);
  },
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Image.name]: Image,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
};


</script>

<style>
.list{
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  align-items:center;
  margin-top: 2px;
}
.text-display-detail{
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  height: 40px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  margin-left: 5px;
  flex-grow: 1;
}
.text-title{
  font-size: 16px;
  line-height: 24px;
  color: #323233;
}

.text-tips{
  color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}
</style>
