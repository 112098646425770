<template>
  <div>
    <Card :bordered="false" dis-hover>
      <p slot="title">预约试压系统选择</p>
      <p >长沙、湖北地区客户请使用新系统预约试压。其他地区请使用旧系统预约试压</p>
    </Card>
    <div style="padding:15px;">
      <Button type="success" @click="newsystem" long style="width:100%;height:35px;">新系统预约试压</Button>
    </div>
    <div style="padding:15px;">
      <Button type="primary" @click="oldsystem" long style="width:100%;height:35px;">旧系统预约试压</Button>
    </div>
  </div>
</template>


<script>
export default {
  methods:{
    newsystem(){
      this.$router.push({name:"orderprepressform"});
    },
    oldsystem(){      
      window.location="http://wechat.reaca.net/PreOrder/Create/466";
    }
  }
};
</script>

<style>
</style>
