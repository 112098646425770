<template>
  <div class="listform">
    <div class="form-title">
      待审核清洗单
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table
        :border="false"
        
        size="small"
        @on-sort-change="Sort"
        :columns="table.tablecolumsTitle"
        :loading="table.loading"
        :data="table.datalist"
      ></Table>
    </div>
    <div class="footerpage">
      <Page
        :total="table.total"
        :page-size="table.pageSize"
        @on-change="PageChange"
        :current="table.pageIndex"
        show-elevator
      ></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      sel_values: [
        { value: "CustName", label: "客户姓名" },
        { value: "CustTel", label: "电话" },
        { value: "CustAddress", label: "地址" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "是否传图",
            key: "Imgs",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.Imgs != null && row.Imgs != "" && "success") || "error";
              const text = (row.Imgs != null && row.Imgs != "" && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "审核状态",
            key: "Status",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 0 && "success") || "error";
              const text = (row.Status == 0 && "待审核") || "不通过";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "客户姓名",
            key: "CustName",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddress",
            width: 250,
            align: "center",
            tooltip: true
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "试压员",
            key: "WorkerNameDesc",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "管材品牌",
            key: "Brand",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "收费金额",
            key: "ChargeMoney",
            width: 80,
            align: "center",
            tooltip: true
          },
          {
            title: "备注",
            key: "Remark",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "上传时间",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            width: 120,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.ViewImgs(params.row);
                        }
                      }
                    },
                    "审核"
                  )
                ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: "_CreateTime asc"
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_waitauditwashcustomerlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("WashCustomer/GetWaitAuditWashCustomerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];

      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    ViewImgs: function(row) {
      this.$router.push({
        name: "dealer_waitauditwashcustomeroption",
        params: { id: row.ID, agentId: row.AgentId }
      });
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 