<template>
  <div style="height:100%;">
    <van-nav-bar
      title="幸运抽奖"
      left-text=""
      left-arrow>
    </van-nav-bar>
    <!-- <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe vertical class="notice-swipe" :autoplay="1500" :show-indicators="false">
          <van-swipe-item v-for="(item,index) in luckyerList" :key="index">{{item}}</van-swipe-item>
        </van-swipe>
      </van-notice-bar> -->
    <div class="dial">
      
      <div style="text-align:right;margin-top:35%;margin-right:10px;">
        <van-tag mark type="primary" size="large" @click="toDrawLog()">中奖记录</van-tag>
      </div>
      <div style="margin-top:5%;text-align:center;font-weight:bold;">
        <van-tag type="primary" size="large" style="font-size:15px;">您还有 {{remaindrawChance}} 次抽奖机会</van-tag>
      </div>
      <!-- 转盘包裹 -->
      <div class="rotate">
        <!-- 绘制圆点 -->
        <div
          :class="'circle circle_'+index"
          v-for="(item,index) in circleList"
          :key="index"
          :style="{background:index%2==0?colorCircleFirst:colorCircleSecond}">
        </div>
        <!-- 转盘图片 -->
        <img class="dish" src="../../assets/draw/dial4.png" :style="{transform:rotate_deg,transition:rotate_transition}" />
        <!-- 指针图片 -->
        <img class="pointer" src="../../assets/draw/click.png" @click="start" />
      </div>

    </div>

  </div>
  
</template>
<script>
import { Dialog, Image, NavBar, NoticeBar, Swipe, SwipeItem, Tag, Toast } from "vant";
import { showToast } from 'vant'
import AppVue from "../../App.vue";
var light_timer; //灯光定时器
export default {
  name: "dial",
  data() {
    return {
      // LuckyClick: 3,
      circleList: [], //原点设置
      colorCircleFirst: "#FE4D32", //圆点颜色
      colorCircleSecond: "#fff", //圆点颜色
 
      cat: 90, //总共4个扇形区域，每个区域约90度
      isAllowClick: true, //是否能够点击
      rotate_deg: 0, //指针旋转的角度
      rotate_transition: "transform 3s ease-in-out", //初始化选中的过度属性控制
      remaindrawChance: 0,
      luckyerList: []
    };
  },

  created() {
    var warranty=this.$route.params.warranty;
    this.showcircleList();
    this.GetData(warranty);
  },
  computed: {
    isLogined() {
      return this.$store.state.isLogin;
    },
  },
  methods: {
    //绘制圆点设置
    showcircleList() {
      let circleList = [];
      for (var i = 0; i < 12; i++) {
        circleList.push(i);
      }
      this.circleList = circleList;
      this.light();
    },
 
    //闪动效果
    light: function() {
      var that = this;
      clearInterval(light_timer);
      light_timer = setInterval(function() {
        if (that.colorCircleFirst == "#FE4D32") {
          that.colorCircleFirst = "#fff";
          that.colorCircleSecond = "#FE4D32";
        } else {
          that.colorCircleFirst = "#FE4D32";
          that.colorCircleSecond = "#fff";
        }
      }, 300); //设置圆点闪烁的效果
    },
 
    start() {
      // if (this.remaindrawChance == 0) {
      //   alert("机会已经用完了");
      //   return;
      // }
      this.rotating();
    },
 
    rotating() {
      if (!this.isAllowClick) return;
      this.isAllowClick = false;
      this.rotate_transition = "transform 3s ease-in-out";
      // this.remaindrawChance--;
      // var rand_circle = 5; //默认多旋转5圈
      var warranty = this.$route.params.warranty;
      this.$http
        .get("Customer/LuckyDraw2023?period=1", {
          params: {
            warrantyNum:warranty
          },
        }).then((res) => {
          if (res.data.Msg == "success") {
            this.remaindrawChance--;
            var rand_circle = 5; //默认多旋转5圈

            console.log(res.data.Data);
            var winningIndex = res.data.Data;
            console.log(winningIndex);
            var randomDeg = 360 - winningIndex*90+45; //当前下标对应的角度    90是总共4个扇形区域，每个区域约90度 （指针停留在奖品中间）
      
            var deg = rand_circle * 360 + randomDeg; //将要旋转的度数  由于是顺时针的转动方向需要用360度来减
            this.rotate_deg = "rotate(" + deg + "deg)";
      
            var that = this;
            setTimeout(function() {
              that.isAllowClick = true;
              that.rotate_deg = "rotate(" + 0 + "deg)"; //定时器关闭的时候重置角度
              that.rotate_transition = "";

              if (winningIndex == 1) {
                that.$dialog({
                  title: '中奖了',
                  message: '恭喜您，获得豪华全景VR一套',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              } else if (winningIndex == 2) {
                that.$dialog({
                  title: '中奖了',
                  message: '恭喜您，获得天力定制版精美雨伞一把',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              } else if (winningIndex == 3) {
                that.$dialog({
                  title: '中奖了',
                  message: '恭喜您，获得天力精品水杯一个',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              } else if (winningIndex == 4) {
                that.$dialog({
                  title: '中奖了',
                  message: '恭喜您，获得现金大礼2023元',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                });
              }
            }, 3500);

          } else {
            console.log(res.data);
            this.$toast(res.data);
          }
        });
    },
    toDrawLog() {
      var warranty=this.$route.params.warranty;
      this.$router.push({
        name: "luckDrawRecord2023",
        params: {
          warranty:warranty,
        },
      });
    },
    GetData(warranty) {
      var that = this;
      that.$http.get("Customer/GetDrawChance2023",
      {
        params: {
          warrantyNum:warranty,
          period:1
        },
      }).then((res) => {
        that.remaindrawChance = res.data.Data.RemainChance;
      });
    },
     onClickLeft() {
      this.$router.back(-1);
    },
 
    //设置概率
    set() {
      var winIndex;
 
      //方法2
      var __rand__ = Math.random();
      if (__rand__ < 0.3) winIndex = 2;
      else if (__rand__ < 0.6) winIndex = 1;
      else if (__rand__ < 0.99) winIndex = 4;
      else if (__rand__ < 0.92) winIndex = 3;
      else if (__rand__ == 0.99) winIndex = 0;
 
      return winIndex;
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Tag.name]: Tag,
    [Image.name]: Image,
    [Toast.name]: Toast,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Dialog.name]: Dialog,
  },
};
</script>
<style scoped>
.dial{
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url('../../assets/draw/main_bg.jpg');
  background-repeat:no-repeat;
  overflow: hidden;
  background-size: contain;
}
.times {
  text-align: center;
  line-height: 1.8rem;
  background: #fff;
}
.rotate {
  width: 21.1rem;
  height: 21.1rem;
  /* background: #ffbe04; */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}
 
.rotate .dish {
  width: 19.5rem;
  height: 19.5rem;
}
 
.pointer {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
.prize {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  z-index: 100;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

</style>