<template>
  <div class="listform">
    <div class="form-title">客户档案
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>

    </div>
    <div class="search_option">
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "质保卡号",
            key: "WarrantyNum",
            align: "center",
            tooltip: true
          },
          {
            title: "试压结果",
            key: "PressureResult",
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.PressureResult == 1 && "success") || "error";
              const text = (row.PressureResult == 1 && "合格") || "不合格";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "客户姓名",
            key: "CustName",
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddress",
            align: "center",
            tooltip: true
          },
          {
            title: "试压员",
            key: "WorkerNameDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "管道类型",
            key: "PipeLineTypeDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "上传时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "操作",
            key: "",
            width: 150,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.ViewImgs(params.row);
                      }
                    }
                  },
                  "试压图"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
      dist_modal: {
        currentdealer: 0,
        show: false,
        distnum: 0
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_childcustomer",
        params: { preFilterCode: this.$route.params.preFilterCode }
      });
    },
    GetTableList: function() {
      var conditions = [];
      conditions.push({
        PropertyName: "PreFilterCode",
        Operater: 0,
        LogicOperater: 0,
        Value: this.$route.params.preFilterCode
      });

      this.$http.SetConditions(conditions);
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Customer/GetFilterCodeCustomerPageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    ViewImgs: function(row) {
      this.$router.push({
        name: "dealer_childcustomerimage",
        params: { id: row.ID, agentId: row.AgentId }
      });
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 