<template>
<div class="my-form">
      <Upload 
            ref="upload"    
            :show-upload-list="true"
            :on-format-error="handleFormatError"
            :on-success="handleSuccess"
            :on-error="handleError"
            :with-credentials="true"
            :format ="['xlsx','xls']"
            :action="uploadUrl">
         <Button type="info" icon="ios-cloud-upload-outline">选择文件</Button>
      </Upload>
 </div>
 
  
</template>

<script>
export default {
  data() {
    return {
      empployeeId: "",
      uploadUrl: "",
    };
  },
  props: {
    aftsalerow: ""
  },
  methods: {
         handleFormatError(file){
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: '文件 ' + file.name + ' 格式不正确，请上传.xls,.xlsx文件。'
            })
        },
        handleSuccess(res,file){
            if(res == "success"){
                this.dialoLead = false
                this.$Message.success("数据导入成功！")
                this.$router.push({ name: "dealer_businesslist" });
                this.$refs.upload.clearFiles()
            }
        },
        handleError(error,file){
            this.$Message.error("数据导入失败！")
        },

  },

  created: function() {
    // 加载业务员
    this.empployeeId = this.$route.params.employeeid;
    // 导入地址
    this.uploadUrl =
            this.$file.url +
            "api/Employee/ImportSDWorker?employeeid=" +
            this.empployeeId;
  }
  
};
</script>

<style>
</style>
