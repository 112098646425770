<template>
  <div class="modal_list">
    <div class="search_option">
      <!-- <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button> -->
      <p style="padding: 10px;font-size: 14px;font-weight: bolder;color: #ed4014;"> 当前试压员【进行中】试压单量数据为： 【 {{table.total}} 】 单 </p>
    </div>
    <div class="datatable">
      <Table highlight-row :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      /* 搜索栏相关数据 */
      sel_field: "",
      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "TelNum", label: "电话" }
      ],
      queryvalue: "",

      /* table+翻页相关数据 */
      table: {
        tablecolumsTitle: [
          {
            title: "预约单号",
            key: "PreSaleCode",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 200,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "地址",
            key: "CustAddr",
            width: 250,
            align: "center",
            tooltip: true
          },
          {
            title: "短信号码",
            key: "MsgTel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "预约上门试压日期",
            key: "PressureStartTime",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },

          {
            title: "客户姓名",
            key: "CustName",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "试压员",
            key: "WorkerName",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom"
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  components: {},
  props: {
    workerid: ""
  },
  methods: {
    GetTable: function() {
      var that = this;
      var conditions = [];
      conditions.push({
        PropertyName: "WorkerId",
        Operater: 2,
        LogicOperater: 0,
        Value: that.workerid
      });
      that.$http.SetConditions(conditions);
      that.table.loading = true;
      this.$http
        .get("PrePress/GetPrePressPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            sort: that.table.sort,
            status: 40
          }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.loading = true;
      this.GetTable();
    },
    Search() {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTable();
    }
  },
  created: function() {
    this.GetTable();
  }
};
</script>

<style>
</style>
