<template>
  <div class="listform">
    <div class="form-title">文件下载
      <Icon type="briefcase"></Icon>
    </div>

    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "文件名称",
            key: "FileName",
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "",
            width: 150,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.Download(params.row);
                      }
                    }
                  },
                  "下载"
                )
              ]);
            }
          }
        ],
        datalist: [
          {"FileName":"数据导出申请单.docx","FileUrl":"http://oss.reaca.net/FileImgs/%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%87%BA%E7%94%B3%E8%AF%B7%E5%8D%95.docx"},
          {"FileName":"积分修改申请单.docx","FileUrl":"http://oss.reaca.net/FileImgs/%E7%A7%AF%E5%88%86%E4%BF%AE%E6%94%B9%E7%94%B3%E8%AF%B7%E5%8D%95.docx"},
        ],
        
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.EditWorker(undefined, e);
        return;
      }
      this.PreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    Download:function (e) {//导出
      var that=this;
      var filename = e.FileName;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      // that.$http.get("FileImgs/",{
      //   params:{filename:filename}
      // }).then(res=>{
      //   debugger;
      // })
        that.$Spin.hide();
        var url=that.$file.url + "/FileImgs/"+filename;
        console.log(url);
        window.open(url);
    }
  },
  created: function() {
    
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 