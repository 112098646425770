<template>
  <div style="padding:10px;">
    <Card :bordered="true" dis-hover v-for="(customer,customerindex) in datalist" :key="customerindex">
      <p slot="title">{{customer.Address}}</p>
      <div>
        <p>质保卡号: {{customer.WarrantyNum}}</p>
        <p>客户姓名: {{customer.Name}}</p>
        <p>客户电话: {{customer.Tel}}</p>
        <p>厨房: {{customer.KitchenNum}} 个</p>
        <p>卫生间: {{customer.WashNum}} 个</p>
        <p>管道类型: {{customer.PipelineName}}</p>
      </div>
      <div class="img-list">
        <div v-for="(item, index) in customer.imgurl" @click="viewimg(customerindex,index)" :key="index"
          class="img-cell"
          v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      datalist: [{ CustAddress: "", imgurl: [] }],
      dataobj: {},
      base_url: this.$file.url,
      // base_url: "https://sale.reaca.net/",
      // base_url: "http://localhost:24737/",
    };
  },
  methods: {
    GetData() {
      var that = this;
      this.$http.get("OldCustomer/GetOldCustomersByTel").then((res) => {
        that.LoadImgNew(res);
        // that.LoadImg(res);
      });
    },
    GetDataByWarrantyNum(WarrantyNum) {
      var that=this;
      this.$http.get("OldCustomer/GetOldCustomersByWarrantyNum?warrantyNum="+WarrantyNum).then((res) => {
        that.LoadImgNew(res);
        // that.LoadImg(res);
      });
    },
    LoadImgNew(res) {
      var that = this;
      if (res.data.Msg == "success") {
        that.datalist = res.data.ListData;
        for (let i = 0; i < res.data.ListData.length; i++) {
          if (res.data.ListData[i].imgs != null&&res.data.ListData[i].imgs.length>0) {
              var imgStr = res.data.ListData[i].imgs;
              var imgarray = imgStr.split(",");
              // var imgUrlArray = [];

              var imgUrlArray = imgarray.map((item) => {
                if (item.search("Images") != -1) {
                  //老数据的图片路径
                  return that.base_url + item;
                } else if (item.search("Images") == -1) {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.ListData[i].WarrantyNum +
                    "/" +
                    item +
                    ".jpg"
                  );
                }
              });
            that.datalist[i].imgurl = imgUrlArray;
          } else {
            that.datalist[i].imgurl = [];
          }
        }
      }
    },
    viewimg(item, index) {
      console.log(this.datalist[item].imgurl, index);
      var imgarray = [];
      this.datalist[item].imgurl.map((item) => {
        imgarray.push(item);
      });
      ImagePreview(imgarray, index);
    },
  },
  created: function () {
    console.log(this.$route.params.WarrantyNum);
    var WarrantyNum = this.$route.params.WarrantyNum;
    if (WarrantyNum !=undefined) {
      this.GetDataByWarrantyNum(WarrantyNum);
    } else {
      this.GetData();
    }
    // this.$http();
  },
};
</script>

<style>
</style>
