<template>
  <div class="listform">
    <div class="form-title">开单编码
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>

    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort"  :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sel_field: "",
      sel_values: [
        { value: "Code", label: "开单编码" },
        { value: "DealerName", label: "经销商姓名" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "编码",
            key: "Code",
            align: "center",
            tooltip: true
          },
          // {
          //   title: "是否已使用",
          //   key: "IsUse",
          //   align: "center",
          //   tooltip: true,
          //   render: (h, params) => { // 0未使用 1已使用
          //     const row = params.row;
          //     const text = (row.IsUse == 1 && "已使用") || "未使用";
          //     return h("div",
          //       text
          //     );
          //   }
          // },
          {
            title: "经销商姓名",
            key: "DealerName",
            align: "center",
            tooltip: true
          },
          {
            title: "创建日期",
            key: "_CreateTime",
            align: "center",
            tooltip: true
          },
        ],
        queryvalue: "",
        codecount:0,
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort:" "
      }
    };
  },
  methods: {
    PageChange: function(e) {   // 分页查询
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name:"dealer_billcodelist",
        query: { pageindex: e }
      });
    },
    Search: function(e) {   // 根据输入的编码查询
      this.loading = true;
      var conditions = [];            
      // conditions.push({
      //   PropertyName: "Code",
      //   Operater: 0,
      //   LogicOperater: 0,
      //   Value: this.queryvalue.trim()
      // }); 
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }     
      
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GetTableList: function() {   // 查询开单编码列表
      var that = this;
      that.table.loading = true;
      this.$http
        .get("BillCode/GetBillCodePageList", {
          params: { pageIndex: that.table.pageIndex,sort:that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort:function (sort) {     // 排序
      if(sort.key!=undefined&&sort.order!=undefined){
        if(sort.order!="normal"){
          this.table.sort=sort.key+" "+sort.order;
        }else{
          this.table.sort="";
        }
      }
      this.GetTableList();
    },
  },
  created: function() {   // 初始化加载过滤器编码
    this.GetTableList();
  }
};
</script>
<style>
</style>
 