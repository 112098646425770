<template>
  <div class="listform">
    <div class="form-title">水工积分记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>

    </div>
    <div class="search_option">
    </div>

      <Tabs @on-click="tabchange" type="card">
        <TabPane label="积分记录">
          <div class="">
            <div class="datatable">
              <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
            </div>
            <div class="footerpage">
              <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
            </div>
          </div>
        </TabPane>
        <!-- <TabPane label="物资积分记录">
          <matIntegralLog-List></matIntegralLog-List>
        </TabPane> -->
      </Tabs>

  </div>
</template>
<script>
// import matIntegralLog from "./sdworkerMatIntegralLog";
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "工地地址",
            key: "CustAddress",
            width: 230,
            align: "center",
            tooltip: true,              
          },
          {
            title: "管道类型",
            key: "PipeLineTypeDesc",
            width: 120,
            align: "center",
            tooltip: true,              
          },
          {
            title: "积分变更数量",
            key: "ChangeIntegralNumber",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "变更类型",
            key: "DataSourceDesc",
            width: 160,
            align: "center",
            tooltip: true
          },
          {
            title: "数据来源",
            key: "DataSourceId",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              if(params.row.DataSource==1){
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "primary", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.DataSource(params.row);
                        }
                      }
                    },
                    "关联数据"
                  )
                ]);
              }
            }
          },
          {
            title: "备注",
            key: "Remark",
            width: 420,
            align: "center",            
            tooltip: true
          },
          {
            title: "变更时间",
            key: "_CreateTime",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom"
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
      dist_modal: {
        currentdealer: 0,
        show: false,
        distnum: 0
      }
    };
  },
  // components:{
  //   "matIntegralLog-List": matIntegralLog
  // },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_sdworkerintegrallog",
        params: { dealerid: this.$route.params.sdworkerid }
      });
    },
    GetTableList: function() {
      var conditions = [];
      conditions.push({
        PropertyName: "SDWorkerId",
        Operater: 2,
        LogicOperater: 0,
        Value: this.$route.params.sdworkerid
      });

      this.$http.SetConditions(conditions);
      var that = this;
      that.table.loading = true;
      this.$http
        .get("SDWorker/GetIntegralLogPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          res.data.ListData.map(item => {
            item.DataSourceDesc =
              (item.DataSource == 1 && "上传客户档案获得积分") ||
              (item.DataSource == 0 && "手工调整积分") ||
              (item.DataSource == 2 && "水工兑换积分") ||
              "数据异常";
          });
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DataSource: function(row) {
      this.$http.SetConditions();
      var conditions = [];
      if(row.DataSource==1){
        conditions.push({
          PropertyName: "ID",
          Operater: 2,
          LogicOperater: 0,
          Value: row.DataSourceId
        });
  
        this.$http.SetConditions(conditions);
        this.$router.push({ name: "dealer_customerlist" });
      }else if(row.DataSource==2){
        conditions.push({
          PropertyName: "ID",
          Operater: 2,
          LogicOperater: 0,
          Value: row.DataSourceId
        });
        this.$http.SetConditions(conditions);
        this.$router.push({ name: "dealer_customerlist" });
      }
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 