<template>
  <div class="listform">
    <div class="form-title">
      系统积分统计
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <!-- <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
      <Button @click="BatchExchange()" type="primary" icon="ios-clipboard">批量消分(请谨慎操作)</Button> -->
      <DatePicker type="month" @on-change="DateTimeSelect" placement="bottom-end" placeholder="根据时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
    </div>

    <div class="datatable">
      <Table
        :border="false"
        row-key="DealerId"
        border
        size="small"        
        :columns="table.tablecolumsTitle"
        :loading="table.loading"
        :data="table.datalist"
        show-summary
      ></Table>
    </div>
    <!-- <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "经销商姓名",
            key: "DealerName",
            align: "center",
            // tooltip: true,
            // tree: true,
            fixed: "left",
            width: 250,
          },
          {
            title: "目前总积分",
            key: "CurrentIntegralNum",
            align: "center",
            tooltip: true,
          },
          {
            title: "支出积分",
            key: "pay",
            align: "center",
            tooltip: true,
          },
          {
            title: "录单积分",
            key: "record",
            align: "center",
            tooltip: true,
          },
          {
            title: "成单奖励积分（非红宝石）",
            key: "record1",
            align: "center",
            tooltip: true,
          },
          {
            title: "成单奖励积分（红宝石）",
            key: "record2",
            align: "center",
            tooltip: true,
          },
          {
            title: "水工兑换积分",
            key: "exchange",
            align: "center",
            tooltip: true,
          },
          // {
          //   title: "积分类型",
          //   key: "DataSource",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "次数",
          //   key: "ActiveCount",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "1月",
          //   key: "1月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "2月",
          //   key: "2月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "3月",
          //   key: "3月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "4月",
          //   key: "4月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "5月",
          //   key: "5月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "6月",
          //   key: "6月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "7月",
          //   key: "7月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "8月",
          //   key: "8月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "9月",
          //   key: "9月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "10月",
          //   key: "10月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "11月",
          //   key: "11月",
          //   align: "center",
          //   tooltip: true,
          // },
          // {
          //   title: "12月",
          //   key: "12月",
          //   align: "center",
          //   tooltip: true,
          // },
        ],
        datalist: [],
        pageIndex:1,
        pageSize:10,
        total:0
      },
    };
  },
  components: {},
  methods: {
    //行选中事件
    WorkerRowSel: function (e) {},
    ModalCancel: function (e) {},
    Search: function(e) {
      this.loading = true;
      this.GetTableList();
    },
    DateTimeSelect: function (e) {
      this.dateSearchValue = e;
    },
    PageChange:function (e) {
      this.table.pageIndex = e;
      this.GetTableList();
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;      
      this.$http
        .get("Dealer/GetDealerIntegralByMonth", {
          params: {
              month:that.dateSearchValue,
              pageIndex:that.table.pageIndex
          },
        })
        .then((res) => {
          that.table.loading = false;
          // var list=res.data.ListData;
          // if(cb!=undefined){
          //   cb(list);
          //   return;
          // }
          // list.map((v,i,list)=>{
          //     v._loading=false;
          //     v.children=[];
          //     v.DataSource='-'
          // })
          // that.table.total = res.data.TotalCount;
          // that.table.pageSize = res.data.PageSize;
          that.table.datalist = res.data.ListData;          
        });
    },
    handleSpan: function ({ row, column, rowIndex, columnIndex }) {},
    handleLoadData (item, callback) {
        console.log(item);
        this.GetTableList(item.DealerId,1,data=>{
            data.map((v,i,list)=>{
                v.DealerName='';
                v.CurrentIntegralNum='';                
                if(v.DataSource==0){
                    v.DataSource = "手工调整";
                }
                if(v.DataSource==1){
                    v.DataSource = "录单积分";
                }
                if(v.DataSource==2){
                    v.DataSource = "水工兑换";
                }
                
            });
            callback(data);
        })
    },
    ExportExcel: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http
        .get("Dealer/ExportReward", {
          params: {
            month: that.dateSearchValue,
          }
        })
        .then(res => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
  },
  created: function () {
    var timestamp = Date.parse(new Date());
    var date = new Date(timestamp);
    //获取年份
    var Y = date.getFullYear();
    //获取月份
    var M = date.getMonth()+1;
    this.dateSearchValue=Y+"-"+M;
    this.GetTableList();
  },
};
</script>
<style>
/* .radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
} */
</style>
 