
<template>
  <div class="my-form">
    <div class="form-title">新建试压员
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="60">
      <FormItem label="姓名 ">
        <Input v-model="postdata.Name" style="width:500px" placeholder="请填写姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="postdata.TelNum" style="width:500px" placeholder="请填写11位手机电话"></Input>
      </FormItem>
      <FormItem label="密码 ">
        <Input v-model="postdata.Password" style="width:500px" placeholder="请填写密码"></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="false"></Spin>
  </div>
</template>
<script>
export default {  
  data() {
    return {
      postdata: {
        Name: "",
        TelNum: "",
        Password: ""
      },
    };
  },
  methods: {
    SubmitData: function(e) {
      var data = {
        Name: this.postdata.Name,
        TelNum: this.postdata.TelNum,
        Password: this.postdata.Password
      };
      if(!this.CheckData(data)){
        return ;
      }
      this.$http.post("Worker/AddWorker", data).then(res => {
          var modaltitle = "";
          if (res.data != "success") {
            modaltitle = res.data;
          } else {
            modaltitle = "提交成功！";
          }
          this.$Modal.info({
            title: "提示",
            content: modaltitle
          });
          this.$router.push({ name: "dealer_workerlist" });
      });
      // if(this.postdata.Name!=""&&this.postdata.TelNum!="")
      // {
      //   this.$http.post("Worker/AddWorker", this.postdata).then(res => {
      //     var modaltitle = "";
      //     if (res.data != "success") {
      //       modaltitle = res.data;
      //     } else {
      //       modaltitle = "提交成功！";
      //     }
      //     this.$Modal.info({
      //       title: "提示",
      //       content: modaltitle
      //     });
      //     this.$router.push({ name: "dealer_workerlist" });
      //   });
      // }else{
      //   this.$Modal.info({
      //       title: "提示",
      //       content: '请填写完整数据'
      //     });
      //   return;
      // }

    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.Name == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.TelNum == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (obj.Password == "") {
        content = "请填写密码";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    BackList: function() {
      this.$router.push({ name: "dealer_workerlist" });
    },

  }
};
</script>

<style>
</style>
