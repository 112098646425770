<template>
  <div class="listform">
    <div class="form-title">售后单据
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end" placeholder="根据创建时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="success" @click="NewSaleBill" icon="ios-document">新建</Button>
      <Button @click="ExportAfterSale()" type="primary" icon="ios-clipboard">导出</Button>
      <!-- <Button type="warning" icon="md-trash">删除</Button> -->
      <!-- <Button type="info" icon="clipboard">导出</Button> -->
    </div>
    <div class="datatable">
      <Table :border="true" size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.after_sale_list"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="选择处理人" width="1000" height="600" v-model="emp_modal.show" @on-ok="ModalOK" @on-cancel="ModalCancel">
      <employee-list :aftsalerow="emp_modal.aftsalerow" v-if="emp_modal.show" v-on:rowsel="ModalRowSel"></employee-list>
    </Modal>
    <Modal title="待删除" width="600" height="350" v-model="del_modal.show" @on-ok="del_ModalOK" @on-cancel="ModalCancel">
      <div>
        <p>备注：</p>
        <Input v-model="del_modal.delRemark" type="textarea" placeholder="填写待删除备注"></Input>
      </div>
    </Modal>
  </div>
</template>

<script>
import employee_modal from "../../components/public_component/emp_modal";
import processVue from "../../components/public_component/process.vue";

export default {
  data() {
    return {
      sel_field: "",
      sel_values: [
        { value: "CustomerName", label: "姓名" },
        { value: "CustomerTel", label: "电话" },
        { value: "AddressDetail", label: "地址" },
      ],
      dateSearchOption: {
        shortcuts: [
          {
            text: "一周内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: "一月内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: "一年内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              return [start, end];
            }
          }
        ]
      },
      dateSearchValue: "",
      queryvalue: "",
      table: {
        tablecolumsTitle: [
          {
            type: "expand",
            width: 30,
            render: (h, params) => {
              return h(processVue, {
                props: {
                  row: params.row
                }
              });
            }
          },
          // { type: "selection", width: 60, align: "center" },
          // { title: "ID", key: "ID", width: 60 },
          {
            title: "审核状态",
            key: "Status",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 0 && "error") || (row.Status == 1 && "success") || "error";
              const text = (row.Status == 0 && "待审核") || (row.Status == 1 && "已审核") || "不通过";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          // { title: "单号", key: "AfterSaleCode", width: 120,align:'center' },
          { title: "创建时间", key: "_CreateTime" ,width:150,align:'center',tooltip:true},
          { title: "姓名", key: "CustomerName", width: 100 ,align:'center'},
          { title: "电话", key: "CustomerTel", width: 120,align:'center' },
          { title: "售后类别", key: "AfterBillTypeDesc",width:130,align:'center' },
          { title: "详细地址", key: "AddressDetail",width:200,align:'center',tooltip:true,
          render: (h, params) => {
              let remark = params.row.DelRemark;
              if(remark!=undefined&&remark!="")
              {
                return h('span',{
                  'style':{
                      'color':'red'
                  }
                },params.row.AddressDetail);
              } else {
                return h('span',{
                },params.row.AddressDetail);
              }
            } 
          },

          { title: "处理人", key: "DisposeByName", width: 100,align:'center' },
          { title: "行政区", key: "AddressText", width: 180,align:'center',tooltip:true },
          { title: "创建人", key: "CreateByName",width:100,align:'center' },
          { title: "未处理原因", key: "Reason",width:150,align:'center' },
          { title: "待删除备注", key: "DelRemark",width:150,align:'center' },
          // { title: "代理商", key: "FilialeName",width:100,align:'center' },
          // { title: "代理商", key: "AgentDesc",width:100,align:'center' },
          {
            title: "进度",
            key: "ProcessStatusDesc",
            width: 175,
            align:"left",
            fixed: "right",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = "primary";
              const text = row.ProcessStatusDesc;
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "操作",
            key: "action",
            width: 190,
            align: "center",
            fixed: "right",            
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small"
                    },
                    style: {
                      marginRight: "3px"
                    },
                    on: {
                      click: () => {
                        this.EditSaleBill(params.row.ID);
                      }
                    }
                  },
                  "编辑"
                ),
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: {
                      marginRight: "3px"
                    },
                    on: {
                      click: () => {
                        this.DeleteAftSaleBill(params.row.ID);
                      }
                    }
                  },
                  "删除"
                ),
                h(
                  "Button",
                  {
                    props: { type: "default", size: "small" },
                    on: {
                      click: () => {
                        this.DelAftersale(params.row.ID);
                      }
                    }
                  },
                  "待删除"
                )
              ]);
            }
          },
          {
            title: "业务",
            key: "bus",
            width: 160,
            align: "center", 
            fixed: "right",           
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.ShowDisposeModal(params.row);
                      }
                    }
                  },
                  "分配人员"
                ),
                h(
                  "Button",
                  {
                    props: { type: "default", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.$router.push('/dealerindex/billdetail/'+params.row.ID)
                      }
                    }
                  },
                  "详细"
                )
              ]);
            }
          }
        ],
        after_sale_list: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },

      emp_modal: {
        show: false,
        emp_sel: "",
        aftsalerow: ""
      },
      del_modal: {
        currentaftersaleid: 0,
        show: false,
        delRemark: ""
      }
    };
  },
  components: {
    "employee-list": employee_modal
  },
  methods: {
    NewSaleBill: function() {
      this.$router.push({ name: "newaftersalebill" });
    },
    Search: function() {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    PageChange: function(e) {
      this.table.pageIndex = e;      
      this.GetTableList();
      this.$router.push({ name: "aftersalelist", query: { pageindex: e } });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading=true;
      
      this.$http
        .get("AfterSaleBill/GetAfterSaleBillPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            sort: that.table.sort
          }
        })
        .then(res => {
          that.table.loading = false;
          this.$Modal.remove();
          that.table.after_sale_list = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        })
        .catch(function(error) {
          that.table.loading = false;
          that.$Message.error({
            content: "网络连接错误！",
            duration: 10,
            closable: true
          });
          console.log(error);
        });
    },
    DeleteAftSaleBill: function(id) {
      this.$Modal.confirm({
        title: "提示",
        content: "<p>确认删除此条数据吗?</p>",
        loading: true,
        onOk: () => {
          // const params = {"ids":id+""}
          // params.append("", id);
          this.$http.post("AfterSaleBill/RangeDelete"+"?ids="+id).then(() => {
            this.GetTableList();
            this.$Message.info("删除成功");
          });
        }
      });
    },
    DelAftersale: function(row) {
      this.del_modal.show = true;
      this.del_modal.currentaftersaleid = row;
    },
    del_ModalOK: function() {
      var that = this;
      debugger;
      if (that.del_modal.delRemark.length <= 0) {
        this.$Message.warning("必须填写待删除备注");
        return;
      }
      that.$http.get('AfterSaleBill/AddDelRemark',{
        params:{
          aftersaleid:that.del_modal.currentaftersaleid,
          delRemark:that.del_modal.delRemark
        }
      }).then(res=>{
        if(res.data!='success'){
          this.$Message.warning(res.data);
        }else{
          this.$Message.info('保存成功!');
          that.GetTableList();
          that.del_modal.delRemark="";
          that.del_modal.show=false;
        }
      });
    },
    EditSaleBill: function(id) {
      this.$router.push({ path:"/dealerindex/newbill/"+id});
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },

    /**选择售后人员弹窗相关 */
    ShowDisposeModal(row) {
      this.emp_modal.aftsalerow = row;
      this.emp_modal.show = true;
    },

    ModalRowSel(e) {
      this.emp_modal.emp_sel = e;
    },
    ModalOK() {
      this.$http.SetConditions();
      this.$http
        .get("AfterSaleBill/SetDisposer", {
          params: {
            id: this.emp_modal.aftsalerow.ID,
            empid: this.emp_modal.emp_sel.ID
          }
        })
        .then(res => {
          console.log(res.data);
          this.GetTableList();
        });
    },
    ModalCancel() {
      this.$Modal.remove();
    },
    ExportAfterSale:function () {
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("AfterSaleBill/ExportAfterSaleBill",{
       
      }).then(res=>{
        that.$Spin.hide();
        that.GetTableList();
        window.open(that.$config.fileUrl + res.data);
      })
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>

<style>
</style>
