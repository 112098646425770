<template>
  <div class="modal_list">
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table highlight-row :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.dealers_list"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="查看派单情况" @on-cancel="ModalCancel" width="1000" height="600" :footer-hide="true" v-model="modal.show">
      <workerPressList-View v-if="modal.show" :workerid="modal.workerid" v-on:rowsel="ModalRowSel"></workerPressList-View>
    </Modal>
  </div>
</template>

<script>
import workerPressList from './workerPressList'
export default {
  data() {
    return {
      /* 搜索栏相关数据 */
      sel_field: "",
      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "TelNum", label: "电话" }
      ],
      queryvalue: "",
      modal:{
        show:false,
        workerid:''
      },
      /* table+翻页相关数据 */
      table: {
        tablecolumsTitle: [
          { title: "姓名", key: "Name" },
          { title: "电话", key: "TelNum" },
          {
            title: "操作",
            key: "bus",
            width: 200,
            align: "center",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.SelRow(params.row);
                      }
                    }
                  },
                  "选取"
                ),
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.ViewMore(params.row);
                      }
                    }
                  },
                  "查看派单情况"
                )
              ]);
            }
          }
        ],
        dealers_list: [],
        pageIndex: 1,
        total: 0,
        pageSize: 20,
        loading: true
      }
    };
  },
  props: {
    
  },
  components:{
    "workerPressList-View": workerPressList
  },
  methods: {
    GetTable() {
      this.$http
        .get("Worker/GetWorkerPageList", {
          params: {
            pageIndex: this.table.pageIndex,
            sort: ""
          }
        })
        .then(e => {
          this.table.loading = false;
          this.table.dealers_list = e.data.ListData;
          this.table.total = e.data.TotalCount;
          this.table.pageSize = e.data.PageSize;
        });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.loading = true;
      this.GetTable();
    },
    ViewMore:function(e){
      this.modal.workerid=e.ID;
      this.modal.show=true;
    },
    ModalCancel:function(e){
      this.$http.SetConditions([]);
    },
    Search() {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTable();
    },
    SelRow(e) {
      this.$http.SetConditions();
      this.$emit("rowsel", e);
    }
  },
  created: function() {
    this.GetTable();
  }
};
</script>

<style>
</style>
