<template>
  <div class="my-form">
    <div class="form-title">商品详情
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="商品名称">
        <Input v-model="postdata.GoodsName" style="width:500px" placeholder="输入商品名称"></Input>
      </FormItem>
      <FormItem label="单位">
        <Input v-model="postdata.Unit" style="width:500px" placeholder="输入单位"></Input>
      </FormItem>
      <FormItem label="单价">
        <InputNumber v-model="postdata.UnitPrice" :min="0" style="width:500px" placeholder="单价"></InputNumber>
      </FormItem>
      <FormItem label="是否上架">
        <RadioGroup v-model="postdata.Status">
          <Radio :label="constNum[0]">否</Radio>
          <Radio :label="constNum[1]">是</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否推荐">
        <RadioGroup v-model="postdata.IsRecommend">
          <Radio :label="constNum[0]">否</Radio>
          <Radio :label="constNum[1]">是</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="商品类别">
        <Cascader @on-change="CategoryChange" :data="CategoryList" v-model="postdata.Categorys"
          :load-data="LoadCategory" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="商品描述">
        <Input v-model="postdata.GoodsDesc" type="textarea" style="width:700px" :autosize="{minRows: 3,maxRows: 10}"
          placeholder="Enter something..."></Input>
      </FormItem>
      <!-- :default-file-list="postdata.imgArray" -->
      <FormItem label="商品图片上传">
        <div class="img-list">
          <div v-for="(item, index) in postdata.imgArray" :key="index" class="img-cell"
            v-bind:style="{'background-image':'url(' + item.url+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
              <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(item)"></Icon>
            </div>
          </div>
        </div>
        <Upload type="drag" :format="['jpg']" :max-size="2048" :with-credentials="true" :show-upload-list="false"
          :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
          style="display: inline-block;min-width:600px;" :action="uploadUrl">
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
            <p>将图片拖拽到此处上传</p>
          </div>
        </Upload>
      </FormItem>

      <FormItem label="商品详情图片">
        <div class="img-list">
          <div v-for="(item, index) in postdata.imgdetArray" :key="index" class="img-cell"
            v-bind:style="{'background-image':'url(' + item.url+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
              <Icon type="ios-trash-outline" size="30" @click.native="handledetRemove(item)"></Icon>
            </div>
          </div>
        </div>
        <Upload type="drag" :format="['jpg']" :max-size="2048" :with-credentials="true" :show-upload-list="false"
          :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
          style="display: inline-block;min-width:600px;" :action="uploaddetUrl">
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
            <p>将商品详情图片拖拽到此处上传</p>
          </div>
        </Upload>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <!-- <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button> -->
      </FormItem>
    </Form>
    <Modal v-model="imgviewmodal" width="880px">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl.url" alt="">
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // AddressValue: [],
      CategoryList: [],
      imgviewmodal: false,
      currentImgurl: "",
      uploadUrl: "",
      uploaddetUrl: "",
      constNum: [0, 1],
      postdata: {
        Category1: 0,
        Category2: 0,
        GoodsCode: "",
        GoodsDesc: "",
        GoodsDetailImg: "",
        GoodsImg: "",
        GoodsName: "",
        ID: 0,
        Unit: "",
        UnitPrice: 0,
        // _CreateBy: 0,
        // _CreateTime: "",
        // _IsDelete: 0
        // _UpdateBy: 0,
        // _UpdateTime: ""
        Status: 1,
        IsRecommend: 0
      }
    };
  },
  methods: {
    SubmitData: function() {
      var that = this;

      var url = "Order/UpdateGoods";
      var data = this.postdata;
      if (!this.CheckData(data)) {
        return;
      }
      if (this.$route.query.id == undefined) {
        url = "Order/AddGoods";
      }

      this.$http.post(url, data).then(e => {
        this.$Message.info("操作成功!");
      });
    },

    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.GoodsName == "") {
        content = "请填写商品名称";
        checkflag = false;
      }
      if (obj.Unit == "") {
        content = "请填写商品单位";
        checkflag = false;
      }
      if (obj.UnitPrice == "") {
        content = "请填写商品价格";
        checkflag = false;
      }
      if (obj.Categorys.length <= 0) {
        content = "请选择商品类别";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },

    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.postdata.imgArray[this.postdata.imgArray.length - 1];
      } else {
        nextImgUrl = this.postdata.imgArray[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.postdata.imgArray.length - 1) {
        nextImgUrl = this.postdata.imgArray[index + 1];
      } else {
        nextImgUrl = this.postdata.imgArray[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    handleRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteGoodsImg", {
          params: { id: that.postdata.ID, filename: item.name }
        })
        .then(res => {
          this.$Message.info("操作成功!");
          that.GetData(that.$route.query.id);
        });
    },
    handledetRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteGoodsDetImg", {
          params: { id: that.postdata.ID, filename: item.name }
        })
        .then(res => {
          this.$Message.info("操作成功!");
          that.GetData(that.$route.query.id);
        });
    },
    CategoryChange: function(value, selectedData) {
      this.postdata.Category1 = value[0];
      this.postdata.Category2 = value[1];
    },
    LoadCategory: function(item, callback) {
      this.GetCategory(item.value).then(res => {
        item.children = PushCategorys(res.data.Data);
        item.loading = false;
        callback();
      });
    },
    GetCategory: function(paraentId) {
      var that = this;
      return that.$http("Order/GetCategoryList?id=" + paraentId);
    },
    GetData: function(id) {
      let that = this;
      if (id > 0) {
        that.$http.get("Order/GetGoodsById?id=" + id).then(res => {
          var imgNameArray = res.data.Data.GoodsImg.split(",");
          var imgArray = [];
          imgNameArray.forEach(item => {
            imgArray.push({
              name: item,
              url:
                that.$file.url +
                "Images/Mall/GoodsImg/" +
                res.data.Data.GoodsCode +
                "/" +
                item
            });
          });
          var imgdetNameArray = res.data.Data.GoodsDetailImg.split(",");
          var imgdetArray = [];
          imgdetNameArray.forEach(item => {
            imgdetArray.push({
              name: item,
              url:
                that.$file.url +
                "Images/Mall/GoodsImg/" +
                res.data.Data.GoodsCode +
                "/" +
                item
            });
          });
          res.data.Data.imgArray = imgArray;
          res.data.Data.imgdetArray = imgdetArray;
          res.data.Data.Categorys = [];
          res.data.Data.Categorys.push(res.data.Data.Category1);
          res.data.Data.Categorys.push(res.data.Data.Category2);
          that.postdata = res.data.Data;
          that.uploadUrl =
            that.$file.url +
            "api/Order/AddGoodsImg?goodsid=" +
            res.data.Data.ID;
          that.uploaddetUrl =
            that.$file.url +
            "api/Order/AddGoodsDetImg?goodsid=" +
            res.data.Data.ID;
        });
      }
    },

    UploadSuccess: function(response, file, fileList) {
      this.$Message.info("上传成功!");
      this.GetData(this.$route.query.id);
    },
    UploadError: function(error, file, fileList) {
      this.$Notice.error({
        title: "系统异常"
      });
    },
    handleBeforeUpload() {
      if (this.$route.query.id == undefined) {
        this.$Notice.warning({
          title: "提示",
          desc: "请先保存基础信息后上传图片！"
        });
        return false;
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: "文件 " + file.name + " 格式错误,请选择jpg格式"
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件超出大小",
        desc: "文件: " + file.name + " 不能超过2M"
      });
    }
  },
  watch: {},
  created: function() {
    this.GetData(this.$route.query.id);

    // this.GetCheckbox();
    // console.log(this.$route.query.id);
    this.GetCategory(0).then(res => {
      this.CategoryList = PushCategorys(res.data.Data);
    });
  }
};
function PushCategorys(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 1) {
      regionList.push({
        value: item.ID,
        label: item.CategoryName,
        level: item.Level,
        parentID: item.ParentId,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.CategoryName,
        level: item.Level,
        parentID: item.ParentId
      });
    }
  });
  return regionList;
}
</script>

<style>
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
