<template>
  <div class="listform">
    <div class="form-title">水工管理
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
      <Button style="margin-left: 10px;" type="success" @click="NewSDWorker()" icon="ios-document">新建</Button>      
    </div>
    <div class="search_option">
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" }
      ],
      table: {
        tablecolumsTitle:  [
          {
            title: "姓名",
            key: "Name",            
            align: "center",
            tooltip: true,
            fixed: "left"
          },
          {
            title: "电话",
            key: "Tel",            
            align: "center",
            tooltip: true
          },
          {
            title: "身份",
            key: "Identity",            
            align: "center",
            tooltip: true,
            render: (h, params) => { // 0水工 1项目经理
              const row = params.row;
              const text = (row.SDWorkerType == 1 && "项目经理") || "水工";
              return h("div",
                text
              );
            }
          },
          {
            title: "类型",
            key: "SDWorkerType",            
            align: "center",
            tooltip: true,
            render: (h, params) => { // 0竞品 1核心
              const row = params.row;
              const text = (row.SDWorkerType == 1 && "核心") || "竞品";
              return h("div",
                text
              );
            }
          },
          {
            title: "操作",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "warning", size: "small" },
                      style: { marginRight: "3px" },
                      on: {
                        click: () => {
                          this.Delete(params.row);
                        }
                      }
                    },
                    "删除"
                  )
                ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    NewSDWorker: function(e) {
      this.$router.push({ 
        name: "dealer_newsdworker" ,
        params: { employeeid: this.$route.params.employeeid }
      });
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_employeesdworkerlist",
        params: { employeeid: this.$route.params.employeeid }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Employee/GetSDWorkerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort, empId:  this.$route.params.employeeid}
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GoBack: function(e) {
      //this.$http.SetConditions();
      //this.$router.go(-1);
      this.$router.push({ name: "dealer_businesslist" });
    },
    Delete: function(e) {   // 删除水工
      var that=this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认删除吗?",
        onOk: () => {
          that.$http.get('Employee/DeleteSDWorker',{
            params:{id:e.ID,employeeid: this.$route.params.employeeid}
          })
          .then(res=>{
            if (res.data == "success") {
                that.$Message.info("删除成功");
                that.GetTableList();
            } else {
                this.$Message.info(res.data);
            }
          })
        }      
      });
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 