<template>
  <div class="my-form">
    <div class="form-title">材料详情
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="名称">
        <Input v-model="postdata.Name" style="width:500px" placeholder="输入名称"></Input>
      </FormItem>
      <FormItem label="单位">
        <Input v-model="postdata.UnitName" style="width:500px" placeholder="输入单位"></Input>
      </FormItem>
      <FormItem label="是否上架">
        <RadioGroup v-model="postdata._IsDelete">
          <Radio :label="constNum[0]">是</Radio>
          <Radio :label="constNum[1]">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="商品类别">
        <Select v-model="postdata.CategoryId" style="width:200px">
            <Option v-for="item in CategoryList" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="图片上传">
        <div class="img-list">
          <div v-for="(item, index) in postdata.imgArray" :key="index" class="img-cell"
            v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
            <div class="demo-upload-list-cover">
              <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
              <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(item)"></Icon>
            </div>
          </div>
        </div>
        <Upload type="drag" :format="['jpg']" :max-size="1024" :with-credentials="true" :show-upload-list="false"
          :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
          style="display: inline-block;min-width:600px;" :action="uploadUrl">
          <div style="padding: 20px 0">
            <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
            <p>将图片拖拽到此处上传</p>
          </div>
        </Upload>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <!-- <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button> -->
      </FormItem>
    </Form>
    <Modal v-model="imgviewmodal" width="880px">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt="">
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // AddressValue: [],
      CategoryList: [],
      imgviewmodal: false,
      currentImgurl: "",
      uploadUrl: "",      
      constNum: [0, 1],
      postdata: {
        CategoryId: 0,                
        Name: "",
        UnitName: "",
        ImgUrl: "",        
        ID: 0,
        _IsDelete: 0
      }
    };
  },
  methods: {
    SubmitData: function() {
      var that = this;

      var url = "SDWorkerMatList/UpdateMat";
      var data = this.postdata;
      if (!this.CheckData(data)) {
        return;
      }
      if (this.$route.query.id == undefined) {
        url = "SDWorkerMatList/AddMat";
      }

      this.$http.post(url, data).then(e => {
        this.$Message.info("操作成功!");
      });
    },

    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.Name == "") {
        content = "请填写名称";
        checkflag = false;
      }
      if (obj.UnitName == "") {
        content = "请填写单位";
        checkflag = false;
      }
      if (obj.CategoryId <= 0) {
        content = "请选择类别";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },

    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.postdata.imgArray[this.postdata.imgArray.length - 1];
      } else {
        nextImgUrl = this.postdata.imgArray[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.postdata.imgArray.length - 1) {
        nextImgUrl = this.postdata.imgArray[index + 1];
      } else {
        nextImgUrl = this.postdata.imgArray[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    handleRemove(item) {
      var that = this;
      that.$http
        .get("SDWorkerMatList/DeleteMatImg", {
          params: { id: that.postdata.ID, filename: item.name }
        })
        .then(res => {
          this.$Message.info("操作成功!");
          that.GetData(that.$route.query.id);
        });
    },
    CategoryChange: function(value, selectedData) {
      this.postdata.Category1 = value[0];
      this.postdata.Category2 = value[1];
    },
    GetCategory: function() {
      var that = this;
      that.$http("SDWorkerMatList/GetMaterialCateList").then(res=>{
        console.log(res);
        that.CategoryList=res.data.ListData;
      });
    },
    GetData: function(id) {
      let that = this;
      if (id > 0) {
        that.$http.get("SDWorkerMatList/GetSDWorkerMatById?id=" + id).then(res => {
          var array=[];
          array.push(that.$file.url +
                "Images/SDWorkerMaterial/" +
                res.data.Data.ID +
                "/" +
                res.data.Data.ImgUrl);
          res.data.Data.imgArray = array ;
          that.postdata = res.data.Data;
          that.uploadUrl =
            that.$file.url +
            "api/SDWorkerMatList/AddMatImg?id=" +
            res.data.Data.ID;
        });
      }
    },

    UploadSuccess: function(response, file, fileList) {
      this.$Message.info("上传成功!");
      this.GetData(this.$route.query.id);
    },
    UploadError: function(error, file, fileList) {
      this.$Notice.error({
        title: "系统异常"
      });
    },
    handleBeforeUpload() {
      if (this.$route.query.id == undefined) {
        this.$Notice.warning({
          title: "提示",
          desc: "请先保存基础信息后上传图片！"
        });
        return false;
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: "文件 " + file.name + " 格式错误,请选择jpg格式"
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件超出大小",
        desc: "文件: " + file.name + " 不能超过1M"
      });
    }
  },
  watch: {},
  created: function() {
    
    this.GetData(this.$route.query.id);
    
    this.GetCategory();
  }
};

</script>

<style>
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
