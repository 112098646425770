<template>
  <div>
    <Row :gutter="24">
      <Col :span="6">
      <Card>
        <p slot="title">客户档案信息</p>
        <!-- <Tag  color="blue">{{dataobj.ProcessStatusDesc}}</Tag> -->        
        <Button slot="extra" style="margin-left: 10px;" type="info" @click="GoBack()" icon="md-arrow-back">返回</Button>
        <p>质保卡号: {{dataobj.WarrantyNum}}</p>
        <p>客户姓名: {{dataobj.CustName}}</p>
        <p>客户电话: {{dataobj.CustTel}}</p>
        <p>详细地址: {{dataobj.CustAddress}}</p>
        <p>厨房: {{dataobj.KitchenNum}} 个</p>
        <p>卫生间: {{dataobj.WashNum}} 个</p>
        <p>阀门品牌: {{dataobj.Brand}}</p>
        <p>户型: {{dataobj.HouseTypeName}}</p>
        <p>户型满足条件: {{dataobj.ConditionName}}</p>
        <p>试压开始时间: {{dataobj.StartGuaranteedTime}}</p>
        <p>加压前压力: {{dataobj.PrePressure}} Mpa</p>
        <p>试压结束时间: {{dataobj.EndGuaranteedTime}}</p>
        <p>加压后压力: {{dataobj.AftPressure}} Mpa</p>
        <p>管道米数: {{dataobj.PipeLength}}</p>
        <p>管道类型: {{dataobj.PipeLineName}}</p>
        <p>过滤器数量: {{dataobj.PreFilterNum}}</p>
        <p>过滤器类型: {{dataobj.PreFilterName}}</p>
        <p>过滤器编码: {{dataobj.PreFilterCode}}</p>
        <p>大流量新品数量: {{dataobj.BigFlowNum}}</p>
        <p>大流量新品备注: {{dataobj.BigFlowRemark}}</p>
        <p>其他积分: {{dataobj.OtherIntegral}}</p>
        <p>其他积分备注: {{dataobj.OtherIntegralRemark}}</p>
        <p>丝口: {{dataobj.ScrewNum}}</p>
        <p>配套产品: {{dataobj.OtherProductDesc}}</p>
        <p>试压员: {{dataobj.WorkerName}}</p>
        <p>是否走顶回水: {{dataobj.InstallTypeDesc}}</p>
        <p>是否二次试压: {{dataobj.IsSecondPre=="1"?"是":"否"}}</p>
        <p>预约单备注: {{dataobj.PreRemark}}</p>
        <p>客户档案备注: {{dataobj.Remark}}</p>
        <p>是否赠送促销活动产品: {{dataobj.IsGiveProduct=="1" ? "是" : "否"}}</p>
        <p>是否上传业主群推广图片: {{dataobj.IsPopularize=="1" ? "是" : "否"}}</p>
        <p>是否上传朋友圈推广图片: {{dataobj.IsWechat=="1" ? "是" : "否"}}</p>
        <p>不合格原因: {{dataobj.UnReason}}</p>
      </Card>
      </Col>
      <Col :span="4">
        <Card>
          <p slot="title">积分对象信息</p>
          <p>现金积分人: {{integralobj.Name}}</p>
          <p>现金积分人电话: {{integralobj.Tel}}</p>
          <p>物资积分人: {{integralobj.MatName}}</p>
          <p>物资积分人电话: {{integralobj.MatTel}}</p>
          <p>现金积分: {{integralobj.Integral}}</p>
          <p>物资积分: {{integralobj.MatIntegral}}</p>
        </Card>
      </Col>
      <Col :span="14">
      <Tabs type="card" v-if="agentId==1" @on-click="handleClick">
          <TabPane label="全部" name="all">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurls" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="卫生间" name="wash">
            <div class="img-list">
              <div
                v-for="(item, index) in waimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="厨房" name="kitchen">
            <div class="img-list">
              <div
                v-for="(item, index) in kiimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="客厅" name="parlour">
            <div class="img-list">
              <div
                v-for="(item, index) in paimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="阳台" name="balcony">
            <div class="img-list">
              <div
                v-for="(item, index) in baimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="其他" name="">
            <div class="img-list">
              <div
                v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="安装不规范" name="standard">
            <div style="margin-left:5px;">安装不规范备注: {{StandardDesc}}</div>
            <div class="img-list">
              <div
                v-for="(item, index) in stimgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              </div>
            </div>
          </TabPane>
          <TabPane label="活动图片">
            <div class="img-list">
              <div
                v-for="(item, index) in actimgurl" @click="viewactimg(item,1)" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
                </span>-->
              </div>
            </div>
          </TabPane>
          <Button @click="downloadImg" size="small" slot="extra">下载</Button>
        </Tabs>
      <Tabs type="card" v-if="agentId!=1">
        <TabPane label="现场图片">
          <div class="img-list">
            <div v-for="(item, index) in imgurl" @click="viewimg(item,1)" :key="index" class="img-cell"
              v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <TabPane label="活动图片">
          <div class="img-list">
            <div v-for="(item, index) in actimgurl" @click="viewactimg(item,1)" :key="index" class="img-cell"
              v-bind:style="{'background-image':'url(' + item+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </TabPane>
        <Button @click="downloadImg" size="small" slot="extra">下载</Button>
      </Tabs>
      </Col>
    </Row>
    
    <Modal v-model="imgviewmodal" :type="info" width="880px">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;" v-if="agentId==1">
        <div @click="preImgNew()" @keyup.left="preImgNew">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt />
        <div @click="nextImgNew()" @keyup.right="nextImgNew">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
      <div style="text-align:center;display: flex;align-items: center;" v-if="agentId!=1">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40"/>
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl" alt="">
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40"/>    
        </div>
      </div>
    </Modal>
    <Modal v-model="actimgviewmodal" :type="info" width="880px">
      <p slot="header" style="text-align:center;">
        <!-- <Icon type="information-circled"></Icon> -->
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preActImg()">
          <Icon type="md-arrow-dropleft" size="40"/>
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentActImgurl" alt="">
        <div @click="nextActImg()">
          <Icon type="md-arrow-dropright" size="40"/>    
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dataobj: {},
      integralobj: {},
      imgurl: [],
      imgviewmodal: false,
      currentImgurl: "",
      actimgurl: [],
      actimgviewmodal: false,
      currentActImgurl: "",
      waimgurl: [],
      kiimgurl: [],
      paimgurl: [],
      baimgurl: [],
      stimgurl: [],
      StandardDesc: "",
      agentId: 0,
      active: "all",
      imgurls: [],
      // base_url: "http://121.41.11.131:8088/"
      base_url: this.$file.url  
      // base_url: "http://localhost:24738/"
    };
  },
  methods: {
    downloadImg: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      this.$http
        .get("/Customer/ExportCustomerImgs", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;      
    },
    viewactimg(item, type) {
      this.actimgviewmodal = true;
      this.currentActImgurl = item;      
    },
    preImg(){
      var index= this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl="";
      if(index==0){
        nextImgUrl=this.imgurl[this.imgurl.length-1]
      }else{
        nextImgUrl=this.imgurl[index-1]
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg(){
      var index= this.imgurl.indexOf(this.currentImgurl);
      var nextImgUrl="";
      if(index<this.imgurl.length-1){
        nextImgUrl=this.imgurl[index+1];
      }else{
        nextImgUrl=this.imgurl[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    preImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex == 0) {
            anextImgUrl = this.imgurls[this.imgurls.length - 1];
          } else {
            anextImgUrl = this.imgurls[aindex - 1];
          }
          this.currentImgurl = anextImgUrl;
          break;
        case "wash":
          var windex = this.waimgurl.indexOf(this.currentImgurl);
          var wnextImgUrl = "";
          if (windex == 0) {
            wnextImgUrl = this.waimgurl[this.waimgurl.length - 1];
          } else {
            wnextImgUrl = this.waimgurl[windex - 1];
          }
          this.currentImgurl = wnextImgUrl;
          break;
        case "kitchen":
          var kindex = this.kiimgurl.indexOf(this.currentImgurl);
          var knextImgUrl = "";
          if (kindex == 0) {
            knextImgUrl = this.kiimgurl[this.kiimgurl.length - 1];
          } else {
            knextImgUrl = this.kiimgurl[kindex - 1];
          }
          this.currentImgurl = knextImgUrl;
          break;
        case "parlour":
          var pindex = this.paimgurl.indexOf(this.currentImgurl);
          var pnextImgUrl = "";
          if (pindex == 0) {
            pnextImgUrl = this.paimgurl[this.paimgurl.length - 1];
          } else {
            pnextImgUrl = this.paimgurl[pindex - 1];
          }
          this.currentImgurl = pnextImgUrl;
          break;
        case "balcony":
          var bindex = this.baimgurl.indexOf(this.currentImgurl);
          var bnextImgUrl = "";
          if (bindex == 0) {
            bnextImgUrl = this.baimgurl[this.baimgurl.length - 1];
          } else {
            bnextImgUrl = this.baimgurl[bindex - 1];
          }
          this.currentImgurl = bnextImgUrl;
          break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex == 0) {
            snextImgUrl = this.stimgurl[this.stimgurl.length - 1];
          } else {
            snextImgUrl = this.stimgurl[bindex - 1];
          }
          this.currentImgurl = snextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index == 0) {
            nextImgUrl = this.imgurl[this.imgurl.length - 1];
          } else {
            nextImgUrl = this.imgurl[index - 1];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    nextImgNew() {
      var pic = this.active;
      switch (pic) {
        case "all":
          var aindex = this.imgurls.indexOf(this.currentImgurl);
          var anextImgUrl = "";
          if (aindex < this.imgurls.length - 1) {
            anextImgUrl = this.imgurls[aindex + 1];
          } else {
            // anextImgUrl = this.imgurls[aindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            anextImgUrl = this.imgurls[0];
          }
          this.currentImgurl = anextImgUrl;
          break;
        case "wash":
          var windex = this.waimgurl.indexOf(this.currentImgurl);
          var wnextImgUrl = "";
          if (windex < this.waimgurl.length - 1) {
            wnextImgUrl = this.waimgurl[windex + 1];
          } else {
            // wnextImgUrl = this.waimgurl[windex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            wnextImgUrl = this.waimgurl[0];
          }
          this.currentImgurl = wnextImgUrl;
          break;
        case "kitchen":
          var kindex = this.kiimgurl.indexOf(this.currentImgurl);
          var knextImgUrl = "";
          if (kindex < this.kiimgurl.length - 1) {
            knextImgUrl = this.kiimgurl[kindex + 1];
          } else {
            // knextImgUrl = this.kiimgurl[kindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            knextImgUrl = this.kiimgurl[0];
          }
          this.currentImgurl = knextImgUrl;
          break;
        case "parlour":
          var pindex = this.paimgurl.indexOf(this.currentImgurl);
          var pnextImgUrl = "";
          if (pindex < this.paimgurl.length - 1) {
            pnextImgUrl = this.paimgurl[pindex + 1];
          } else {
            // pnextImgUrl = this.paimgurl[pindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            pnextImgUrl = this.paimgurl[0];
          }
          this.currentImgurl = pnextImgUrl;
          break;
        case "balcony":
          var bindex = this.baimgurl.indexOf(this.currentImgurl);
          var bnextImgUrl = "";
          if (bindex < this.baimgurl.length - 1) {
            bnextImgUrl = this.baimgurl[bindex + 1];
          } else {
            // bnextImgUrl = this.baimgurl[bindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            bnextImgUrl = this.baimgurl[0];
          }
          this.currentImgurl = bnextImgUrl;
          break;
        case "standard":
          var sindex = this.stimgurl.indexOf(this.currentImgurl);
          var snextImgUrl = "";
          if (sindex < this.stimgurl.length - 1) {
            snextImgUrl = this.stimgurl[bindex + 1];
          } else {
            // bnextImgUrl = this.baimgurl[bindex];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            snextImgUrl = this.stimgurl[0];
          }
          this.currentImgurl = snextImgUrl;
          break;
        default:
          var index = this.imgurl.indexOf(this.currentImgurl);
          var nextImgUrl = "";
          if (index < this.imgurl.length - 1) {
            nextImgUrl = this.imgurl[index + 1];
          } else {
            // nextImgUrl = this.imgurl[index];
            // this.$Modal.info({ title: "提示", content: "已是最后一张图片" });
            nextImgUrl = this.imgurl[0];
          }
          this.currentImgurl = nextImgUrl;
          break;
      }
    },
    preActImg(){
      var index= this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl="";
      if(index==0){
        nextImgUrl=this.actimgurl[this.actimgurl.length-1]
      }else{
        nextImgUrl=this.actimgurl[index-1]
      }
      this.currentActImgurl = nextImgUrl;
    },
    nextActImg(){
      var index= this.actimgurl.indexOf(this.currentActImgurl);
      var nextImgUrl="";
      if(index<this.actimgurl.length-1){
        nextImgUrl=this.actimgurl[index+1];
      }else{
        nextImgUrl=this.actimgurl[0];
      }
      this.currentActImgurl = nextImgUrl;
    },
    GetData() {
      this.$http
        .get("/Customer/GetCustomer", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;
          this.dataobj = res.data.Data;
          if (res.data.Data.Imgs != null && res.data.Data.Imgs.length > 0) {
            var imgStr = res.data.Data.Imgs;
            var imgarray = imgStr.split(",");
            var imgUrlArray = [];
            var otherProductArray = res.data.Data.OtherProduct.split(",");
            if (otherProductArray.length >= 3) {
              res.data.Data.OtherProduct = 40;
            } else {
              res.data.Data.OtherProduct = 0;
            }
            var imgUrlArray = imgarray.map(item => {
              if (item.search("Images") != -1) {
                //老数据的图片路径
                return that.base_url + item;
              } else if (item.search("Images") == -1) {
                //新路径格式 2018年9月10日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.Data.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
              }
            });
            that.imgurl = imgUrlArray;
          } else {
            that.imgurl = [];
          }
          if (res.data.Data.ActImgs != null && res.data.Data.ActImgs.length > 0) {
            var imgStr = res.data.Data.ActImgs;
            var imgarray = imgStr.split(",");
            var actImgUrlArray = [];
            
            var actImgUrlArray = imgarray.map(item => {
              //路径格式 2020年9月10日
                return (
                  that.base_url +
                  "Images/activity/" +
                  res.data.Data.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
        });
        
    },
    GetDataNew(url) {
      this.$http
        .get("/Customer/GetCustomerPic", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;
          this.dataobj = res.data.Data.customer;
          // 查询管路图是否合格，未设置则按钮可用，已设置则按钮不可用
          that.ischecks = res.data.Data.customer.IsPipeCheck;
          if(that.ischecks == 0){
            that.dis = false;
          }else{
            that.dis = true;
          }
          
          switch (url) {
            case "all":
              that.imgurls=[];
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.WashImgs != null && res.data.Data.customerex.WashImgs.length > 0) {
                var awimgStr = res.data.Data.customerex.WashImgs;
                var awimgarray = awimgStr.split(",");
                var awimgUrlArray = [];
                var awimgUrlArray = awimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "wash" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.waimgurl = awimgUrlArray;
                that.imgurls=that.imgurls.concat(that.waimgurl);
                // that.imgurls.push(that.waimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.KitchenImgs != null && res.data.Data.customerex.KitchenImgs.length > 0) {
                var akimgStr = res.data.Data.customerex.KitchenImgs;
                var akimgarray = akimgStr.split(",");
                var akimgUrlArray = [];
                var akimgUrlArray = akimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "kitchen" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.kiimgurl = akimgUrlArray;
                that.imgurls=that.imgurls.concat(that.kiimgurl);
                // that.imgurls.push(that.kiimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.ParlourImgs != null && res.data.Data.customerex.ParlourImgs.length > 0) {
                var apimgStr = res.data.Data.customerex.ParlourImgs;
                var apimgarray = apimgStr.split(",");
                var apimgUrlArray = [];
                var apimgUrlArray = apimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "parlour" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.paimgurl = apimgUrlArray;
                that.imgurls=that.imgurls.concat(that.paimgurl);
                // that.imgurls.push(that.paimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.BalconyImgs != null && res.data.Data.customerex.BalconyImgs.length > 0) {
                var abimgStr = res.data.Data.customerex.BalconyImgs;
                var abimgarray = abimgStr.split(",");
                var abimgUrlArray = [];
                var abimgUrlArray = abimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "balcony" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.baimgurl = abimgUrlArray;
                that.imgurls=that.imgurls.concat(that.baimgurl);
                // that.imgurls.push(that.baimgurl);
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.StandardImgs != null && res.data.Data.customerex.StandardImgs.length > 0) {
                var asimgStr = res.data.Data.customerex.StandardImgs;
                var asimgarray = asimgStr.split(",");
                var asimgUrlArray = [];
                var asimgUrlArray = asimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      "standard" +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.stimgurl = asimgUrlArray;
                that.imgurls=that.imgurls.concat(that.stimgurl);
                // that.imgurls.push(that.baimgurl);
              }
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var aimgStr = res.data.Data.customer.Imgs;
                var aimgarray = aimgStr.split(",");
                var aimgUrlArray = [];
                var aimgUrlArray = aimgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = aimgUrlArray;
                that.imgurls=that.imgurls.concat(that.imgurl);
                // that.imgurls.push(that.imgurl);
              }
              break;
            case "wash":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.WashImgs != null && res.data.Data.customerex.WashImgs.length > 0) {
                var wimgStr = res.data.Data.customerex.WashImgs;
                var wimgarray = wimgStr.split(",");
                var wimgUrlArray = [];
                var wimgUrlArray = wimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.waimgurl = wimgUrlArray;
              } else {
                that.waimgurl = [];
              }
              break;
            case "kitchen":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.KitchenImgs != null && res.data.Data.customerex.KitchenImgs.length > 0) {
                var kimgStr = res.data.Data.customerex.KitchenImgs;
                var kimgarray = kimgStr.split(",");
                var kimgUrlArray = [];
                var kimgUrlArray = kimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.kiimgurl = kimgUrlArray;
              } else {
                that.kiimgurl = [];
              }
              break;
            case "parlour":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.ParlourImgs != null && res.data.Data.customerex.ParlourImgs.length > 0) {
                var pimgStr = res.data.Data.customerex.ParlourImgs;
                var pimgarray = pimgStr.split(",");
                var pimgUrlArray = [];
                var pimgUrlArray = pimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.paimgurl = pimgUrlArray;
              } else {
                that.paimgurl = [];
              }
              break;
            case "balcony":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.BalconyImgs != null && res.data.Data.customerex.BalconyImgs.length > 0) {
                var bimgStr = res.data.Data.customerex.BalconyImgs;
                var bimgarray = bimgStr.split(",");
                var bimgUrlArray = [];
                var bimgUrlArray = bimgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.baimgurl = bimgUrlArray;
              } else {
                that.baimgurl = [];
              }
              break;
            case "standard":
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.StandardImgs != null && res.data.Data.customerex.StandardImgs.length > 0) {
                var simgStr = res.data.Data.customerex.StandardImgs;
                var simgarray = simgStr.split(",");
                var simgUrlArray = [];
                var simgUrlArray = simgarray.map(item => {
                  //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      url +
                      "/" +
                      item +
                      ".jpg"
                    );
                });
                that.stimgurl = simgUrlArray;
              } else {
                that.stimgurl = [];
              }
              if (res.data.Data.customerex!=null&&res.data.Data.customerex.StandardDesc != null) {
                that.StandardDesc = res.data.Data.customerex.StandardDesc;
              }
              break;
            default:
              if (res.data.Data.customer.Imgs != null && res.data.Data.customer.Imgs.length > 0) {
                var imgStr = res.data.Data.customer.Imgs;
                var imgarray = imgStr.split(",");
                var imgUrlArray = [];
                var otherProductArray = res.data.Data.customer.OtherProduct.split(",");
                if (otherProductArray.length >= 3) {
                  res.data.Data.customer.OtherProduct = 40;
                } else {
                  res.data.Data.customer.OtherProduct = 0;
                }
                var imgUrlArray = imgarray.map(item => {
                  if (item.search("Images") != -1) {
                    //老数据的图片路径
                    return that.base_url + item;
                  } else if (item.search("Images") == -1) {
                    //新路径格式 2018年9月10日
                    return (
                      that.base_url +
                      "Images/upload/" +
                      res.data.Data.customer.WarrantyNum +
                      "/" +
                      item +
                      ".jpg"
                    );
                  }
                });
                that.imgurl = imgUrlArray;
              } else {
                that.imgurl = [];
              }
              break;
          }
          // 活动图片
          if (res.data.Data.customer.ActImgs != null && res.data.Data.customer.ActImgs.length > 0) {
            var aimgStr = res.data.Data.customer.ActImgs;
            var aimgarray = aimgStr.split(",");
            var actImgUrlArray = [];
            
            var actImgUrlArray = aimgarray.map(item => {
              //路径格式 2020年9月10日
              return (
                  that.base_url +
                  "Images/activity/" +
                  res.data.Data.customer.WarrantyNum +
                  "/" +
                  item +
                  ".jpg"
                );
            });
            that.actimgurl = actImgUrlArray;
          } else {
            that.actimgurl = [];
          }
        });
      
    },
    GetIntegral() {
      this.$http
        .get("/Customer/GetIntegral", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          var that = this;
          this.integralobj = res.data.Data;
        });
    },
    handleClick: function(e) {
      this.active=e;
      this.GetDataNew(e);
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
  },
  created() {
    // this.GetData();
    this.agentId = this.$route.params.agentId;
    if(this.agentId == 1){
      this.GetDataNew(this.active);
    }else{
      this.GetData();
    }
    this.GetIntegral();
  }
};
</script>

<style>
.detail-form {
}
</style>
