<template>
  <div>
    <img src="../../assets/logo.jpg" fit="contain" width="100%" alt="" />
    <div class="btn">
      <van-button round type="default" size="small" style="width:65px;" @click="tovrview">
        跳过{{count}}
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant';
export default {
  name: "Index",
  data() {
    return {
      warranty:'',
      count:0,
    };
  },
  components: {
    [Button.name]: Button,
  },
  mounted() {
    this.warranty = this.$route.params.warranty;
    this.clickJump();
  },
  methods: {
    //3秒后进入跳转页面
    clickJump(){
      const timejump = 3;
      if(!this.timer){
        this.count = timejump ;
        this.show = false;
        this.timer = setInterval(()=>{
          if(this.count > 0 && this.count <= timejump ){
            this.count--;
          }else{
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.$router.push({name: "vrview", params: {warranty:this.warranty}});
          }
      },1000)
      }
    },
    tovrview(){
      var that=this;
      that.$router.push({
        name: "vrview",
        params: {warranty:this.warranty},
      });
    },
  },
};
</script>
<style>
*{
  margin:0;
  padding: 0;
}
div{
  position: relative;
}
.btn{
  position: absolute;
  right: 20px;
  top: 30px;
}
</style>