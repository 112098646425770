<template>
  <div class="listform">
    <div class="form-title">客户档案(请使用时间筛选功能以提高加载速度)
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end"
        placeholder="根据上传时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
    <Modal v-model="editInfoModal.show" title="修改客户档案资料" @on-ok="EditCustomerInfoHandler">
      <Form :model="tempData" :label-width="100">
        <FormItem label="电话 ">
          <Input v-model="tempData.CustTel" placeholder="输入要修改的客户电话"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "CustName", label: "客户姓名" },
        { value: "CustTel", label: "电话" },
        { value: "CustAddress", label: "地址" }
      ],
      select: {
        Agent: []
      },
      dateSearchValue: [],
      dateSearchOption: {
        shortcuts: [
          {
            text: "一周内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: "一月内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: "一年内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              return [start, end];
            }
          }
        ]
      },
      table: {
        tablecolumsTitle: [
          {
            title: "是否抽查",
            key: "Status",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 2 && "success") || "error";
              const text = (row.Status == 2 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "客户姓名",
            key: "CustName",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "CustTel",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "CustAddress",
            width: 250,
            align: "center",
            tooltip: true
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "试压员",
            key: "WorkerNameDesc",
            width: 120,
            align: "center",
            tooltip: true
          },
          
          {
            title: "管材品牌",
            key: "Brand",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "收费金额",
            key: "ChargeMoney",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "备注",
            key: "Remark",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "上传时间",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "是否传图",
            key: "Imgs",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.Imgs != null && row.Imgs != "" && "success") || "error";
              const text = (row.Imgs != null && row.Imgs != "" && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "审核人",
            key: "_CreateDesc",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "",
            width: 160,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.ViewImgs(params.row);
                      }
                    }
                  },
                  "试压图"
                ),
                h(
                  "Button",
                  {
                    props: { type: "Primary", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.EditCustomerInfoModal(params.row);
                      }
                    }
                  },
                  "修改"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
      tempData: {
        CustTel:''
      },
      editInfoModal: {
        show: false,
        loading: true
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_washcustomerlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("WashCustomer/GetWashCustomerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];

      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 5,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    GoBack: function(e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    ViewImgs: function(row) {
      this.$router.push({
        name: "dealer_washcustomerimage",
        params: { id: row.ID, agentId: row.AgentId }
      });
    },
    ExportExcel: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http
        .get("WashCustomer/ExportWashCustomerExcel", {
          params: {
            status: that.currentStatus
          }
        })
        .then(res => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    EditCustomerInfoHandler() {
      var that = this;
      this.$http.post("WashCustomer/EditWashCustomer", this.tempData).then(res => {
        that.editInfoModal.show = false;
        that.GetTableList();
      });
    },
    //修改资料弹窗
    EditCustomerInfoModal: function(row_data) {
      var that=this;      
      var id=row_data.ID;
      that.table.loading = true;
      this.$http.get("WashCustomer/GetWashCustomer",{params:{id:id}}).then(res=>{
        console.log(res.data.Data);
        that.tempData=res.data.Data; 
        that.editInfoModal.show = true;
        that.table.loading = false;
      })
    },
    AgentSearch: function(e) {
      this.loading = true;
      var conditions = [];

      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 5,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      
      if (e != "") {
        conditions.push({
          PropertyName: "AgentId",
          Operater: 2,
          LogicOperater: 0,
          Value: e
        });
      }

      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 