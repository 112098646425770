<template>
  <div>
    <Row :gutter="24">
      <Col :span="4">
        <Card>
          <p slot="title">待分配预约单</p>

          <Button
            slot="extra"
            style="margin-left: 30px"
            type="primary"
            @click="ReFresh()"
            icon="md-barcode"
            >刷新数据</Button
          >
          <div>
            <Select v-model="sel_field" style="width: 200px">
              <Option
                v-for="item in sel_values"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
            <Input
              v-model="queryvalue"
              placeholder="输入要查询的内容"
              style="width: 200px"
            ></Input>
            <Button type="primary" @click="Search" icon="ios-search"
              >搜索</Button
            >
          </div>

          <div class="datatable">
            <Table
              highlight-row
              :border="false"
              size="small"
              @on-sort-change="Sort"
              :columns="table.tablecolumsTitle"
              :loading="table.loading"
              :data="table.datalist"
              @on-current-change="RowSel"
            ></Table>
          </div>
        </Card>
      </Col>
      <Col :span="16">
        <Card>
          <p slot="title">试压地址</p>

          <div id="map_container" style="width: 100%; height: 900px"></div>
          <div id="toolControl">
            <div
              @click="openMuiltSel"
              :class="['toolItem', { active: enableMuiltSel }]"
              id="rectangle"
              title="框选多个点"
            ></div>
          </div>
          <p>
            地图中自动展示待分配的试压单。
            <br />
            1.红色标记为已预选试压员，蓝色标记为还未选试压员
            <br />
            2.鼠标左键点击标记可查看详细信息
            <br />
            3.双击可以加入右边临时列表，方便批量分配试压员
            <br />
            4.点击地图中间的框选模式,可以一次选择一批地点加入临时列表
          </p>
        </Card>
      </Col>
      <Col :span="4">
        <Card>
          <p slot="title">已选中预约单</p>
          <Button
            slot="extra"
            style="margin-left: 30px"
            type="primary"
            @click="ModelShow()"
            icon="md-barcode"
            >预选试压员</Button
          >
          <Table
            :border="false"
            size="small"
            :columns="tempTable.tablecolumsTitle"
            :data="tempTable.datalist"
          ></Table>
        </Card>
      </Col>
    </Row>
    <Modal
      title="选择试压员"
      @on-cancel="ModalCancel"
      width="1000"
      height="600"
      :footer-hide="true"
      v-model="workermodal.show"
    >
      <worker-RowSel
        v-if="workermodal.show"
        v-on:rowsel="WorkerRowSel"
      ></worker-RowSel>
    </Modal>
  </div>
</template>

<script>
// 引入加载TXMap的方法
import TMap from "@/utils/initMap";
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      tMap: null, // 腾讯地图实例
      TXMap: null, // 腾讯地图原型
      markerArr: [],
      styleOption: {},
      style2Option: {},
      enableMuiltSel: false,
      rectangleIds: [],
      workermodal: {
        show: false,
      },

      queryvalue: "",

      sel_field: "",
      sel_values: [
        { value: "RegionFullDesc", label: "行政区" },
        { value: "WorkerName", label: "试压员" },
        { value: "Address", label: "地址" },
      ],

      table: {
        tablecolumsTitle: [
          {
            title: "地址",
            key: "Address",
            width: 180,
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 145,
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "试压员",
            key: "WorkerName",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          // {
          //   title: "操作",
          //   key: "bus",
          //   width: 100,
          //   align: "center",

          //   render: (h, params) => {
          //     if (params.IsAddTemp == 1) {
          //       return h("div", [
          //         h(
          //           "Button",
          //           {
          //             props: { type: "primary", size: "small" },
          //             style: { marginRight: "3px" },
          //             on: {
          //               click: () => {
          //                 this.AcceptOrder(params.row);
          //               },
          //             },
          //           },
          //           "接受预约"
          //         ),
          //       ]);
          //     }
          //   },
          // },
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 100,
        loading: true,
        sort: " ",
      },
      tempTable: {
        tablecolumsTitle: [
          {
            title: "地址",
            key: "Address",
            width: 180,
            align: "center",
            tooltip: true,
          },
          {
            title: "试压员",
            key: "WorkerName",
            width: 100,
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "操作",
            key: "bus",
            width: 80,
            align: "center",

            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.RemoveTemp(params.row);
                      },
                    },
                  },
                  "移除"
                ),
              ]);
            },
          },
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 100,
        loading: false,
        sort: " ",
      },
    };
  },
  methods: {
    PageChange: function (e) {
      this.table.pageIndex = e;
      this.GetTableList();
      //   this.$router.push({
      //     name: "dealer_prepressmap",
      //     query: { pageindex: e },
      //   });
    },

    ReFresh: function () {
      this.GetTableList();
    },
    Search: function (e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim(),
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      that.$http
        .get("PrePress/GetPrePressMapPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            pageSize: that.table.pageSize,
            sort: that.table.sort,
            status: 30, //只查待分配试压的
          },
        })
        .then((res) => {
          var latlngBounds = new that.TXMap.LatLngBounds();
          res.data.ListData.map((item) => {
            latlngBounds.extend(new that.TXMap.LatLng(item.Lat, item.Lgt));
            item.WorkerName = item.WorkerName || "未分配";
            item.IsAddTemp = 0;
          });
          //   that.tMap.setScale(that.tMap.getScale()-0.1)

          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
          if (latlngBounds&&res.data.ListData.length>0) {
            that.tMap.fitBounds(latlngBounds);
            that.setMarkImg();
            that.renderMarker();
            that.SetTemptableList();
          }
        });
    },

    ModalCancel: function (e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    ModelShow: function () {
      var that = this;
      this.$http.SetConditions();
      this.workermodal.show = true;
    },
    WorkerRowSel: function (e) {
      var that = this;
      console.log(e.ID);
      var preressIds = [];
      this.tempTable.datalist.map((item) => {
        preressIds.push(item.ID);
      });

      this.$http
        .post(`Prepress/PreSetWorkerList?workerid=${e.ID}`, preressIds)
        .then((res) => {
          if (res.data == "success") {
            that.$Modal.success({
              title: "操作提醒",
              content: "预分配成功",
            });
            //that.$Message.info("预分配成功");
          } else {
            that.$Message.warning(res.data);
          }
          this.workermodal.show = false;
          that.GetTableList();
        });
    },
    SetTemptableList: function () {
      var tempTableList = [];
      this.table.datalist.map((item) => {
        if (item.IsAddTemp == 1) {
          tempTableList.push(item);
        }
      });
      this.tempTable.datalist = tempTableList;
    },
    RemoveTemp: function (evt) {
      this.table.datalist.map((item) => {
        if (item.ID == evt.ID) {
          item.IsAddTemp = 0;
        }
      });
      this.SetTemptableList();
    },
    Sort: function (sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },

    RowSel: function (currentRow, oldCurrentRow) {
      console.log(currentRow, oldCurrentRow);
      this.tMap.easeTo(
        {
          zoom: 15,
          center: new this.TXMap.LatLng(currentRow.Lat, currentRow.Lgt),
        },
        { duration: 2000 }
      ); //平滑缩放,旋转到指定级别
      let { Lat, Lgt, Address, PreressStatus, WorkerName } = currentRow;
      this.initWindow(Lat, Lgt, Address, PreressStatus, WorkerName);
      //this.tMap.panTo(new this.TXMap.LatLng(currentRow.Lat, currentRow.Lgt),{duration: 2000});//平滑缩放,旋转到指定级别
    },
    //创建marker事件
    hanleMarker() {
      let myMarkers = [];
      this.table.datalist.map((item, index) => {
        let obj = {
          id: item.ID,
          styleId: "marker" + item.ID,
          position: new this.TXMap.LatLng(item.Lat * 1, item.Lgt * 1),
          properties: {
            ID: item.ID,
            title: `${item.Address}`,
            status: item.PrepressStatus,
            workerName: item.WorkerName,
          },
          content: `${item.Title}`,
        };
        myMarkers.push(obj);
      });
      return myMarkers;
    },

    // 渲染marker
    renderMarker() {      
      var that = this;
      let markerArr = this.hanleMarker();
      // let marker2Arr = this.hanle2Marker();
      let map = this.tMap;

      if (this.marker) {
        // this.markerCluster.updateGeometries(markerArr);

        this.marker.updateGeometries(markerArr);
        this.marker.setStyles(that.styleOption);
      } else {

        this.marker = new this.TXMap.MultiMarker({
          id: "marker-layer",
          map: map,
          styles: that.styleOption,
          geometries: markerArr,
        });
      }
      if (!this.editor) {
        var rectangle = new this.TXMap.MultiRectangle({
          map: map,
        });
        // 初始化几何图形及编辑器
        this.editor = new this.TXMap.tools.GeometryEditor({
          map, // 编辑器绑定的地图对象
          overlayList: [
            // 可编辑图层
            {
              //GeometryEditor 以 MultiPolygon（可以理解为多边形图层）激活进行编辑
              id: "rectangle",
              overlay:rectangle
            },
          ],
          // selectable:true,
          actionMode: that.TXMap.tools.constants.EDITOR_ACTION.DRAW, //编辑器的工作模式
          snappable: true, // 开启邻近吸附
        });

        // 监听绘制结束事件，获取绘制几何图形
        this.editor.on("draw_complete", (geometry,sb) => {
              // 获取矩形顶点坐标
            var id = geometry.id;
            var geo = rectangle.geometries.filter(function (item) {
              return item.id === id;
            });
            console.log('绘制的矩形定位的坐标：', geo[0].paths);
          that.table.datalist.map((item) => {
            var pos = new that.TXMap.LatLng(item.Lat * 1, item.Lgt * 1);
            if(that.TXMap.geometry.isPointInPolygon(pos,geo[0].paths)){
              item.IsAddTemp=1
            }

          });
          this.rectangleIds.push(geometry.id);
          that.SetTemptableList();
        });
        this.editor.disable();
      }

      //给标识添加点击事件
      this.marker.on("click", (event) => {
        let { lat, lng } = event.latLng;
        let { Id, title, status, workerName } = event.geometry.properties;
        this.initWindow(lat, lng, title, status, workerName);
        this.infoWin.open();
      });

      this.marker.on("dblclick", (event) => {
        let { ID, title, status, workerName } = event.geometry.properties;
        var tempTableList = [];
        that.table.datalist.map((item) => {
          if (item.ID == ID) {
            item.IsAddTemp = 1;
          }
        });
        this.SetTemptableList();
      });
    },
    setMarkImg() {
      let styleOption = {};
      // let style2Option = {};
      // 遍历图标集合
      this.table.datalist.map((item) => {
        var src =
          "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerNew.png";
        if (item.WorkerName == "未分配") {
          src =
            "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png";
        }
        styleOption["marker" + item.ID] = new this.TXMap.MarkerStyle({
          cursor: "pointer",
          width: 25,
          height: 25,
          anchor: { x: 16, y: 32 },
          src: src,
          alignment: "center",
          verticalAlignment: "middle",
          direction: "bottom", // 标注点文本文字相对于标注点图片的方位
          offset: { x: 0, y: 8 }, // 标注点文本文字基于direction方位的偏移属性
          fontWeight: "normal",
          angle: 0,
        });
        // styleOption["marker" + item.ID] = new this.TXMap.LabelStyle({
        //   color: "#000000",
        //   size: 15,
        //   offset: { x: 26, y: -32 },
        //   angle: 0,
        //   alignment: "center",
        //   verticalAlignment: "middle",
        //   fontWeight: "normal",
        // });
        // style2Option["label" + item.ID] = new this.TXMap.LabelStyle({
        //   color: "#000000",
        //   size: 15,
        //   offset: { x: 26, y: -32 },
        //   angle: 0,
        //   alignment: "center",
        //   verticalAlignment: "middle",
        //   fontWeight: "normal",
        // });
      });
      this.styleOption = styleOption;
      // this.style2Option = style2Option;
      //   return styleOption;
    },

    // 初始化一个信息窗口
    initWindow(lat, lng, title, status, workerName) {
      // 一次只能打开一个窗口 打开之前先关闭之前打开的
      if (this.infoWin) {
        this.closeWindow();
      }
      this.infoWin = new this.TXMap.InfoWindow({
        map: this.tMap,
        position: new this.TXMap.LatLng(lat, lng),
        // enableCustom: true,
        content: `<ul class="equipment">
        <li>地址：<span>${title}</span></li>
        <li>试压员：<span class="${status == 1 ? "good" : "bad"}">        
        ${workerName}
        </span></li>        
        </ul>`,
        offset: { x: 0, y: -30 }, //设置信息窗相对position偏移像素
      });
      // 信息窗口关闭回调
      this.infoWin.on("closeclick", () => {
        // ...
      });
    },

    // 关闭信息窗口
    closeWindow() {
      if (this.infoWin) {
        this.infoWin.close();
      }
    },
    openMuiltSel() {
      if(!this.editor){
        this.$Message.info({
          title: "提示",
          content: "没有数据，请先标记地图位置",
        });
        this
        return;
      }
      if (this.enableMuiltSel) {
        this.$Modal.info({
          title: "提示",
          content: "关闭框选模式",
        });
        this.editor.setActionMode(
          this.TXMap.tools.constants.EDITOR_ACTION.INTERACT
        );
        // this.editor.setActiveOverlay('rectangle')
        this.editor.select(this.rectangleIds);
        this.editor.delete();
        this.editor.disable();
        this.rectangleIds = [];
      } else {
        this.$Modal.info({
          title: "提示",
          content: "开启框选模式",
        });
        this.editor.enable();
        this.editor.setActionMode(
          this.TXMap.tools.constants.EDITOR_ACTION.DRAW
        );
      }
      this.enableMuiltSel = !this.enableMuiltSel;
    },
  },
  created: function () {
    // this.initMap();
    //this.GetCurEmployee();
  },
  components: {
    "worker-RowSel": workerRolSel,
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    var that = this;
    TMap.init().then((TMap) => {
      console.log(TMap);
      this.TXMap = TMap;
      this.tMap = new TMap.Map("map_container", {
        center: new TMap.LatLng(28.19652, 112.977361), //设置地图中心点坐标
        zoom: 15, //设置地图缩放级别
        viewMode: "2D",
        doubleClickZoom: false,
        showControl: false,
      });
      this.GetTableList();
    });
  },
};
</script>

<style>
#toolControl {
  position: absolute;
  top: 20px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 120px;
  z-index: 1001;
}
#rectangle {
  background-image: url("https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/rectangle.png");
}
#delete {
  background-image: url("https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/delete.png");
}
.toolItem {
  width: 40px;
  height: 40px;
  float: left;
  margin: 1px;
  padding: 4px;
  border-radius: 3px;
  background-size: 30px 30px;
  background-position: 4px 4px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #e4e7ef;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.active {
  border-color: #d5dff2;
  background-color: #d5dff2;
}

.toolItem:hover {
  border-color: #789cff;
}
</style>