<template>
  <div class="listform">
    <div class="form-title">待审核售后单
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end" placeholder="根据创建时间筛选" style="width: 200px"></DatePicker>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="true" size="small"  :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>

<script>
import processVue from "../../components/public_component/process.vue";

export default {
  data() {
    return {
      sel_field: "",
      sel_values: [
        { value: "CustomerName", label: "姓名" },
        { value: "CustomerTel", label: "电话" },
        { value: "AddressDetail", label: "地址" },
      ],
      dateSearchOption: {
        shortcuts: [
          {
            text: "一周内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: "一月内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: "一年内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              return [start, end];
            }
          }
        ]
      },
      dateSearchValue: "",
      queryvalue: "",
      table: {
        tablecolumsTitle: [
          {
            title: "售后单号",
            key: "AfterSaleCode",
            width: 130,
            align: "center",
            tooltip: true,
            fixed: "left"
          },
          {
            title: "是否提交审核",
            key: "IsSubmit",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.IsSubmit == 0 && "error") || (row.IsSubmit == 1 && "success");
              const text = (row.IsSubmit == 0 && "否") || (row.IsSubmit == 1 && "是");
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "审核状态",
            key: "Status",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 0 && "success")  || "error";
              const text = (row.Status == 0 && "待审核") || "不通过";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          { title: "客户姓名", key: "CustomerName", width: 100 ,align:'center'},
          { title: "客户电话", key: "CustomerTel", width: 120,align:'center' },
          { title: "售后类别", key: "AfterBillTypeDesc",width:100,align:'center' },
          { title: "售后地址", key: "AddressDetail",width:200,align:'center',tooltip:true },
          { title: "行政区", key: "AddressText", width: 180,align:'center',tooltip:true },
          { title: "售后人员", key: "DisposeByName", width: 100,align:'center' },
          {
            title: "售后进度",
            key: "status",
            width: 130,
            align:'center',
            render: (h, params) => {
              const row = params.row;
              const color = "primary";
              const text = row.ProcessStatusDesc;
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          { title: "创建时间", key: "_CreateTime" ,width:150,align:'center',tooltip:true},
          { title: "提交审核时间", key: "SubmitTime" ,width:150,align:'center',tooltip:true},
          {
            title: "操作",
            key: "action",
            width: 130,
            align: "center",
            fixed: "right",            
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small"
                    },
                    style: {
                      marginRight: "3px"
                    },
                    on: {
                      click: () => {
                        this.ViewImgs(params.row);
                      }
                    }
                  },
                  "审核"
                )
              ]);
            }
          },
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true
      },
    };
  },
  components: {
  },
  methods: {
    Search: function() {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    PageChange: function(e) {
      this.table.pageIndex = e;      
      this.GetTableList();
      this.$router.push({ name: "waitauditaftersalelist", query: { pageindex: e } });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading=true;
      
      this.$http
        .get("AfterSaleBill/GetWaitAuditAfterSaleBillPageList", {
          params: {
            pageIndex: that.table.pageIndex
          }
        })
        .then(res => {
          that.table.loading = false;
          this.$Modal.remove();
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        })
        .catch(function(error) {
          that.table.loading = false;
          that.$Message.error({
            content: "网络连接错误！",
            duration: 10,
            closable: true
          });
          console.log(error);
        });
    },
    ViewImgs: function(row) {
      this.$router.push({
        name: "waitauditaftersaledetail",
        params: { id: row.ID }
      });
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>

<style>
</style>
