<template>
  <div class="listform">
    <div class="form-title">次级经销商
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Select filterable style="width:200px" clearable @on-change="AgentSearch" v-if="IsSystem==1">
        <Option v-for="item in select.Agent" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
      </Select>
      <!-- <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end" placeholder="根据上传时间筛选" style="width: 200px"></DatePicker> -->
      <Button type="success" v-if="IsSystem==1||AgentId==3" @click="NewDealer" icon="ios-document">新建</Button>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="输入要增加的数量" width="300" height="600" v-model="dist_modal.show" @on-ok="Dist_ModalOK" @on-cancel="ModalCancel">
      <Input v-model="dist_modal.distnum" :number="dist_modal.show" placeholder="填写数量"></Input>
    </Modal>
    <Modal title="误工积分消分" width="400" height="800" v-model="IntegralExchange_modal.show" @on-ok="ExChange_ModalOK" @on-cancel="ModalCancel">
      <div style="margin-bottom:10px;">
        <p>数量：</p>
        <Input v-model="IntegralExchange_modal.changeintegral" placeholder="填写要兑换的积分数量"></Input>
      </div>
      <div>
        <p>备注：</p>
        <Input v-model="IntegralExchange_modal.remark" type="textarea" placeholder="填写要兑换积分备注记录"></Input>
      </div>
    </Modal>
    <Modal title="现金积分提货消分" width="400" height="800" v-model="CaIntegralExchange_modal.show" @on-ok="CaExChange_ModalOK" @on-cancel="ModalCancel">
      <div style="margin-bottom:10px;">
        <p>数量：</p>
        <Input v-model="CaIntegralExchange_modal.changecaintegral" placeholder="填写要提货的现金积分"></Input>
      </div>
      <div>
        <p>备注：</p>
        <Input v-model="CaIntegralExchange_modal.remark" type="textarea" placeholder="填写要提货积分备注"></Input>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "Tel", label: "电话" }
      ],
      select: {
        Agent: []
      },
      IsSystem: 0,
      AgentId: 0,
      EmployeeType: 0,
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "Name",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "店名",
            key: "DealerName",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "Tel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "误工积分",
            key: "IntegralNumber",
            width: 120,
            align: "center",
            tooltip: true,
            sortable:'custom'
          },
          {
            title: "现金积分",
            key: "CashIntegral",
            width: 120,
            align: "center",
            tooltip: true,
            sortable:'custom'
          },
          // {
          //   title: "自动编号前缀",
          //   key: "AutoPreCode",
          //   width: 150,
          //   align: "center",
          //   tooltip: true
          // },
          {
            title: "联系电话",
            key: "ContactTel",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "地址",
            key: "DealerAddr",
            width: 240,
            align: "center",
            tooltip: true
          },
          {
            title: "维护人",
            key: "Broker",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "是否系统内",
            key: "LevelDesc",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "积分归属",
            key: "ParentDealerDesc",
            width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "是否有红宝石开单权限",
            key: "IsNewBill",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.IsNewBill == 1 && "success") || "error";
              const text = (row.IsNewBill == 1 && "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "档案号情况",
            align: "center",
            children: [
              {
                title: "已使用档案数",
                key: "CurrentInCreaseNum",
                width: 100,
                align: "center",
                tooltip: true,
                sortable: "custom"
              },
              {
                title: "共分配档案数",
                key: "TotalInCreaseNum",
                width: 100,
                align: "center",
                tooltip: true
              }
            ]
          },
          {
            title: "操作",
            key: "",
            width: 280,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              if(this.IsSystem == 0)
              {
                if(this.EmployeeType==4&&this.AgentId!=1&&this.AgentId!=5&&this.AgentId!=38){
                  return h("div", [
                    h(
                      "Button",
                      {
                        props: { type: "info", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.Edit(params.row);
                          }
                        }
                      },
                      "编辑"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "success", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.IntegralLogList(params.row);
                          }
                        }
                      },
                      "积分记录"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.IntegralExchange(params.row);
                          }
                        }
                      },
                      "积分兑换"
                    )
                  ]);

                }else{
                  return h("div", [
                    h(
                      "Button",
                      {
                        props: { type: "info", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.Query(params.row);
                          }
                        }
                      },
                      "查看"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "success", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.IntegralLogList(params.row);
                          }
                        }
                      },
                      "积分记录"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "success", size: "small" },
                        style: { marginRight: "10px", marginTop: "5px" },
                        on: {
                          click: () => {
                            this.CaIntegralLogList(params.row);
                          }
                        }
                      },
                      "现金记录"
                    )
                  ]);
                }
              } else {
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "5px", marginTop: "5px" },
                      on: {
                        click: () => {
                          this.Edit(params.row);
                        }
                      }
                    },
                    "编辑"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "success", size: "small" },
                      style: { marginRight: "5px", marginTop: "5px" },
                      on: {
                        click: () => {
                          this.IntegralLogList(params.row);
                        }
                      }
                    },
                    "积分记录"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "", size: "small" },
                      style: { marginRight: "5px", marginTop: "5px" },
                      on: {
                        click: () => {
                          this.IntegralExchange(params.row);
                        }
                      }
                    },
                    "积分兑换"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "", size: "small" },
                      style: { marginRight: "5px", marginTop: "5px" },
                      on: {
                        click: () => {
                          this.CashIntegralExchange(params.row);
                        }
                      }
                    },
                    "积分提货"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "success", size: "small" },
                      style: { marginRight: "5px", marginTop: "5px" },
                      on: {
                        click: () => {
                          this.CaIntegralLogList(params.row);
                        }
                      }
                    },
                    "现金记录"
                  )
                ]);
              }
              // return h("div", [
                // h(
                //   "Button",
                //   {
                //     props: { type: "primary", size: "small" },
                //     style: { marginRight: "10px" },
                //     on: {
                //       click: () => {
                //         this.DistributeNum(params.row);
                //       }
                //     }
                //   },
                //   "分配档案号"
                // ),
              //   h(
              //     "Button",
              //     {
              //       props: { type: "info", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.Edit(params.row);
              //         }
              //       }
              //     },
              //     "编辑"
              //   ),
              //   h(
              //     "Button",
              //     {
              //       props: { type: "success", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.IntegralLogList(params.row);
              //         }
              //       }
              //     },
              //     "积分记录"
              //   ),
              //   h(
              //     "Button",
              //     {
              //       props: { type: "", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.IntegralExchange(params.row);
              //         }
              //       }
              //     },
              //     "积分兑换"
              //   )
              // ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
      dist_modal: {
        currentdealer: 0,
        show: false,
        distnum: 0
      },
      IntegralExchange_modal: {
        currentdealerid: 0,
        show: false,
        changeintegral: 0,
        remark: ""
      },
      CaIntegralExchange_modal: {
        currentdealerid: 0,
        show: false,
        changecaintegral: 0,
        remark: ""
      }
    };
  },
  methods: {
    //分配经销商档案
    DistributeNum: function(row) {
      if (row.Level != 1) {
        this.$Message.warning("只能为系统内经销商分配数额");
        return;
      }
      this.dist_modal.currentdealer = row.ID;
      this.dist_modal.show = true;
    },
    //编辑经销商
    Edit: function(item) {
      this.$router.push({
        name: "dealer_childdealerdetail",
        query: { id: item.ID }
      });
    },
    //查看经销商
    Query: function(item) {
      this.$router.push({
        name: "dealer_childdealerinfo",
        query: { id: item.ID }
      });
    },
    ModalCancel: function() {},
    Dist_ModalOK: function() {
      var that = this;
      var r = /^\+?[1-9][0-9]*$/;
      if (!r.test(that.dist_modal.distnum)) {
        this.$Message.warning("分配的档案数应该为一个正整数！");
        return;
      }
      this.$http
        .get("Dealer/DistributeNum", {
          params: {
            dealerid: that.dist_modal.currentdealer,
            num: that.dist_modal.distnum
          }
        })
        .then(res => {
          if (res.data == "success") {
            that.GetTableList();
          }
          this.$Message.success("操作成功");
        });
    },
    ExChange_ModalOK: function() {
      var that = this;
      //var r = /^\+?[1-9][0-9]*$/;
      var r = /^[0-9]+(.?[0-9]{1,2})?$/;//积分可为整数或带两位小数的数字
      if (!r.test(that.IntegralExchange_modal.changeintegral)) {
        this.$Message.warning("请输入一个正整数或两位小数的数字！");
        return;
      }
      if (that.IntegralExchange_modal.remark.length <= 0) {
        this.$Message.warning("必须填写兑换备注");
        return;
      }
      that.$http.get('Dealer/DealerExchangeIntegral',{
        params:{
          changeintegral:that.IntegralExchange_modal.changeintegral,
          dealerid:that.IntegralExchange_modal.currentdealerid,
          remark:that.IntegralExchange_modal.remark
        }
      }).then(res=>{
        if(res.data!='success'){
          this.$Message.warning(res.data);
        }else{
          this.$Message.info('兑换成功!');
          that.GetTableList();
          that.IntegralExchange_modal.changeintegral=0;
          that.IntegralExchange_modal.remark="";
          that.IntegralExchange_modal.show=false;
        }
      });

    },
    CaExChange_ModalOK: function() {
      var that = this;
      var r = /^[0-9]+(.?[0-9]{1,2})?$/;//积分可为整数或带两位小数的数字
      if (!r.test(that.CaIntegralExchange_modal.changecaintegral)) {
        this.$Message.warning("请输入一个正整数或两位小数的数字！");
        return;
      }
      if (that.CaIntegralExchange_modal.remark.length <= 0) {
        this.$Message.warning("必须填写兑换备注");
        return;
      }
      that.$http.get('Dealer/DealerExchangeCaIntegral',{
        params:{
          changeintegral:that.CaIntegralExchange_modal.changecaintegral,
          dealerid:that.CaIntegralExchange_modal.currentdealerid,
          remark:that.CaIntegralExchange_modal.remark
        }
      }).then(res=>{
        if(res.data!='success'){
          this.$Message.warning(res.data);
        }else{
          this.$Message.info('兑换成功!');
          that.GetTableList();
          that.CaIntegralExchange_modal.changecaintegral=0;
          that.CaIntegralExchange_modal.remark="";
          that.CaIntegralExchange_modal.show=false;
        }
      });
    },
    NewDealer: function(params) {
      this.$router.push({ name: "dealer_newchilddealer" });
    },

    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_childdealerlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Dealer/GetDealerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          res.data.ListData.map(item => {
            item.LevelDesc = (item.Level == 1 && "是") || "否";
          });
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    IntegralLogList: function(e) {
      this.$router.push({
        name: "dealer_childdealerintegrallog",
        params: { dealerid: e.ID }
      });
    },
    CaIntegralLogList: function(e) {
      this.$router.push({
        name: "dealer_childdealercaintegrallog",
        params: { dealerid: e.ID }
      });
    },
    //积分兑换弹窗
    IntegralExchange: function(row) {
      this.IntegralExchange_modal.show = true;
      this.IntegralExchange_modal.currentdealerid = row.ID;
    },
    //现金提货兑换
    CashIntegralExchange: function(row) {
      this.CaIntegralExchange_modal.show = true;
      this.CaIntegralExchange_modal.currentdealerid = row.ID;
    },
    GetAgent: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.select.Agent = res.data;
      });
    },
    GetCurEmployee: function() {
      let that = this;
      that.$http.get("Employee/GetCurEmployee").then(res => {
        that.IsSystem=res.data.IsSystem;
        that.AgentId=res.data.AgentDealerId;
        that.EmployeeType=res.data.EmployeesType;
      });
    },
    AgentSearch: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      
      if (e != "") {
        conditions.push({
          PropertyName: "AgentId",
          Operater: 2,
          LogicOperater: 0,
          Value: e
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
    this.GetAgent();
    this.GetCurEmployee();
  }
};
</script>
<style>
</style>
 