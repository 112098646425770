<template>
  <div>
    <div style="margin-top:2px;">
      <img src="../../assets/logo3.png" fit="contain" width="100%" alt="" />
    </div>
    <Card :bordered="false" dis-hover>
      <p slot="title" style="font-weight:bold;">手机号码查询管路图</p>
      <van-cell-group>
        <van-field v-model="tel" label="手机号" placeholder="请输入手机号" error-message="" />
        <van-field v-model="smscode" center clearable label="短信验证码" placeholder="请输入短信验证码">
          <van-button slot="button" @click="sendMsg" size="small" type="primary">发送验证码</van-button>
        </van-field>
      </van-cell-group>
    </Card>
    <div style="padding:5px;">
      <Button type="primary" @click="customerlogin" long style="width:100%;height:35px;">查询</Button>
    </div>
    <Card :bordered="false" dis-hover>
      <p slot="title" style="font-weight:bold;">质保卡号查询管路图</p>
      <van-cell-group>
        <van-field v-model="WarrantyNum" label="质保卡号" placeholder="请输入质保卡号" error-message="" />
      </van-cell-group>
    </Card>
    <div style="padding:5px;">
      <Button type="primary" @click="Query" long style="width:100%;height:35px;">查询</Button>
    </div>
    <!-- <img src="../../assets/draw/index.png" fit="contain" width="100%" alt="" /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: "",
      smscode: "",
      WarrantyNum: "",
    };
  },
  methods: {
    sendMsg: function (e) {
      var that = this;
      if (!/^1(1|2|3|4|5|6|7|8|9)\d{9}$/.test(this.tel)) {
        that.$Message.info("号码格式错误");
        return false;
      }
      this.$http
        .get("Common/SendAuthCode", {
          params: { tel: that.tel, usertype: "customer" },
        })
        .then((res) => {
          if (res.data != "success") {
            that.$Message.info(res.data);
          } else {
            that.$Message.info("发送成功");
          }
        });
    },
    customerlogin: function () {
      var that = this;
      that.$http
        .get("Customer/CustomerLogin", {
          params: {
            tel: that.tel,
            authcode: that.smscode,
          },
        })
        .then((res) => {
          if (res.data != "success") {
            that.$Message.info(res.data);
          } else {
            that.QueryTel();
            // that.$router.push({
            //   name: "customerImg",
            //   params: { tel: that.tel },
            // });
          }
        });
    },
    QueryTel: function () {
      var that = this;
      this.$http.get("Customer/GetByCustTel?custTel="+that.tel).then((res) => {
        // if (res.data.Data.flag == true || res.data.Data.custVisit != null || res.data.Data.customer.AgentId != 1) {
        //   that.$router.push({
        //     name: "customerImg",
        //     params: { tel: that.tel },
        //   });
        // } else {
        //   that.$router.push({
        //     name: "customerVisit",
        //     params: { 
        //       WarrantyNum: res.data.Data.customer.WarrantyNum,
        //       CustAddr: res.data.Data.customer.CustAddress,
        //       CustomerID: res.data.Data.customer.ID,
        //       Resource: 1
        //     },
        //   });
        // }
        that.$router.push({
          name: "customerInfo",
          params: { tel: that.tel },
        });
      });
    },
    Query: function () {
      var that = this;
      this.$http.get("Customer/GetByWarrantyNum?warrantyNum="+that.WarrantyNum).then((res) => {
        if (res.data.Data.customer == null) {
          that.$Message.info("您的质保卡不存在");
        } else {
          // if (res.data.Data.flag == true || res.data.Data.custVisit != null || res.data.Data.customer.AgentId != 1) {
          //   that.$router.push({
          //     name: "customerImg",
          //     params: { WarrantyNum: that.WarrantyNum },
          //   });
          // } else {
          //   that.$router.push({
          //     name: "customerVisit",
          //     params: { 
          //       WarrantyNum: res.data.Data.customer.WarrantyNum,
          //       CustAddr: res.data.Data.customer.CustAddress,
          //       CustomerID: res.data.Data.customer.ID,
          //       Resource: 2
          //     },
          //   });
          // }
          that.$router.push({
            name: "customerInfo",
            params: { WarrantyNum: that.WarrantyNum },
          });
        }
      });
      // that.$router.push({
      //   name: "customerImg",
      //   params: { WarrantyNum: that.WarrantyNum },
      // });
    },
  },
};
</script>

<style>
</style>
