<template>
    <div>
        <div class="form-title">基础数据统计
            <Icon type="briefcase"></Icon>
        </div>
        <!--售后单据、销售档案、客户档案以及水工档案新增统计-->
        <Row :gutter="24">
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>新增售后单</span></p>
                    <div slot="extra"><Tag color="green">昨日</Tag></div>
                    <span style="font-size: 30px;">{{ postdata.AfterbillNum }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总售后单</Col>
                            <Col :span="12" style="text-align:right;">{{ postdata.AfterTotalNum }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>新增销售单</span></p>
                    <div slot="extra"><Tag color="green">今日</Tag></div>
                    <span style="font-size: 30px;">{{ postdata.PresaleNum }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总销售单</Col>
                            <Col :span="12" style="text-align:right;">{{ postdata.PresaleTotalNum }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>新增客户档案</span></p>
                    <div slot="extra"><Tag color="green">昨日</Tag></div>
                    <span style="font-size: 30px;">{{ postdata.CustomerNum }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总客户档案</Col>
                            <Col :span="12" style="text-align:right;">{{ postdata.CustomerTotalNum }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>新增水工</span></p>
                    <div slot="extra"><Tag color="green">昨日</Tag></div>
                    <span style="font-size: 30px;">{{ postdata.SDWorkerNum }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总水工数</Col>
                            <Col :span="12" style="text-align:right;">{{ postdata.SDWorkerTotalNum }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>新增红宝石录单</span></p>
                    <div slot="extra"><Tag color="green">昨日</Tag></div>
                    <span style="font-size: 30px;">{{ postdata.Presale2Num }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总红宝石录单</Col>
                            <Col :span="12" style="text-align:right;">{{ postdata.Presale2TotalNum }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
        <Row :gutter="24" style="margin-left: -12px; margin-right: -12px;margin-top: 16px!important;">
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>长沙审核单</span></p>
                    <div slot="extra"><Tag color="green">上周</Tag></div>
                    <span style="font-size: 30px;">{{ csauduitcount }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总获得积分</Col>
                            <Col :span="12" style="text-align:right;">{{ csauditintegral }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
            <Col :xs="24" :sm="12" :md="12" :lg="12" :xl="4" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>浏阳审核单</span></p>
                    <div slot="extra"><Tag color="green">上周</Tag></div>
                    <span style="font-size: 30px;">{{ lyauditcount }}</span>
                    <Divider style="margin: 8px 0px;"/>
                    <div>
                        <Row :gutter="24">
                            <Col :span="12">总获得积分</Col>
                            <Col :span="12" style="text-align:right;">{{ lyauditintegral }}</Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
        <!-- <Row :gutter="24" style="margin-left: -12px; margin-right: -12px;margin-top: 16px!important;">
            <Col :xs="24" :sm="6" :md="6" :lg="6" :xl="12" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                </Card>
            </Col>
            <Col :xs="24" :sm="6" :md="6" :lg="6" :xl="12" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                </Card>
            </Col>
        </Row> -->
        <Row :gutter="24" style="margin-left: -12px; margin-right: -12px;margin-top: 16px!important;">
            <!--客户档案每月统计-->
            <Col :xs="24" :sm="6" :md="6" :lg="6" :xl="10" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>客户档案数据分析(本年度)</span></p>
                    <div id="myChart1" style="width: 500px;height:400px;"></div>
                </Card>
            </Col>
            <!--管道类型分组统计-->
            <Col :xs="24" :sm="6" :md="6" :lg="6" :xl="10" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>管道类型数据分析(本月)</span></p>
                    <div id="myChart2" style="width: 500px;height:400px;"></div>
                </Card>
            </Col>
        </Row>
        <Row :gutter="24" style="margin-left: -12px; margin-right: -12px;margin-top: 16px!important;">
            <!--经销商地区分组统计-->
            <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="20" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>经销商数据分析</span></p>
                    <div id="myChart3" style="width: 800px;height:400px;"></div>
                </Card>
            </Col>
            
        </Row>
        <!--经销商地区统计-->
        <!-- <Row :gutter="24" style="margin-left: -12px; margin-right: -12px;margin-top: 16px!important;">
            <Col :xs="24" :sm="24" :md="24" :lg="24" :xl="12" style="padding-left: 12px; padding-right: 12px;">
                <Card dis-hover :bordered="true">
                    <p slot="title"><span>经销商数据分析</span></p>
                    <div id="myChart2" style="width: 800px;height:400px;"></div>
                </Card>
            </Col>
        </Row> -->
    </div>
</template>
<script>
// 引入基本模板
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
// require('echarts/lib/chart/bar')
// require('echarts/lib/chart/pie')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
export default {
  data: function () {
    return {
        postdata: {
            AfterbillNum:0,
            AfterTotalNum:0,
            PresaleNum:0,
            PresaleTotalNum:0,
            CustomerNum:0,
            CustomerTotalNum:0,
            SDWorkerNum:0,
            SDWorkerTotalNum:0,
            Presale2Num:0,
            Presale2TotalNum:0
        },
        ydata:[],
        ydata2:[],
        ydata3:[],
        csauduitcount:0,
        csauditintegral:0,
        lyauditcount:0,
        lyauditintegral:0,
    };
  },
  mounted() {
    // this.drawLine();
    // this.drawPie();
  },
  methods: {
    GetStatistics: function() {
        var that = this;
        this.$http
        .get("Employee/GetStatistics", { })
        .then(res => {
          that.postdata = res.data;
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    GetAuditCS: function() {
        var that = this;
        this.$http
        .get("Employee/GetCustomerAudit1", { })
        .then(res => {
            var cs = res.data;
            that.csauduitcount = cs[0].AuditCount;
            that.csauditintegral = cs[0].AuditIntegral;
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    GetAuditLY: function() {
        var that = this;
        this.$http
        .get("Employee/GetCustomerAudit2", { })
        .then(res => {
            var ly = res.data;
            that.lyauditcount = ly[0].AuditCount;
            that.lyauditintegral = ly[0].AuditIntegral;
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    GetCustomerByYear: function() {
        var that = this;
        this.$http
        .get("Employee/GetCustomerByYear", {})
        .then(res => {
            that.ydata = res.data;
            let myChart = echarts.init(document.getElementById('myChart1'));
            var option ={
                title: { text: '客户档案趋势' },
                tooltip: {},
                xAxis: {
                    type: 'category',
                    data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
                },
                yAxis: {
                    type: 'value',
                    name: '单量',
                },
                series: [{
                    data: that.ydata,
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    }
                }]
            };
            // 绘制图表
            myChart.setOption(option);
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    GetPipeLineByMonth: function() {
        var that = this;
        this.$http
        .get("Employee/GetPipeLineByMonth", {})
        .then(res => {
            that.ydata2 = res.data;
            let myChart2 = echarts.init(document.getElementById('myChart2'));
            // var option2 ={
            //     tooltip: {
            //         trigger: 'item'
            //     },
            //     legend: {
            //         orient: 'vertical',
            //         left: 'left',
            //     },
            //     series: [
            //         {
            //             name: '访问来源',
            //             type: 'pie',
            //             radius: '50%',
            //             data: that.ydata2,
            //             emphasis: {
            //                 itemStyle: {
            //                     shadowBlur: 10,
            //                     shadowOffsetX: 0,
            //                     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //                 }
            //             }
            //         }
            //     ]
            // };
            var option2={
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                },
                series: [
                    {
                        name: '类型',
                        type: 'pie',
                        radius: '55%',
                        center: ['40%', '50%'],
                        data: that.ydata2,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            // 绘制图表
            myChart2.setOption(option2);
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    GetDealerByAgent: function() {
        var that = this;
        this.$http
        .get("Employee/GetDealerByAgent", {})
        .then(res => {
            that.ydata3 = res.data;
            let myChart3 = echarts.init(document.getElementById('myChart3'));
            var option3={
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    right: 10,
                    top: 20,
                    bottom: 20,
                },
                series: [
                    {
                        name: '区域',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: that.ydata3,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
            // 绘制图表
            myChart3.setOption(option3);
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    
  },
  created: function() {//页面初始化
    this.GetStatistics();
    this.GetAuditCS();
    this.GetAuditLY();
    this.GetCustomerByYear();
    this.GetPipeLineByMonth();
    this.GetDealerByAgent();
  }
};
</script>