<template>
  <div class="listform">
    <div class="form-title">经销商积分统计
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Button @click="ExportExcel()" type="primary" icon="ios-clipboard">导出</Button>
      <Button @click="Export2Excel()" type="primary" icon="ios-clipboard">导出(现金积分)</Button>
      <Button @click="BatchExchange()" type="primary" icon="ios-clipboard">批量消分(请谨慎操作)</Button>
    </div>

    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist" show-summary></Table>
    </div>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "经销商姓名",
            key: "Name",
            align: "center",
            tooltip: true
          },
          {
            title: "目前总积分",
            key: "Integral",
            align: "center",
            tooltip: true
          },
          {
            title: "累积获得积分(截止上月)",
            key: "PeriodTotalIntegral",
            align: "center",
            tooltip: true
          },
          {
            title: "累积消分(截止上月)",
            key: "MinusIntegral",
            align: "center",
            tooltip: true           
          },
          {
            title: "本月应消分",
            key: "NotIntegral",
            align: "center",
            tooltip: true
          },
          {
            title: "本月已消分",
            key: "AlreadyIntegral",
            align: "center",
            tooltip: true
          },
          {
            title: "本月现金积分",
            key: "CashIntegral",
            align: "center",
            color: "red",
            tooltip: true,
            render: (h, params) => {
              return h('span',{
                  'style':{
                      'color':'red'
                  }
              },params.row.CashIntegral);
            }
          },
          {
            title: "本月现金积分消分",
            key: "OffsetCashIntegral",
            align: "center",
            color: "red",
            tooltip: true,
            render: (h, params) => {
              return h('span',{
                  'style':{
                      'color':'red'
                  }
              },params.row.OffsetCashIntegral);
            }
          },
          {
            title: "目前现金积分",
            key: "TotalCaIntegral",
            align: "center",
            color: "red",
            tooltip: true,
            render: (h, params) => {
              return h('span',{
                  'style':{
                      'color':'red'
                  }
              },params.row.TotalCaIntegral);
            }
          },
          // {
          //   title: "是否异常",
          //   key: "Unusual",
          //   align: "center",
          //   tooltip: true
          // },
        ],
        datalist: [],
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.EditWorker(undefined, e);
        return;
      }
      this.PreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Dealer/GetDealerIntegral", {
          params: {}
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.Data;
        });
    },
    ExportExcel:function () {//导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("Dealer/ExportDealerIntegral",{
        params:{}
      }).then(res=>{
        that.$Spin.hide();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    Export2Excel:function () {//导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("Dealer/Export2DealerIntegral",{
        params:{}
      }).then(res=>{
        that.$Spin.hide();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    BatchExchange: function(item) {//批量消分
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要批量消分吗?",
        onOk: function(e) {
          that.$http
            .get("Dealer/BatchExchangeIntegral", {
              params: {
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("操作成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    }
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 