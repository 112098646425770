<template>
  <div class="listform">
    <div class="form-title">订单列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button @click="ExportOrder()" type="primary" icon="ios-clipboard">导出</Button>
      <Button @click="importDelivery()" type="primary" icon="ios-clipboard">导入</Button>
      <Button @click="SetAllConfirm()" type="primary" icon="ios-clipboard">一键确认</Button>
    </div>
    <div class="radio-group">
      <RadioGroup v-model="currentStatus" @on-change="StatusChange" type="button">
        <Radio label="">全部</Radio>
        <Radio label="0">待确认</Radio>
        <Radio label="1">待发货</Radio>
        <Radio label="2">已发货</Radio>
        <Radio label="3">已完成</Radio>
      </RadioGroup>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
    <Modal :title="detailmodal.title" @on-cancel="ModalCancel" width="1000" height="600" :footer-hide="true" v-model="detailmodal.show">
      <order-detail :orderid="detailmodal.orderid" v-if="detailmodal.show"></order-detail>
    </Modal>
    
  </div>
</template>
<script>
import orderDetail from "../../components/public_component/orderdetail";
export default {
  data() {
    return {
      currentStatus: "",
      detailmodal: {
        show: false,
        orderid: "",
        title: ""
      },
      queryvalue: "",
      sel_field: "",
      sel_values: [
        { value: "OrderCode", label: "订单编号" },
        { value: "ContractName", label: "收货联系人" },
        { value: "DetailAddr", label: "收货地址" },
        { value: "ContractTel", label: "收货电话" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "订单编号",
            key: "OrderCode",
            width: 220,
            align: "center",
            tooltip: true,
          },
          {
            title: "总价",
            key: "TotalPrice",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "收货电话",
            key: "ContractTel",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "收货联系人",
            key: "ContractName",
            width: 180,
            align: "center",
            tooltip: true
          },
          {
            title: "收货地址",
            key: "DetailAddr",
            width: 350,
            align: "center",
            tooltip: true
          },
          {
            title: "订单状态",
            key: "StatusDesc",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "创建时间",
            key: "_CreateTime",
            width: 130,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          // {
          //   title: "快递单号",
          //   key: "ExpressBillCode",
          //   width: 160,
          //   align: "center",
          //   tooltip: true
          // },
          {
            title: "备注",
            key: "OrderRemark",
            width: 130,
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "",
            width: 290,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              // return h("div", [
              //   h(
              //   "Button",
              //   {
              //     props: { type: "default", size: "small" },
              //     style: { marginRight: "10px" },
              //     on: {
              //       click: () => {
              //         this.ViewDetail(params.row);
              //       }
              //     }
              //   },
              //   "查看明细"
              //   ),
              //   h(
              //     "Button",
              //     {
              //       props: { type: "success", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.AcceptOrder(params.row);
              //         }
              //       }
              //     },
              //     "确认订单"
              //   ),
              //   h(
              //     "Button",
              //     {
              //       props: { type: "error", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.CancelOrder(params.row);
              //         }
              //       }
              //     },
              //     "取消订单"
              //   )
              // ]);
              var btnlist = [];
              var viewBtn = h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              );
              btnlist.push(viewBtn);
              if (params.row.Status == 0) {
                var optBtn = h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.AcceptOrder(params.row);
                      }
                    }
                  },
                  "确认订单"
                );
                var canBtn = h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                );
                btnlist.push(optBtn);
                btnlist.push(canBtn);
              } else if (params.row.Status == 1) {
                // var sendBtn = h(
                //   "Button",
                //   {
                //     props: { type: "info", size: "small" },
                //     style: { marginRight: "10px" },
                //     on: {
                //       click: () => {
                //         this.SendGoods(params.row);
                //       }
                //     }
                //   },
                //   "发货"
                // );
                var canBtnTwo = h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                );
                // btnlist.push(sendBtn);
                btnlist.push(canBtnTwo);
              } else if (params.row.Status == 2) {
                var comBtn = h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.confirmOrder(params.row);
                      }
                    }
                  },
                  "确认收货"
                );
                btnlist.push(comBtn);
              }
              return h("div", btnlist);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      },
    };
  },
  components: {
    "order-detail": orderDetail
  },
  methods: {
    ModalCancel: function(e) {
      this.$http.SetConditions([]);
    },
    // Express_ModalOK: function() {
    //   var that = this;
    //   if (that.expressmodal.expressbillcode.length <= 0) {
    //     this.$Message.warning("请填写快递运单号！");
    //     return;
    //   }
    //   this.$http
    //     .get("Order/SendGoods", {
    //       params: {
    //         expressbillcode: that.expressmodal.expressbillcode,
    //         id: that.expressmodal.orderid,
    //         Status: 2
    //       }
    //     })
    //     .then(res => {
    //       if (res.data == "success") {
    //         that.GetTableList();
    //         that.expressmodal.expressbillcode="";
    //       }
    //       this.$Message.success("操作成功");
    //     });
    // },
    StatusChange: function(e) {
      this.currentStatus = e;
      this.table.pageIndex = 1;
      if (e == "0") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 285,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              ),
              h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.AcceptOrder(params.row);
                      }
                    }
                  },
                  "确认订单"
                ),
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                )
            ]);
          }
        });
      } else if (e == "1") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 260,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              ),
              h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                )
            ]);
          }
        });
      } else if (e == "2") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 260,
          align: "center",
          fixed: "right",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              ),
              h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.confirmOrder(params.row);
                      }
                    }
                  },
                  "确认收货"
                )
            ]);
          }
        });
      } else if (e == "3") {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 260,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              )
            ]);
          }
        });
      } else {
        this.table.tablecolumsTitle.pop();
        this.table.tablecolumsTitle.push({
          title: "操作",
          key: "bus",
          width: 285,
          fixed: "right",
          align: "center",
          render: (h, params) => {
            var btnlist = [];
              var viewBtn = h(
                "Button",
                {
                  props: { type: "default", size: "small" },
                  style: { marginRight: "10px" },
                  on: {
                    click: () => {
                      this.ViewDetail(params.row);
                    }
                  }
                },
                "查看明细"
              );
              btnlist.push(viewBtn);
              if (params.row.Status == 0) {
                var optBtn = h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.AcceptOrder(params.row);
                      }
                    }
                  },
                  "确认订单"
                );
                var canBtn = h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                );
                btnlist.push(optBtn);
                btnlist.push(canBtn);
              } else if (params.row.Status == 1) {
                var canBtnTwo = h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.CancelOrder(params.row);
                      }
                    }
                  },
                  "取消订单"
                );
                btnlist.push(canBtnTwo);
              } else if (params.row.Status == 2) {
                var comBtn = h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.confirmOrder(params.row);
                      }
                    }
                  },
                  "确认收货"
                );
                btnlist.push(comBtn);
              }
              return h("div", btnlist);
            }
        });
      }
      this.GetTableList();
    },
    ViewDetail: function(params) {
      this.detailmodal.orderid = params.ID;
      this.detailmodal.title = "查看明细-"+params.ContractName;
      this.detailmodal.show = true;
    },
    AcceptOrder: function(row) {
      var that = this;
      this.$http("Order/UpdateOrderStatus", {
        params: { id: row.ID, Status: 1 }
      }).then(res => {
        that.$Message.info("操作成功!");
        that.GetTableList();
      });
    },
    confirmOrder: function(row) {
      var that = this;
      this.$http("Order/UpdateOrderStatus", {
        params: { id: row.ID, Status: 3 }
      }).then(res => {
        that.$Message.info("操作成功!");
        that.GetTableList();
      });
    },
    // SendGoods: function(row) {   //发货
    //   this.expressmodal.orderid = row.ID;
    //   this.expressmodal.show = true;
    //   var that = this;
    //   this.$http("Order/UpdateOrderStatus", {
    //     params: { id: row.ID, Status: 2 }
    //   }).then(res => {
    //     that.$Message.info("操作成功!");
    //     that.GetTableList();
    //   });
    // },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_orderlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Order/GetOrderPageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort,
            status: that.currentStatus 
            }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    ExportOrder:function () {  //导出
      var that=this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http.get("Order/ExportOrder",{
       
      }).then(res=>{
        that.$Spin.hide();
        that.GetTableList();
        window.open(that.$config.fileUrl + res.data);
      })
    },
    CancelOrder: function(item) {   //取消订单
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要取消订单吗?",
        onOk: function(e) {
          that.$http
            .get("Order/CancelOrder", {
              params: {
                id: item.ID
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("操作成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    },
    SetAllConfirm: function() {//一键确认订单
      var that = this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认要一键确认订单吗?",
        onOk: function(e) {
          that.$http
            .get("Order/SetAllConfirm", {
              params: {
              }
            })
            .then(res => {
              if (res.data == "success") {
                that.$Message.info("确认成功");
                that.GetTableList();
              } else {
                this.$Message.info(res.data);
              }
            });
        }
      });
    },
    importDelivery: function(e) {
      this.$router.push({ 
        name: "dealer_importdelivery",
        params: { }
      });
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
</style>
 