
<template>
  <div class="my-form">
    <div class="form-title">新建账户
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="120">
      <FormItem label="组织机构">
        <RadioGroup v-model="postdata.FilialeID">
          <Radio v-for="item in postdata.Filiales" :label="item.ID" :key="item.ID">{{item.FilialeName}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否服务人员">
        <RadioGroup v-model="postdata.IsWorker">
          <Radio label="1">是</Radio>
          <Radio label="0">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="用户类型">
        <RadioGroup v-model="postdata.EmployeesType">
          <Radio label="1">售后</Radio>
          <Radio label="2">客服</Radio>
          <Radio label="3">业务员</Radio>
          <Radio label="4">代理商</Radio>
          <!--<Radio label="5">管理员</Radio>-->
        </RadioGroup>
      </FormItem>
      <FormItem label="员工编号 ">
        <Input v-model="postdata.LiquidCode" style="width:500px" placeholder="请填写员工编号"></Input>
      </FormItem>
      <FormItem label="姓名 ">
        <Input v-model="postdata.Name" style="width:500px" placeholder="请填写姓名"></Input>
      </FormItem>
      <FormItem label="电话号码 ">
        <Input v-model="postdata.TelNum" style="width:500px" placeholder="请填写电话"></Input>
      </FormItem>
      <FormItem label="用户名 ">
        <Input v-model="postdata.UserName" style="width:500px" placeholder="请填写用户名"></Input>
      </FormItem>
      <FormItem label="密码 ">
        <Input v-model="postdata.PassWord" style="width:500px" placeholder="请填写密码"></Input>
      </FormItem>
      <FormItem label="代理运营商">
         <Select v-model="postdata.AgentDealerId" filterable style="width:200px">
            <Option v-for="item in postdata.AgentDealer" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="是否地市管理员">
        <RadioGroup v-model="postdata.IsMannager">
          <Radio label="1">是</Radio>
          <Radio label="0">否</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="false"></Spin>
  </div>
</template>
<script>
  import dealerSel from "../../components/public_component/dealerSel";
export default {  
  data() {
    return {
      postdata: {
        LiquidCode: "",
        Name: "",
        TelNum: "",
        UserName: "",
        PassWord: "",
        FilialeID: "",
        IsWorker: "0",
        SuperiorEmployeeID: 1,
        AgentId: 0,
        IsMannager: "0",
        AgentDealerId: "",
        IsSystem: 0,
        EmployeesType: "",
        AgentDealer: [],
        Filiales: []
      },
      modal: {
        show: false
      }
    };
  },
  components: {
    "dealer-RowSel": dealerSel
  },
  methods: {
    SubmitData: function(e) {//提交
      if(!this.CheckData(this.postdata)){
        return ;
      }
      this.$http.post("Employee/AddAgent", this.postdata).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_agentlist" });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.FilialeID == "") {
        content = "请选择组织机构";
        checkflag = false;
      }
      if (obj.IsWorker == "") {
        content = "请选择是否服务人员";
        checkflag = false;
      }
      if (obj.EmployeesType == "") {
        content = "请选择用户类型";
        checkflag = false;
      }
      if (obj.Name == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.TelNum == "") {
        content = "请填写电话号码";
        checkflag = false;
      }
      if (obj.UserName == "") {
        content = "请填写用户名";
        checkflag = false;
      }
      if (obj.PassWord == "") {
        content = "请填写密码";
        checkflag = false;
      }
      if (obj.AgentDealerId == "") {
        content = "请选择代理运营商";
        checkflag = false;
      }
      if (obj.IsMannager == "") {
        content = "请选择是否地市管理员";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    BackList: function() {//返回
      this.$router.push({ name: "dealer_agentlist" });
    },
    ModalRowSel: function(item) {
      this.postdata.ParentId=item.ID;
      this.postdata.ParentDealerDesc=item.Name;
      this.modal.show=false;
      this.ModalCancel();
    },
    ModalCancel: function() {
      this.$http.SetConditions();
    },
    GetAgentDealer: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.postdata.AgentDealer = res.data;
      });
    },
    GetFiliales: function() {//查询组织机构
      let that = this;
      that.$http.get("Filiale/GetFiliales").then(res => {
        that.postdata.Filiales = res.data;
      });
    }
  },
  watch: {},
  created: function() {//页面初始化
    this.GetFiliales();
    this.GetAgentDealer();
  }
};
</script>

<style>
</style>
