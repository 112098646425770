<template>
  <div>
      <van-nav-bar title="抽奖记录" left-text="" left-arrow @click-left="onClickLeft()" />       
          <van-grid :column-num="4" style="margin-top:20px;">
            <van-grid-item text="时间" />
            <van-grid-item text="奖品" />
            <van-grid-item text="兑奖码" />            
            <van-grid-item text="是否兑换" />
          </van-grid>
          <van-grid :column-num="4" v-for="(item,idx) in integralList" v-bind:key="idx">
            <van-grid-item :text="item._CreateTime" />
            <van-grid-item :text="item.PriceName+''" />
            <van-grid-item :text="item.WinningCode+''" />
            <van-grid-item :text="item.IsGrantDesc+''" />
          </van-grid>
          <van-pagination
            v-model="currentPage"
            :total-items="total"
            :show-page-size="3"
            :items-per-page="pageSize"
            force-ellipses
          style="margin-top:20px;" @change="handleCurrentChange" />      
    </div>    
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, NavBar, Grid, GridItem, Tabs, Tab } from "vant";
import { Button, Field } from "vant";
import { Divider } from "vant";
import { Popup } from "vant";
import { CountDown } from 'vant';
import { Pagination } from 'vant';

export default {
  name: "LuckDrawRecord",
  data() {
    return {      
      currentPage: 1,
      mcurrentPage: 1,
      integralList: [],
      matintegralList: [],
      loading: false, 		// 是否处在加载状态
      finished: false, 		// 是否已加载完成
      total: 0,				    // 数据总条数
      mattotal: 0,				// 数据总条数
      pageCount: 0,       // 总页数
      mpageCount: 0,    // 总页数
      pageSize: 0,    // 每页条数
      mpageSize: 0,    // 每页条数
      active: 0
    };
  },
  props: {},
  computed:{
    isLogined(){return this.$store.state.isLogin}
  },
  methods: {
    ShowLogin(){
      this.$store.commit("showLogin");
    },
    
    GetData(){
      var that=this;
      if(that.isLogined){        
        that.$http.get("SDWorker/GetLuckyDrawRecordXt", {
              params: {
                pageindex: that.currentPage,
                sort: '_CreateTime DESC',
              }
            }).then(res=>{
              for (let i = 0; i < res.data.ListData.length; i++) {
                  if(res.data.ListData[i].IsGrant==1){
                    res.data.ListData[i].IsGrantDesc="是";
                  }else{
                    res.data.ListData[i].IsGrantDesc="否";
                  }
              }
              that.integralList=res.data.ListData;
              that.total = res.data.TotalCount;		// 给数据条数赋值
              that.loading = true;			// 加载状态结束 
              that.pageCount = res.data.PageCount;
              that.pageSize = res.data.PageSize;
              // 如果list长度大于等于总数据条数,数据全部加载完成
              if (that.integralList.length >= res.data.TotalCount) {
                  that.finished = true;		// 结束加载状态
              }
        });
      }
    },
    handleCurrentChange (page) {
      this.currentPage = page;
      this.GetData();
    },
    /*返回 */
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  created() {
    this.active = 0;
    this.GetData();
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [CountDown.name]:CountDown,
    [Pagination.name]:Pagination,
    [NavBar.name]:NavBar,
    [Grid.name]:Grid,
    [GridItem.name]:GridItem,
    [Tabs.name]:Tabs,
    [Tab.name]:Tab
  },
};
</script>


<style scoped>
  
    body {
        margin: 0;
        padding: 0;
    }

    .scroll-table-demo {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .scroll-table-title {
        height: 80px;
        line-height: 24px;
        font-weight: bold;
        background-color: #7fd75a;
        color: #fff;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    /* 全局css配置 */
    .scroll-table {
        height: calc(100% - 80px);
    }

    .scroll-table table {
        border: none;
        border-collapse: collapse;

    }

    .scroll-table td,
    .scroll-table th {
        padding: 0;
    }

    .scroll-table-container {
        display: flex;
        height: 100%;
        overflow: hidden;
    }

    .scroll-table-left,
    .scroll-table-slider {
        display: flex;
        flex-direction: column;
        box-shadow: 1px 3px 5px #eee;
    }


    .scroll-table-left-top,
    .scroll-table-slider-top {
        height: 45px;
        background-color: #f5f7fa;
        position: relative;
        z-index: 10;
    }

    .scroll-table-right-top {
        background-color: #f5f7fa;
    }

    .scroll-table-left-top div,
    .scroll-table-slider div {
        width: 60px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-left-top tr,
    .scroll-table-slider-top tr {
        border-bottom: 1px solid #ebeef5;
    }

    .scroll-table-left-freeze,
    .scroll-table-slider-freeze {
        flex: 1;
        height: calc(100% - 45px - 45px);
    }

    .scroll-table-left-freeze tr,
    .scroll-table-slider-freeze tr {
        border-bottom: 1px solid #ebeef5;
        box-sizing: border-box;
    }

    .scroll-table-left-freeze td>div,
    .scroll-table-slider-freeze td>div {
        width: 60px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-left-bottom,
    .scroll-table-slider-bottom {
        height: 45px;
        background-color: #f5f7fa;
        position: relative;
        z-index: 10;
    }

    .scroll-table-left-bottom tr,
    .scroll-table-slider-bottom .tr {
        margin: 0;
        padding: 0;
    }

    .scroll-table-left-bottom th>div,
    .scroll-table-slider-bottom th>div {
        width: 60px;
    }

    .scroll-table-right {
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }

    .scroll-table-right-bottom {
        background-color: #1890ff;
        color: #fff;
    }

    .scroll-table-right-bottom td>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-right-top tr {
        border-bottom: 1px solid #ebeef5;
        background-color: #f5f7fa;
    }

    .scroll-table-right-top th>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .scroll-table-right-content {
        flex: 1;
        overflow: hidden;
    }

    .scroll-table-right-content tr {
        border-bottom: 1px solid #ebeef5;
    }

    .scroll-table-right-content td>div {
        width: 90px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    }
    .van-grid-item {
        height: 45px;
    }
    .van-grid {
        border-radius: 5px;
        margin-top: 0px;
        height: 45px;
        overflow: hidden;
    }

</style>
