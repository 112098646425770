<template>
  <div>
    <Card :bordered="false" dis-hover>
      <p slot="title">旧系统试压管道图查看</p>
      <van-cell-group>
        <van-field v-model="tel" label="手机号" placeholder="请输入手机号" error-message="" />
        <van-field v-model="smscode" center clearable label="短信验证码" placeholder="请输入短信验证码">
          <van-button slot="button" @click="sendMsg" size="small" type="primary">发送验证码</van-button>
        </van-field>
      </van-cell-group>
    </Card>
    <div style="padding:15px;">
      <Button type="primary" @click="customerlogin" long style="width:100%;height:35px;">登录</Button>
    </div>
    <Card :bordered="false" dis-hover>
      <p slot="title">质保卡号查图</p>
      <van-cell-group>
        <van-field v-model="WarrantyNum" label="质保卡号" placeholder="请输入质保卡号" error-message="" />
      </van-cell-group>
    </Card>
    <div style="padding:15px;">
      <Button type="primary" @click="Query" long style="width:100%;height:35px;">查询</Button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: "",
      smscode: "",
      WarrantyNum: "",
    };
  },
  methods: {
    sendMsg: function (e) {
      var that = this;
      if (!/^1(1|2|3|4|5|6|7|8|9)\d{9}$/.test(this.tel)) {
        that.$Message.info("号码格式错误");
        return false;
      }
      this.$http
        .get("Common/SendAuthCode", {
          params: { tel: that.tel, usertype: "oldcustomer" },
        })
        .then((res) => {
          if (res.data != "success") {
            that.$Message.info(res.data);
          } else {
            that.$Message.info("发送成功");
          }
        });
    },
    customerlogin: function () {
      var that = this;
      that.$http
        .get("OldCustomer/OldCustomerLogin", {
          params: {
            tel: that.tel,
            authcode: that.smscode,
          },
        })
        .then((res) => {
          if (res.data != "success") {
            that.$Message.info(res.data);
          } else {
            that.$router.push({
              name: "oldCustomerImg",
              params: { tel: that.tel },
            });
          }
        });
    },
    Query: function () {
      var that = this;
      this.$http.get("OldCustomer/GetByWarrantyNum?warrantyNum="+that.WarrantyNum).then((res) => {
        if (res.data.Data.customer == null) {
          that.$Message.info("您的质保卡不存在");
        } else {
          that.$router.push({
            name: "oldCustomerImg",
            params: { WarrantyNum: that.WarrantyNum },
          });
        }
      });
      // that.$router.push({
      //   name: "oldCustomerImg",
      //   params: { WarrantyNum: that.WarrantyNum },
      // });
    },
  },
};
</script>

<style>
</style>
