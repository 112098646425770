<template>
  <div class="listform">
    <div class="form-title">物资积分规则管理
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="success" @click="NewRule" icon="ios-document">新建</Button>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "PipeTypeDesc", label: "管道类型" },
        { value: "AgentDealerDesc", label: "代理运营商" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "积分类型",
            key: "IntegralType",
            align: "center",
            tooltip: true,
            render: (h, params) => { 
              const row = params.row;
              const text = (
                row.IntegralType == 30 && "每单获取若干积分") 
                || (row.IntegralType == 31 && "大流量新品积分") 
                || (row.IntegralType == 32 && "配套产品积分")
                || (row.IntegralType == 33 && "丝口数量积分");
              return h("div",
                text
              );
            }
          },
          {
            title: "管道类型",
            key: "PipeTypeDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "代理运营商",
            key: "AgentDealerDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "倍率",
            key: "MaterialIntegralMultiple",
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "",
            width: 200,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    style: { marginRight: "10px" },
                    on: {
                      click: () => {
                        this.Edit(params.row);
                      }
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    //编辑积分规则
    Edit: function(item) {
      this.$router.push({
        name: "dealer_materialintegralruledetail",
        query: { id: item.ID }
      });
    },
    ModalCancel: function() {},
    NewRule: function(params) {//新建积分规则
      this.$router.push({ name: "dealer_newmaterialintegralrule" });
    },
    PageChange: function(e) {//分页
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_materialintegralrulelist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {//查询物资积分规则列表
      var that = this;
      that.table.loading = true;
      this.$http
        .get("IntegralRule/GetMaterialRulePageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {//排序
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {//条件查询
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    }
  },
  created: function() {//页面初始化
    this.GetTableList();
  }
};
</script>
<style>
</style>
 