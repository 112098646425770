<template>
  <div class="my-form">
    <div class="form-title">新建水工
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="姓名 ">
        <Input v-model="postdata.Name" style="width:500px" placeholder="输入水工姓名"></Input>
      </FormItem>
      <FormItem label="客户电话 ">
        <Input v-model="postdata.Tel" style="width:500px" placeholder="输入水工电话"></Input>
      </FormItem>
      <FormItem label="身份">
        <RadioGroup v-model="postdata.Identity">
          <Radio label="0">水工</Radio>
          <Radio label="1">项目经理</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="类型">
        <RadioGroup v-model="postdata.SDWorkerType">
          <Radio label="0">竞品</Radio>
          <Radio label="1">核心</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
export default {
  data() {
    return {
      empployeeId: "",
      postdata: {
        Name: "",
        Tel: "",
        SDWorkerType: "",
        Integral: 0,
        MaterialIntegral: 0,
        ServiceCount: 0,
        AgentId: "",
        WXOpenId: "",
        Identity: "",
        ID: 0,
        _CreateBy: 0,
        _CreateTime: "",
        _IsDelete: 0,
        _UpdateBy: 0,
        _UpdateTime: ""
      }
    };
  },
  methods: {
    
    BackList: function() {
      this.$router.push({ name: "dealer_employeesdworkerlist" });
    },
    SubmitData: function() {
      var that = this;
      var url = "SDWorker/AddSDWorker?employeeid="+that.empployeeId;
      
      var data = {
        Name: that.postdata.Name,
        Tel: that.postdata.Tel,
        Identity: that.postdata.Identity,
        SDWorkerType: that.postdata.SDWorkerType,
        _CreateTime: new Date(),
        _UpdateTime: new Date()
      };
      if(!this.CheckData(data)){
        return ;
      }
      
      that.$http.post(url, data).then(e => {
        if (e.data == "success") {
            this.$Modal.info({
                title: "提示",
                content: "提交成功！"
            });
        }
        that.BackList();
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.Name == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.Tel == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (obj.Identity == "") {
        content = "请选择水工身份";
        checkflag = false;
      }
      if (obj.SDWorkerType == "") {
        content = "请选择水工类型";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    
  },
  watch: {
  },
  created: function() {
    //初始化加载业务员id
    this.empployeeId = this.$route.params.employeeid;
  }
};
</script>

<style>
</style>
