<template>
  <div class="mo-imgupload">
    <!-- <van-steps :active="1">
      <van-step>买家下单</van-step>
      <van-step>商家接单</van-step>
      <van-step>买家提货</van-step>
      <van-step>交易完成</van-step>
    </van-steps> -->

    <Card :bordered="false">
      <p slot="title">基础信息</p>
      <Tag slot="extra" color="blue">{{aftersalebill_obj.ProcessStatusDesc}}</Tag>
      <Row>
        <Col :span="6">工单编号:</Col>
        <Col :span="16">{{aftersalebill_obj.AfterSaleCode}}</Col>
      </Row>
      <Row>
        <Col :span="6">客户姓名:</Col>
        <Col :span="16">{{aftersalebill_obj.CustomerName}}</Col>
      </Row>
      <Row>
        <Col :span="6">客户电话:</Col>
        <Col :span="16">{{aftersalebill_obj.CustomerTel}}</Col>
      </Row>
      <Row>
        <Col :span="6">详细地址:</Col>
        <Col :span="16">{{aftersalebill_obj.AddressDetail}}</Col>
      </Row>
      <Row>
        <Col :span="6">备注信息:</Col>
        <Col :span="16">{{aftersalebill_obj.DetailText}}</Col>
      </Row>
    </Card>

    <van-tabs v-model="active" @click="tabchange" :sticky="true">
      <van-tab :title="' 现场勘察图 '">
        <Card :bordered="false">
          <p slot="title">已上传图片({{imgcount}})</p>
          <div class="mo-img-list">
            <div v-for="(item, index) in step1imgs" :key="index" class="mo-img-cell" v-bind:style="{'background-image':'url(' + base_url+aftersalebill_obj.AfterSaleCode+'/'+'xianchang/'+item+'.jpg' + ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">

              <!-- <span class="mo-img-deletespan" @click="hard_delete_img(item,'1')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </Card>
      </van-tab>
      <van-tab :title="' 修复过程图 '" :disabled="step1imgs.length<=0">
        <Card :bordered="false">
          <p slot="title">已上传图片({{imgcount}})</p>
          <div class="mo-img-list">
            <div v-for="(item, index) in step2imgs" :key="index" class="mo-img-cell" v-bind:style="{'background-image':'url(' + base_url+aftersalebill_obj.AfterSaleCode+'/'+'xiufuguocheng/'+item+'.jpg' + ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">

              <!-- <span class="mo-img-deletespan" @click="hard_delete_img(item,'2')">
                <van-icon name="close" />
              </span> -->
            </div>
          </div>
        </Card>
      </van-tab>
      <van-tab :title="' 修复后图 '" :disabled="step1imgs.length<=0">
        <Card :bordered="false">
          <p slot="title">已上传图片({{imgcount}})</p>
          <div class="mo-img-list">
            <div v-for="(item, index) in step3imgs" :key="index" class="mo-img-cell" v-bind:style="{'background-image':'url(' + base_url+aftersalebill_obj.AfterSaleCode+'/'+'xiufuwancheng/'+item+'.jpg' + ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="mo-img-deletespan" @click="hard_delete_img(item,'3')">
                 <van-icon name="close" />
               </span> -->
            </div>
          </div>
        </Card>
      </van-tab>
      <van-tab :title="' 单据图 '" :disabled="step1imgs.length<=0">
        <Card :bordered="false">
          <p slot="title">已上传图片({{imgcount}})</p>
          <div class="mo-img-list">
            <div v-for="(item, index) in step4imgs" :key="index" class="mo-img-cell" v-bind:style="{'background-image':'url(' + base_url+aftersalebill_obj.AfterSaleCode+'/'+'danju/'+item+'.jpg' + ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
              <!-- <span class="mo-img-deletespan" @click="hard_delete_img(item,'3')">
                 <van-icon name="close" />
               </span> -->
            </div>
          </div>
        </Card>
      </van-tab>
      <van-tab :title="' 补偿费用 '" :disabled="step1imgs.length<=0">
        <Card :bordered="false">
          <van-cell-group>
            <van-field v-model="aftersalebill_obj.CostPrice" label="补偿费用" placeholder="输入补偿费用" />
            <van-field v-model="aftersalebill_obj.CostRemark" label="费用备注" type="textarea" placeholder="请输入费用备注" rows="1" autosize />
          </van-cell-group>
          <Button style="margin-top:1vh" @click="EditAfterSaleBill" type="success" long>保存</Button>
        </Card>
      </van-tab>
      <van-tab :title="' 材料单 '" :disabled="step1imgs.length<=0">
        <div style="margin:2vh">
          <Select @on-change="MaterialTypeSelChange" v-model="MaterialTypeSel" style="width:200px">
            <Option v-for="item in MaterialTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <Button style="margin-left:3vh" @click="UnUseSaveDetail" type="warning" >未使用材料</Button>
        </div>
        <div style="margin-top:10px;">
          <div class="mo-cell" v-for="(item) in billdetail_form" :key="item.ID">
            <div style="width:60px;">
              <p style="line-height: 2vh;">{{item.MaterialName}}</p>
            </div>
            <div style="width:50px">
              <p style="line-height: 2vh;">{{item.Standard}}</p>
            </div>
            <van-stepper integer :min="0" v-model="item.MaterialCount" :step="1" :default-value="item.MaterialCount" />
            <!-- <Button size="small" type="info">备注</Button>             -->
          </div>
          <Button style="margin-top:1vh" @click="SaveDetail" type="success" long>保存</Button>
          
        </div>
      </van-tab>
      <van-tab :title="' 完结信息 '" :disabled="step1imgs.length<=0">
        <Card :bordered="false">
          <van-cell-group>            
            <van-field v-model="aftersalebill_obj.RespReMark" label="其他备注" type="textarea" placeholder="请输入其他备注" rows="3" autosize />
          </van-cell-group>
          <Button style="margin:1vh" @click="EditAfterSaleBill" type="success" long>保存</Button>
        </Card>
      </van-tab>
    </van-tabs>

    <Card v-if="active<4" :bordered="false" dis-hover>
      <p slot="title">待上传图片</p>

      <div class="mo-img-list">
        <div v-for="(item, index) in aftcompress" :key="index" class="mo-img-cell" v-bind:style="{'background-image':'url(' + item + ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
          <span class="mo-img-deletespan" @click='delete_img(index)'>
            <van-icon name="close" />
          </span>
        </div>
        <div class="mo-img-cell">
          <van-uploader :after-read="onRead" :before-read="before_read" accept="image/*" multiple>
            <van-icon name="add-o" />
            <!-- <p style="margin-top:5px">轻触选择图片</p> -->
          </van-uploader>
        </div>
      </div>
      <canvas id="canvas" width="150" height="150" style="display: none">
      </canvas>
      <Button style="margin-top:1vh" @click="updloadimg" type="info" long>上传</Button>
    </Card>
    <BackTop></BackTop>
  </div>
</template>
<script>
import VueCoreImageUpload from "vue-core-image-upload";
export default {
  data() {
    return {
      active: 0,
      step1imgs: [],
      step2imgs: [],
      step3imgs: [],
      step4imgs: [],
      aftcompress: [],
      aftersalebill_obj: {},
      billdetail_form: [],
      MaterialTypeSel: "",
      MaterialTypeList: "",
      // base_url: "http://192.168.31.146:8099/imgs/"
      // base_url: "http://localhost:24737/imgs/"
      // base_url: "http://121.41.11.131:8088/imgs/"
      base_url: this.$file.url+"imgs/"      
    };
  },
  computed: {
    imgcount() {
      if (this.active == 0) {
        return this.step1imgs.length;
      }
      if (this.active == 1) {
        return this.step2imgs.length;
      }
      if (this.active == 2) {
        return this.step3imgs.length;
      }
      if (this.active == 3) {
        return this.step4imgs.length;
      }
      return 0;
    }
  },
  methods: {
    tabchange(index, change) {
      if (index == 5) {
        this.GetMaterialTypes();
      }
    },
    updloadimg(e) {
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "load-c",
                size: 25
              }
            }),
            h("div", "上传中……")
          ]);
        }
      });
      var blobfiles = [];
      var formData = new FormData();
      for (let i = 0; i < this.aftcompress.length; i++) {
        blobfiles.push(dataURItoBlob(this.aftcompress[i]));
        formData.append("imagefile", blobfiles[i]);
      }
      formData.append("Id", this.$route.params.id);
      formData.append("step", this.active + 1);
      this.$http.post("AfterSaleBill/Upload", formData).then(e => {
        this.aftcompress = [];
        this.GetAfterBill();
      });
    },
    delete_img(index) {
      this.aftcompress.splice(index, 1);
    },
    before_read(files) {
      if (files.hasOwnProperty("length")) {
        if (files.length > 10) {
          this.$Message.warning("请一次不要上传超过10张图片");
          return false;
        }
      }
      return true;
    },
    onRead(files, content) {
      var that = this;
      if (!files.hasOwnProperty("length")) {
        const element = files;
        var reader = new FileReader();
        reader.onload = function(e) {
          var inserted = e.target.result;
          var image = new Image();
          image.src = inserted;
          image.onload = () => {
            console.log("图片加载中", image.complete);
            if (image.complete) {
              var dataUrl = compress(image);
              that.aftcompress.push(dataUrl);
            }
          };
        };
        reader.readAsDataURL(files.file);
      } else {
        this.$Spin.show({
          render: h => {
            return h("div", [
              h("Icon", {
                class: "demo-spin-icon-load",
                props: {
                  type: "load-c",
                  size: 25
                }
              }),
              h("div", "加载中……")
            ]);
          }
        });
        var count = files.length;
        var current_count = 0;
        for (let i = 0; i < files.length; i++) {
          const element = files[i];
          var reader = new FileReader();

          reader.onload = function(e) {
            var inserted = e.target.result;
            var image = new Image();
            image.src = inserted;
            image.onload = () => {
              if (image.complete) {
                var dataUrl = compress(image);
                that.aftcompress.push(dataUrl);
                current_count++;
              }
              if (current_count == count) {
                that.$Spin.hide();
              }
            };
          };
          reader.readAsDataURL(files[i].file);
        }
      }
    },
    GetAfterBill() {
      this.$http
        .get("/aftersalebill/EditAfterSaleBillPre", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          this.$Spin.hide();
          this.aftersalebill_obj = res.data.Data;
          if(res.data.Data.Step1Imgs!=null){
            if (res.data.Data.Step1Imgs.length > 0) {
              this.step1imgs = res.data.Data.Step1Imgs.split(",");
              this.step1imgs.pop();
            }
          }
          if(res.data.Data.Step2Imgs!=null){
            if (res.data.Data.Step2Imgs.length > 0) {
              this.step2imgs = res.data.Data.Step2Imgs.split(",");
              this.step2imgs.pop();
            }
          }
          if(res.data.Data.Step3Imgs!=null){
            if (res.data.Data.Step3Imgs.length > 0) {
              this.step3imgs = res.data.Data.Step3Imgs.split(",");
              this.step3imgs.pop();
            }
          }
          if(res.data.Data.Step4Imgs!=null){
            if (res.data.Data.Step4Imgs.length > 0) {
              this.step4imgs = res.data.Data.Step4Imgs.split(",");
              this.step4imgs.pop();
            }
          }
        });
    },
    GetBillDetail() {
      this.$http
        .get("/AfterSaleBillDetail/GetAfterSaleBillDetail", {
          params: {
            id: this.$route.params.id
          }
        })
        .then(res => {
          res.data.forEach(e1 => {
            this.billdetail_form.forEach(e2 => {
              if (e1.MaterialId == e2.ID) {
                e2.MaterialCount = e1.MaterialCount;
              }
            });
          });
          this.$Spin.hide();
        });
    },
    SaveDetail() {
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "load-c",
                size: 25
              }
            }),
            h("div", "保存中……")
          ]);
        }
      });
      var postdata = [];
      this.billdetail_form.forEach(item => {
        if (item.MaterialCount > 0) {
          postdata.push(item);
        }
      });
      this.$http
        .post("/AfterSaleBillDetail/SaveBillDetail", {
          id: this.$route.params.id,
          ProductCategoryCode: this.MaterialTypeSel + "",
          detail: postdata
        })
        .then(() => {
          // this.GetAfterBill();
          this.GetAllMaterial(this.MaterialTypeSel);
        });
    },
    GetAllMaterial(type) {
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "load-c",
                size: 25
              }
            }),
            h("div", "加载中……")
          ]);
        }
      });
      this.$http
        .get("/AfterSaleBillDetail/GetAllMaterials", {
          params: {
            type: type
          }
        })
        .then(res => {
          this.billdetail_form = res.data;
          this.billdetail_form.forEach(item => {
            this.$set(item, "MaterialCount", 0);
          });
          this.GetBillDetail();
        });
    },
    MaterialTypeSelChange(e) {
      this.GetAllMaterial(e);
    },
    GetMaterialTypes() {
      this.$http.get("/AfterSaleBillDetail/GetMaterialTypes").then(res => {
        this.MaterialTypeList = res.data;
      });
    },
    EditAfterSaleBill() {
      var cost = this.aftersalebill_obj.CostPrice;
      if (isNaN(cost) || cost < 0) {
        this.$Message.info("金额必须是大于零数字！");
        return;
      }
      this.$http
        .post("/AfterSaleBill/EditAfterSaleBill", this.aftersalebill_obj)
        .then(res => {
          this.GetAfterBill();
        });
    },
    //清空所有材料单
    UnUseSaveDetail(){
      this.billdetail_form=[];
      this.MaterialTypeSel="";
      this.SaveDetail();
    }
  },
  created() {
    console.log(this.$route.params.id);
    this.GetAfterBill();
  }
};

function compress(img) {
  console.log("开始压缩图片");
  var canvas = document.getElementById("canvas");
  var ctx = canvas.getContext("2d");

  var tCanvas = document.createElement("canvas");
  var tctx = tCanvas.getContext("2d");

  var initSize = img.src.length;
  var width = img.width;
  var height = img.height;
  //如果图片大于两百万像素，计算压缩比并将大小压至200万以下
  var ratio;
  if ((ratio = width * height / 2000000) > 1) {
    ratio = Math.sqrt(ratio);
    width /= ratio;
    height /= ratio;
  } else {
    ratio = 1;
  }
  canvas.width = width;
  canvas.height = height;
  //        铺底色
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  //如果图片像素大于100万则使用瓦片绘制
  var count;
  if ((count = width * height / 1000000) > 1) {
    count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
    //            计算每块瓦片的宽和高
    var nw = ~~(width / count);
    var nh = ~~(height / count);
    tCanvas.width = nw;
    tCanvas.height = nh;
    for (var i = 0; i < count; i++) {
      for (var j = 0; j < count; j++) {
        tctx.drawImage(
          img,
          i * nw * ratio,
          j * nh * ratio,
          nw * ratio,
          nh * ratio,
          0,
          0,
          nw,
          nh
        );
        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
      }
    }
  } else {
    ctx.drawImage(img, 0, 0, width, height);
  }
  //进行最小压缩
  var ndata = canvas.toDataURL("image/jpeg");  
  console.log("压缩前：" + initSize);
  console.log("压缩后：" + ndata.length);
  console.log(
    "压缩率：" + ~~(100 * (initSize - ndata.length) / initSize) + "%"
  );
  tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
  return ndata;
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
</script>

<style>
.mo-cell {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 15px;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  line-height: 24px;
  position: relative;
  background-color: #fff;
  color: #333;
  font-size: 12px;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.02);
  margin-top: 3px;
}
.mo-cell::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0 solid #e5e5e5;
}
.mo-img-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2px;
  justify-content: flex-start;
}
.mo-img-cell {
  width: 12vh;
  height: 12vh;
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}
.van-uploader i {
  margin-top: 2.5vh;
  font-size: 8vh;
}
.mo-imgupload {
  margin-bottom: 20vh;
  /* text-align: center; */
}
.mo-img-deletespan {
  float: right;
  margin: 3px;
  color: white;
  font-size: large;
  /* background-color: black; */
}
</style>
