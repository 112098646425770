<template>
  <div class="listform">
    <div class="form-title">误工积分规则管理
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <!-- <Button type="success" @click="NewRule" icon="ios-document">新建</Button> -->
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="true" size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      sel_values: [
        { value: "IntegralTypeDesc", label: "积分类型" },
        { value: "PipeTypeDesc", label: "管道类型" },
        { value: "AgentDealerDesc", label: "代理运营商" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "初始积分",
            key: "InitIntegralNum",
            width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "积分类型",
            key: "IntegralTypeDesc",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => { // 为空显示0
              const row = params.row;
              const text = (row.IntegralTypeDesc == null && "0") || row.IntegralTypeDesc;
              return h("div",
                text
              );
            }
          },
          {
            title: "厨房倍率",
            key: "KitChenMultiple",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "管道米数倍率",
            key: "PipeLengthMultiple",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "管道类型",
            key: "PipeTypeDesc",
            width: 150,
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => { // 为空显示0
              const row = params.row;
              const text = (row.PipeTypeDesc == null && "0") || row.PipeTypeDesc;
              return h("div",
                text
              );
            }
          },
          {
            title: "丝口倍率",
            key: "ScrewMultiple",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "卫生间倍率",
            key: "WashMultiple",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "大流量倍率",
            key: "BigFlowMultiple",
            width: 120,
            align: "center",
            tooltip: true
          },
          {
            title: "代理运营商",
            key: "AgentDealerDesc",
            width: 180,
            align: "center",
            tooltip: true
          },
          {
            title: "积分适用对象",
            key: "IntegralObject",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom",
            render: (h, params) => { // 1水工 2经销商
              const row = params.row;
              const text = (row.IntegralObject == 1 && "水工") || "经销商";
              return h("div",
                text
              );
            }
          },
          {
            title: "过滤器类型",
            key: "FilterTypeDesc",
            width: 120,
            align: "center",
            tooltip: true,
            render: (h, params) => { // 为空显示0
              const row = params.row;
              const text = (row.FilterTypeDesc == null && "0") || row.FilterTypeDesc;
              return h("div",
                text
              );
            }
          },
          {
            title: "前置过滤器积分倍率",
            key: "FilterMultiple",
            width: 120,
            align: "center",
            tooltip: true
          }
          // {
          //   title: "操作",
          //   key: "",
          //   width: 180,
          //   align: "center",
          //   tooltip: true,
          //   fixed: "right",
          //   render: (h, params) => {
          //     return h("div", [
          //       h(
          //         "Button",
          //         {
          //           props: { type: "info", size: "small" },
          //           style: { marginRight: "10px" },
          //           on: {
          //             click: () => {
          //               this.Edit(params.row);
          //             }
          //           }
          //         },
          //         "编辑"
          //       )
          //     ]);
          //   }
          // }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    //编辑积分规则
    // Edit: function(item) {
    //   this.$router.push({
    //     name: "dealer_integralruledetail",
    //     query: { id: item.ID }
    //   });
    // },
    ModalCancel: function() {},
    // NewRule: function() {//新建积分规则
    //   this.$router.push({ name: "dealer_newintegralrule" });
    // },
    PageChange: function(e) {//分页
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_integralrulelist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {//查询误工积分规则列表
      var that = this;
      that.table.loading = true;
      this.$http
        .get("IntegralRule/GetRulePageList", {
          params: { 
            pageIndex: that.table.pageIndex, 
            sort: that.table.sort 
          }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {//排序
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function() {//条件查询
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    }
  },
  created: function() {//页面初始化
    this.GetTableList();
  }
};
</script>
<style>
</style>
 