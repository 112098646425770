<template>
  <div id="page">
    <van-nav-bar
        title="评价服务"
        left-text=""
        @click-left="onClickLeft()">
    </van-nav-bar>
    <div style="padding-left:20px;padding-right:20px;">
      <div style="margin-top:20px;font-size:18px;">
        <span style="margin-left:25%;font-weight:bold;">测压服务满意度调查</span>
      </div>
      <div style="word-wrap: break-word; word-break: normal;margin-top:10px;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;尊敬的先生/女士，为了提升本公司无忧管家测压服务品质，恳请您花少许时间填写这份调查表，
        作为改善无忧管家测压服务的参考，提交资料后可查询管路图，谢谢您的合作与支持。
      </div>
      <Form :model="postdata">
        <FormItem>
          <div>1、您与此户的关系是（单选）<span style="color:red;">*</span></div>
          <RadioGroup v-model="postdata.Relation" vertical>
            <Radio label="1">
                <span>业主</span>
            </Radio>
            <Radio label="2">
                <span>水电师傅</span>
            </Radio>
            <Radio label="3">
                <span>项目经理</span>
            </Radio>
            <Radio label="4">
                <span>其他</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem>
          <div>2、测试此户水管压力时，您是否在现场（单选）<span style="color:red;">*</span></div>
          <RadioGroup v-model="postdata.IsPresent" vertical @on-change="PresentChange">
            <Radio label="0">
                <span>否</span>
            </Radio>
            <Radio label="1">
                <span>是</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem v-if="isHide==1">
          <div>3、您对此次测压服务是否满意（单选）<span style="color:red;">*</span></div>
          <RadioGroup v-model="postdata.IsSatisfy" vertical>
            <Radio label="1">
                <span>不满意</span>
            </Radio>
            <Radio label="2">
                <span>一般</span>
            </Radio>
            <Radio label="3">
                <span>满意</span>
            </Radio>
            <Radio label="4">
                <span>非常满意</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem v-else>
          <div>3、测压服务结束后我司无忧管家是否有电话告知业主测压结果（单选）<span style="color:red;">*</span></div>
          <RadioGroup v-model="postdata.IsInformResult" vertical>
            <Radio label="0">
                <span>否</span>
            </Radio>
            <Radio label="1">
                <span>是</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem>
          <div>4、建议</div>
          <Input v-model="postdata.Propose" type="textarea" style="width:335px" :autosize="{minRows: 3,maxRows: 10}" placeholder="请填写您的宝贵建议"></Input>
        </FormItem>
        <FormItem style="text-align:center;">
          <Button type="primary" @click="SubmitData" long style="width:100%;height:35px;">提交</Button>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";
export default {
  data() {
    return {
      resource: 0,
      isHide: 1,
      postdata: {
        CustomerID: 0,
        CustAddr: "",
        WarrantyNum: "",
        IsPresent: 0,
        Relation: 0,
        IsSatisfy: 0,
        IsInformResult: 0,
        Propose: "",
        Resource:0
      },
    };
  },
  methods: {
    PresentChange: function(value) {
      if(value == 0){
        this.isHide = 2;
        this.postdata.IsSatisfy=0;
      } else {
        this.isHide = 1;
        this.postdata.IsInformResult=0;
      }
    },

    SubmitData: function() {
      var that = this;
      var data = {
        CustomerID: that.postdata.CustomerID,
        CustAddr: that.postdata.CustAddr,
        WarrantyNum: that.postdata.WarrantyNum,
        IsPresent: that.postdata.IsPresent,
        Relation: that.postdata.Relation,
        IsSatisfy: that.postdata.IsSatisfy,
        IsInformResult: that.postdata.IsInformResult,
        Propose: that.postdata.Propose,
        Resource: that.resource
      };
      if(!this.CheckData(data)){
        return ;
      }

      that.$http.post("CustVisit/AddVisit", data)
        .then(res => {
        var msg = "";
        if (res.data != "success") {
          msg = res.data;
        } else {
          msg = "提交成功！";
          if(that.resource == 1){
            // that.$router.push({
            //   name: "customerImg",
            //   params: {},
            // });
            that.$router.push({
              name: "customerInfo",
              params: {},
            });
          } else {
            // that.$router.push({
            //   name: "customerImg",
            //   params: { WarrantyNum: that.postdata.WarrantyNum },
            // });
            that.$router.push({
              name: "customerInfo",
              params: { WarrantyNum: that.postdata.WarrantyNum },
            });
          }
        }
        that.$Modal.info({
          title: "提示",
          content: msg
        });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.Relation == "") {
        content = "请选择与此户关系";
        checkflag = false;
      }
      if (obj.IsPresent == "") {
        content = "请选择是否在现场";
        checkflag = false;
      }
      if (obj.IsPresent==1&&obj.IsSatisfy == "") {
        content = "请选择对服务是否满意";
        checkflag = false;
      }
      if (obj.IsPresent==0&&obj.IsInformResult == "") {
        content = "请选择是否电话告知业主试压结果";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    onClickLeft() {
      this.$router.back(-1);
    },
  },
  components: {
    [NavBar.name]: NavBar,
  },
  watch: {},
  created: function() {
    this.postdata.CustomerID = this.$route.params.CustomerID;
    this.postdata.CustAddr = this.$route.params.CustAddr;
    this.postdata.WarrantyNum = this.$route.params.WarrantyNum;
    this.resource = this.$route.params.Resource;
  }
};

</script>

<style>
</style>
