<template>
  <div class="listform">
    <div class="form-title">试压员列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <!-- <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end" placeholder="根据创建时间筛选" style="width: 200px"></DatePicker> -->
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="success" @click="NewWorker" icon="ios-document">新建</Button>
              <!-- <Upload  :max-size="2048" :with-credentials="true" :show-upload-list="false"
         :action="uploadUrl">上传</Upload> -->
    </div>
    <div class="datatable">
      <Table :border="false" size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading"
        :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      uploadUrl:this.$file.url+"api/Test/Upload",
      sel_field: "",
      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "TelNum", label: "电话" }
      ],
      IsSystem: 0,
      AgentId: 0,
      EmployeesType: 0,
      table: {
        tablecolumsTitle: [
          {
            title: "工号",
            key: "CardNum",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "姓名",
            key: "Name",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "电话",
            key: "TelNum",
            // width: 150,
            align: "center",
            tooltip: true
          },
          {
            title: "登录用户名",
            key: "UserName",
            // width: 100,
            align: "center",
            tooltip: true
          },
          {
            title: "登录密码",
            key: "Password",
            // width: 100,
            align: "center",
            tooltip: true
          }, 
          {
            title: "操作",
            width: 150,
            align: "center",
            tooltip: true,
            render: (h, params) => {
              if(this.IsSystem==1&&this.AgentId==1)
              {
                  return h("div", [
                    h(
                      "Button",
                      {
                        props: { type: "success", size: "small" },
                        style: { marginRight: "3px" },
                        on: {
                          click: () => {
                            this.Edit(params.row);
                          }
                        }
                      },
                      "编辑"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "warning", size: "small" },
                        style: { marginRight: "10px" },
                        on: {
                          click: () => {
                            this.Delete(params.row);
                          }
                        }
                      },
                      "删除"
                    )
                  ]);
              } 
              else if(this.EmployeesType==4&&this.AgentId!=1)
              {
                  return h("div", [
                    h(
                      "Button",
                      {
                        props: { type: "success", size: "small" },
                        style: { marginRight: "3px" },
                        on: {
                          click: () => {
                            this.Edit(params.row);
                          }
                        }
                      },
                      "编辑"
                    ),
                    h(
                      "Button",
                      {
                        props: { type: "warning", size: "small" },
                        style: { marginRight: "10px" },
                        on: {
                          click: () => {
                            this.Delete(params.row);
                          }
                        }
                      },
                      "删除"
                    )
                  ]);
              }
              // if(this.IsSystem==0&&this.AgentId==1){
              //   return h("div", []);
              // }else{
              //   return h("div", [
              //     h(
              //       "Button",
              //       {
              //         props: { type: "success", size: "small" },
              //         style: { marginRight: "3px" },
              //         on: {
              //           click: () => {
              //             this.Edit(params.row);
              //           }
              //         }
              //       },
              //       "编辑"
              //     ),
              //     h(
              //       "Button",
              //       {
              //         props: { type: "warning", size: "small" },
              //         style: { marginRight: "10px" },
              //         on: {
              //           click: () => {
              //             this.Delete(params.row);
              //           }
              //         }
              //       },
              //       "删除"
              //     )
              //   ]);
                
              // }
              
              // return h("div", [
              //   h(
              //     "Button",
              //     {
              //       props: { type: "warning", size: "small" },
              //       style: { marginRight: "10px" },
              //       on: {
              //         click: () => {
              //           this.Delete(params.row);
              //         }
              //       }
              //     },
              //     "删除"
              //   )
              // ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_workerlist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Worker/GetWorkerPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    GetCurEmployee: function() {
      let that = this;
      that.$http.get("Employee/GetCurEmployee").then(res => {
        that.IsSystem=res.data.IsSystem;
        that.AgentId=res.data.AgentDealerId;
        that.EmployeesType=res.data.EmployeesType;
      });
    },
    NewWorker: function() {
      this.$router.push({
        name: "dealer_newworker"
      });
    },
    Edit: function(item) {
      this.$router.push({
        name: "dealer_workerdetail",
        query: { id: item.ID }
      });
    },
    Delete: function(e) {
      var that=this;
      this.$Modal.confirm({
        title: "提示",
        content: "确认删除吗?",
        onOk: () => {
          that.$http.get('Worker/DeleteWorker',{
            params:{id:e.ID}
          })
          .then(res=>{
            that.GetTableList();
          })
        }      
      });
    }
  },
  created: function() {
    this.GetTableList();
    this.GetCurEmployee();
  }
};
</script>
<style>
</style>
 