var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"10px"}},[_c('van-nav-bar',{attrs:{"title":"天力管房屋管路图","left-text":""},on:{"click-left":function($event){return _vm.onClickLeft()}}}),_vm._l((_vm.datalist),function(customer,customerindex){return _c('Card',{key:customerindex,staticStyle:{"margin-top":"5px"},attrs:{"bordered":true,"dis-hover":""}},[_c('div',{staticStyle:{"padding":"15px"}},[(_vm.showViewVR)?_c('Button',{staticStyle:{"width":"100%","height":"35px"},attrs:{"type":"primary","long":""},on:{"click":function($event){return _vm.ViewVRImg(customer.WarrantyNum)}}},[_vm._v("查看全景图")]):_vm._e()],1),_c('Tabs',{attrs:{"type":"card","name":"img"}},[_c('TabPane',{attrs:{"label":"全部","name":"all"}},[_c('div',{staticClass:"img-list"},_vm._l((customer.imgurl),function(item,index){return _c('div',{key:index,staticClass:"img-cell",style:({
              'background-image': 'url(' + item + ')',
              'background-size': 'cover',
              'background-position': 'center center',
              'background-repeat': 'no-repeat',
              'background-color': 'black',
            }),on:{"click":function($event){return _vm.viewimg(customerindex, index)}}})}),0)]),_c('TabPane',{attrs:{"label":"安装不规范","name":"standard"}},[_c('div',[_c('p',{staticStyle:{"margin-left":"5px"}},[_vm._v("安装不规范描述: "+_vm._s(customer.StandardDesc))])]),_c('div',{staticClass:"img-list"},_vm._l((customer.img2url),function(item,index){return _c('div',{key:index,staticClass:"img-cell",style:({
              'background-image': 'url(' + item + ')',
              'background-size': 'cover',
              'background-position': 'center center',
              'background-repeat': 'no-repeat',
              'background-color': 'black',
            }),on:{"click":function($event){return _vm.viewimg2(customerindex, index)}}})}),0)])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }