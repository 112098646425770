
<template>
  <div class="layout">
    <Sider :style="{position: 'fixed', height: '100vh', left: 0, overflow: 'auto'}">
      <Card style="height:100px;background-color:#bbc1cd;border:0;border-radius:0;">
        <div style="text-align:center">
          <img style="height:50px" src="../assets/logo.png">
          <h3>客户服务系统(代理商)</h3>
        </div>
      </Card>
      <Menu active-name="" theme="dark" width="auto" :open-names="['1']" @on-select="changeUrl">
        <!-- <Submenu :name="item.MenuName" v-for="item in menuList" :key="item.ID">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            {{item.MenuName}}
          </template>
          <MenuItem :name="item2.MenuPath" v-for="item2 in item.Children" :key="item2.ID">
          {{item2.MenuName}}
          </MenuItem>
        </Submenu> -->
        <Submenu name="3">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            售后服务
          </template>
          <MenuItem name="3-1">售后单据</MenuItem>
          <MenuItem name="3-2">待审核售后单</MenuItem>
        </Submenu>
        <Submenu name="1">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            试压服务
          </template>
          <MenuItem name="1-1">销售档案</MenuItem>
          <MenuItem name="1-2">试压预约单</MenuItem>
          <MenuItem name="1-3">客户档案</MenuItem>
          <MenuItem name="1-4">水工档案</MenuItem>
          <MenuItem name="1-5" v-if="agentid==1">过滤器编码</MenuItem>
          <MenuItem name="1-6">待审核试压单</MenuItem>
          <!-- <MenuItem name="1-7" v-if="agentid==5">抽奖记录</MenuItem> -->
        </Submenu>
        <Submenu name="8">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            清洗服务
          </template>
          <MenuItem name="8-1">清洗预约单</MenuItem>
          <MenuItem name="8-2">客户档案</MenuItem>
          <MenuItem name="8-3">待审核清洗单</MenuItem>
        </Submenu>
        <!-- <Submenu name="8">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            试压服务(浏阳)
          </template>
          <MenuItem name="8-1">销售档案</MenuItem>
          <MenuItem name="8-2">试压预约单</MenuItem>
        </Submenu> -->
        <Submenu name="5" v-if="agentid==1">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            物资积分商城
          </template>
          <!-- <MenuItem name="5-1">订单列表</MenuItem>
          <MenuItem name="5-2">商品列表</MenuItem> -->
          <MenuItem name="5-3">抽奖记录</MenuItem>
          <MenuItem name="5-4">抽奖记录(下)</MenuItem>
        </Submenu>
        <!-- <Submenu name="6" v-if="agentid==1">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            水电清单
          </template>
          <MenuItem name="6-1">材料清单</MenuItem>
        </Submenu> -->
        <Submenu name="2">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            系统管理
          </template>
          <MenuItem name="2-1">经销商</MenuItem>
          <MenuItem name="2-2" v-if="issystem==1">账户管理</MenuItem>
          <MenuItem name="2-4">试压员维护</MenuItem>
          <MenuItem name="2-5">误工积分规则</MenuItem>
          <!-- <MenuItem name="2-6">物资积分规则</MenuItem> -->
          <MenuItem name="2-7" v-if="agentid==1">开单编码</MenuItem>
          <MenuItem name="2-8">文件下载</MenuItem>
        </Submenu>
        <Submenu name="7" v-if="issystem==1">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            报表统计
          </template>
          <MenuItem name="7-1">经销商积分统计</MenuItem>
          <MenuItem name="7-2">经销商积分按月统计</MenuItem>
          <MenuItem name="7-3">公司积分按月统计</MenuItem>
          <MenuItem name="7-4">公司积分统计</MenuItem>
        </Submenu>
        <Submenu name="9" v-if="agentid==1">
          <template slot="title">
            <Icon type="ios-navigate"></Icon>
            物料管理
          </template>
          <MenuItem name="9-1" v-if="agentid==1&&issystem==1">物料列表</MenuItem>
          <MenuItem name="9-2">物料申请记录</MenuItem>
          <MenuItem name="9-3" v-if="agentid==1&&issystem==1">物料库存记录</MenuItem>
          <MenuItem name="9-4" v-if="agentid==1&&issystem==1">公司申请记录</MenuItem>
        </Submenu>

      </Menu>
    </Sider>
    <Layout :style="{marginLeft: '200px'}">
      <Header :style="{background: '#fff', boxShadow: '0 2px 3px 2px rgba(0,0,0,.1)'}">
        <Menu mode="horizontal" @on-select="UserTabChange" style="width: auto;float: right;">
          <Submenu name="3">
            <template slot="title">
              <Icon type="ios-navigate"></Icon>
              用户操作
            </template>
            <MenuGroup title="使用">

              <MenuItem name="3-2">注销</MenuItem>
            </MenuGroup>
          </Submenu>
        </Menu>
      </Header>
      <Content :style="{padding: '0 16px 16px'}">
        <Breadcrumb :style="{margin: '16px 0'}">
          <BreadcrumbItem>首页</BreadcrumbItem>
          <BreadcrumbItem>数据列表</BreadcrumbItem>
          <!-- <BreadcrumbItem>Layout</BreadcrumbItem> -->
        </Breadcrumb>
        <Card>
          <div class="content">
            <!-- <keep-alive> -->
            <router-view>
            </router-view>
            <!-- </keep-alive> -->

            <!-- <router-view v-if="!$route.meta.keepAlive">              
            </router-view> -->
          </div>
        </Card>
      </Content>
    </Layout>
    <Modal title="报表导出" @on-ok="ModalOK" v-model="modal.show">
      <DatePicker type="daterange" show-week-numbers @on-change="DateTimeSelect" v-model="report.dateSearchValue"
        confirm placement="bottom" placeholder="选择日期范围" style="width: 200px"></DatePicker>

      <p style="padding:10px">提示:{{report.remark}}</p>
    </Modal>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      modal: {
        show: false,
      },
      report: {
        dateSearchValue: "",
        url: "",
        remark: "",
      },
      username: this.$parent.$data.userinfo.Name,
      agentid: 0,
      issystem:0,
      menuList: []
    };
  },
  methods: {
    changeUrl: function (e) {
      this.$http.SetConditions([]);
      switch (e) {
        case "1-1":
          this.$router.push({ name: "dealer_presalelist" });
          break;
        case "1-2":
          this.$router.push({ name: "dealer_prepresslist" });
          break;
        case "1-3":
          this.$router.push({ name: "dealer_customerlist" });
          break;
        case "1-4":
          this.$router.push({ name: "dealer_sdworkerlist" });
          break;
        case "1-5":
          this.$router.push({ name: "dealer_prefiltercodelist" });
          break;
        case "1-6":
          this.$router.push({ name: "dealer_waitauditcustomerlist" });
          break;
        // case "1-7":
        //   this.$router.push({ name: "dealer_lyluckydrawlist" });
        //   break;
        case "8-1":
          this.$router.push({ name: "dealer_washlist" });
          break;
        case "8-2":
          this.$router.push({ name: "dealer_washcustomerlist" });
          break;
        case "8-3":
          this.$router.push({ name: "dealer_waitauditwashcustomerlist" });
          break;
        // case "8-1":
        //   this.$router.push({ name: "dealer_lypresalelist" });
        //   break;
        // case "8-2":
        //   this.$router.push({ name: "dealer_lyprepresslist" });
        //   break;
        case "2-1":
          this.$router.push({ name: "dealer_childdealerlist" });
          break;
        case "2-2":
          this.$router.push({ name: "dealer_agentlist" });
          break;
        case "2-3":
          this.$router.push({ name: "dealer_businesslist" });
          break;
        case "2-4":
          this.$router.push({ name: "dealer_workerlist" });
          break;
        case "2-5":
          this.$router.push({ name: "dealer_integralrulelist" });
          break;
        // case "2-6":
        //   this.$router.push({ name: "dealer_materialintegralrulelist" });
        //   break;
        case "2-7":
          this.$router.push({ name: "dealer_billCode" });
          break;
        case "2-8":
          this.$router.push({ name: "dealer_filedownload" });
          break;
        case "3-1":
          this.$router.push({ name: "aftersalelist" });
          break;
        case "3-2":
          this.$router.push({ name: "waitauditaftersalelist" });
          break;
        case "4-1":
          this.modal.show = true;
          this.report.url = "Dealer/ExportDealerIntegralDetail";
          this.report.dateSearchValue = "";
          this.report.remark =
            "请选择要导出的经销商积分记录的日期范围,报表包含选择截止日期的积分数以及日期范围内获得总积分数。";
          break;
        case "4-2":
          this.modal.show = true;
          break;
        case "5-1":
          this.$router.push({ name: "dealer_orderlist" });
          break;
        case "5-2":
          this.$router.push({ name: "dealer_goodslist" });
          break;
        case "5-3":
          this.$router.push({ name: "dealer_luckydrawlist" });
          break;
        case "5-4":
          this.$router.push({ name: "dealer_luckydrawlist2" });
          break;
        // case "5-4":
        //   this.$router.push({ name: "dealer_xtluckydrawlist" });
        //   break;
        case "6-1":
          this.$router.push({ name: "sdworker_matlist" });
          break;
        case "7-1":
          this.$router.push({ name: "dealer_statistics" });
          break;
        case "7-2":
          this.$router.push({ name: "dealer_statistics_month" });
          break;
        case "7-3":
          this.$router.push({ name: "dealer_statistics2_month" });
          break;
        case "7-4":
          this.$router.push({ name: "dealer2_statistics" });
          break;
        case "9-1":
          this.$router.push({ name: "dealer_materiallist" });
          break;
        case "9-2":
          this.$router.push({ name: "dealer_materialloglist" });
          break;
        case "9-3":
          this.$router.push({ name: "dealer_matstockloglist" });
          break;
        case "9-4":
          this.$router.push({ name: "dealer_companyloglist" });
          break;
      }
    },
    // changeUrl: function (e) {
    //   this.$http.SetConditions([]);
    //   this.$router.push({ 
    //     name: e 
    //   });
    // },
    DateTimeSelect: function (e) {
      this.report.dateSearchValue = e;
    },
    ModalOK: function () {
      var that = this;
      this.$Spin.show({
        render: (h) => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18,
              },
            }),
            h("div", "生成中.."),
          ]);
        },
      });
      if (that.report.dateSearchValue == "") {
        this.$Modal.warning({
          title: "提示",
          content: "请选择日期范围",
        });
        return;
      }
      this.$http
        .get(that.report.url, {
          params: {
            startdate: that.report.dateSearchValue[0],
            enddate: that.report.dateSearchValue[1],
          },
        })
        .then((res) => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    UserTabChange(e) {
      console.log(e);
      if (e == "3-1") {
        this.UserInfo();
      } else {
        this.loginout();
      }
    },
    loginout() {
      this.$http.get("Employee/LoginOut").then((res) => {
        console.log(res);
        this.$router.push({ path: "/" });
      });
    },
    UserInfo() {
      console.log(this.$parent.$data);
      const contentinfo =
        "<p>用户名：" +
        this.$parent.$data.userinfo.Name +
        "</p><br><p>编号：" +
        this.$parent.$data.userinfo.LiquidCode +
        "</p>";
      this.$Modal.info({
        title: "个人信息",
        content: contentinfo,
      });
    },
    // initData: function() {
    //   let that = this;
    //   that.$http.get("Employee/GetUserMenu").then(res => {
    //     that.menuList = res.data.Data;
    //   });
    // },

  },
  created: function() {//页面初始化
    var user = this.$store.state.currentUser;
    this.agentid = user.AgentDealerId;
    this.issystem = user.IsSystem;
    console.log(user);
    // this.initData();
  }
};
</script>
<style scoped>
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  min-height: 100%;
}
.layout-header-bar {
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.layout-logo {
  width: 100px;
  height: 30px;
  background: #bbc1cd;
  border-radius: 3px;
  float: left;
  position: relative;
  top: 15px;
  left: 20px;
  text-align: center;
}
.layout-nav {
  width: 420px;
  margin: 0 auto;
  margin-right: 20px;
}
.content {
  min-height: 400px;
  min-width: 600px;
}
.ivu-menu-horizontal.ivu-menu-light:after {
  content: none;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
</style>