<template>
  <div class="listform">
    <div class="form-title">公司申请记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" no-data-text="数据加载为空" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist">
      </Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator>
      </Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "Range", label: "质保卡" },
        { value: "Region", label: "区域" },
      ],
      WarrantyExchange_modal: {
        currentid: 0,
        show: false,
        warrantyRange: ""
      },
      table: {
        tablecolumsTitle: [
          {
            title: "质保卡类型",
            key: "WarrantyType",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "卡号区间",
            key: "Range",
            align: "center",
            tooltip: true,
          },
          {
            title: "申请数量",
            key: "ApplyNum",
            align: "center",
            tooltip: true,
          },
          {
            title: "区域",
            key: "Region",
            align: "center",
            tooltip: true,
          },
          {
            title: "申请时间",
            key: "ApplyTime",
            align: "center",
            tooltip: true,
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort:"ApplyTime DESC "
      },
    };
  },
  methods: {
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_companyloglist",
        query: { pageindex: e }
      });
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("WorkerMaterial/GetCompanyLogPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort },
        })
        .then((res) => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    GoBack: function (e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
  },
  created: function () {
    this.GetTableList();
  },
};
</script>
<style>
</style>
 