
<template>
  <div class="my-form">
    <div class="form-title">编辑误工积分规则
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="120">
      <FormItem label="初始积分 ">
        <Input v-model="postdata.InitIntegralNum" style="width:500px" placeholder="请填写初始积分"></Input>
      </FormItem>
      <FormItem label="根据管道类型积分 ">
        <RadioGroup v-model="postdata.IntegralType">
          <Radio v-for="item in radio.Integraltypes" :label="item.ID" :key="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="厨房倍率 ">
        <Input v-model="postdata.KitChenMultiple" style="width:500px" placeholder="请填写厨房倍率"></Input>
      </FormItem>
      <FormItem label="管道米数倍率 ">
        <Input v-model="postdata.PipeLengthMultiple" style="width:500px" placeholder="请填写管道米数倍率"></Input>
      </FormItem>
      <FormItem label="管道类型 ">
        <RadioGroup v-model="postdata.PipeType">
          <Radio v-for="item in radio.Pipetypes" :label="item.ID" :key="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="丝口倍率 ">
        <Input v-model="postdata.ScrewMultiple" style="width:500px" placeholder="请填写丝口倍率"></Input>
      </FormItem>
      <FormItem label="卫生间倍率 ">
        <Input v-model="postdata.WashMultiple" style="width:500px" placeholder="请填写卫生间倍率"></Input>
      </FormItem>
      <FormItem label="代理运营商">
         <Select v-model="postdata.AgentId" filterable style="width:500px">
            <Option v-for="item in select.Agents" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="积分规则适用对象">
        <RadioGroup v-model="postdata.IntegralObject">
          <Radio :label="1">水工</Radio>
          <Radio :label="2">经销商</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="过滤器类型 ">
        <RadioGroup v-model="postdata.FilterType">
          <Radio v-for="item in radio.Filtertypes" :label="item.ID" :key="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="前置过滤器积分倍率 ">
        <Input v-model="postdata.FilterMultiple" style="width:500px" placeholder="请填写前置过滤器积分倍率"></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
    <Spin size="large" fix v-if="false"></Spin>
  </div>
</template>
<script>
  import dealerSel from "../../components/public_component/dealerSel";
export default {  
  data() {
    return {
      spinShow: false,
      select: {
        Agents: []
      },
      radio: {
        Integraltypes: [],
        Pipetypes: [],
        Filtertypes: []
      },
      postdata: {
        InitIntegralNum: 0,
        IntegralType: 0,
        KitChenMultiple: 0,
        PipeLengthMultiple: 0,
        PipeType: 0,
        ScrewMultiple: 0,
        WashMultiple: 0,
        AgentId: "",
        IntegralObject: "",
        FilterType: 0,
        FilterMultiple: 0
      },
      modal: {
        show: false
      }
    };
  },
  components: {
    "dealer-RowSel": dealerSel
  },
  methods: {
    SubmitData: function(e) {//提交
      if(!this.CheckData(this.postdata)){
        return ;
      }
      this.$http.post("IntegralRule/EditIntegralRule", this.postdata).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
        this.$router.push({ name: "dealer_integralrulelist" });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.AgentId == "") {
        content = "请选择运营代理商";
        checkflag = false;
      }
      if (obj.IntegralObject == "") {
        content = "积分规则适用对象";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    GetIntegralRule: function(id) {
      var that = this;
      this.$http
        .get("IntegralRule/GetIntegralRule", { params: { id: id } })
        .then(res => {
          that.postdata = res.data;
        })
        .then(res => {
          that.spinShow = false;
        });
    },
    BackList: function() {//返回
      this.$router.push({ name: "dealer_integralrulelist" });
    },
    GetAgentDealer: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.select.Agents = res.data;
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=6").then(res => {
        that.radio.Integraltypes = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=4").then(res => {
        that.radio.Pipetypes = res.data;
      });
      that.$http.get("CheckBox/GetCheckBoxs?type=7").then(res => {
        that.radio.Filtertypes = res.data;
      });
    }
  },
  watch: {},
  created: function() {//页面初始化
    this.spinShow = true;
    this.GetCheckbox();
    this.GetAgentDealer();
    this.GetIntegralRule(this.$route.query.id);
  }
};
</script>

<style>
</style>
