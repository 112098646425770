<template>
  <div style="padding-bottom:60px">
    <!-- <Table :columns="table.tablecolumsTitle" :data="table.after_sale_list" size="small"></Table> -->
    <ul class="mo-ul">
      <li >
        <Row class="mo-li" v-for="item in after_sale_list" :key="item.ID"  type="flex" justify="center" align="middle" >
          <Col span="12">
          <p>姓名：{{item.CustomerName}}</p>
          <p>电话：{{item.CustomerTel}}</p>   
          <p>地址:{{item.AddressText+item.AddressDetail}}</p>       
          </Col>
          <Col span="8"><Tag type="dot" color="blue">{{item.ProcessStatusDesc}}</Tag></Col>
          <Col span="4"><Button @click="Dispose(item.ID,item.ProcessStatusCode)" type="info" size="small">处理</Button></Col>
        </Row>
      </li>
    </ul>
    <div class="footerpage">
      <Page size="small" :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      after_sale_list: [],
      table: {
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true
      }
    };
  },
  methods: {

    Dispose(id,processcode){           
      this.$router.push({path:'/index_mobile/imgupload/'+id});            
    },
    PageChange(e) {
      this.table.pageIndex = e;
      this.GetAfterSales();
    },
    GetAfterSales() {
      this.$http
        .get("AfterSaleBill/GetAfterSaleBillPageListByToDisposer", {
          params: {
            pageindex: this.table.pageIndex
          }
        })
        .then(res => {
          this.after_sale_list = res.data.ListData;
          this.table.total = res.data.TotalCount;
          this.table.pageSize = res.data.PageSize;
          console.log(res);
        });
    }
  },
  created: function() {
    this.GetAfterSales();
  }
};
</script>

<style>
.mo-ul {
  font-size: 12px;
}
.mo-li {
  margin: 10px, 3px, 10px, 3px;
  padding: 10px 10px 10px 10px;
  /* height: 10em; */
  border-bottom: 1px solid gainsboro;
}
</style>
