<template>
  <div style="height:100%">
    <div class="login" @keydown.enter="handleSubmit">
      <div class="login-con">
        <Card :bordered="false">
          <p slot="title">
            <Icon type="log-in"></Icon>
            欢迎登录代理商服务系统
          </p>
          <div class="form-con">
            <Form ref="loginForm" :model="form" :rules="rules">
              <FormItem prop="userName">
                <Input v-model="form.userName" placeholder="请输入用户名">
                <span slot="prepend">
                  <Icon :size="16" type="ios-person-outline"></Icon>
                </span>
                </Input>
              </FormItem>
              <FormItem prop="password">
                <Input type="password" v-model="form.password" placeholder="请输入密码">
                <span slot="prepend">
                  <Icon :size="14" type="ios-lock-outline"></Icon>
                </span>
                </Input>
              </FormItem>
              <FormItem>
                <Button @click="handleSubmit" type="primary" long>登录</Button>
              </FormItem>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        userName: "",
        password: ""
      },
      rules: {
        userName: [
          { required: true, message: "账号不能为空", trigger: "blur" }
        ],
        password: [{ required: true, message: "密码不能为空", trigger: "blur" }]
      }
    };
  },
  mounted: function() {},
  methods: {
    handleSubmit() {
      var that = this;
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$http
            .post("Employee/LoginPC", {
              UserName: that.form.userName,
              PassWord: that.form.password
            })
            .then(e => {
              if (e.data.Code != "200") {
                this.$Message.info("账户名或密码错误！");
                return;
              }
              // if (e.data.Code == "202") {
              //   this.$Message.info("账户名或密码错误！");
              //   return;
              // } else if (e.data.Code == "203"){
              //   this.$Message.info("用户权限信息为空，请联系管理员！");
              //   return;
              // } else if (e.data.Code == "204"){
              //   this.$Message.info("用户角色信息为空，请联系管理员！");
              //   return;
              // }
              if(e.data.Data.UserName == 'WF'){
                this.$router.push({name:"dealer_main"});
              }
              else
              {
                this.$router.push({name:"dealer_presalelist"});
              }
              that.$store.commit("setCurrentUser", e.data.Data);
            })
            .catch(e => {
              console.log(e);
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background-image: url("https://file.iviewui.com/iview-admin/login_bg.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  &-con {
    position: absolute;
    right: 160px;
    top: 50%;
    transform: translateY(-60%);
    width: 300px;
    &-header {
      font-size: 16px;
      font-weight: 300;
      text-align: center;
      padding: 30px 0;
    }
    .form-con {
      padding: 10px 0 0;
    }
    .login-tip {
      font-size: 10px;
      text-align: center;
      color: #c3c3c3;
    }
  }
}
.mobieform {
  height: 100%;
  padding-top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://file.iviewui.com/iview-admin/login_bg.jpg");
}
</style>