<template>
  <div style="padding: 10px">
    <van-nav-bar
        title="天力管房屋管路图"
        left-text=""
        @click-left="onClickLeft()">
    </van-nav-bar>
    <Card
      :bordered="true"
      dis-hover
      v-for="(customer, customerindex) in datalist"
      :key="customerindex" style="margin-top:5px;">
      <div style="padding: 15px">
        <Button
          type="primary"
          v-if="showViewVR"
          @click="ViewVRImg(customer.WarrantyNum)"
          long
          style="width: 100%; height: 35px"
          >查看全景图</Button
        >

      </div>
      <Tabs type="card" name="img">
        <TabPane label="全部" name="all">
          <div class="img-list">
            <div
              v-for="(item, index) in customer.imgurl"
              @click="viewimg(customerindex, index)"
              :key="index"
              class="img-cell"
              v-bind:style="{
                'background-image': 'url(' + item + ')',
                'background-size': 'cover',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
                'background-color': 'black',
              }"
            ></div>
          </div>
        </TabPane>
        <TabPane label="安装不规范" name="standard">
          <div>
            <p style="margin-left: 5px">安装不规范描述: {{ customer.StandardDesc }}</p>
          </div>
          <div class="img-list">
            <div
              v-for="(item, index) in customer.img2url"
              @click="viewimg2(customerindex, index)"
              :key="index"
              class="img-cell"
              v-bind:style="{
                'background-image': 'url(' + item + ')',
                'background-size': 'cover',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
                'background-color': 'black',
              }"
            ></div>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  </div>
</template>

<script>
import VConsole from "vconsole";
import wx from "weixin-js-sdk";
//const vConsole = new VConsole({ theme: 'dark' });
import { Button, ImagePreview, NavBar } from "vant";
export default {
  data() {
    return {
      datalist: [{ CustAddress: "", imgurl: [], img2url: [],StandardDesc:""}],
      dataobj: {},
      imgurls: [],
      img2urls: [],
      StandardDesc: "",
      base_url: this.$file.url,
      // base_url: "http://localhost:24737/",
      showViewVR: false,
      VRImgUrl: "",
      showViewDraw: false,
    };
  },
  methods: {
    // GetData() {
    //   var that = this;
    //   this.$http.get("Customer/GetCustomersByTel").then((res) => {
    //     that.LoadImgNew(res);
    //     // that.LoadImg(res);
    //   });
    // },
    // GetDataByWarrantyNum(WarrantyNum) {
    //   var that = this;
    //   this.$http
    //     .get("Customer/GetCustomersByWarrantyNum?warrantyNum=" + WarrantyNum)
    //     .then((res) => {
    //       that.LoadImgNew(res);
    //       // that.LoadImg(res);
    //     });
    // },
    GetData(WarrantyNum) {
      var that = this;
      this.$http.get("Customer/GetCustomersByWarrantyNum",{
        params: {
            warrantyNum: WarrantyNum,
        },}).then((res) => {
        that.LoadImgNew(res);
        // that.LoadImg(res);
      });
    },
    LoadImgNew(res) {
      var that = this;
      if (res.data.Msg == "success") {
        that.datalist = res.data.ListData;
        for (let i = 0; i < res.data.ListData.length; i++) {
          that.imgurls = [];
          that.img2urls = [];
          if (res.data.ListData[i].AgentId == 1) {
            if (
              res.data.ListData[i].WarrantyImgs != null &&
              res.data.ListData[i].WarrantyImgs.length > 0
            ) {
              var wrimgStr = res.data.ListData[i].WarrantyImgs;
              var wrimgarray = wrimgStr.split(",");
              // var wimgUrlArray = [];

              var wrimgUrlArray = wrimgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "warranty" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = wrimgUrlArray;
              that.imgurls = that.imgurls.concat(wrimgUrlArray);
            }
            if (
              res.data.ListData[i].WashImgs != null &&
              res.data.ListData[i].WashImgs.length > 0
            ) {
              var wimgStr = res.data.ListData[i].WashImgs;
              var wimgarray = wimgStr.split(",");
              // var wimgUrlArray = [];

              var wimgUrlArray = wimgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "wash" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = wimgUrlArray;
              that.imgurls = that.imgurls.concat(wimgUrlArray);
            }
            if (
              res.data.ListData[i].KitchenImgs != null &&
              res.data.ListData[i].KitchenImgs.length > 0
            ) {
              var kimgStr = res.data.ListData[i].KitchenImgs;
              var kimgarray = kimgStr.split(",");
              // var kimgUrlArray = [];

              var kimgUrlArray = kimgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "kitchen" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = kimgUrlArray;
              that.imgurls = that.imgurls.concat(kimgUrlArray);
            }
            if (
              res.data.ListData[i].ParlourImgs != null &&
              res.data.ListData[i].ParlourImgs.length > 0
            ) {
              var pimgStr = res.data.ListData[i].ParlourImgs;
              var pimgarray = pimgStr.split(",");
              // var pimgUrlArray = [];

              var pimgUrlArray = pimgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "parlour" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = pimgUrlArray;
              that.imgurls = that.imgurls.concat(pimgUrlArray);
            }
            if (
              res.data.ListData[i].BalconyImgs != null &&
              res.data.ListData[i].BalconyImgs.length > 0
            ) {
              var bimgStr = res.data.ListData[i].BalconyImgs;
              var bimgarray = bimgStr.split(",");
              // var bimgUrlArray = [];

              var bimgUrlArray = bimgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "balcony" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = bimgUrlArray;
              that.imgurls = that.imgurls.concat(bimgUrlArray);
            }
            if (
              res.data.ListData[i].Imgs != null &&
              res.data.ListData[i].Imgs.length > 0
            ) {
              var oimgStr = res.data.ListData[i].Imgs;
              var oimgarray = oimgStr.split(",");
              // var oimgUrlArray = [];

              var oimgUrlArray = oimgarray.map((item) => {
                if (item.search("Images") != -1) {
                  //老数据的图片路径
                  return that.base_url + item;
                } else if (item.search("Images") == -1) {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.ListData[i].WarrantyNum +
                    "/" +
                    item +
                    ".jpg"
                  );
                }
              });
              res.data.ListData[i].imgurl = oimgUrlArray;
              that.imgurls = that.imgurls.concat(oimgUrlArray);
            }
            if (
              res.data.ListData[i].VRImgs != null &&
              res.data.ListData[i].VRImgs.length > 0
            ) {
              this.showViewVR = true;
              this.VRImgUrl = res.data.ListData[i].VRImgs;
            }
            if (
              res.data.ListData[i].StandardImgs != null &&
              res.data.ListData[i].StandardImgs.length > 0
            ) {
              var simgStr = res.data.ListData[i].StandardImgs;
              var simgarray = simgStr.split(",");
              // var simgUrlArray = [];

              var simgUrlArray = simgarray.map((item) => {
                //新路径格式 2021年6月30日
                return (
                  that.base_url +
                  "Images/upload/" +
                  res.data.ListData[i].WarrantyNum +
                  "/" +
                  "standard" +
                  "/" +
                  item +
                  ".jpg"
                );
              });
              res.data.ListData[i].imgurl = simgUrlArray;
              that.img2urls = that.img2urls.concat(simgUrlArray);
            }
            if (res.data.ListData[i].StandardDesc != null) {
              that.datalist[i].StandardDesc=res.data.ListData[i].StandardDesc;
              // that.StandardDesc = res.data.ListData[i].StandardDesc;
            }
          } else {
            if (res.data.ListData[i].Imgs != null) {
              var imgStr = res.data.ListData[i].Imgs;
              var imgarray = imgStr.split(",");
              // var imgUrlArray = [];

              var imgUrlArray = imgarray.map((item) => {
                if (item.search("Images") != -1) {
                  //老数据的图片路径
                  return that.base_url + item;
                } else if (item.search("Images") == -1) {
                  //新路径格式 2018年9月10日
                  return (
                    that.base_url +
                    "Images/upload/" +
                    res.data.ListData[i].WarrantyNum +
                    "/" +
                    item +
                    ".jpg"
                  );
                }
              });

              that.datalist[i].imgurl = imgUrlArray;
              that.imgurls = that.imgurls.concat(imgUrlArray);
            } else {
              that.datalist[i].imgurl = [];
            }

            if (
              res.data.ListData[i].VRImgs != null &&
              res.data.ListData[i].VRImgs.length > 0
            ) {
              this.showViewVR = true;
              this.VRImgUrl = res.data.ListData[i].VRImgs;
            }
          }

          that.datalist[i].imgurl = that.imgurls;
          that.datalist[i].img2url = that.img2urls;

          // 使用电子质保卡 可查询质保卡内容
          if(res.data.ListData[i].WarrantyNum.indexOf("R")==0){
            that.datalist[i].flag=true;
          }else if(res.data.ListData[i].WarrantyNum.indexOf("W")==0){
            res.data.ListData[i].flag=true;
          }else{
            res.data.ListData[i].flag=false;
          }
        }
      }
    },
    viewimg(item, index) {
      console.log(this.datalist[item].imgurl, index);
      var imgarray = [];
      this.datalist[item].imgurl.map((item) => {
        imgarray.push(item);
      });
      // this.imgurls.map((item) => {
      //   imgarray.push(item);
      // });
      ImagePreview(imgarray, index);
    },
    viewimg2(item, index) {
      console.log(this.datalist[item].imgurl, index);
      var imgarray = [];
      this.datalist[item].img2url.map((item) => {
        imgarray.push(item);
      });
      // this.imgurls2.map((item) => {
      //   imgarray.push(item);
      // });
      ImagePreview(imgarray, index);
    },
    ViewVRImg(num) {
      var that = this;
      that.$router.push({
        name: "vrview",
        params: { warranty: num },
      });
      console.log(num);
    },
    // ViewLucklyDraw(num) {
    //   var that = this;
    //   that.$router.push({
    //     name: "luckdraw2023",
    //     params: { warranty: num },
    //   });
    //   console.log(num);
    // },
    onClickLeft() {
      this.$router.back(-1);
    },
  },

  
  created: function () {
    // console.log(this.$route.params.WarrantyNum);
    // var WarrantyNum = this.$route.params.WarrantyNum;
    // // this.GetMiniProgramLink();
    // if (WarrantyNum != undefined) {
    //   this.GetDataByWarrantyNum(WarrantyNum);
    // } else {
    //   this.GetData();
    // }
    var WarrantyNum = this.$route.params.WarrantyNum;
    this.GetData(WarrantyNum);

    // this.$http();
  },
  mounted: function () {},
  components: {
    [Button.name]: Button,
    [NavBar.name]: NavBar
  },
};
</script>

<style>
</style>
