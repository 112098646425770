<template>
  <div class="listform">
    <div class="form-title">物料申请记录
      <Icon type="briefcase"></Icon>
      <Button style="margin-left: 30px;" type="primary" @click="GoBack()" icon="md-arrow-back">返回</Button>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false" border size="small" no-data-text="数据加载为空" @on-sort-change="Sort" :columns="table.tablecolumsTitle"
        :loading="table.loading" :data="table.datalist">
      </Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator>
      </Page>
    </div>
    <!-- <Modal title="质保卡号登记" width="400" height="500" v-model="WarrantyExchange_modal.show" @on-ok="ExChange_ModalOK" @on-cancel="ModalCancel">
      <div style="margin-bottom:10px;">
        <p>质保卡号区间：</p>
        <Input v-model="WarrantyExchange_modal.warrantyRange" placeholder="填写领取的质保卡号区间"></Input>
      </div>
    </Modal> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",

      sel_values: [
        { value: "WorkerName", label: "试压员姓名" },
        { value: "MaterialName", label: "物料名称" },
      ],
      WarrantyExchange_modal: {
        currentid: 0,
        show: false,
        warrantyRange: ""
      },
      table: {
        tablecolumsTitle: [
          {
            title: "审核状态",
            key: "Status",
            width: 130,
            align: "center",
            sortable: "custom",
            render: (h, params) => {
              const row = params.row;
              const color = (row.Status == 1 && "success") || "error";
              const text = (row.Status == 1 && "已审核") || "待审核";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "物料名称",
            key: "MaterialName",
            align: "center",
            tooltip: true,
          },
          {
            title: "申请数量",
            key: "ApplyNum",
            align: "center",
            tooltip: true,
          },
          {
            title: "质保卡号区间",
            key: "WarrantyRange",
            width: 150,
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "申请人",
            key: "WorkerName",
            align: "center",
            tooltip: true,
            sortable: "custom",
          },
          {
            title: "收费",
            key: "RealPrice",
            align: "center",
            tooltip: true,
          },
          {
            title: "申请时间",
            key: "_CreateTime",
            align: "center",
            tooltip: true,
          },
          {
            title: "是否已领取",
            key: "IsDraw",
            align: "center",
            tooltip: true,
            render: (h, params) => {
              const row = params.row;
              const color =
                (row.IsDraw ==1 && "success") || "error";
              const text = (row.IsDraw==1&& "是") || "否";
              return h(
                "Tag",
                {
                  props: {
                    type: "dot",
                    color: color
                  }
                },
                text
              );
            }
          },
          {
            title: "操作",
            key: "",
            width: 120,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              if(params.row.IsDraw==0&&params.row.Status==1 && params.row.MaterialID!=3)
              {
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "10px" },
                      on: {
                        click: () => {
                          this.EditDraw(params.row);
                        }
                      }
                    },
                    "领取"
                  )
                ]);
              }
              // if(params.row.IsDraw==0 && params.row.Status==1
              // && params.row.MaterialID!=4 && params.row.MaterialID!=23 && params.row.MaterialID!=24 && params.row.MaterialID!=3)
              // {
              //   return h("div", [
              //     h(
              //       "Button",
              //       {
              //         props: { type: "info", size: "small" },
              //         style: { marginRight: "10px" },
              //         on: {
              //           click: () => {
              //             this.EditDraw(params.row);
              //           }
              //         }
              //       },
              //       "领取"
              //     )
              //   ]);
              // }
              // else if(params.row.IsDraw==0 && params.row.Status==1
              // && (params.row.MaterialID==4 || params.row.MaterialID==23 || params.row.MaterialID==24))
              // {
              //     return h("div", [
              //     h(
              //       "Button",
              //       {
              //         props: { type: "info", size: "small" },
              //         style: { marginRight: "10px" },
              //         on: {
              //           click: () => {
              //             this.WarrantyExchange(params.row);
              //           }
              //         }
              //       },
              //       "领取"
              //     )
              //   ]);
              // }
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort:"ID DESC "
      },
    };
  },
  methods: {
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      this.table.pageIndex = 1;
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_materialloglist",
        query: { pageindex: e }
      });
    },
    GetTableList: function () {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("WorkerMaterial/GetMaterialLogPageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort },
        })
        .then((res) => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    GoBack: function (e) {
      this.$http.SetConditions();
      this.$router.go(-1);
    },
    EditDraw: function(e) {
      var that = this;
      var id = e.ID;
      this.$http
        .get("WorkerMaterial/UpdateMaterial", {
          params: {
            id: id
          }
        }).then(res => {
          if(res.data=="success"){
            this.$Modal.info({
              title: "提示",
              content: "领取成功"
            });
          }else{
            this.$Modal.info({
              title: "提示",
              content: res.data
            });
          }
          that.GetTableList();
        });
    },
    //质保卡号弹窗
    // WarrantyExchange: function(row) {
    //   this.WarrantyExchange_modal.show = true;
    //   this.WarrantyExchange_modal.currentid = row.ID;
    // },
    // ExChange_ModalOK: function() {
    //   var that = this;
    //   if (that.WarrantyExchange_modal.warrantyRange.length <= 0) {
    //     this.$Message.warning("必须填写质保卡区间");
    //     return;
    //   }
    //   that.$http.get('WorkerMaterial/Update2Material',{
    //     params:{
    //       warrantyNum:that.WarrantyExchange_modal.warrantyRange,
    //       id:that.WarrantyExchange_modal.currentid,
    //     }
    //   }).then(res=>{
    //     if(res.data!='success'){
    //       this.$Message.warning(res.data);
    //     }else{
    //       this.$Message.info('领取成功!');
    //       that.GetTableList();
    //       that.WarrantyExchange_modal.warrantyRange="";
    //       that.WarrantyExchange_modal.show=false;
    //     }
    //   });
    // },
    // ModalCancel: function(e) {
    //   console.log("取消");
    //   this.$http.SetConditions();
    // },
  },
  created: function () {
    this.GetTableList();
  },
};
</script>
<style>
</style>
 