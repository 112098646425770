<template>
  <div class="listform">
    <div class="form-title">商品类别列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="category">
      <div class="tree">

        <Tree :data="baseData" @on-select-change="selTree" show-checkbox></Tree>
      </div>
      <div class="tree-opt">
        <Form :model="postdata" :label-width="200">
          <FormItem label="商品名称">
            <Input v-model="postdata.CategoryName" style="width:500px" placeholder="输入商品名称"></Input>
          </FormItem>
          <FormItem label="略缩图片上传(建议100*80)">
            <div class="img-list">
              <div v-for="(item, index) in postdata.imgArray" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item.url+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
                  <Icon type="ios-trash-outline" size="30" @click.native="handleRemove(item)"></Icon>
                </div>
              </div>
            </div>
            <Upload type="drag" :format="['jpg']" :max-size="512" :with-credentials="true" :show-upload-list="false"
              :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
              style="display: inline-block;min-width:600px;" :action="uploadUrl">
              <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
                <p>将图片拖拽到此处上传</p>
              </div>
            </Upload>
          </FormItem>

          <FormItem label="类别大图(建议375*200)">
            <div class="img-list">
              <div v-for="(item, index) in postdata.imgdetArray" :key="index" class="img-cell"
                v-bind:style="{'background-image':'url(' + item.url+ ')','background-size': 'cover','background-position': 'center center','background-repeat':'no-repeat','background-color': 'black'}">
                <div class="demo-upload-list-cover">
                  <Icon type="ios-eye-outline" size="30" @click.native="viewimg(item)"></Icon>
                  <Icon type="ios-trash-outline" size="30" @click.native="handledetRemove(item)"></Icon>
                </div>
              </div>
            </div>
            <Upload type="drag" :format="['jpg']" :max-size="512" :with-credentials="true" :show-upload-list="false"
              :on-success="UploadSuccess" :on-error="UploadError" :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload"
              style="display: inline-block;min-width:600px;" :action="uploaddetUrl">
              <div style="padding: 20px 0">
                <Icon type="ios-cloud-upload" size="30" style="color: #3399ff"></Icon>
                <p>将商品详情图片拖拽到此处上传</p>
              </div>
            </Upload>
          </FormItem>
          <!-- <FormItem>
            <Button @click="SubmitData" type="primary">保存</Button>
          </FormItem> -->
        </Form>
            <Modal v-model="imgviewmodal" width="880px">
      <p slot="header" style="text-align:center;">
        <span>图片预览</span>
      </p>
      <div style="text-align:center;display: flex;align-items: center;">
        <div @click="preImg()">
          <Icon type="md-arrow-dropleft" size="40" />
        </div>
        <img style="max-width: 760px;max-height: 600px;" :src="currentImgurl.url" alt="">
        <div @click="nextImg()">
          <Icon type="md-arrow-dropright" size="40" />
        </div>
      </div>
    </Modal>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      baseData: [],
      imgviewmodal: false,
      currentId: 0,
      currentImgurl: "",
      postdata: {
        ID: 0,
        CategoryName: "",
        Level: 0,
        ParentId: 0,
        GoodsImg: "",
        CategoryImg: "",
      },
      imgList: [],
      imgList2: [],
    };
  },
  methods: {
    GetData() {
      var that = this;
      this.$http.get("Order/GetCategoryTreeList").then((res) => {
        that.baseData = res.data.Data;
      });
    },
    GetCategoryById() {
      var id = this.currentId;
      var  that =this;
      this.$http.get("Order/GetCategoryById?id=" + id).then((res) => {
        var imgNameArray = res.data.Data.CategoryImg.split(",");
        var imgArray = [];
        imgNameArray.forEach((item) => {
          if(item!=""){
            imgArray.push({
              name: item,
              url: that.$file.url + "Images/Mall/Category/" + item,
            });
          }
        });
        var imgdetNameArray = res.data.Data.GoodsImg.split(",");
        var imgdetArray = [];
        imgdetNameArray.forEach((item) => {
          if(item!=""){

            imgdetArray.push({
              name: item,
              url: that.$file.url + "Images/Mall/Category/" + item,
            });
          }
        });
        res.data.Data.imgArray = imgArray;
        res.data.Data.imgdetArray = imgdetArray;
        that.postdata = res.data.Data;

        that.uploadUrl =that.$file.url + "api/Order/AddCategoryImg?id=" + res.data.Data.ID;
        that.uploaddetUrl =that.$file.url +"api/Order/AddCategoryGoodsImg?id=" +res.data.Data.ID;
      });
    },
    selTree(item) {
      this.currentId = item[0].ID;
      this.GetCategoryById();
    },

    viewimg(item, type) {
      this.imgviewmodal = true;
      this.currentImgurl = item;
    },
    preImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index == 0) {
        nextImgUrl = this.postdata.imgArray[this.postdata.imgArray.length - 1];
      } else {
        nextImgUrl = this.postdata.imgArray[index - 1];
      }
      this.currentImgurl = nextImgUrl;
    },
    nextImg() {
      var index = this.postdata.imgArray.indexOf(this.currentImgurl);
      var nextImgUrl = "";
      if (index < this.postdata.imgArray.length - 1) {
        nextImgUrl = this.postdata.imgArray[index + 1];
      } else {
        nextImgUrl = this.postdata.imgArray[0];
      }
      this.currentImgurl = nextImgUrl;
    },
    handleRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteCategoryImg", {
          params: { id: that.postdata.ID, filename: item.name },
        })
        .then((res) => {
          this.$Message.info("操作成功!");
          that.GetCategoryById();
        });
    },
    handledetRemove(item) {
      var that = this;
      that.$http
        .get("Order/DeleteCategoryGoodsImg", {
          params: { id: that.postdata.ID, filename: item.name },
        })
        .then((res) => {
          this.$Message.info("操作成功!");
          that.GetCategoryById();
        });
    },

    UploadSuccess: function (response, file, fileList) {
      this.$Message.info("上传成功!");
      this.GetCategoryById();
    },
    UploadError: function (error, file, fileList) {
      this.$Notice.error({
        title: "系统异常",
      });
    },
    handleBeforeUpload() {},
    handleFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: "文件 " + file.name + " 格式错误,请选择jpg格式",
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: "文件超出大小",
        desc: "文件: " + file.name + " 不能超过512Kb",
      });
    },
  },
  created: function () {
    this.GetData();
  },
};
</script>
<style>
.category {
  display: flex;
  flex-direction: row;
  justify-items: center;
  /* justify-content:space-between; */
}
.tree {
  width: 30%;
}
.tree-opt {
  width: 70%;
}
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
 