<template>
  <div class="listform">
    <div class="form-title">业务员水工管理
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex" show-elevator></Page>
    </div>
    <Modal title="选择业务员" width="1000" height="600" v-model="employee_modal.show" @on-ok="ModalOK" @on-cancel="ModalCancel">
      <employee-list :aftsalerow="employee_modal.aftsalerow" v-if="employee_modal.show" v-on:rowsel="ModalRowSel"></employee-list>
    </Modal> 
    
  </div>
</template>
<script>
import employee_modal from "../../components/public_component/employee_modal";

export default {
  data() {
    return {
      employee_modal: {
        show: false,
        emp_sel: "",
        aftsalerow: ""
      },
      queryvalue: "",
      sel_field: "",
      sel_values: [
        { value: "Name", label: "姓名" },
        { value: "TelNum", label: "电话" }
      ],
      table: {
        tablecolumsTitle: [
          {
            title: "姓名",
            key: "Name",
            align: "center",
            tooltip: true
          },
          {
            title: "电话号码",
            key: "TelNum",
            align: "center",
            tooltip: true
          },
          {
            title: "代理运营商",
            key: "AgentDealeDesc",
            align: "center",
            tooltip: true
          },
          {
            title: "用户名",
            key: "UserName",
            align: "center",
            tooltip: true
          },
          {
            title: "密码",
            key: "PassWord",
            align: "center",
            tooltip: true
          },
          {
            title: "操作",
            key: "bus",
            width: 350,
            align: "center",
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "Button",
                  {
                    props: { type: "primary", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.SDWorkerList(params.row);
                      }
                    }
                  },
                  "水工管理"
                ),
                h(
                  "Button",
                  {
                    props: { type: "success", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.importSDWorker(params.row);
                      }
                    }
                  },
                  "批量导入"
                ),
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    style: { marginRight: "3px" },
                    on: {
                      click: () => {
                        this.ShowDisposeModal(params.row);
                      }
                    }
                  },
                  "水工转移"
                )
              ]);
            }
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: " "
      }
    };
  },
  components: {
    "employee-list": employee_modal
  },
  methods: {
    importSDWorker: function(e) {
      this.$router.push({ 
        name: "dealer_importsdworker" ,
        params: { employeeid: e.ID }
      });
    },
    //水工管理
    SDWorkerList: function(e) {
      this.$router.push({
        name: "dealer_employeesdworkerlist",
        params: { employeeid: e.ID }
      });
    },
    /**选择业务员弹窗相关 */
    ShowDisposeModal(row) {
      this.employee_modal.aftsalerow = row;
      this.employee_modal.show = true;
    },
    //行选中事件
    ModalRowSel(e) {
      this.employee_modal.emp_sel = e;
    },
    ModalOK(e) {
      this.$http.SetConditions();
      this.$http
        .get("Employee/TransferSDWorker", {
          params: {
            id: this.employee_modal.aftsalerow.ID,
            newid: this.employee_modal.emp_sel.ID
          }
        })
        .then(res => {
          if (res.data == "success") {
              this.$Message.info("转移成功");
            } else {
              this.$Message.warning(res.data);
            }
            this.employee_modal.show = false;
          this.GetTableList();
        });
    },
    ModalCancel(e) {
      this.$Modal.remove();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];
      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 0,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }
      
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_businesslist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Employee/GetBusinessPageList", {
          params: {
            pageIndex: that.table.pageIndex,
            sort: that.table.sort
          }
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 