<template>
  <div class="listform">
    <div class="form-title">公司积分统计
      <Icon type="briefcase"></Icon>
    </div>

    <div class="datatable">
      <Table :border="false" border size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading" :data="table.datalist" show-summary></Table>
    </div>
  </div>
</template>
<script>
import workerRolSel from "../../components/public_component/workerRowSel";
export default {
  data() {
    return {
      dateSearchValue: "",
      sel_field: "",
      table: {
        tablecolumsTitle: [
          {
            title: "经销商姓名",
            key: "Name",
            align: "center",
            tooltip: true
          },
          {
            title: "目前总积分",
            key: "Integral",
            align: "center",
            tooltip: true
          },
          {
            title: "累积获得积分(截止上月)",
            key: "PeriodTotalIntegral",
            align: "center",
            tooltip: true
          },
          {
            title: "累积消分(截止上月)",
            key: "MinusIntegral",
            align: "center",
            tooltip: true           
          },
          {
            title: "本月应消分",
            key: "NotIntegral",
            align: "center",
            tooltip: true
          },
          {
            title: "本月已消分",
            key: "AlreadyIntegral",
            align: "center",
            tooltip: true
          },
        ],
        datalist: [],
      }
    };
  },
  components: {
    "worker-RowSel": workerRolSel
  },
  methods: {
    //行选中事件
    WorkerRowSel: function(e) {
      if (this.IsEditWorker) {
        this.EditWorker(undefined, e);
        return;
      }
      this.PreSelWorker(undefined, e);
    },
    ModalCancel: function(e) {
      console.log("取消");
      this.$http.SetConditions();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("Dealer/GetDealer2Integral", {
          params: {}
        })
        .then(res => {
          that.table.loading = false;
          that.table.datalist = res.data.Data;
        });
    },
  },
  created: function() {
    this.GetTableList();
  }
};
</script>
<style>
.radio-group {
  margin: 10px;
  padding-left: 10px;
}
.datatable {
  margin-top: 5px;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked {
  background: #2d8cf0;
  color: #f8f8f8;
}
</style>
 