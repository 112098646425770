<template>
  <div class="my-form">
    <div class="form-title">新建预约单（管道清洗）
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="姓名">
        <Input v-model="postdata.CustName" style="width:500px" placeholder="输入客户姓名"></Input>
      </FormItem>
      <FormItem label="电话 ">
        <Input v-model="postdata.CustTel" style="width:500px" placeholder="输入客户电话"></Input>
      </FormItem>
      <FormItem label="短信电话 ">
        <Input v-model="postdata.MsgTel" style="width:500px" placeholder="用于发送清洗服务进度的短信接收号码"></Input>
      </FormItem>
      <FormItem label="身份">
        <RadioGroup v-model="postdata.CurrentIdentity">
          <Radio v-for="item in postdata.Identity" :label="item.ID">{{item.Name}}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="行政区">
        <Cascader @on-change="AddressChange" :data="AddressData" v-model="postdata.selRegionCode" :load-data="loadregions" style="width:500px"></Cascader>
      </FormItem>
      <FormItem label="安装地址">
        <Input v-model="postdata.CustAddr" style="width:500px" placeholder="具体门牌地址"></Input>
      </FormItem>
      <FormItem label="户型">
        <div style="width:30px;float:left;">
          <Input v-model="postdata.KitchenNum" style="width:30px"></Input>
        </div>
        <div style="width:30px;float:left;margin-left:5px;">厨</div>
        <div style="width:30px;float:left;">
          <Input v-model="postdata.WashNum" style="width:30px"></Input>
        </div>
        <div style="width:30px;float:left;margin-left:5px;">卫</div>
      </FormItem>
      <FormItem label="安装年限">
        <Input v-model="postdata.InstallYear" style="width:500px" placeholder="输入年限"></Input>
      </FormItem>
      <FormItem label="预约清洗日期">
        <DatePicker v-model="postdata.WashDate" type="date" placeholder="Select date" style="width: 200px"></DatePicker>
      </FormItem>
      <!-- <FormItem label="报单号码">
        <Input v-model="postdata.CallTel" style="width:500px" placeholder="输入报单号码"></Input>
      </FormItem> -->
      <FormItem label="备注">
        <Input v-model="postdata.Remark" type="textarea" style="width:700px" :autosize="{minRows: 2,maxRows: 10}" placeholder="Enter something..."></Input>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AddressData: [],
      postdata: {
        CustName: "",
        CustTel: "",
        CustAddr:"",
        MsgTel:"",
        KitchenNum: "",
        WashNum: "",
        WashDate:"",
        HouseType:"",
        InstallYear:"",
        // CallTel: "",
        Remark: "",
        Identity: [],
        CurrentIdentity: "",
        region: "省市区",
        selRegionCode: []
      },
    };
  },
  methods: {
    AddressChange: function(value, selectedData) {
      // this.postdata.selRegionCode = selectedData;
      this.postdata.region =
        selectedData[0].label + selectedData[1].label + selectedData[2].label;
    },

    SubmitData: function() {
      var that = this;
      var url = "WashPrePress/AddWashPrePress";

      var IdentityDesc = "";
      for (let i = 0; i < this.postdata.Identity.length; i++) {
        if (
          this.postdata.Identity[i].ID ==
          this.postdata.CurrentIdentity
        ) {
          IdentityDesc = this.postdata.Identity[i].Name;
        }
      }
      var data = {
        CustName: this.postdata.CustName,
        CustTel: this.postdata.CustTel,
        CustAddr:this.postdata.CustAddr,
        MsgTel:this.postdata.MsgTel,
        Identity: this.postdata.CurrentIdentity,
        HouseType:this.postdata.KitchenNum+'厨'+this.postdata.WashNum+'卫',
        // WashNum: this.postdata.WashNum,
        // KitchenNum: this.postdata.KitchenNum,
        WashDate:this.postdata.WashDate,
        InstallYear:this.postdata.InstallYear,
        // CallTel: this.postdata.CallTel,
        Remark: this.postdata.Remark,
        RegionLevel1: this.postdata.selRegionCode[0],
        RegionLevel2: this.postdata.selRegionCode[1],
        RegionLevel3: this.postdata.selRegionCode[2],
        RegionFullDesc: this.postdata.region,
        DealerId:6,
        _CreateTime: new Date()
      };
      if(!this.CheckData(data)){
        return ;
      }
      
      this.$http.post(url, data).then(res => {
        var modaltitle = "";
        if (res.data == "success") {
          modaltitle = "提交成功！";
          that.$router.push({ name: "dealer_washlist" });
        }else{
          modaltitle = res.data;
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.CustName == "") {
        content = "请填写姓名";
        checkflag = false;
      }
      if (obj.CustTel == "") {
        content = "请填写电话";
        checkflag = false;
      }
      if (obj.MsgTel == "") {
        content = "请填写短信电话";
        checkflag = false;
      }
      if (obj.IdentityDesc == "") {
        content = "请选择身份信息";
        checkflag = false;
      }
      if (this.postdata.selRegionCode.length <= 0) {
        content = "请选择行政区";
        checkflag = false;
      }
      if (obj.CustAddr =="") {
        content = "请填写安装地址";
        checkflag = false;
      }
      if (this.postdata.WashDate =="") {
        content = "请选择预约清洗日期";
        checkflag = false;
      }
      if (obj.HouseType =="") {
        content = "请填写户型";
        checkflag = false;
      }
      if (obj.InstallYear =="") {
        content = "请填写安装年限";
        checkflag = false;
      }

      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    loadregions: function(item, callback) {
      item.loading = true;
      this.GetRegions(item.level + 1, item.value).then(res => {
        item.children = PushAddress(res.data);
        item.loading = false;
        callback();
      });
    },
    GetRegions: function(level, regionID) {
      let that = this;
      return that.$http.get("Region/GetRegionByLevel", {
        params: {
          level: level,
          regionID: regionID
        }
      });
    },
    GetCheckbox: function() {
      let that = this;
      that.$http.get("CheckBox/GetCheckBoxs?type=5").then(res => {
        that.postdata.Identity = res.data;
      });
    }
  },
  watch: {},
  created: function() {
    this.GetCheckbox();
    this.GetRegions(1, "").then(res => {
      this.AddressData = PushAddress(res.data);
    });
  }
};

//地址数组填充
function PushAddress(list) {
  var regionList = [];
  list.forEach(item => {
    if (item.Level != 3) {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID,
        children: [],
        loading: false
      });
    } else {
      regionList.push({
        value: item.ID,
        label: item.RegionText,
        level: item.Level,
        parentID: item.ParentID
      });
    }
  });
  return regionList;
}
</script>

<style>
</style>
