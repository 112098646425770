<template>
  <div class="listform">
    <div class="form-title">销售档案列表
      <Icon type="briefcase"></Icon>
    </div>
    <div class="search_option">
      <Select v-model="sel_field" style="width:200px">
        <Option v-for="item in sel_values" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>
      <Input v-model="queryvalue" placeholder="输入要查询的内容" style="width: 200px"></Input>
      <DatePicker type="daterange" @on-change="DateTimeSelect" :options="dateSearchOption" placement="bottom-end"
        placeholder="根据上传时间筛选" style="width: 200px"></DatePicker>
      <Select filterable style="width:200px" clearable @on-change="AgentSearch">
        <Option v-for="item in select.Agent" :value="item.ID" :key="item.ID">{{ item.Name }}</Option>
      </Select>
      <Button type="primary" @click="Search" icon="ios-search">搜索</Button>
      <Button type="primary" @click="Export" icon="ios-clipboard" v-show="IsExort">导出</Button>
    </div>
    <div class="datatable">
      <Table :border="false"  size="small" @on-sort-change="Sort" :columns="table.tablecolumsTitle" :loading="table.loading"
        :data="table.datalist"></Table>
    </div>
    <div class="footerpage">
      <Page :total="table.total" :page-size="table.pageSize" @on-change="PageChange" :current="table.pageIndex"
        show-elevator></Page>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryvalue: "",
      sel_field: "",
      select: {
        Agent: []
      },
      IsExort:false,

      sel_values: [
        { value: "AutoPreSaleCode", label: "编号(预约号)" },
        { value: "InstallAddress", label: "地址" },
        { value: "DealerName", label: "经销商" },
        { value: "BuyerName", label: "购买人姓名" },
        { value: "BuyerTel", label: "购买人电话" },
        { value: "OwnerName", label: "业主姓名" },
        { value: "OwnerTel", label: "业主电话" },
        { value: "PipeTypeDesc", label: "管道类型" }
      ],
      dateSearchValue: [],
      dateSearchOption: {
        shortcuts: [
          {
            text: "一周内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: "一月内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: "一年内",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              return [start, end];
            }
          }
        ]
      },
      table: {
        tablecolumsTitle: [
          {
            title: "上传时间",
            key: "_CreateTime",
            width: 120,
            align: "center",
            tooltip: true,
            sortable: "custom",
            fixed: "left"
          },
          {
            title: "操作",
            key: "",
            width: 160,
            align: "center",
            tooltip: true,
            fixed: "right",
            render: (h, params) => {
              if(params.row.PipeType==126){
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "3px" },
                      on: {
                        click: () => {
                          this.AddPrePress(params.row);
                        }
                      }
                    },
                    "预约试压"
                  ),
                  h(
                    "Button",
                    {
                      props: { type: "primary", size: "small" },
                      style: { marginRight: "3px" },
                      on: {
                        click: () => {
                          this.CheckDetail(params.row);
                        }
                      }
                    },
                    "查看"
                  )
                ]);
              }else{
                return h("div", [
                  h(
                    "Button",
                    {
                      props: { type: "info", size: "small" },
                      style: { marginRight: "3px" },
                      on: {
                        click: () => {
                          this.AddPrePress(params.row);
                        }
                      }
                    },
                    "预约试压"
                  )
                ]);
              }
            }
          },
          {
            title: "编号",
            key: "AutoPreSaleCode",
            width: 110,
            align: "center",
            tooltip: true,
            fixed: "left"
          },
          {
            title: "安装地址",
            key: "InstallAddress",
            width: 200,
            align: "center",
            tooltip: true
          },
          {
            title: "行政区",
            key: "RegionFullDesc",
            width: 145,
            align: "center",
            sortable: "custom",
            tooltip: true
          },
          {
            title: "管道类型",
            key: "PipeTypeDesc",
            width: 120,
            align: "center",
            sortable: "custom",
            tooltip: true
          },
          {
            title: "购买人姓名",
            key: "BuyerName",
            width: 80,
            align: "center",
            tooltip: true
          },
          {
            title: "购买人电话",
            key: "BuyerTel",
            width: 110,
            align: "center",
            tooltip: true
          },
          {
            title: "购买人身份",
            key: "BuyerIdentityDesc",
            width: 85,
            align: "center",
            tooltip: true
          },
          {
            title: "业主姓名",
            key: "OwnerName",
            width: 80,
            align: "center",
            tooltip: true
          },
          {
            title: "业主电话",
            key: "OwnerTel",
            width: 110,
            align: "center",
            tooltip: true
          },
          // {
          //   title: "卫生间数量",
          //   key: "WashNum",
          //   width: 80,
          //   align: "center",
          //   tooltip: true
          // },
          // {
          //   title: "厨房数量",
          //   key: "KitchenNum",
          //   width: 80,
          //   align: "center",
          //   tooltip: true
          // },
          {
            title: "经销商",
            key: "DealerName",
            width: 105,
            align: "center",
            sortable: "custom",
            tooltip: true
          },
          {
            title: "是否家装单",
            key: "CompanyId",
            width: 75,
            align: "center",
            tooltip: true
          },
          {
            title: "装饰公司",
            key: "CompanyName",
            width: 105,
            align: "center",
            sortable: "custom",
            tooltip: true
          },
          {
            title: "是否已试压",
            key: "IsChecked",
            width: 75,
            align: "center",
            tooltip: true
          },
          {
            title: "获得积分",
            key: "Integral",
            width: 75,
            align: "center",
            tooltip: true,
            sortable: "custom"
          },
          {
            title: "连接方式",
            key: "ConnectModeDesc",
            width: 80,
            align: "center",
            tooltip: true
          }
        ],
        datalist: [],
        pageIndex: 1,
        total: 0,
        pageSize: 1,
        loading: true,
        sort: "_CreateTime DESC "
      }
    };
  },
  methods: {
    PageChange: function(e) {
      this.table.pageIndex = e;
      this.GetTableList();
      this.$router.push({
        name: "dealer_presalelist",
        query: { pageindex: e }
      });
    },
    GetTableList: function() {
      var that = this;
      that.table.loading = true;
      this.$http
        .get("PreSale/GetPreSalePageList", {
          params: { pageIndex: that.table.pageIndex, sort: that.table.sort }
        })
        .then(res => {
          var list = res.data.ListData.map(item => {
            item.IsChecked = (item.IsChecked == null && "否") || "是";
            item.CompanyId = (item.CompanyId == 0 && "否") || "是";
          });
          that.table.loading = false;
          that.table.datalist = res.data.ListData;
          that.table.total = res.data.TotalCount;
          that.table.pageSize = res.data.PageSize;
        });
    },
    Sort: function(sort) {
      if (sort.key != undefined && sort.order != undefined) {
        if (sort.order != "normal") {
          this.table.sort = sort.key + " " + sort.order;
        } else {
          this.table.sort = "";
        }
      }
      this.GetTableList();
    },
    Search: function(e) {
      this.loading = true;
      var conditions = [];

      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 5,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
    DateTimeSelect: function(e) {
      this.dateSearchValue = e;
    },
    AddPrePress:function (row) {
      var id=row.ID;
      this.$http.get("PrePress/AddPrePressByPreSale",{
        params:{id:id}
      }).then(res=>{
        if(res.data=="success"){
          this.$Message.info('操作成功');
        }else{
          this.$Message.error(res.data);
        }
      })
    },
    Export: function() {
      var that = this;
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "ios-loading",
                size: 18
              }
            }),
            h("div", "生成中..")
          ]);
        }
      });
      that.$http
        .get("PreSale/ExportPresale", {
          params: {
          }
        })
        .then(res => {
          that.$Spin.hide();
          window.open(that.$config.fileUrl + res.data);
        });
    },
    CheckDetail: function(item) {
      this.$router.push({
        name: "dealer_presaledetail",
        query: { id: item.ID }
      });
    },
    GetAgent: function() {//查询代理运营商
      let that = this;
      that.$http.get("Employee/GetAgentDealer").then(res => {
        that.select.Agent = res.data;
      });
    },
    GetCurEmployee: function() {
      let that = this;
      that.$http.get("Employee/GetCurEmployee").then(res => {
        if(res.data.IsSystem==1){
          that.IsExort=true;
        }
      });
    },
    AgentSearch: function(e) {
      this.loading = true;
      var conditions = [];

      if (this.sel_field != "" && this.queryvalue.trim() != "") {
        conditions.push({
          PropertyName: this.sel_field,
          Operater: 5,
          LogicOperater: 0,
          Value: this.queryvalue.trim()
        });
      }

      if (this.dateSearchValue != "") {
        if (this.dateSearchValue[0] != "" && this.dateSearchValue[1] != "") {
          if (this.dateSearchValue[0] == this.dateSearchValue[1]) {
            conditions.push({
              PropertyName: "_CreateTime",
              Operater: 2,
              LogicOperater: 0,
              Value: this.dateSearchValue[0]
            });
          } else {
            conditions.push(
              {
                PropertyName: "_CreateTime",
                Operater: 3,
                LogicOperater: 0,
                Value: this.dateSearchValue[0]
              },
              {
                PropertyName: "_CreateTime",
                Operater: 4,
                LogicOperater: 0,
                Value: this.dateSearchValue[1]
              }
            );
          }
        }
      }
      
      if (e != "") {
        conditions.push({
          PropertyName: "AgentId",
          Operater: 2,
          LogicOperater: 0,
          Value: e
        });
      }
      this.$http.SetConditions(conditions);
      this.GetTableList();
    },
  },
  created: function() {
    this.GetTableList();
    this.GetAgent();
    this.GetCurEmployee();
  }
};
</script>
<style>
</style>
 