<template>
    <div class="mo-content">

        <router-view></router-view>

        <!-- <van-tabbar v-model="active"> -->
            <!-- <van-tabbar-item @click="goto" icon="records">任务列表</van-tabbar-item> -->
            <!-- <van-tabbar-item icon="chat">个人中心</van-tabbar-item> -->
        <!-- </van-tabbar> -->
    </div>
</template>

<script>
export default {
  data: () => {
    return {
      active: 0
    };
  },
  methods:{
      goto:function () {
          this.$router.push({path:'/index_mobile'})
      }
  }
};
</script>

<style>
.mo-content{
    height: 100%;
    margin-bottom: 20vh;
}
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
</style>