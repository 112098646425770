<template>
  <div>
    <div class="userinfo">
      <div class="user-img"></div>
      <van-image round width="4rem" height="4rem" fit="cover" :src="avatar" />

      <div v-if="isLogined" class="user-name">{{user.Tel}}</div>
      <div class="login-class" v-else>
        <van-button round @click="ShowLogin" type="info">点击登录</van-button>
      </div>
    </div>

    <div class="points-box">
      <van-grid :columnNum="2" size="16px">
        <van-grid-item>
          <div class="balance-text" slot="text">
            <div class="balance-name">现金积分</div>
            <div class="balance-value">{{user.Integral}}</div>
          </div>
        </van-grid-item>
        <van-grid-item>
          <div class="balance-text" slot="text">
            <div class="balance-name">物资积分</div>
            <div class="balance-value">{{user.MaterialIntegral}}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="aui-take-item">
      <div class="aui-flex">
        <div class="aui-flex-box">
          <h1>常用功能</h1>
        </div>
        <div class="aui-arrow" @click="quit()">
          <span>退出登录</span>
        </div>

      </div>
      <div class="aui-palace">
        <a href="javascript:;" class="aui-palace-grid" @click="onClickLog(user.ID)">
          <div class="aui-palace-grid-icon">
            <img src="../assets/积分记录.jpg" alt="">
          </div>
          <div class="aui-palace-grid-text">
            <h2>积分记录</h2>
          </div>
        </a>
        <a href="javascript:;" class="aui-palace-grid" @click="onLucklyDraw(2)">
          <div class="aui-palace-grid-icon">
            <img src="../assets/zhongjiang.png" alt="">
          </div>
          <div class="aui-palace-grid-text">
            <h2>抽奖活动</h2>
          </div>
        </a>
        <a href="javascript:;" class="aui-palace-grid" @click="onLucklyDrawRecord()">
          <div class="aui-palace-grid-icon">
            <img src="../assets/zhongjiang.png" alt="">
          </div>
          <div class="aui-palace-grid-text">
            <h2>中奖记录</h2>
          </div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { Row, Col, Icon, Cell, CellGroup, Grid, GridItem } from "vant";
import { Button, Field } from "vant";
import { Divider } from "vant";
import { Popup } from "vant";
import { CountDown } from "vant";
export default {
  name: "myCenter",
  data() {
    return {
      showLuckly2: false,
      user: {
        MaterialIntegral: 0,
        Integral: 0,
        LockIntegral: 0,
        Tel: "",
        ID: 0,
      },
      period_matintegral: 0,
      avatar: this.$file.url + "Images/Mall/UserDefaultAvatar/" + "user.jpg",
    };
  },
  props: {},
  computed: {
    isLogined() {
      return this.$store.state.isLogin;
    },
  },
  methods: {
    ShowLogin() {
      // this.$store.commit("showLogin");
      this.$router.push({
        name: "lyUserLogin",
        params: {},
      });
    },
    GetData() {
      var that = this;
      if (this.isLogined) {
        this.$http.get("Order/GetMallSdWorkerInfo").then((res) => {
          if(res.data.Msg=="success"){
              that.user = res.data.Data;
          }
        });
      }
    },
    onClickLog(id) {
      if (!this.isLogined) {
        return this.$toast.fail("您还未登录");
      }
      this.$router.push({
        name: "lyIntegrallogList",
        params: { sdworkerid: id },
      });
    },
    onLucklyDraw(period) {
      if (!this.isLogined) {
        return this.$toast.fail("您还未登录");
      }
      this.$router.push({
        name: "lyLuckDraw",
        params: { period: period },
      });
    },
    onLucklyDrawRecord() {
      if (!this.isLogined) {
        return this.$toast.fail("您还未登录");
      }
      this.$router.push({
        name: "lyLuckDrawRecord",
        params: {},
      });
    },
    quit() {
      if (!this.isLogined) {
        return this.$toast.fail("您还未登录");
      }
      this.$dialog.confirm({
        title: "提示",
        message: "确定退出登录?",
        beforeClose: this.beforeClose,
      });
    },
    beforeClose(action, done) {
      var that = this;
      this.$http
        .get("SDWorker/SDWorkerLoginOut", {
          params: {},
        })
        .then((res) => {
          if (res.data == "success") {
            that.$store.commit("loginOut");
            that.$toast.success("退出成功");
            that.user = { MaterialIntegral: 0, Integral: 0, Tel: "", ID: 0 };
          } else {
            that.$toast(res.data);
          }
          done();
        });
    },
  },
  created() {
    console.log(this.user);
    this.GetData();
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [CountDown.name]: CountDown,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.login-class {
  padding: 20px;
}
.userinfo {
  background-color: #ffffff;
  text-align: center;
  color: #323233;
  padding-top: 25px;
}
.user-text {
  font-size: 14px;
}
.user-name {
  font-size: 20px;
  margin-top: 15px;
  padding-bottom: 20px;
}

.integral-number {
  font-size: 20px;
  background-color: white;
  line-height: 60px;
}
.grid-text {
  font-size: 12px;
  color: #646566;
  padding-top: 10px;
}
.tips {
  font-size: 12px;
  font-style: italic;
  padding: 10px 10px 0 10px;
  margin: 5px;
  color: gray;
  text-align: left;
}

/* 样式 */
html,
body {
  color: #333;
  margin: 0;
  height: 100%;
  font-family: "Myriad Set Pro", "Helvetica Neue", Helvetica, Arial, Verdana,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

a {
  text-decoration: none;
  color: #000;
}

a,
label,
button,
input,
select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  width: 100%;
  height: auto;
  display: block;
  border: 0;
}

body {
  background: #efefef;
  color: #666;
}

html,
body,
div,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
button,
fieldset,
form,
input,
legend,
textarea,
th,
td {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #08acee;
}

button {
  outline: 0;
}

img {
  border: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
}

li {
  list-style: none;
}

a {
  color: #666;
}

.clearfix::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}

.aui-flex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px;
  position: relative;
}

.aui-flex-box {
  -webkit-box-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
  text-align: left;
}

/* 必要布局样式css */
.aui-flexView {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.aui-scrollView {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.aui-palace {
  padding: 0.5rem 0;
  position: relative;
  overflow: hidden;
}

.aui-palace-grid {
  position: relative;
  float: left;
  padding: 1px;
  width: 33%;
  box-sizing: border-box;
  margin: 5px 0;
}

.aui-palace-grid-icon {
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.aui-palace-grid-icon img {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.aui-palace-grid-text {
  display: block;
  text-align: center;
  color: #333;
  font-size: 0.85rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 0.2rem;
}

.aui-palace-grid-text h2 {
  font-size: 0.8rem;
  font-weight: normal;
  color: #666666;
}

.aui-flex-three {
  background: #fff;
  margin: 1rem;
  border-radius: 5px;
}

.aui-take-item {
  background: #fff;
  border-radius: 5px;
  margin: 1rem;
}

.aui-arrow {
  text-align: right;
  color: #394154;
  padding-right: 12px;
  position: relative;
  font-size: 0.85rem;
}

.aui-arrow span {
  color: #999;
  font-size: 0.8rem;
}

.aui-arrow:after {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  border-width: 2px 2px 0 0;
  border-color: #999999;
  border-style: solid;
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  position: relative;
  top: -2px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 2px;
  border-radius: 1px;
}

.aui-flex-box h4 {
  text-align: center;
  font-weight: normal;
  color: #555555;
}

.aui-flex-box h4 em {
  font-style: normal;
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
}

.aui-flex-three {
  padding: 0.8rem;
}

.aui-flex-three .aui-flex-box-info:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid #d9d9d9;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  z-index: 22;
}

.aui-flex-box h1 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.points-box {
  height: 80px;
  margin: 0 10px;
  text-align: center;
}
.van-grid {
  border-radius: 5px;
  margin-top: 5px;
  height: 80px;
  overflow: hidden;
}
.van-grid-item__content {
  padding: 4px;
}
.balance-text {
  margin-top: 0;
  font-size: 10px;
}
.balance-name {
  color: #666;
}
.balance-value {
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
  color: #b00;
}
/* .user-poster {
    width: 100%;
    height: 220px;
    background: #099 center top no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
  }
  .user-info {
    padding-top: 4rem;
  }
  .user-avatar {
    align-items: center;
    margin-right:10px;
  }
  .user-info-text {
    text-align: left;
    color: #fff;
    font-size: 14px;
  }
  .user-nickname {
    font-size: 16px;
  } */
</style>
