<template>
  <div class="my-form">
    <div class="form-title">物料详情
      <Icon type="edit"></Icon>
    </div>
    <Form :model="postdata" :label-width="100">
      <FormItem label="物料名称">
        <Input v-model="postdata.MaterialName" style="width:500px" placeholder="输入物料名称"></Input>
      </FormItem>
      <FormItem label="最高领取数量">
        <Input v-model="postdata.HighNum" :min="0" style="width:500px" placeholder="输入最高领取数量"></Input>
      </FormItem>
      <FormItem label="库存">
        <InputNumber v-model="postdata.TotalStock" :min="0" style="width:500px" placeholder="输入库存"></InputNumber>
      </FormItem>
      <FormItem>
        <Button @click="SubmitData" type="primary">保存</Button>
        <Button @click="BackList" type="default" style="margin-left: 8px">返回</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      postdata: {
        MaterialName: "",
        ID: 0,
        HighNum: 0,
        TotalStock: 0
      }
    };
  },
  methods: {
    SubmitData: function() {
      var that = this;
      var url = "WorkerMaterial/UpdateMaterials";
      var data = this.postdata;
      if (!this.CheckData(data)) {
        return;
      }
      if (this.$route.query.id == undefined) {
        url = "WorkerMaterial/AddMaterials";
      }
      this.$http.post(url, data).then(res => {
        var modaltitle = "";
        if (res.data != "success") {
          modaltitle = res.data;
        } else {
          modaltitle = "提交成功！";
          that.$router.push({ name: "dealer_materiallist" });
        }
        this.$Modal.info({
          title: "提示",
          content: modaltitle
        });
      });
    },
    CheckData: function(obj) {
      var checkflag = true;
      var content = "请填写完整信息";
      if (obj.MaterialName == "") {
        content = "请填写物料名称";
        checkflag = false;
      }
      if (obj.HighNum == 0) {
        content = "请填写最高领取数量";
        checkflag = false;
      }
      if (obj.TotalStock == 0) {
        content = "请填写库存";
        checkflag = false;
      }
      if (!checkflag) {
        this.$Modal.warning({
          title: "提示",
          content: content
        });
        return false;
      }
      return true;
    },
    GetData: function(id) {
      let that = this;
      if (id > 0) {
        that.$http.get("WorkerMaterial/GetMaterial?id=" + id).then(res => {
          that.postdata = res.data.Data;
        });
      }
    },
    BackList: function() {
      this.$router.push({ name: "dealer_materiallist" });
    },
  },
  watch: {},
  created: function() {
    var id=this.$route.query.id;
    this.GetData(id);
  }
};
</script>

<style>
.demo-upload-list-cover {
  display: none;
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.img-cell:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
